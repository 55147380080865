import React from "react";

const TabNavItem = ({  title, isActive, setActiveTab }) => {
  

  return (
    <li onClick={setActiveTab} className={`selectContainer custom-btn bg-transparent text-black medium fs-16 border-0 mb-md-0 mb-2 ${isActive ? "navitem active" : ""}`}>
      {title}
    </li>
  );
};

export default TabNavItem;
