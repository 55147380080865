import React from 'react'
import api from "../../../api";
import { Endpoints } from "../../../api/Endpoints";
import { useQuery } from '@tanstack/react-query';

const useUsers = (page, itemsPerPage) => {

    const GetUser = useQuery({
      queryKey: ['createuser', page, itemsPerPage],
      keepPreviousData:true,
      refetchOnWindowFocus: false,
      queryFn: async() => {
        let resp = await api.get(`account/users/?page=${page || 1}&items_per_page=${itemsPerPage || 1000}`);
        return resp?.data
      }
    })

  return GetUser
}

export default useUsers