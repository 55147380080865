import React from "react";
import DoubleBarChart from "../../ui/graphs/DoubleBarChart";
import useGetProductivityGraph from "../../libs/queries/graphs/useGetProductivityGraph";

const LeadGenerated = ({productivitydata}) => {
  // const { data: productivity } = useGetProductivityGraph();
  const {lead_generated_data} = productivitydata || {};
  // console.log(lead_generated_data)

  const leadgenerateddata = lead_generated_data?.map((item) => item?.lead_generated)
  const leadgeneratedlabel = lead_generated_data?.map((item) => item?.name)
  const leadtarget = lead_generated_data?.map((item) => item?.leads_generated_target)

  return (
    <div className="bg-white py-4 px-4 border " style={{ width: "100%" }}>
      <div className="card-body">
        <h3 className="mb-3">Lead Generated</h3>
        <DoubleBarChart leadtarget={leadtarget}  data={leadgenerateddata} label={leadgeneratedlabel}/>
      </div>
    </div>
  );
};

export default LeadGenerated;
