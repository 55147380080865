import { Outlet } from "react-router-dom";
import Report from "../Pages/Report/Report";

const reportRouter = {
    path: "/reports/",
    element: <Outlet />,
    children: [
        {
            path: "",
            element: <Report />
        },
    ]
}

export default reportRouter;