import React, { useState } from "react";
import whiteEditIcon from "../../img/icon/edit-white.svg";
import hdfc from '../../img/hdfc.png'
import AgreeementListEdit from "./AgreementListEdit";

const AgreementListModal = ({ data }) => {
  const [edit, setEdit] = useState(false);
  const {
    contact_name,
    contact_department_display,
    mobile_contact,
    signatory_authority_name,
    client_execution_owner_name,
    cv_validity_display,
    email,
    pricing,
    replacement_clause_display,
    ats_non_ats,
    remarks,
    client_category_display,
    lead_contact_display,
  } = data || {};
  // console.log("this is model", data);

  return (
    <>
      {!edit && (
        <div className="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3 pt-5 ">
          <table className="action-table w-100">
            <tbody>
              <tr>
                <td className="action-first">
                  <div className="d-flex align-items-center gap-lg-4 gap-2">
                    {/* <img
                      src={hdfc}
                      width="48"
                      height="48"
                      className="rounded-3"
                      alt=""
                    /> */}
                  </div>
                </td>

                <td className="action-mid">
                  {/* <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Contact Name
                    </h6>
                    <h5 className="regular">{contact_name}</h5>
                  </div> */}

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Client Execution Owner 
                    </h6>
                    <h5 className="regular">{lead_contact_display}</h5>
                  </div>

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Replacement Clause
                    </h6>
                    <h5 className="regular">{replacement_clause_display}</h5>
                  </div>

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Category Of Client
                    </h6>
                    <h5 className="regular">{client_category_display}</h5>
                  </div>
                </td>

                <td className="action-mid">
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Contact Department
                    </h6>
                    <h5 className="regular">{contact_department_display}</h5>
                  </div>

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      CV Validity
                    </h6>
                    <h5 className="regular">{cv_validity_display}</h5>
                  </div>

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      ATS / Non ATS
                    </h6>
                    <h5 className="regular">{ats_non_ats}</h5>
                  </div>
                </td>

                <td className="action-mid">
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">Client Execution Owner Mobile</h6>
                    <h5 className="regular">{mobile_contact}</h5>
                  </div>
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Client Execution Owner Email
                    </h6>
                    <h5 className="regular">{email}</h5>
                  </div>
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Remarks
                    </h6>
                    <h5 className="regular">{remarks}</h5>
                  </div>
                </td>

                <td className="action-last">
                  <div className="d-md-flex justify-content-between">
                    <div>
                      <div className="pb-2">
                        <h6 className="fs-12 text-sky regular  pb-md-1">
                          Signatory Authority
                        </h6>
                        <h5 className="regular">{signatory_authority_name}</h5>
                      </div>

                      <div className="pb-2">
                        <h6 className="fs-12 text-sky regular  pb-md-1">
                          Pricing
                        </h6>
                        <h5 className="regular">
                          {"₹ "+pricing}
                        </h5>
                      </div>
                      {/* <div className="pb-2">
                        <h6 className="fs-12 text-sky regular  pb-md-1">
                          Category Of Client
                        </h6>
                        <h5 className="regular">{client_category_display}</h5>
                      </div> */}
                    </div>

                    <div>
                      <a className="action-icon selectContainer" onClick={() => setEdit(true)}>
                        <img src={whiteEditIcon} />
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {edit && <AgreeementListEdit data={data} onHide={() => setEdit(false)} />}
    </>
  );
};

export default AgreementListModal;
