import React from "react";
import HalfDonut from "../../ui/graphs/HalfDonut";
import useGetAgreementGraph from "../../libs/queries/graphs/useGetAgreementGraph";

const ContactLevel = ({agreementdata}) => {
  // const { data } = useGetAgreementGraph();
  const { contact_level_data, contact_level_label, contact_level_color } =
  agreementdata || {};
  // console.log(data);

  return (
    <div className="bg-white py-4 px-4 border w-50">
      <div className="card-body">
        <h3 className="mb-3">Contact Level</h3>
        <div className="d-flex justify-content-center">
        {!contact_level_data?.length ?
        <div className='my-4'>
          No Data
        </div>
        :
          <HalfDonut
            data={contact_level_data}
            datalabel={contact_level_label}
            datacolor={contact_level_color}
          />
        }
        </div>
      </div>
    </div>
  );
};

export default ContactLevel;
