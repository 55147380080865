export const manageMenu = [
    {path: "/manage/locations", label:"Locations"},
    // {path: "#", label:"Trigger"},
    {path: "/manage/stages", label:"Stages"},
    // {path: "#", label:"Tags"},
    {path: "/manage/emailtemplate", label:"Email Template"},
    {path: "/manage/leadgeneration", label:"Lead Generated Form"},
    {path: "/manage/agreementgeneratedform", label:"Agreement Generated Form"},
    {path: "/manage/marketgeneration", label:"Market Intelligence Generated Form"},
    // {path: "#", label:"Document Upload Form"},
    // {path: "#", label:"New Campaign Form"},
    // {path: "#", label:"Form____"},
   
]

