import React from "react";
/// hooks used ///
import { useSelector } from "react-redux";

/// Imports of Component ///
import StageRow from "../../../Components/Manage/NewStage/StageRow";
import FilterRow from "../../../Components/Manage/Leadgeneration/FilterRow";
import LeadGenerationContainer from "../../../Components/Manage/Leadgeneration/LeadGenerationContainer"
import { Endpoints } from "../../../api/Endpoints";

const LeadGeneration = () => {
  
  
  const { filternamechange } = useSelector((state) => state.leadSlice);
  const { filterid } = useSelector((state) => state.leadSlice);

  
  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <FilterRow />
        <StageRow
        heading={filternamechange}
        url={Endpoints.dropdownCsv(filterid)}
        />
        <LeadGenerationContainer />

      </div>
    </div>
  );
};

export default LeadGeneration;
