import React, { useEffect, useState } from "react";
import Target from "../../../img/target.png";
import useUpdateProfile from "../../../libs/mutation/updateprofile/useUpdateProfile";
import { useForm, Controller } from "react-hook-form";
import EditProfile from "../../../img/editprofile.jpg";
import "../../../Styles/Profile.css";
import useProfile from "../../../libs/queries/profile/useProfile";
import { backendURL } from "../../../api";

const AccountSetting = () => {
  const { data } = useProfile();


  const { fullname, image, role, role_display, designation, team, phone, email } = data || {};


  const [files, setFiles] = useState([]);
  const [profilepic, setProfilePic] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate: updateProfile, isLoading } = useUpdateProfile(() => setIsModalOpen(false));
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm();


  const handleChange = (event, onChange) => {
    setProfilePic(event.target.files[0]);
    onChange(event.target.files[0]);
  };

  const imageshow = backendURL+data?.image
  

  useEffect(() => {
    if (data) {
      reset({
        fullname,
        phone,
        email,
        designation,
        role,
        team
      
      });
    }
  }, [data]);


  return (
    <div>
      <a
        style={{ cursor: "pointer" }}
        onClick={() => {
          setIsModalOpen(true);
        }}
        className="collapse-butn medium p-4 d-block selectContainer"
        data-bs-toggle="collapse"
        data-bs-target="#setting"
        aria-expanded={isModalOpen}
      >
        Account Settings
      </a>
      {isModalOpen && (
        <div
          className="collapse open-collapse"
          id="setting"
          style={{ height: "100vh", overflowY: "scroll" }}
        >
          <div className="bg-sky py-3 px-4 d-flex align-items-center gap-3">
            <img src={Target} width="52" height="52" alt="" />
            <h4 className="semibold text-white fs-20">Account Settings</h4>
          </div>
          <form
            onSubmit={handleSubmit(updateProfile)}
            action=""
            className=" px-4 py-3"
          >
            <div style={{ overflowY: "scroll" }}>
              <div className="pb-3">
                <label className="d-block medium fs-14 pb-2">
                  Profile Image
                </label>
                <div>
                  <Controller
                    name="image"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <label
                          htmlFor="photo-upload"
                          className="custom-file-upload fas"
                        >
                          <div className=" img-wrap img-upload">
                            <img
                              for="photo-upload"
                              height={60}
                              width={60}
                              className="rounded-circle"
                              src={
                                profilepic
                                  ? URL.createObjectURL(profilepic)
                                  : data?.image
                                  ? imageshow
                                  : EditProfile

                              }
                            />
                          </div>
                          <input
                            style={{ display: "none" }}
                            id="photo-upload"
                            type="file"
                            onChange={(event) => handleChange(event, onChange)}
                          />
                        </label>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="pb-3">
                <label htmlFor="" className="d-block medium fs-14 pb-2">
                  Full Name
                </label>
                <input
                  id={"fullname"}
                  name="fullname"
                  defaultValue={data?.fullname}
                  type="text"
                  className="form-field"
                  placeholder=""
                  {...register("fullname", {
                    required: true,
                  })}
                />
                <span className="fs-12 text-red">
                  {errors?.fullname?.type == "required" &&
                    "Full Name is Required"}
                </span>
              </div>

              <div className="pb-3">
                <label htmlFor="" className="d-block medium fs-14 pb-2">
                  Mobile Number
                </label>
                <input
                  id="phone"
                  name="phone"
                  defaultValue={data?.phone}
                  type="tel"
                  className="form-field"
                  placeholder=""
                  {...register("phone", {
                    required: true,
                  })}
                />

                <span className="fs-12 text-red">
                  {errors?.phone?.type == "required" && "Phone is Required"}
                </span>
              </div>

              <div className="pb-3">
                <label htmlFor="" className="d-block medium fs-14 pb-2">
                  E-Mail
                </label>
                <input
                  style={{ backgroundColor: "rgba(200,200,200,0.6)" }}
                  id="email"
                  name="email"
                  defaultValue={data?.email}
                  type="email"
                  className="form-field"
                  placeholder=""
                  readOnly="readonly"
                  {...register("email", {
                    // required: true,
                  })}
                />
              </div>

              <div className="pb-3">
                <label htmlFor="" className="d-block medium fs-14 pb-2">
                  Designation
                </label>
                <input
                  style={{ backgroundColor: "rgba(200,200,200,0.6)" }}
                  disabled={true}
                  defaultValue={data?.designation}
                  type="text"
                  className="form-field"
                  placeholder=""
                />
              </div>

              <div className="pb-3">
                <label htmlFor="" className="d-block medium fs-14 pb-2">
                  Role
                </label>
                <input
                  style={{ backgroundColor: "rgba(200,200,200,0.6)" }}
                  disabled={true}
                  defaultValue={role_display}
                  type="text"
                  className="form-field"
                  placeholder=""
                />
              </div>

              <div className="pb-3">
                <label htmlFor="" className="d-block medium fs-14 pb-2">
                  Team
                </label>
                <input
                  style={{ backgroundColor: "rgba(200,200,200,0.6)" }}
                  disabled={true}
                  defaultValue={data?.team}
                  type="text"
                  className="form-field"
                  placeholder=""
                />
              </div>

              <div className="d-flex border-0 butn-group gap-3 pt-2">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="custom-btn border-0 py-2 rounded-4 raleway medium"
                >
                  Save
                </button>
                <button
                  type="button"
                  className="custom-btn close-modal py-2 rounded-4 bg-white border-bl-btn px-4 raleway semibold"
                  data-bs-toggle="collapse"
                  data-bs-target="#setting"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AccountSetting;
