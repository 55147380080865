import React from "react";
import Header from "../Components/dashboard/Header";
import Categories from "../Components/dashboard/Categories";
import { Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const MainLayout = () => {
  useAuth();

  return (
    <div className="wrapper">
      <div className="header page-header">
        <Header />
        <Categories />
      </div>
      <Outlet />
    </div>
  );
};

export default MainLayout;
