import { Endpoints } from "../../../api/Endpoints";
import api from "../../../api/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const useUpdatePrs = () => {
  const queryClient = useQueryClient();
  const updatePrs = useMutation({
    mutationFn: async (data) => {
      const res = await api.post(Endpoints.updatePrs, data);
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(`prsList`);
      if(data?.status){
        toast.success(data.message);
      }
      if(!data?.status){
        toast.error(data.message);
      }
    },
    onError: (error) => {
      toast.error(`${error['response'].data.message}`);
    },
  });
  return updatePrs;
};

export default useUpdatePrs;
