import React from "react";
import DoubleBarChart from "../../ui/graphs/DoubleBarChart";
import BarChart from "../../ui/graphs/BarChart";

const OnBoardingWeek = ({weekData}) => {
  // console.log('weekdata__',JSON.stringify(weekData,null,4))

    
  const { clients_onboarded } = weekData?.data || {};

  
  // const countdata = clients_onboarded?.map((item) => item?.count);
  // const labeldata = clients_onboarded?.map(
  //   // (item) => item?.lead__potential_service_offering__name
  //   (item) => item?.name
  //   );
  //   const target = clients_onboarded?.map((item) => item?.clients_onboarded_target)


    

  return (
    <div>
      <BarChart
      // datalabel={labeldata} 
      // datacolor={['orange']} 
      // data={countdata} 
      dataOnboarded={clients_onboarded}
      />
      {/* <DoubleBarChart data={countdata} label={labeldata} leadtarget={target} /> */}
    </div>
  );
};

export default OnBoardingWeek;
