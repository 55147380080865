import React from "react";

/// Icons Or Images import ///
import InnerProfile from "../../img/inner-profile.png";
import PhoneIcon from "../../img/icon/profile-phone.svg";
import EmailIcon from "../../img/icon/profile-email.svg";
import useProfile from "../../libs/queries/profile/useProfile";
import EditProfile from "../../img/editprofile.jpg";
import { backendURL } from "../../api";

const UserDetailSection = () => {
  const { data } = useProfile();

  const image = data?.image ? backendURL + data?.image : EditProfile;

  return (
    <div className="d-flex align-items-center gap-1 pb-4 px-4">
      <img
        src={image}
        className="rounded-circle"
        width="110"
        height="110"
        alt=""
      />
      <div className="user-info">
        <h4 className="pb-2">{data?.fullname}</h4>
        <h5 className="fs-12 pb-1">{data?.role_display}</h5>
        <h5 className="fs-12 pb-1">Team- {data?.team}</h5>
        <a href="tel:9859684785" className="d-block fs-12 pb-1">
          <img src={PhoneIcon} className="me-2" width="10" height="10" alt="" />{" "}
          {data?.phone}
        </a>
        <a href="mailto:sorabh@posterity.in" className="d-block fs-11">
          <img src={EmailIcon} className="me-2" width="10" height="10" alt="" />{" "}
          {data?.email}
        </a>
      </div>
    </div>
  );
};

export default UserDetailSection;
