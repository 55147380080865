import { Endpoints } from "../../../api/Endpoints";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";


const useGetLeadLogs = (id) => {

    const queryresult = useQuery({
        queryKey: [`lead_logs`, {id}],
        keepPreviousData:true,
        queryFn: async () => {
            const res = await api.get(Endpoints.getLeadLogs + id);
            // console.log(res?.data)
            return res?.data?.results;
        }
    })
  return queryresult
}

export default useGetLeadLogs