import React, { useState } from "react";
import InternalEscTableBody from "./InternalEscTableBody";
import ExternalEscTableBody from "./ExternalEscTableBody";

const EscalationTableContainer = ({
  id,
  levelId,
  data,
  setCheckBoxAdd,
  checkBoxAdd,
  externalEscalation,
  setExternalEscalation,
  setSearch,
  search,
}) => {
  // console.log("this is container",checkBoxAdd)

  return (
    <div className="bg-white rounded-10 overflow-hidden shadow">
      <div className="row border-bottom">
        <div className="col-md-6 border-end">
          <a
            // href="#"
            onClick={() => setExternalEscalation("external")}
            className={`fs-20 medium text-center selectContainer ${
              externalEscalation === "external" && "text-sky"
            } d-block px-2 py-3`}
          >
            External Escalation Matrix
          </a>
        </div>
        <div className="col-md-6">
          <a
            // href="#"
            onClick={() => setExternalEscalation("internal")}
            className={`fs-20 medium text-center selectContainer ${
              externalEscalation === "internal" && "text-sky"
            } d-block px-2 py-3`}
          >
            Internal Escalation Matrix
          </a>
        </div>

        {/* {externalEscalation === "internal" && (
     <>
       <div className="col-md-6 border-end">
         <a
           // href="#"
           onClick={() => setExternalEscalation(false)}
           className="fs-20 medium text-center text-gray d-block px-2 py-3"
         >
           External Escalation Matrix
         </a>
       </div>
       <div className="col-md-6">
         <a
           // href="#"
           onClick={() => setExternalEscalation(true)}
           className="fs-20 medium text-center text-sky d-block px-2 py-3"
         >
           Internal Escalation Matrix
         </a>
       </div>
     </>
   )} */}
      </div>

      <table className="w-100 alert-table crm-table">
        <thead>
          <tr>
            <th width="5%"></th>
            <th width="5%" className="fs-20 medium text-nowrap">
              Sr. no.
            </th>
            <th width="20%" className="fs-20 medium text-nowrap">
              Name
            </th>
            <th width="20%" className="fs-20 medium text-nowrap">
              Designation
            </th>
            <th width="20%" className="fs-20 medium text-nowrap">
              Email
            </th>
            <th width="20%" className="fs-20 medium text-nowrap">
              Level
            </th>
            <th width="10%" className="fs-20 medium">
              Action
            </th>
          </tr>
        </thead>

        <tbody>
          {externalEscalation === "external" && (
            <ExternalEscTableBody
              data={data}
              levelId={levelId}
              crmid={id}
              setCheckBoxAdd={setCheckBoxAdd}
              checkBoxAdd={checkBoxAdd}
              search={search}
              setSearch={setSearch}
            />
          )}

          {externalEscalation === "internal" && (
            <InternalEscTableBody
              data={data}
              levelId={levelId}
              crmid={id}
              setCheckBoxAdd={setCheckBoxAdd}
              checkBoxAdd={checkBoxAdd}
              search={search}
              setSearch={setSearch}
            />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EscalationTableContainer;
