import React from "react";

const DeactivateButton = ({onClick, disabled}) => {
  return (
    <button
      type="button"
      style={{ color: "#fff" }}
      className="custom-btn close-modal bg-red border-0 py-2 mx-3 rounded-11"
      onClick={onClick}
      disabled={disabled}
    >
      Deactivate
    </button>
  );
};

export default DeactivateButton;
