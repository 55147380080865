import React from "react";
import useCampaignSearch from "../../libs/mutation/market/useCampaignSearch";
import { useSelector } from "react-redux";
import CampaignTableBody from "./CampaignTableBody";
import useGetCampaignSearch from "../../libs/queries/market/useGetCampaignSearch";

const CampaignTableContainer = ({ setCheckBoxAdd, checkBoxAdd }) => {
  const {data: campaigndata} = useGetCampaignSearch()
  

  return (
    <div className="bg-white rounded-10 overflow-hidden shadow">
      <table class="w-100 alert-table">
        <thead>
          <tr>
            <th></th>
            <th class="fs-20 medium" width="10%">
              Sr. no.
            </th>
            <th class="fs-20 medium" width="35%">
              Client Name
            </th>
            <th class="fs-20 medium" width="35%">
              Lead Name
            </th>
            <th class="fs-20 medium" width="25%">
              Contact
            </th>
            <th class="fs-20 medium">Action</th>
          </tr>
        </thead>
      
          <CampaignTableBody
            data={campaigndata}
            setCheckBoxAdd={setCheckBoxAdd}
            checkBoxAdd={checkBoxAdd}
          />
      
      </table>
    </div>
  );
};

export default CampaignTableContainer;
