import React, { useEffect, useRef, useState } from "react";
import useFilterData from "../../libs/queries/manage/useFilterData";
import { useSelector } from "react-redux";
import useGetRelationshipManager from "../../libs/queries/crm/useGetRelationshipManager";
import RadioFilters from "./RadioFilters";
import useResearcherUsers from "../../libs/queries/graphs/useResearcherUsers";
import useGetLeadListContact from "../../libs/queries/leads/useGetLeadListContact";
import useUsers from "../../libs/queries/profile/useUsers";
import UserRadioFilter from "./UserRadioFilter";
import UserFiltername from "./UserFiltername";
import useGetDocument from "../../libs/queries/market/useGetDocument";

const MarketIntelligenceSort = ({
  onDocTypeSelect,
  onSkillsSelect,
  onResearcherSelect,
  showSort,
  onStartDateSelect,
  onEndDateSelect,
  onClientSelect,
  onPaidSelect,
  onViewStart,
  onViewEnd,
  onCampaignStart,
  onCampaignEnd,
  type,
}) => {
  const { data: industryoptions } = useFilterData(1, null, true, true);
  const { data: researcherOptions } = useResearcherUsers();
  const {data: userList} = useUsers();
  const { data: docOptions } = useFilterData(30, null, true, true);
  const { data: skillOptions } = useFilterData(31, null, true, true);
  const { data: contactlist } = useGetLeadListContact({
    stage_id: null,
    leadType: false,
  });
  const {data: clientFilter} = useGetDocument({ page: 1, marketType: type});
  const [isOpen, setIsOpen] = useState(showSort);
  const [docId, setDocId] = useState("");
  const [skillId, setSkillId] = useState("");
  const [clientId, setClientId] = useState("");
  const [researcherId, setResearcherId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paid, setPaid] = useState(false);
  const [viewStartDate, setViewStartDate] = useState("");
  const [viewEndDate, setViewEndDate] = useState("");
  const [campaignStartDate, setCampaignStartDate] = useState("");
  const [campaignEndDate, setCampaignEndDate] = useState("");
  const MarketDropdownRef = useRef(null);
  const userlist = userList?.results

  useEffect(() => {
    setIsOpen(showSort);
  }, [showSort]);

  const handleClickOutside = (event) => {
    if (
      MarketDropdownRef.current &&
      !MarketDropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    onDocTypeSelect?.(docId);
  }, [docId]);

  useEffect(() => {
    onSkillsSelect?.(skillId);
  }, [skillId]);

  useEffect(() => {
    onResearcherSelect?.(researcherId);
  }, [researcherId]);

  useEffect(() => {
    onClientSelect?.(clientId);
  }, [clientId]);

  useEffect(() => {
    onPaidSelect?.(paid);
  }, [paid]);

  useEffect(() => {
    // if (startDate && endDate) {
      onStartDateSelect?.(startDate);
      onEndDateSelect?.(endDate);
    // }
  }, [startDate, endDate]);

  useEffect(() => {
    // if (campaignStartDate && campaignEndDate) {
      onCampaignStart?.(campaignStartDate);
      onCampaignEnd?.(campaignEndDate);
    // }
  }, [campaignStartDate, campaignEndDate]);

  useEffect(() => {
    // if (viewStartDate && viewEndDate) {
      onViewStart?.(viewStartDate);
      onViewEnd?.(viewEndDate);
    // }
  }, [viewStartDate, viewEndDate]);

  const handleCheckboxChange = () => {
    setPaid(!paid);
  };

  return (
    <div
      ref={MarketDropdownRef}
      style={{
        position: "absolute",
        right: 0,
        bottom: "auto",
        left: "auto",
        // inset:'left'
      }}
      className={`dropdown-menu filter-menu p-0 rounded-10 dropdown-menu-end ${
        isOpen ? "show" : ""
      }`}
    >
      <h6 className="text-white bg-sky p-3 filter-title semibold mb-1">
        Sort by
      </h6>
      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#status"
          role="button"
          aria-expanded="false"
          aria-controls="status"
        >
          Doc Type
        </a>
        <div className="collapse" id="status">
          <RadioFilters
            options={docOptions?.data}
            value={docId}
            onChange={setDocId}
            name="doc_id"
          />
        </div>
      </div>

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#client"
          role="button"
          aria-expanded="false"
          aria-controls="client"
        >
          For Client
        </a>
        <div className="collapse" id="client">
          <div style={{ overflowY: "scroll", height: "110px" }}>
            <RadioFilters
              // options={contactlist?.results}
              options={clientFilter?.results}
              value={clientId}
              onChange={setClientId}
              name="client"
            />
          </div>
        </div>
      </div>

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#skill"
          role="button"
          aria-expanded="false"
          aria-controls="skill"
        >
          Skills
        </a>
        <div className="collapse" id="skill">
          <div style={{ overflowY: "scroll", height: "110px" }}>
            <RadioFilters
              options={skillOptions?.data}
              value={skillId}
              onChange={setSkillId}
              name="skill_id"
            />
          </div>
        </div>
      </div>

      {(
        <div className="border-bottom">
          <a
            className="collapse-butn semibold"
            data-bs-toggle="collapse"
            href="#manager"
            role="button"
            aria-expanded="false"
            aria-controls="manager"
          >
            Researcher
          </a>
          <div className="collapse" id="manager">
            <div style={{ overflowY: "scroll", height: "110px" }}>
              {/* <RadioFilters
                options={researcherOptions}
                value={researcherId}
                onChange={setResearcherId}
                name="relation_id"
              /> */}
              <UserFiltername options={userlist} value={researcherId} onChange={setResearcherId} name="relation_id"  />
              {/* <UserRadioFilter options={userlist} value={researcherId} onChange={setResearcherId} name="relation_id" /> */}
            </div>
          </div>
        </div>
      )}

        <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#paid"
          role="button"
          aria-expanded="false"
          aria-controls="paid"
        >
          Paid
        </a>
        <div className="collapse" id="paid">
          <div className="my-2 d-flex justify-content-between align-items-center py-1 px-3">
            <label className="medium fs-14" for="paid">
                {'Paid'}
            </label>
            <input 
            onChange={handleCheckboxChange}
            checked={paid} 
            type='checkbox' 
            id="paid" />
          </div>
        </div>
      </div>

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#date"
          role="button"
          aria-expanded="false"
          aria-controls="date"
        >
          Date of Upload
        </a>
        <div className="collapse" id="date">
          <div style={{ overflowY: "scroll", height: "140px" }}>
            <div className="my-2 align-items-center py-1 px-3">
              <label className="medium fs-14" for="date">
                {"Start Date"}
              </label>
              <input
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                style={{
                  borderRadius: 0,
                  padding: "5px",
                }}
                className="mt-1"
                type="date"
              />
              <label className="medium fs-14 mt-2" for="sourcedate">
                {"End Date"}
              </label>
              <input
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                style={{
                  borderRadius: 0,
                  padding: "5px",
                }}
                className="mt-1"
                type="date"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#viewdate"
          role="button"
          aria-expanded="false"
          aria-controls="viewdate"
        >
          Last View
        </a>
        <div className="collapse" id="viewdate">
          <div style={{ overflowY: "scroll", height: "140px" }}>
            <div className="my-2 align-items-center py-1 px-3">
              <label className="medium fs-14" for="viewdate">
                {"Start Date"}
              </label>
              <input
                onChange={(e) => {
                  setViewStartDate(e.target.value);
                }}
                style={{
                  borderRadius: 0,
                  padding: "5px",
                }}
                className="mt-1"
                type="date"
              />
              <label className="medium fs-14 mt-2" for="viewdate">
                {"End Date"}
              </label>
              <input
                onChange={(e) => {
                  setViewEndDate(e.target.value);
                }}
                style={{
                  borderRadius: 0,
                  padding: "5px",
                }}
                className="mt-1"
                type="date"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#campdate"
          role="button"
          aria-expanded="false"
          aria-controls="campdate"
        >
          Last Campaign
        </a>
        <div className="collapse" id="campdate">
          <div style={{ overflowY: "scroll", height: "140px" }}>
            <div className="my-2 align-items-center py-1 px-3">
              <label className="medium fs-14" for="campdate">
                {"Start Date"}
              </label>
              <input
                onChange={(e) => {
                  setCampaignStartDate(e.target.value);
                }}
                style={{
                  borderRadius: 0,
                  padding: "5px",
                }}
                className="mt-1"
                type="date"
              />
              <label className="medium fs-14 mt-2" for="campdate">
                {"End Date"}
              </label>
              <input
                onChange={(e) => {
                  setCampaignEndDate(e.target.value);
                }}
                style={{
                  borderRadius: 0,
                  padding: "5px",
                }}
                className="mt-1"
                type="date"
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default MarketIntelligenceSort;
