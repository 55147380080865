import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Legend,
  Tooltip,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";

const DoubleLineChart = ({proposallabel=[], proposaldata=[], proposaltarget=[]}) => {
  ChartJS.register(
    ArcElement,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement
  );
 
  const data = {
    labels: [" ", 'January'],
    datasets: [
      {
        label: 'Monthly Sales',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [65],
      },
    ],
  };

  const datalineweight = {
    labels: proposallabel?.map((item) => item),
    datasets: [
      {
        label: "Target",
        data: proposaltarget?.map((item) => item),
        backgroundColor: "#50a1fd",
        borderColor: "#50a1fd",
        tension: 0.3,
        // pointRadius: 10,
        pointRadius: 1,
        pointHitRadius: 10,
        pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: "#50a1fd",
        pointHoverRadius: 10,
        pointBorderWidth: 4,
        pointBorderColor: "transparent",
        pointBorderColor: '#50a1fd',
        pointHoverBorderColor: "white",
        pointHoverBorderWidth: 4,
      },

      {
        label: "Achieved",
        data: proposaldata?.map((item) => item),
        backgroundColor: "rgb(255, 165, 0)",
        borderColor: "rgb(255, 165, 0)",
        tension: 0.3,
        // pointRadius: 10,
        pointRadius: 1,
        pointHitRadius: 10,
        pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: "rgb(255, 165, 0)",
        pointHoverRadius: 10,
        pointBorderWidth: 4,
        pointBorderColor: "transparent",
        pointBorderColor: 'rgb(255, 165, 0)',
        pointHoverBorderColor: "white",
        pointHoverBorderWidth: 4,
      },
    ],
  };

  const optionsweight = {
    maintainAspectRatio: false,
    plugins: {
      legend: true,
      tooltip: {
        callbacks: {
          label: (context) => {
            const costvalue = context.formattedValue + "";
            return costvalue;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: Math.max(...proposaldata, ...proposaltarget),
        grid: {
          display: true,
        },
        display: true,
      },

      x: {
        // min: 0,
        // max: Math.max(...proposaldata, ...proposaltarget),
        grid: {
          display: false,
        },
        display: true,
      },
    },
  };

  return (
    <div>
    {(proposaldata.length<1  && proposaltarget.length<1) ?
    <div className="my-2 d-flex justify-content-center">No Data</div>
    :
      <Line 
      style={{height: '400px'}}
      // data={data}
      data={datalineweight} 
      options={optionsweight} 
      />
    } 
    </div>
  );
};

export default DoubleLineChart;
