import React, { useState } from "react";
import DeleteIcon from "../../img/icon/trash.svg";
import EditIcon from "../../img/icon/edit-white.svg";
import { Link } from "react-router-dom";
import EmailIcon from "../../img/icon/email2.svg";
import CampaignEmailModal from "../../ui/modals/CampaignEmailModal";
import SingleEmailModal from "../../ui/modals/SingleEmailModal";
import useDocumentEmail from "../../libs/queries/market/useDocumentEmail";
import { useSelector } from "react-redux";

const CampaignTableBody = ({ data, setCheckBoxAdd, checkBoxAdd }) => {
  const [emailView, setEmailView] = useState(false);
  const [singleEmailData, setSingleEmailData] = useState([]);
  const [emailId, setEmailId] = useState(null);

  const { documentId } = useSelector((state) => state.campaignSlice);
  console.log(documentId);

  // console.log("this is data", marketemail, emailId)

  const handleAddCheckbox = (multiemail) => {
    const index = checkBoxAdd.indexOf(multiemail);
    if (index === -1) {
      checkBoxAdd.push(multiemail);
    } else {
      checkBoxAdd.splice(index, 1);
    }
    setCheckBoxAdd([...checkBoxAdd]);
    // console.log(checkBoxAdd)
  };

  // const handleDelete = () => {
  //   deletemember();
  //   setdele(false);
  // };

  return (
    <tbody>
      {data?.results?.map((item, index) => {
        console.log("df", item?.lead?.legal_entity_name);
        return (
          <tr key={index}>
            <td>
              <input
                id="default-checkbox"
                type="checkbox"
                // checked={
                //   checkBoxAdd.filter((item) => item == multiname).length > 0
                //     ? true
                //     : false
                // }
                onClick={() => handleAddCheckbox(item?.email)}
              />
            </td>
            <td>{index + 1}</td>
            <td>{item?.lead_detail?.legal_entity_name}</td>
            <td>{item?.name}</td>
            <td>{item?.mobile}</td>
            <td>
              <div className="d-flex align-items-center gap-xl-3 gap-2">
                {/* <a
                  className="action-icon bg-red"
                  //      onClick={() =>
                  //     dispatch(setmemberId(item?.id)) && setdele(true)
                  //   }             >
                >
                  <img src={DeleteIcon} />
                </a> */}

                <Link
                  className="action-icon bg-sky"
                  onClick={() => {
                    setEmailView(true);
                    setEmailId(item?.id);
                    setSingleEmailData(item?.email);
                  }}
                >
                  <img src={EmailIcon} />
                </Link>
              </div>
            </td>

            {emailView && (
              <SingleEmailModal
                singleemail={singleEmailData}
                onClose={() => setEmailView(false)}
              />
            )}
          </tr>
        );
      })}

      {!data?.results?.length && (
      <>
       <tr>
        <td style={{border: "0px"}}></td>
        <td style={{border: "0px"}}></td>
        <td style={{border: "0px"}}></td>
       <td
          style={{
            textAlign: "center",
            border: "0px",
            width: "200px"
          }}
          className="mt-3"
        >
          No Data Found
        </td>
        <td style={{border: "0px"}}></td>
        <td style={{border: "0px"}}></td>
        <td style={{border: "0px"}}></td>
       </tr>
          
      </>
       
      )}
    </tbody>
  );
};

export default CampaignTableBody;
