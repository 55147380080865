import React from "react";
import Counter from "./Counter";
import useDashboardList from "../../libs/queries/dashboard/useDashboardList";

const CounterContainer = ({type}) => {

  const {data: dashboardlist} = useDashboardList({
    dashboardType: type
  })
console.log(dashboardlist)


  return (
    <div className="row gx-lg-5">

      <Counter data={dashboardlist} heading="Lead Count" previousMonthCounts={dashboardlist?.previous_month_lead_counts} averageCount={dashboardlist?.average_lead_counts} currentCount={dashboardlist?.current_month_lead_counts} backgroundcolor="box-1" />
      <Counter data={dashboardlist} heading="Client Onboarded" previousMonthCounts={dashboardlist?.previous_month_onboarded_count} currentCount={dashboardlist?.current_month_onboarded_count} averageCount={dashboardlist?.average_onboarded_count} backgroundcolor="box-2"  />
      <Counter data={dashboardlist} heading="CRM Lead Count" previousMonthCounts={dashboardlist?.previous_month_crm_counts} currentCount={dashboardlist?.current_month_crm_counts} averageCount={dashboardlist?.average_crm_counts} backgroundcolor="box-3" />
      <Counter data={dashboardlist} heading="Proposal Sent" previousMonthCounts={dashboardlist?.previous_month_proposal_sent} averageCount={dashboardlist?.average_proposal_counts} currentCount={dashboardlist?.current_month_proposal_sent} backgroundcolor="box-4" />
      
      
    </div>
  );
};

export default CounterContainer;
