import React from "react";
import Heading from "../../ui/modals/Heading";
import NewCampaignForm from "../../Components/marketintelligence/NewCampaignForm";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import useLocalStorage from "../../hooks/useLocalStorage";

const NewCampaign = () => {

  const [type, setType] = useLocalStorage("campaignType", "my");

  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <Subheader
          leftsideshow={
            <TwoButtonHolder onSelect={setType} label="Document Repository" />
          }
        />

        <Heading heading="Document Repository" isLocation="false" />

        <div className="pt-4">
          <div className="rounded-10 overflow-hidden">
            <div className="widget-header px-3 py-3 bg-sky">
              <h3 className="bold text-white fs-18">Search a Campaign</h3>
            </div>
            <NewCampaignForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCampaign;
