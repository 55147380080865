import React from "react";
import { Bar } from "react-chartjs-2";

const HorizontalBarChart = ({ data = [], datalabel = [], datacolor = [] }) => {
  // console.log("this is hori", data, datalabel, datacolor);
  console.log('checktheme__', localStorage.getItem("theme"));
  const theme = localStorage.getItem("theme");

  const dataconfig = {
    labels: datalabel?.map((item) => item),
    datasets: [
      {
        // label: datalabel?.map((item) => item),
        label: '',
        indexAxis: "y",
        backgroundColor: datacolor?.map((item) => item),
        // backgroundColor: 'red',
        data: data.map((item) => item),
      },
    ],
    // datasets: data.map((item, index) => ({
    //   label: datalabel[index],
    //   indexAxis: "y",
    //   backgroundColor: [datacolor[index]],
    //   data: data.map((item) => item)
    // })),

    options: {
    
      legend: {
        display: true,
        // labels: {
        //   color: 'transparent', // Set label color to transparent
        // },
      },
      datalabel: {
        display: false
      },
      scales: {
        x: {
          display: false,
          min: 0,
          max: 10,
        },
      },
     
      tooltips: {
        callbacks: {
          label: function(tooltipItem) {
          console.log(tooltipItem)
            return tooltipItem.yLabel;
          }
        }
      }
    },
  };

  const optionsKg = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        min: 0,
        max: 50,
        grid: {
          display: true,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
      {
        data?.map((item, index)=>{
          return(
            <div className="d-flex align-items-center">
              <div style={{
                height: '10px',
                width: '40px',
                backgroundColor: datacolor?.[index]
              }}></div>
              <div className="ms-2 me-3">{datalabel?.[index]}</div>
            </div>
          )
        })
      }
      <div style={{
        position: 'absolute',
        backgroundColor: 'white',
        height: '25px',
        width: '50px',
        marginTop: '25px'
      }}></div>
      </div>
      <Bar
        style={{ height: "200px", width: "200px" }}
        options={optionsKg}
        data={dataconfig}
      />
    </div>
  );
};

export default HorizontalBarChart;
{/* <div className="d-flex justify-content-center">
{
   data?.map((item, index)=>{
    return(
      <div className="d-flex align-items-center">
        <div style={{
          height: '10px',
          width: '40px',
          backgroundColor: datacolor?.[index]
        }}></div>
        <div className="ms-2 me-3">{datalabel?.[index]}</div>
      </div>
    )
  })
}
</div> */}