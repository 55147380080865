export const Endpoints = {
    // baseUrl: 'http://bdcrmbackend.dignitech.com',
    baseUrl: process.env.REACT_APP_BASE_URL,
    checkin: 'account/check-in/',
    checkout: 'account/check-out/',
    changePassword: 'account/update-password/',
    emailTemplatesCsv: 'record/email-templates/?download=1',
    agreementCsv: 'management/agreements/?download=1',
    dropdownCsv: (id)=> `record/dropdown-values/${id}?download=1`,
    stageCsv: process.env.REACT_APP_BASE_URL + "/api/v1/management/stages/?download=1",
    usergenerationCsv: 'account/users/?download=1',
    getProfile: 'account/profile/',
    updateProfile: 'account/profile-update/',
    accountRoles: 'account/roles/',
    createUser: 'account/user/create',
    updateTarget: 'account/update/target/',
    getLocationList: 'record/locations/',
    createStage: 'management/stage/create/',
    getStageList: "management/stages/",
    deleteStage: 'management/stage/delete/',
    updateStage: 'management/stage/update/',
    createLocation: 'record/location/create/',
    updateLocation: "record/location/update/",
    getSendToValues: 'record/email-send-to/',
    createAgreement: 'management/agreement/create/',
    getAgreementList: 'management/agreements/',
    deleteAgreement: 'management/agreement/delete/',
    updateAgreement: 'management/agreement/update/',
    updateStage: "management/leadcontact/update-stage/",
    updateManageStage: "management/stage/update/",
    // getEmailContent: 'management/stage-email/',
    getEmailContent: 'management/contact-email/',
    getLeadContacts: 'management/lead-contacts',
    sendEmail: 'account/send-email-via-azure',
    getPrsData: 'management/prs',
    getPrsList: 'management/prs/list',
    updatePrs: 'management/prs/update',
    contactLogCsv: 'crm/contactlogs?download=1',
    escalationMemberCsv: (id) => `crm/escalationmembers/${id}?download=1`,
    prsCsv: 'management/prs/list?download=1',
    reseacherPrs: 'management/researcherprs/list',
    researcherPrsUpdate: 'management/researcherprs/update',
    getResearcherUser: 'account/researchers',
    getIndustryListPrs: 'management/industry-list',
    getLeadListContact: 'management/lead-contacts',
    getCrmList: 'management/crm/list',
    listDocumentCsv: 'market/documentfiles/',
    campaignCsv: 'market/campaign?download=1',
    addEventCalendar: 'account/calendar-event/create',
    listEventCalendar: 'account/get-calendar-events',
    getPracticeHead: 'account/practicehead',
    nofollowupCSV: 'market/followup',
    agreementalertCSV: 'market/agreement-pending',
    productivityalertCSV: 'market/alert-productivity',
    pendingCSV: 'market/pendingreminders',
    leadReportCSV: 'management/leadreport',
    productivityReportCSV: 'management/productivityreport',
    agreementReportCSV: 'management/agreementreport',
    getAgreeementEmail: 'management/agreement-email/',
    updateLastView: 'market/documentfile/update-last-view/',
    lastCampaign: "market/document-last-campaign",
    prsLeadContactModal: 'management/prs-lead-contacts',
    getLeadLogs: 'management/lead-contact/logs/',
    deleteEmailAttachement: 'record/email-template-file/delete/'
}