import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import AttachementIcon from "../../img/icon/attachment.svg";
import BadgeInput from "../inputs/BadgeInput";
import useSendEmail from "../../libs/mutation/emailtemplate/useSendEmail";
import { useSelector } from "react-redux";
import useDocumentEmail from "../../libs/mutation/market/useDocumentEmail";

const SingleEmailModal = ({ onClose, singleemail, externalEscalation, id }) => {
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    control,
    setError,
    clearErrors,
    getValues,
    watch,
    reset,
  } = useForm({ mode: "all" });

  const [fileupload, setFileUpload] = useState(null);
  const { mutate: sendEmail, isLoading } = useSendEmail(onClose);

  const {mutate: marketemail, isLoading : loader} = useDocumentEmail()
  const { emailData } = useSelector((state) => state.campaignSlice);
 


  const { attachments, linkattachment } = useSelector((state) => state.campaignSlice);
  const defaultValue = linkattachment.join('\n')
  

  const handleChange = (event, onChange) => {
    setFileUpload(event.target.files[0]);
    onChange(event.target.files[0]);
  };

const {name} = fileupload ||{}
const emaildata = JSON.parse(localStorage.getItem("marketemaildata")) || []
const emailmap = emaildata?.map((item) => item?.to_email)
const emailbody = emaildata?.map((item) => item?.body)
const emailsubject = emaildata?.map((item) => item?.subject)
const emailattachment = emaildata?.map((item) => item?.attachments)
console.log(emaildata)
const emailbodydefault = emailbody.join('\n')

useEffect(() => {
  reset({ attachments });
  marketemail()
}, [attachments]);



if(!loader)
  return (
    <div> 
      <form
        onSubmit={handleSubmit(sendEmail)}
        style={{
          backgroundColor: "red",
        }}
        className="col-lg-5 mail-box py-3 rounded-10 shadow bg-white collapse show"
        id="mailbox"
      >
        <div className="px-4">
          <div className="d-flex gap-2 justify-content-end pb-2 align-items-end mail-action">
            <a className="full-screen pb-1">
              <img src="assets/img/icon/full-screen.svg" alt="" />
            </a>
            <a
              onClick={onClose}
              className="mail-close bg-red fs-12 d-flex align-items-center justify-content-center text-white selectContainer"
              aria-expanded="true"
            >
              <i className="fa-solid fa-xmark"></i>
            </a>
          </div>
          <div 
          style={{height: "75px", overflowY: "scroll"}}
          className="d-flex gap-2 pb-2 border-bottom">
            <span className="text-gray pt-2">To: </span>
            <Controller
              name="to_emails"
              control={control}
              defaultValue={singleemail.length || emailmap.length ? [...(emailmap || []) ,singleemail || []] : ""}
              render={({ field }) => (
                <BadgeInput value={field?.value} onChange={field?.onChange} />
              )}
            />
            {/* <input
          type="text"
          defaultValue={to.length > 0 ? to && to?.map((item) => item) : !externalEscalation ? externaldata?.results.map((item) => item.email).join(",") : internaldata?.results.map((item) => item.email).join(",")}
          className="semibold fs-16 raleway border-0"
          placeholder=""
          name="to_emails"
          {...register("to_emails", { required: true })}
        /> */}
          </div>
          <div className="py-2 border-bottom">
            <input
              defaultValue={emailsubject}
              type="text"
              name="subject"
              className="semibold fs-14 raleway border-0"
              placeholder="Subject"
              {...register("subject", { required: true })}
            />
          </div>
        </div>
        
        <div className="choose-heading px-4 pb-5 pt-2">
          <Controller
            name="body"
            control={control}
            defaultValue={defaultValue || emailbodydefault}
            render={({ field: { value, onChange } }) => {
              return (
                <ReactQuill
                defaultValue={defaultValue || emailbodydefault}
                  theme="snow"
                  modules={{ toolbar: true }}
                  value={value}
                  onChange={onChange}
                  style={{ height: "160px" }}
                />
              );
            }}
          />
          {/* <ReactQuill theme="snow" value={""} style={{ height: "200px" }} /> */}
        </div>
        <div 
        style={{height: "50px", overflowY: "scroll"}}
        className="d-flex flex-wrap ps-4 me-4">
            {attachments?.map((item) => {
              return (
                <div style={{ color: "#3366CC" ,marginLeft: "2px",}} className="fs-12 px-2 pt-2">{item?.toString().split("/")?.[3]}</div>
              )
            } )}
          <div style={{ color: "#3366CC" ,marginLeft: "2px",}}>{emailattachment.toString().split("/").join(`\n`)}</div> 
            <div style={{ color: "#3366CC" ,marginLeft: "2px",}} className="fs-12 px-2 pt-2">{name}</div>
           
          </div>
          <div className="send-mail pt-3 px-4 d-flex gap-3 align-items-center">
            <button
              type="submit"
              disabled={isLoading}
              className="selectContainer custom-btn medium rounded-10 semibold fs-16 py-1 px-4"
            >
              {isLoading ? "sending" : "Send"}
            </button>
            <div>
              <Controller
                name="attachments"
                control={control}
                defaultValue={emailattachment}
                render={({ field: { value, onChange } }) => {
                  return (
                    <input
                      type="file"
                      id="upload"
                      className="d-none"
                      files={value}
                      accept="image/*"
                      onChange={(event) => handleChange(event, onChange)}
                      multiple
                    />
                  );
                }}
              />
              {/* {attachments?.length  ? <span>{attachments?.length}</span> : ""} */}
              <label
                htmlFor="upload"
                className="attachment text-center pe-auto"
              >
                {/* <img src={AttachementIcon} alt="" /> */}
              </label>
            </div>
          </div>
      </form>
    </div>
  );
};

export default SingleEmailModal;
