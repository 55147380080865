import React from 'react'
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useGetEmailVariables = () => {

const GetEmailVariable = useQuery({
    queryKey: ['emailvariable'],
    queryFn: async () => {
        const res = await api.get(`record/email-variables`)
        
        return res.data?.data
    }
})

  return GetEmailVariable
}

export default useGetEmailVariables