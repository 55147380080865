import React, { useEffect, useState } from "react";
/// hooks used ///
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

/// icons and images ///
import PlusButton from "./PlusButton";

/// Imports of Component ///
import {
  setmarketgenId,
  setmarketgenname,
  setmarketgennamechange,
  setnewmarketgenId
} from "../../../slice/marketIntGenerationSlice";
import useFilters from "../../../libs/queries/manage/useFilters";

import useFilterData from "../../../libs/queries/manage/useFilterData";

const FilterRow = () => {
  const { data: filters, isLoading } = useFilters("market_intelligence");
  const { marketgennamechange, marketgenid } = useSelector(
    (state) => state.marketIntGenerationSlice
  );
  // const { data } = useFilterData(
  //   filters ? filters[0].id : null
  // );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setactive] = useState("");

  useEffect(() => {
    if (filters && !marketgenid) {
      dispatch(setmarketgenname(filters[0]?.name));
      dispatch(setmarketgenId(filters[0]?.id));
    
    }
  }, [filters]);

  return (
    <div className="row pb-4">
      <div className="col-sm-10 pb-sm-0 pb-3">
        <div className="">
          {filters &&
            filters.map((data, index) => {
              return (
                <a
                  key={index}
                  style={{ marginLeft: "5px", cursor: "pointer" }}
                  className={
                    marketgennamechange === data.name
                      ? "custom-btnlead sm-btn"
                      : "custom-btn sm-btn bg-white text-sky"
                  }
                  onClick={() => {
                    dispatch(setmarketgenId(data.id));
                    dispatch(setnewmarketgenId(data.id));
                    dispatch(setmarketgenname(data.name));
                    dispatch(setmarketgennamechange(data.name));
                    navigate("/manage/marketgeneration");
                  }}
                >
                  {data.name}
                </a>
              );
            })}
        </div>
        <span className="text-black2 fs-12 medium ps-3">
          {/* Organisation Market Intelligence */}
        </span>
      </div>
      <PlusButton />
    </div>
  );
};

export default FilterRow;
