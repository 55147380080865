import { Outlet } from "react-router-dom";
import CalendarTab from "../Pages/Calendar/Calendar";

const calendarRouter = {
    path: "/calendar/",
    element: <Outlet />,
    children: [
        {
            path: "",
            element: <CalendarTab />
        },
    ]
}

export default calendarRouter;