import AgreementGenerated from "../Pages/Manage/AgreementForm/AgreementGenerated"
import NewAgreementGenerated from "../Pages/Manage/AgreementForm/NewAgreementGenerated"
import EmailTemplate from "../Pages/Manage/EmailTemplate/EmailTemplate"
import NewEmailTemplate from "../Pages/Manage/EmailTemplate/NewEmailTemplate"
import LeadGeneration from "../Pages/Manage/LeadGeneration/LeadGeneration"
import NewLeadGeneration from "../Pages/Manage/LeadGeneration/NewLeadGeneration"
import LocationList from "../Pages/Manage/Location/LocationList"
import NewLocation from "../Pages/Manage/Location/NewLocation"
import MarketGeneration from "../Pages/Manage/MarketGeneration/MarketGeneration"
import NewMarketGeneration from "../Pages/Manage/MarketGeneration/NewMarketGeneration"
import NewStage from "../Pages/Manage/Stages/NewStage"
import StagesManage from "../Pages/Manage/Stages/StagesManage"



const manageRouter = {
    path: "/manage/",
    children: [
        {
            path: "locations/",
            element: <LocationList />
        },
        {
            path: "locations/new",
            element: <NewLocation />
        },

        
        {
            path: "stages/",
            element: <StagesManage />,
            
        },
        {
            path: "stages/new",
            element: <NewStage />
        },


        {
            path: "emailtemplate/",
            element: <EmailTemplate />,
            
        },
        {
            path: "emailtemplate/new",
            element: <NewEmailTemplate />
        },


        {
            path: "leadgeneration/",
            element: <LeadGeneration />,
            
        },
        {
            path: "leadgeneration/new",
            element: <NewLeadGeneration />
        },


        {
            path: "agreementgeneratedform/",
            element: <AgreementGenerated />,
        },
        {
            path: "agreementgeneratedform/new",
            element: <NewAgreementGenerated />
        },


        {
            path: "marketgeneration/",
            element: <MarketGeneration />,
            
        },
        {
            path: "marketgeneration/new",
            element: <NewMarketGeneration />
        }
    ]

}

export default manageRouter