import React from "react";
import useGetLeadListContact from "../../libs/queries/leads/useGetLeadListContact";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNavigateSource } from "../../slice/leadviewSlice";

const TableContainer = ({listcontact, type}) => {
  
  // console.log(listcontact?.results.map((item) => item));

  const time = listcontact?.results?.map((item) =>
    item?.updated_at?.substring(0, 16).split("T").join(" ").substring(11, 13)
  );

  const navigate = useNavigate();
  const dispatch = useDispatch()

  return (
    <div
      className="bg-white rounded-10 overflow-hidden shadow"
      style={{ marginTop: "10px" }}
    >
      <div className="row border-bottom">
        <table className="w-100 alert-table crm-table">
          <thead>
            <tr>
              <th width="5%" className="fs-20 medium text-nowrap">
                Sr. no.
              </th>
              <th width="20%" className="fs-20 medium text-nowrap">
                Client Name
              </th>
              <th width="20%" className="fs-20 medium text-nowrap">
                Contact Name
              </th>
              {type=='organisation' && <th width="20%" className="fs-20 medium text-nowrap">
                Lead Owner
              </th>}
              <th width="20%" className="fs-20 medium text-nowrap">
                Stage
              </th>
              <th width="20%" className="fs-20 medium text-nowrap">
                Last Modified Date
              </th>
            </tr>
          </thead>
          <tbody>
            {listcontact?.results?.map((item, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item?.lead?.legal_entity_name}</td>
                  <td>{item?.name}</td>
                  {type=='organisation' &&
                  <td>{item?.lead?.lead_owner_display}</td>}
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "blue",
                    }}
                    onClick={() => {
                      navigate(`/leads/${item?.stage_of_contact}`);
                      dispatch(setNavigateSource(type))
                    }}
                  >
                    {item?.stage_of_contact_display}
                  </td>
                  <td>
                    {item?.updated_at?.substring(0, 16).split("T").join(" ")}
                    {item?.updated_at !== null
                      ? time >= 12
                        ? "PM"
                        : "AM"
                      : ""}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {!listcontact?.results?.length &&
          <div className="d-flex justify-content-center py-4">
          No Data
          </div>
        }
      </div>
    </div>
  );
};

export default TableContainer;
