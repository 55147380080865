import React from "react";
import api, { Imageapi } from "../../../api/index";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const useCreateLeads = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data) => {
      delete data.contact
      delete data.leads
      if(!data?.email){
        delete data?.email
      }
      if(!data?.mobile){
        delete data?.mobile
      }
      console.log(data)
      const formdata = new FormData();

      for (const name in data) {
        if (typeof data[name] === "object" && name !== "news_research_file") {
          if (name === "lead_owner" || "indian_presence") {
            data[name]?.forEach((element) => {
              formdata.append(name, element);
            });
          } else {
            formdata.append(name, JSON.stringify(data[name]));
          }
        } else {
          formdata.append(name, data[name]);
        }
      }
      let res;
console.log(formdata)
      if (data?.news_research_file) {
        // console.log("insideimage");
        res = await Imageapi.post("/management/lead/create/", formdata);
      } else {
        // console.log("insidewithoutimage");
        res = await api.post("/management/lead/create/", data);
      }
      return {...res?.data, stageid: data.stage_of_contact};
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([`leads`]);
      toast.success(data.message);
      navigate("/leads/" + data.stageid);

    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });

  return mutation;
};

export default useCreateLeads;
