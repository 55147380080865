import React, { useState } from "react";
import WhiteEditIcon from "../../../img/icon/edit-white.svg";
import AgreementEdit from "./AgreementEdit";

const AgreementGeneratedModal = ({ data, index }) => {
  const [edit, setEdit] = useState(false);

  const { name, id, ddtype, created_at } = data;

  const date = created_at.substring(0,16)
  const datefinal = date.split("T").join(" ")
  const time = datefinal.substring(11,13)

  return (
    <>
      {!edit && (
        <div className="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3  acco-info-table">
          <table className="action-table w-100 manage-table">
            <tr>
              <td className="action-first">
                <span className="number d-flex align-items-center justify-content-center rounded-circle fs-24 semibold">
                  {index + 1}
                </span>
              </td>

              <td className="action-mid">
                <div className="pb-2">
                  <h6 className="fs-12 text-sky regular  pb-md-1">Name</h6>
                  <h5 className="regular">{name}</h5>
                </div>
              </td>

              <td className="action-mid" style={{width: "200px"}}>
                <div className="pb-2">
                  <h6 className="fs-12 text-sky regular  pb-md-1">Created At</h6>
                  <h5 className="regular">{datefinal} {time >= 12 ? "PM" : "AM"}</h5>
                </div>
              </td>

              <td className="action-last">
                <div>
                  <button
                    href="#"
                    className="selectContainer action-icon ms-auto"
                    onClick={() => setEdit(true)}
                  >
                    <img src={WhiteEditIcon} />
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      )}
      {edit && (
        <AgreementEdit
          index={index}
          data={data}
          onHide={() => setEdit(false)}
        />
      )}
    </>
  );
};

export default AgreementGeneratedModal;
