import { Outlet } from "react-router-dom";
import CreateUserForm from "../Components/Manage/UsersManage/CreateUserForm";
import UsersList from "../Pages/Manage/Users/UsersList";

const userRouter = {
    path: "/users/",
    element: <Outlet />,
    children: [
        {
            path: "", 
            element: <UsersList />
        },
        {
            path: 'new/',
            element: <CreateUserForm />
        }
    ]
}

export default userRouter;
