import React, { useState } from "react";
import TrashIcon from '../../../img/icon/trash.svg'
import useToggle from '../../../hooks/useToggle'
import CommonDelete from "../../../CommonComponents/CommonDelete";
import StageModal from "./StageModal";
// import DeleteEmailTemplate from "./DeleteEmailTemplate";

const StageList = ({index ,data, dataCopy}) => {

  const {id ,name, order_no, created_at, template_name, stage_background_color} = data || {}
  const [detail, toggleDetail] = useToggle(false)
  const [dele, setdele] = useState(false)

  const date = created_at.substring(0,16)
  const datefinal = date.split("T").join(" ")
  const time = datefinal.substring(11,13);

  return (
    <>
      <div className="bg-white rounded-10 px-md-4 px-3 py-3 mb-3 shadow-sm ">
        <table className="action-table w-100  manage-table">
          <tr>
            <td className="action-first">
              <span className="number d-flex align-items-center justify-content-center rounded-circle fs-24 semibold">
                {index + 1}
              </span>
            </td>

            <td className="action-mid"  style={{width: "200px", flexWrap: "wrap"}}>
              <h6 className="fs-12 text-sky regular  pb-md-2"> Name</h6>
              <h5>{name}</h5>
            </td>
            <td className="action-mid" style={{width: "100px", textAlign: "left"}}>
              <h6 className="fs-12 text-sky regular  pb-md-2" >Order No.</h6>
              <h5>{order_no}</h5>
            </td>
            <td className="action-mid" style={{width: "250px"}}>
              <h6 className="fs-12 text-sky regular  pb-md-2">Template Name</h6>
              <h5>{template_name}</h5>
            </td>
            <td className="action-mid">
              <h6 className="fs-12 text-sky regular  pb-md-2" style={{width: "200px"}}>Created At</h6>
              <h5>{datefinal} {time >= 12 ? "PM" : "AM"}</h5>
            </td>
            <td className="action-last">
              <div className="d-flex align-items-center gap-xl-3 gap-2 justify-content-end">
              {
              //  dataCopy?.includes(data) && <a
              !(dataCopy?.filter((item)=>item.id==id)[0]?.id==id) && <a
                  href="#"
                  className="selectContainer action-icon bg-red"
                  onClick={() => setdele(true)}
                >
                  <img src={TrashIcon} />
                </a>}
                <a
                  className="selectContainer acco-info-btn"
                  onClick={() => toggleDetail()}
                >
                  <i className="fa-solid fa-angle-down fs-20 align-middle"></i>
                </a>
              </div>
            </td>
          </tr>
        </table>
      </div>
      {/* <!--END ROW 1 --> */}
      {detail && ( <StageModal data={data} index={index} dataCopy={dataCopy}/>)}
      {dele && (
      <CommonDelete 
      type={'stage'}
      onClose={() => setdele(false)} 
      id={id} 
      />)}
     
    </>
  );
};

export default StageList;
