import React from "react";

/// Imports of Component ///
import DropDown from "./DropDown/DropDown";
import BDFunnel from "./DropDown/BDFunnel";
import ProposalSent from "./DropDown/ProposalSent";
import Onboarded from "./DropDown/Onboarded";

const DropDownContainer = ({ type }) => {
  return (
    <div className="dashboard-acco">
      <DropDown innerbody={<BDFunnel type={type} />} backgroundcolor="pink" tag="#bd_funnel" title="BD Funnel" />
      <DropDown
      backgroundcolor="lightblue"
        innerbody={<ProposalSent type={type} />}
        tag="#proposal"
        title="Proposals Sent"
      />
      <DropDown backgroundcolor="lightgray" innerbody={<Onboarded type={type} />} tag="#onboarded" title="Onboarded" />
    </div>
  );
};

export default DropDownContainer;
