import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  memberid: null,
  membertype: "",
};

const crmSlice = createSlice({
  name: "crmSlice",
  initialState,
  reducers: {
    setmemberId: (state, action) => {
      state.memberid = action.payload;
    },
    setmemberType: (state, action) => {
      state.membertype = action.payload;
    }
  },
});

export const { setmemberId, setmemberType } = crmSlice.actions;
export default crmSlice.reducer;
