import { Outlet } from "react-router-dom"
import Lead from "../Pages/Leads/Lead"
import NewLead from "../Pages/Leads/NewLead"
import LeadList from "../Pages/Leads/LeadList"
import ViewAll from "../Pages/Leads/ViewAll"

const leadsRouter = {
    path: "/leads/",
    element: <Outlet />,
    children: [
        {
            path: "", 
            element: <Lead />,
        },
        {
            path: 'new/',
            element: <NewLead />
        },
        {
            path: ":id",
            element: <LeadList  />
        },
        {
            path: "viewall",
            element: <ViewAll />
        }
      
    ]

}

export default leadsRouter