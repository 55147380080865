import React from "react";
import useGetStageList from "../../libs/queries/StageApi/useGetStageList";
import useChangeStage from "../../libs/mutation/leads/useUpdateStage";
import { useState } from "react";
import RoleRestriction from "../../utils/roleRestriction";

const Slidedown = ({
  onCancel,
  all,
  onUpdate,
  contactId,
  data,
  setCheckBoxAdd,
}) => {
  const [stageId, setStageId] = useState("");
  const [clicked, setClicked] = useState(false);
  const { data: getstage } = useGetStageList();
  const { mutate: changeStage } = useChangeStage(stageId, contactId);
  const restrict = RoleRestriction();

  // console.log('stage', data)
  return (
    <div
      className="modaldelete"
      // id="change_stage_all"
      style={{ zIndex: "10" }}
    >
      <div
        style={
          {
            // backgroundColor: 'red',
            // width: '750px'
          }
        }
        className="modal-dialog modal-460 modal-dialog-centered"
      >
        <div
          style={
            {
              // backgroundColor: 'green',
              // width: '100%'
            }
          }
          className="modal-content rounded-10 border-0 raleway"
        >
          <div className="modal-header pt-4 pb-0 px-4">
            <h4 className="semibold fs-14 text-sky ">Change Stage</h4>
          </div>
          <hr></hr>
          <div className="modal-body px-md-5 px-4 pb-5 ">
            <p className="">
              {all
                ? "Are you sure to change the state for all the fields?"
                : "Change Stage"}
            </p>
            <select
              onClick={(e) => {
                setStageId(e.target?.value);
                setClicked(true);
              }}
              className="field-select w-100"
              name="stage_of_contact"
              // { ...register("stage_of_contact") }
            >
              <option value="">Select Stage</option>
              {all !== true
                ? getstage &&
                  getstage?.results?.map((item, index) => {
                    if (item?.id === 6 && restrict=='relation') return <></>;
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })
                : getstage &&
                  getstage?.results?.map((item, index) => {
                    if (item?.id === 6) return <></>;
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
            </select>
            <span className="fs-12 text-red">
              {!stageId && clicked && "Please select stage"}
            </span>
          </div>
          <div
            style={
              {
                // backgroundColor: 'yellow'
              }
            }
            className="modal-footer border-0 px-md-5 px-4 py-4 butn-group justify-content-start"
          >
            <button
              onClick={() => {
                setClicked(true);
                if (stageId) {
                  if (setCheckBoxAdd) {
                    setCheckBoxAdd([]);
                  }
                  onCancel();
                  changeStage();
                  localStorage.setItem("contactdata", JSON.stringify(data));
                }
              }}
              style={{
                marginRight: "15px",
              }}
              type="button"
              className="custom-btn border-0 py-2 rounded-3 raleway medium"
            >
              Update
            </button>
            <button
              onClick={onCancel}
              type="button"
              className="custom-btn close-modal py-2  rounded-3 border-bl-btn px-4 raleway medium"
              // data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slidedown;
