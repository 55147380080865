import React from "react";
import useGetStageList from "../../libs/queries/StageApi/useGetStageList";
import useChangeStage from "../../libs/mutation/leads/useUpdateStage";
import { useState } from "react";
import useGetLeadListContact from "../../libs/queries/leads/useGetLeadListContact";
import PrsModal2 from "./PrsModal2";
import PrsCrmModal from "./PrsCrmModal";
import useGetCrm from "../../libs/queries/crm/useGetCrm";
import PrsIndustryModal from "./prsIndustryModal";
import useIndustryPrs from "../../libs/queries/graphs/useIndustryPrs";
import useGetPrsModalData from "../../libs/queries/graphs/useGetPrsModalData";

const PrsModal = ({
  onCancel,
  stageId,
  stageName,
  month,
  year,
  leadOwner,
  isCrm,
  contactId,
  isLeadAchieved,
  type,
  isIndustry
}) => {
  const [clicked, setClicked] = useState(false);
//   const { data: getstage } = useGetStageList();
  // const { data: contactlist } = useGetLeadListContact({
  const { data: contactlist } = useGetPrsModalData({
    id: isLeadAchieved? '': stageId, 
    leadType:type,
    leadOwner: leadOwner,
    month,
    year,
    isLeadAchieved: isLeadAchieved,
    userId: isLeadAchieved? leadOwner : '',
    prs_data: 1
  });

  // console.log('uuuu2_', isLeadAchieved)

  const { data } = useGetCrm({
    month : month,
    year: year,
    crmType: type,
    userId: leadOwner
  });

  const { data: industryList } = useIndustryPrs({
    // leadType:type,
    // leadOwner: isLeadAchieved? '': leadOwner,
    month,
    year,
    userId: leadOwner
  });

  return (
    <div
      className="modaldelete"
      // style={{zIndex: "10"}}
    >
      <div
        className="modal-dialog modal-460 modal-dialog-centered"
      >
        <div
        style={{
          // width: '450px',
          backgroundColor: 'white',
          overflowY: "scroll", maxHeight: "350px"
        }}
          // className="rounded-10 border-0 raleway "
          className="modal-content rounded-10 border-0 raleway "
        >
          <div className="modal-header pt-4 pb-0 px-4">
            <h4 className="semibold fs-14 ">{stageName}</h4>
            <button
              onClick={onCancel}
              type="button"
              className="selectContainer custom-btn close-modal py-1  rounded-3 border-bl-btn px-2 raleway medium"
            >
              Close
            </button>
          </div>
          <hr></hr>

          { !isCrm && !isIndustry &&
          <div style={{}}>
          {contactlist?.results?.length>0 &&
          <PrsModal2
          // data={item}
          // key={index}
          maindata={contactlist.results}
          />
          //   [{}].map((item, index) => {
          //     return (
          //       <PrsModal2
          //         data={item}
          //         key={index}
          //         maindata={contactlist.results}
          //       />
          //     );
          // })
          }

          {
            !contactlist?.results?.length && 
            <div className="d-flex justify-content-center">
              No Data
            </div>
          }
          </div>}

          {isIndustry &&
          <div style={{}}>
          {industryList?.data?.length>0 &&
          <PrsIndustryModal
          maindata={industryList?.data}
          />
          }

          {
            !industryList?.data?.length>0 && 
            <div className="d-flex justify-content-center">
              No Data
            </div>
          }
          </div>}

          

          {isCrm && <div>
            {data &&
            <PrsCrmModal
            maindata={data?.results}
            />
          }


          {
            !data?.results?.length && 
            <div className="d-flex justify-content-center">
              No Data
            </div>
          }
          </div>}
          
          <div
            className="modal-footer border-0 px-md-5 px-4 pb-2 butn-group justify-content-center"
          >
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrsModal;