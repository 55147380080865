import React from "react";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useGetLeadsAllGraph = ({
  businessModel,
  industrySector,
  sourceId,
  potentialId,
  graphType,
  isFortune,
  startDate,
  endDate,
  location,
  role,
  user_id
}) => {
  const queryParams = {
    business_model: businessModel || "",
    industry_sector: industrySector || "",
    source: sourceId || "",
    organisation: graphType === "organisation" ? 1 : "",
    potential_service_offering: potentialId || "",
    fortune_1000: isFortune || "",
    start_date: startDate || "",
    end_date: endDate || "",
    location: location || "",
    role: role || "",
    user_id: user_id || '',
  };
  const queryString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "") // Filter out keys with no value
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");
    
  const queryresult = useQuery({
    queryKey: [`reports`, { queryString }],
    // keepPreviousData:true,
    queryFn: async () => {
      const res = await api.get(`management/leadreport?${queryString}`);
     
      return res?.data;
    },
  });
// console.log("dsfafaf")
  return queryresult;
};

export default useGetLeadsAllGraph;
