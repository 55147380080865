import React, { useEffect, useState } from "react";
import ClientSegment from "./ClientSegment";
import ServiceOffering from "./ServiceOffering";
import SourceChart from "./SourceChart";
import Monthwise from "./Monthwise";
import LocationofClient from "./LocationofClient";
import ContactLevel from "./ContactLevel";
import BDowner from "./BDowner";
import CRMmonthwise from "./CRMmonthwise";
import DocumentUpload from "./DocumentUpload";
import RoleRestriction from "../../utils/roleRestriction";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'; 

const LeadsReport = ({data, type, onUserSelect, setDownloadPdf}) => {
  const [bdUserId, setBdUserId] = useState('');
  const restrict = RoleRestriction();

  const downloadPDF = () => {
    const input = document.getElementById('pdf-content'); 
    html2canvas(input)
      .then((canvas) => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 211, 298);
        pdf.save('leadreport.pdf');
      })
      .catch((error) => {
        console.log('Error generating PDF: ', error);
      });
  };

  useEffect(()=>{
    if(bdUserId){
      onUserSelect?.(bdUserId)
    }
  },[bdUserId])

  return (
    <div>
      <h1>Lead Generated Report</h1>
      <div 
      id="pdf-content"
      // className="row"
      className="d-flex flex-column gap-3"
      >
      {/* <div className="row pe-lg-5"> */}
        <div 
        className="d-flex gap-4"
        // className="row col-lg-3 col-md-3 col-sm-3 pb-4"
        >
          <ClientSegment data={data}/>
          <ServiceOffering data={data} />
         {type === "organisation"? <BDowner data={data} onUserSelect={setBdUserId} /> : ""}
          {/* <ContactLevel /> */}
        </div>
      {/* </div> */}

        <div className="d-flex gap-4">
        <div style={{width:'50%'}} className="bg-yellow">
          <SourceChart data={data} />
        </div>
        <div style={{width:'50%'}} className="bg-pink">
        <Monthwise data={data}/>
        </div>
        </div>
        {restrict === "relation" && <CRMmonthwise data={data} />}
        {/* {(restrict === "researcher" || restrict === 'practice') && <DocumentUpload data={data}  />} */}
        <LocationofClient data={data}/>
      </div>
      {/* <button onClick={downloadPDF}>Download PDF</button> */}
    </div>
  );
};

export default LeadsReport;
