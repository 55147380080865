import React, { useCallback, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { render } from "react-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import api, { baseURL } from "../../api";
// management/lead-contacts

const CACHE = {};

async function makeAndHandleRequest(query, page = 1) {
  const res = await api.get(
    `management/lead-contacts?search_key=${query}&searching=1`
  );
 
  return { options: res?.data?.results, total_count: res?.data?.count };
}

const ForClientSeachInput = ({
  value,
  onChange,
  errors,
  name,
  placeholder,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState("");

  const handleInputChange = (q) => {
    setQuery(q);
  };

  const handleSearch = useCallback((q) => {
    if (CACHE[q]) {
      setOptions(CACHE[q].options);
      return;
    }

    

    setIsLoading(true);
    makeAndHandleRequest(q).then((res) => {
      // console.log(res)
      CACHE[q] = { ...res, page: 1 };

      setIsLoading(false);
      setOptions(res.options);
    });
  }, []);

  return (
    <div>
      {/* <label>legal Entity Name</label> */}
      <AsyncTypeahead
        id="async-pagination-example"
        isLoading={isLoading}
        labelKey="name"
        onChange={(selected) => {
          // console.log(selected[0].id)
          onChange(selected[0]?.name);
        }}
        allowNew
        selected={value ? [value] : []}
        //   maxResults={PER_PAGE - 1}
        minLength={2}
        onInputChange={handleInputChange}
        //   onPaginate={handlePagination}
        onSearch={handleSearch}
        options={options}
        paginate
        placeholder={placeholder}
        renderMenuItemChildren={(option) => {
           
          return (
            <div key={option?.id}>
              <span>{option?.name}</span>
            </div>
          );
        }}
        useCache={false}
      />
      {errors[name] && (
        <span className="fs-12 text-red">{errors[name].message}</span>
      )}
    </div>
  );
};

export default ForClientSeachInput;
