import React from "react";
import UserDetailSection from "./UserDetailSection";
import NavDropDownOption from "./NavDropDownOption";
import useLogout from "../../libs/mutation/auth/useLogout";
import { useNavigate } from "react-router-dom";

const NavDropDownContainer = () => {
  const navigate = useNavigate()
  const { mutate: logout, isLoading } = useLogout();
  

  return (
    <>
      <div className="d-flex align-items-center justify-content-between pb-4 px-4">
        <h6 className="fs-20 medium">My Profile</h6>
        <a
          // href="#"
          onClick={logout}
          style={{cursor: "pointer"}}
          className="custom-btn bg-red rounded-10 fs-12 medium border-0 py-2"
        >
          Sign Out
        </a>
      </div>
      <UserDetailSection />
      <NavDropDownOption   />
    </>
  );
};

export default NavDropDownContainer;
