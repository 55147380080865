import React from "react";
/// hooks used ///
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

/// Imports of Component ///
import useCreateFilterData from "../../../libs/mutation/manage/useCreateFilterData";
import InputBox from "../../../ui/inputs/InputBox";
import SaveButton from "../../../ui/buttons/SaveButton";
import CancelButton from "../../../ui/buttons/CancelButton";

const AgreementGeneratedForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { newagreementid } = useSelector((state) => state.agreementSlice);
  const { mutate: createdagreementropdown, isLoading } = useCreateFilterData(
    newagreementid,
    "/manage/agreementgeneratedform"
  );

  const navigate = useNavigate();

  return (
    <div className="bg-white p-4">
      <form
        className="add-form"
        onSubmit={handleSubmit(createdagreementropdown)}
      >
        <div className="row  gx-lg-5 align-items-center">
          <Controller
            name="name"
            control={control}
            rules={{ required: "Name is Required" }}
            render={({ field: { value, onChange } }) => {
              return (
                <InputBox
                  label="Name"
                  name="name"
                  errors={errors}
                  type="text"
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          />
        </div>
        <div className="d-flex border-0 butn-group justify-content-center gap-3 pt-4">
          <SaveButton type="submit" disabled={isLoading} />
          <CancelButton onClick={() => navigate("/manage/agreementgeneratedform")} />
        </div>
      </form>
    </div>
  );
};

export default AgreementGeneratedForm;
