import React from "react";
import BarChart from "../../ui/graphs/BarChart";

const DocumentUpload = ({data}) => {

  const {document_label, document_data, document_color } = data || {}
  const documentcolor = ["rgba(255, 99, 132, 0.5)"]

  return (
    <div className="bg-white py-4 px-4 border ">
      <div id={'pdf-document'} className="card-body">
        <h3 className="mb-3">Document Upload</h3>
        {document_data?.length ? (
          <BarChart
            data={document_data}
            datalabel={document_label}
            datacolor={documentcolor}
          />
        ) : (
          <div className="my-4 d-flex justify-content-center">No Data</div>
        )}
      </div>
    </div>
  );
};

export default DocumentUpload;
