import React from 'react'
import DoubleHorizBarChart from '../../ui/graphs/DoubleHorizBarChart'

const MeetingOrg = ({productivitydata}) => {

  const {meeting_organised_data} = productivitydata || {};
  const meetingdata = meeting_organised_data?.map((item) => item?.meeting_organised)
  const meetinglabel = meeting_organised_data?.map((item) => item?.name)
  const meetingtarget = meeting_organised_data?.map((item) => item?.meeting_organised_target)
 

  return (

    <div className="bg-white py-4 px-4 border " style={{ width: "100%"}}>
    <div className="card-body">
      <h3 className="mb-3">Meeting Organized</h3>
      <DoubleHorizBarChart data={meetingdata} label={meetinglabel} meetingtarget={meetingtarget} />
    </div>
  </div>
  )
}

export default MeetingOrg