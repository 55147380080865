import React from 'react'
import BarChart from '../../ui/graphs/BarChart'

const CRMmonthwise = ({data}) => {

    console.log(data)
    const {crm_data, crm_label} = data || {}

    const crmcolor = ["rgba(255, 99, 132, 0.5)"]



  return (
    <div className="bg-white py-4 px-4 border ">
      <div className="card-body">
        <h3 className="mb-3">Monthwise CRM</h3>
        {
          crm_data?.length?
          <BarChart
          data={crm_data}
          datalabel={crm_label}
          datacolor={crmcolor}
          />
          :
          <div className='my-4 d-flex justify-content-center'>
          No Data
          </div>
        } 
      </div>
    </div>
  )
}

export default CRMmonthwise