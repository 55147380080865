import React, { useEffect, useState } from "react";
import BoxChart from "../../ui/graphs/BoxChart";

const BDowner = ({ data, onUserSelect, title }) => {
  const { BD_Owner:bd_owner_data } = data || {};
  const [bdUserId, setBdUserId] = useState('');

  useEffect(()=>{
    if(bdUserId){
      onUserSelect?.(bdUserId)
    }
  },[bdUserId])

  // const {BD Owner} = bd_owner_data || {}
  // console.log(bd_owner_data.map((item) => item))

  return (
    <div className="bg-white py-4 px-4 border " >
      <div className="card-body w-100 h-100">
        <h3 className="mb-3">{title || "BD Owner"}</h3>
        <div className="d-flex w-100 gap-2" style={{display: "flex", flexWrap: "wrap"}}>
          {bd_owner_data?.length > 0 ?
            bd_owner_data?.map((item, index) => {
              return (
            
                  <BoxChart 
                  key={index} 
                  data={item} 
                  onUserSelect={setBdUserId}
                  />
           
              );
            }) : ""}
        </div>
      </div>
    </div>
  );
};

export default BDowner;
