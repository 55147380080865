import React from "react";
import DoubleBarChart from "../../ui/graphs/DoubleBarChart";
import BarChart from "../../ui/graphs/BarChart";

const OnboardingDay = ({ dayData }) => {

  // console.log('daydata_', JSON.stringify(dayData, null, 4))
  const { clients_onboarded } = dayData?.data || {};

  // const countdata = clients_onboarded?.map((item) => item?.count);
  // const labeldata = clients_onboarded?.map(
  //   (item) => item?.name
  // );
  // const target = clients_onboarded?.map((item) => item?.clients_onboarded_target)
    // console.log(target)

  return (
    <div>
      <BarChart
      // datalabel={labeldata} 
      // datacolor={['orange']} 
      // data={countdata} 
      dataOnboarded={clients_onboarded}
      />
      {/* <DoubleBarChart data={countdata} label={labeldata}  leadtarget={target}  /> */}
    </div>
  );
};

export default OnboardingDay;
