import React from "react";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import PlusButton from "../../ui/buttons/PlusButton";
import Heading from "../../ui/modals/Heading";
import ViewMoreModal from "../../ui/modals/ViewMoreModal";
import useGetStageList from "../../libs/queries/StageApi/useGetStageList";
import { useState } from "react";
import useProfile from "../../libs/queries/profile/useProfile";
import { useNavigate } from "react-router-dom";
import StageRow from "../../Components/Manage/NewStage/StageRow";

const Lead = () => {
  const [type, setType] = useState("my");
  const { data } = useGetStageList(null, null, "lead");

  const { data: userprofile } = useProfile();
  
  const navigate = useNavigate();

  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <Subheader
          leftsideshow={<TwoButtonHolder onSelect={setType} label={"Leads"} />}
          rightsideshow={
            userprofile?.role_display === "Enabling Function" ? (
              ""
            ) : (
              <PlusButton name="New Lead" navto="/leads/new" />
            )
          }
        />
        <Heading heading="Active Leads" isLocation={true} />
       
        <div className="row pt-4">
          {data &&
            data.results?.map((item, index) => {
              return (
                <ViewMoreModal
                  key={index}
                  data={item}
                  heading={item.name}
                  type={type}
                />
              );
            })}

          <div style={{ textAlign: "center", cursor: "pointer" }}>
            <a
              style={{ textDecoration: "underline", color: "blue" }}
              onClick={() => navigate("/leads/viewall", {state: {view: type}})}
            >
              View All
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lead;
