import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    navigatesource: ""
    
  };

  const leadviewSlice = createSlice({
    name: "leadviewSlice",
    initialState,
    reducers: {
      setNavigateSource: (state, action) => {
        state.navigatesource = action.payload;
      },
    
    },
  });

  export const { setNavigateSource } =
  leadviewSlice.actions;
export default leadviewSlice.reducer;
