import React, { useEffect } from "react";
/// hooks used ///
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

/// Imports of Component ///
import useUpdateFilterData from "../../../libs/mutation/manage/useUpdateFilterData";
import EditInputBox from "../../../ui/inputs/EditInputBox";
import SaveButton from "../../../ui/buttons/SaveButton";
import CancelButton from "../../../ui/buttons/CancelButton";

const MarketGeneratedEdit = ({ onHide, data, index }) => {
  const { id, name, ddtype } = data || {};
  const { newmarketgenid } = useSelector(
    (state) => state.marketIntGenerationSlice
  );
  const { mutate: updatedropdown, isLoading } = useUpdateFilterData(
    id,
    onHide,
    newmarketgenid
  );
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    if (data) {
      reset({ name, ddtype });
    }
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmit(updatedropdown)}
        className="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3 edit-info-table "
      >
        <table className="action-table w-100 manage-table">
          <tr>
            <td className="action-first">
              <span className="number d-flex align-items-center justify-content-center rounded-circle fs-24 semibold">
                {index + 1}
              </span>
            </td>

            <td className="action-mid">
              <div className="row pe-xl-5">
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Name is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <EditInputBox
                    
                        name="name"
                        label="Name"
                        errors={errors}
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </div>
            </td>
          </tr>
        </table>

        <div className="d-flex border-0 butn-group justify-content-end  gap-3">
          <SaveButton type="submit" disabled={isLoading} />
          <CancelButton onClick={onHide} />
        </div>
      </form>
    </>
  );
};

export default MarketGeneratedEdit;
