import React, { useEffect, useState } from "react";

const UserRadioFilter = ({ value, onChange, options = [], name }) => {
  const [selected, setSelected] = useState(value);

  const handleReset = (event, id) => {
    console.log(event.target.checked);
    if (selected == id) {
      setSelected(null);
    } else {
      setSelected(id);
    }
  };

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  return (
    
      <>
        {options &&
          options?.map((item, index) => {
            return (
              <div
                key={index}
                //   onChange={(event) => handleChange(event)}
                className="d-flex justify-content-between align-items-center py-1 px-3"
              >
                <label className="medium fs-14" htmlFor={item.id}>
                  {item?.fullname}
                </label>
                <input
                  onClick={(event) => handleReset(event, item?.id)}
                  name={name}
                  checked={selected === item?.id}
                  value={item?.id}
                  type="radio"
                  id={item?.id}
                  // value={item?.id}
                />
              </div>
            );
          })}
      </>
  
  );
};

export default UserRadioFilter;
