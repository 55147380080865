import React from "react";
import api, { Imageapi } from "../../../api/index";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const useCreateDocument = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data) => {
      // console.log(data);
      let postForm = new FormData();
      postForm.append("name", data.name);
      postForm.append("researcher", data.researcher);
      postForm.append("for_client", data.for_client);
      postForm.append("date_of_upload", data.date_of_upload);
      postForm.append("doc_type", data.doc_type);
      postForm.append("description", data.description);
      postForm.append("skills", data.skills);
      // postForm.append("file", data.file);
      postForm.append("paid", data?.paid ? true : false);
      postForm.append("doc_category", data.doc_category);
      let res;
      if (typeof data.file === "object") {
        console.log("append");
        postForm.append("file", data.file);

        res = await Imageapi.post("market/documentfile/create/", postForm);
      } else {
        console.log("noo");

        res = await api.post("market/documentfile/create/", data);
      }

      // console.log(data);
      // const res = await Imageapi.post("market/documentfile/create/", postForm);

      return res?.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([`market`]);
      // toast.success(data.message);
      if(!data?.status){
        toast.error(data?.message)
        // navigate("/documentrepository");
      }
      else{
        toast.success(data?.message);
        navigate("/documentrepository");
      }
      // navigate("/documentrepository");
    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });
  return mutation;
};

export default useCreateDocument;
