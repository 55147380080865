import React, { useState } from "react";
/// hooks used ///
import { useSelector } from "react-redux";

/// Imports of Component ///
import MarketGenerated from "./MarketGenerated";

import useFilterData from "../../../libs/queries/manage/useFilterData";
import useFilters from "../../../libs/queries/manage/useFilters";
import Pagination from "../../../ui/pagination";
import { useEffect } from "react";

const MarketGenerationContainer = () => {
  const [pageToLoad, SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const  [focused, setFocused] = useState(false);
  const [totalPages, setTotalPages] = useState("");
  const { data: filters, isLoading } = useFilters("lead");

  const { marketgenid } = useSelector(
    (state) => state.marketIntGenerationSlice
  );
  // const [initial, setInitial] = useState(0);

  // useEffect(()=>{
  //   setTimeout(()=>{
  //     setInitial(marketgenid);
  //   },2000)
  // },[])
  
  useEffect(()=>{
    if(marketgenid){
      SetPageToLoad(1);
      setFocused(true)
    }
  },[marketgenid])


  const { data } = useFilterData(
    marketgenid ? marketgenid : null,
    pageToLoad,
    itemsPerPage
  );

  return (
    <div>
      <Pagination
        onPageChange={SetPageToLoad}
        onItemsChanged={setItemsPerPage}
        totalPages={Math.ceil(data?.count / itemsPerPage)}
        defaultCount={itemsPerPage}
        focus={pageToLoad==1?true:false}
      />
      {data &&
        data?.results?.map((data, index) => {
          return <MarketGenerated key={index} index={index} data={data} />;
        })}
    </div>
  );
};

export default MarketGenerationContainer;
