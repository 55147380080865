import api from "../../../api/index";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useCreatepwd = () => {

  const {auth} = useSelector((state) => state.authSlice)

  const navigate = useNavigate()

  const CreatepwdMutation = useMutation({
    mutationFn: async (data) => {
      data.code = auth.code
      data.email = auth.email
      const res = await api.post("account/new-password/", data);
      return res.data;
    },
    onSuccess: (data) => {

      if(data.status === true){
        navigate('/gotologin')
      }
    },

    onError: (error) => {
      toast.error(`${error['response'].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });

  return CreatepwdMutation;
};

export default useCreatepwd;
