import React from "react";

const DropDown = ({ innerbody, tag, title, backgroundcolor }) => {


  return (
    <div className="">
      <a
        className={`dash-acco-title d-flex justify-content-between align-items-center rounded-3 px-3 py-2 bg-${backgroundcolor} text-black medium mb-2`}
        data-bs-toggle="collapse"
        href={tag}
        aria-expanded="false"
      >
        {title}
        <i className="fa fa-chevron-down"></i>
      </a>

      {innerbody}
    </div>
  );
};

export default DropDown;
