import React, { useEffect, useState } from "react";
import DownloadIcon from "../../../img/icon/download.svg";
import api from "../../../api";
import Loading from "../../../CommonComponents/Loading";

const StageRow = ({ heading, sort, search, url }) => {
  const [isLoading, setIsLoading] = useState(false);

  const getDownloadData = async()=>{
    try{
      setIsLoading(true);
      let resp = await api.get(url);
      if(resp && resp.data){
        // setData(resp?.data)
        download_csv(resp?.data)
      }
    }
    catch(e){
      setIsLoading(false);
    }
  }

  const download_csv = function (data) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', 'download.csv');
    a.click()
    setIsLoading(false);
  }
  return (
    <div className="d-sm-flex align-items-center justify-content-between">
      <h1 className="page-title fs-24 bold px-3 py-2 w-100">{heading}</h1>
      <div className="d-flex align-items-center gap-2 mt-sm-0 mt-4">
        {search}

        {sort}
  
        {heading!="Locations" && <div className="selectContainer">
          <a
          onClick={()=>{
            getDownloadData();
          }}
          className="filter-bar-icon">
            {isLoading? <Loading isButton={true}/> :<img src={DownloadIcon} />}
            <i></i>
          </a>
        </div>}
      </div>
    </div>
  );
};

export default StageRow;
