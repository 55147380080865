import React, { useState } from "react";
/// hooks used ///

/// Imports of Component ///
import useGetUser from "../../../libs/queries/profile/useUsers";
import UserGenerated from "./UserGenerated";
import Pagination from "../../../ui/pagination";

const UserContainer = () => {
  const [pageToLoad,SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const { data: userlist } = useGetUser(pageToLoad, itemsPerPage);



  return (
    <div>
        <Pagination
        onPageChange={SetPageToLoad}
        onItemsChanged={setItemsPerPage}
        totalPages={Math.ceil(userlist?.count/itemsPerPage)}
        defaultCount={itemsPerPage}
        />
        {userlist && userlist?.results?.map((data, index) => {
          return <UserGenerated key={index} index={index} data={data} />
        })}
    
    </div>
  );
};

export default UserContainer;
