import { Outlet } from "react-router-dom";
import MarketIntelligence from "../Pages/MarketIntelligence/MarketIntelligence";
import NewDocument from "../Pages/MarketIntelligence/NewDocument";
import NewCampaign from "../Pages/MarketIntelligence/NewCampaign";
import CampaignList from "../Pages/MarketIntelligence/CampaignList";

const marketRouter = {
  path: "/documentrepository/",
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <MarketIntelligence />,
    },
    {
      path: "newdocument",
      element: <NewDocument />,
    },
    {
      path: "newcampaign",
      element: <NewCampaign />,
    },
    {
        path: "campaign",
        element: <CampaignList />,
      },
  ],
};

export default marketRouter;
