import React, { useEffect, useState } from "react";
import InputBox from "../../ui/inputs/InputBox";
import { useForm, Controller } from "react-hook-form";
import DropdownInput from "../../ui/inputs/DropdownInput";
import DateInputBox from "../../ui/inputs/DateInputBox";
import CheckboxInput from "../../ui/inputs/CheckboxInput";
import LinkInputBox from "../../ui/inputs/LinkInputBox";
import useUpdateDocument from "../../libs/mutation/market/useUpdateDocument";
import FilePreview from "../leads/FilePreview";
import useFilterData from "../../libs/queries/manage/useFilterData";
import useGetLeadListContact from "../../libs/queries/leads/useGetLeadListContact";
import useUsers from "../../libs/queries/profile/useUsers";
import ResearcherSearchInput from "./ResearcherSearchInput";
import ForClientSeachInput from "./ForClientSeachInput";

const DocumentListEdit = ({ data, onHide }) => {
  const { isLoading: L1 } = useFilterData(34, null, true, true);
  const [selectedLead, setSelectedLead] = useState(null);
  const [forClient, setForClient] = useState(null);
  const { isLoading: L2 } = useFilterData(30, null, true, true);
  const { isLoading: contact } = useGetLeadListContact({
    stage_id: null,
    leadType: false,
  });
  const { isLoading: userslist } = useUsers();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm();

  const {
    id,
    name,
    researcher,
    for_client,
    date_of_upload,
    doc_type,
    doc_category,
    doc_link,
    description,
    skills,
    paid,
    uploaded_by_display,
    file,
  } = data || {};

  const [fileupload, setFileUpload] = useState(null);
  const { mutate: updateDocument } = useUpdateDocument({ id, onHide });
  console.log('doc__',doc_type);
  console.log('watcdoc_', watch("doc_type"))

  const handleChange = (event, onChange) => {
    setFileUpload(event.target.files[0]);
    console.log(fileupload);
    onChange(event.target.files[0]);
  };

  const handleLegalChange = (value, onChange) => {
    if (value?.customOption) {
      setSelectedLead(null);
    } else {
      setSelectedLead(value);
    }
    onChange(value);
    // console.log(value);
  };

  const handleForClientChange = (value, onChange) => {
    if (value?.customOption) {
      setForClient(null);
    } else {
      setForClient(value);
    }
    onChange(value);
  };

  useEffect(() => {
    if ((!L1 && !L2 && !contact, !userslist)) {
      if (data) {
        reset({
          name,
          researcher,
          for_client,
          date_of_upload,
          doc_type,
          description,
          skills,
          doc_link,
          paid,
          doc_category,
        });
      }
    }
  }, [L1, L2, contact, userslist]);

  return (
    <form
      onSubmit={handleSubmit(updateDocument)}
      className="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3 edit-info-table"
    >
      <table className="action-table w-100">
        <tbody>
          <tr>
            <td width="9%">
              <div className="d-flex align-items-center gap-lg-4 gap-2"></div>
            </td>

            <td className="action-mid">
              <div className="row pe-xl-5">
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Legal Entity Name is Required" }}
                  render={({ field: { value, onChange, name } }) => {
                    return (
                      <InputBox
                        label="Document Name"
                        name={name}
                        errors={errors}
                        placeholder="Enter Name"
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />
                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <DropdownInput
                    id={30}
                    name="doc_type"
                    label="Doc Type"
                    register={register}
                    errors={errors}
                  />
                </div>

                <Controller
                  name="uploaded_by"
                  control={control}
                  defaultValue={uploaded_by_display}
                  // rules={{ required: "Legal Entity Name is Required" }}
                  render={({ field: { value, onChange, name } }) => {
                    return (
                      <InputBox
                        disabled="disabled"
                        label="Uploaded By"
                        name={name}
                        errors={errors}
                        placeholder="Enter Name"
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label className="d-block text-sky fs-12 pb-2">
                    Researcher
                  </label>
                  <Controller
                    name={"researcher"}
                    control={control}
                    rules={{ required: "Researcher is Required" }}
                    render={({ field: { value, onChange, name } }) => {
                      // console.log("see value", value);
                      return (
                        <ResearcherSearchInput
                          value={value}
                          placeholder={"Enter Researcher"}
                          onChange={(value) =>
                            handleLegalChange(value, onChange)
                          }
                          errors={errors}
                          name={name}
                        />
                      );
                    }}
                  />
                </div>

                <Controller
                  name="description"
                  control={control}
                  rules={{ required: "Description is Required" }}
                  render={({ field: { value, onChange, name } }) => {
                    return (
                      <InputBox
                        label="Description"
                        name={name}
                        errors={errors}
                        placeholder="Enter Name"
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label className="d-block text-sky fs-12 pb-2">
                    For Client
                  </label>
                  <Controller
                    name={"for_client"}
                    control={control}
                    rules={{ required: "For Client is Required" }}
                    render={({ field: { value, onChange, name } }) => {
                      return (
                        <ForClientSeachInput
                          value={value}
                          placeholder={"Enter For Client"}
                          onChange={(value) =>
                            handleForClientChange(value, onChange)
                          }
                          errors={errors}
                          name={name}
                        />
                      );
                    }}
                  />
                </div>

                <Controller
                  name="date_of_upload"
                  control={control}
                  render={({ field: { value, onChange, name } }) => {
                    return (
                      <DateInputBox
                        disabled="disabled"
                        label="Date of Upload"
                        name={name}
                        errors={errors}
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select a date.",
                    },
                  }}
                />

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <Controller
                    name="paid"
                    control={control}
                    // rules={{ required: "Company Name is Required" }}
                    render={({ field: { value, onChange, name } }) => {
                      // console.log(value);
                      return (
                        <CheckboxInput
                          label="Paid"
                          name={name}
                          checked={value}
                          // value={value || ""}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <DropdownInput
                    id={34}
                    name="doc_category"
                    label="Doc Category"
                    register={register}
                    errors={errors}
                  />
                </div>

                <div className="col-lg-3 col-md-12 col-sm-12 pb-4">
                  <span>
                    <Controller
                      name="file"
                      control={control}
                      rules={{
                        required: {
                          value: watch("doc_type")==doc_type? false : true,
                          message: "Please select file.",
                        },
                      }}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <input
                            type="file"
                            id="upload"
                            className="d-none"
                            files={value}
                            // accept=".doc, .pdf"
                            accept={watch("doc_type") == 643 ?".doc, docx": 
                            watch("doc_type") == 642 ?".pdf": watch("doc_type") == 644 ? ".eml" :
                            watch("doc_type") == 645 ? ".xls, .xlsx": ''}
                            onChange={(event) => handleChange(event, onChange)}
                            multiple
                          />
                        );
                      }}
                    />

                    {/* <input type="file" id="upload" className="d-none" /> */}
                    {watch("doc_type") == 646 ? (
                      <Controller
                        name="doc_link"
                        control={control}
                        rules={{ required: "Link is Required" }}
                        render={({ field: { value, onChange, name } }) => {
                          return (
                            <div>
                              <LinkInputBox
                                // label="New Document"
                                name={name}
                                placeholder="Enter Link"
                                errors={errors}
                                type="text"
                                value={value || ""}
                                onChange={onChange}
                              />
                            </div>
                          );
                        }}
                      />
                    ) : (
                      <label
                        htmlFor="upload"
                        className="px-4 py-2 rounded-2 text-nowrap attach-btn"
                      >
                        Attach a File
                      </label>
                    )}
                  </span>
                  <div className="fs-12 text-red">
                      {errors.file?.type === "required" && "Please Upload File"}
                    </div>

                  {(fileupload || file) && (
                    <FilePreview
                      fileupload={fileupload}
                      data={watch("doc_type")==doc_type? file: ''}
                      setFileUpload={setFileUpload}
                    />
                  )}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="d-flex border-0 butn-group justify-content-end gap-3">
        <button
          type="submit"
          className="custom-btn border-0 py-2 rounded-3 raleway medium"
        >
          Save
        </button>
        <button
          type="button"
          onClick={onHide}
          className="custom-btn py-2  rounded-3 border-bl-btn px-4 raleway semibold"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default DocumentListEdit;
