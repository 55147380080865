import React from "react";
import LeadIcon from '../../img/icon/lead.svg'

const Counter = ({backgroundcolor, data, heading, currentCount, averageCount, previousMonthCounts}) => {



  return (
    <div className="col-md-3 col-sm-6 mb-4">
      <div className={`dashboard-box + ${backgroundcolor}`} >
        <h5 className="medium text-black pb-4">
          <img src={LeadIcon} alt="" />
          {heading}
        </h5>
        <h2 className="d-flex align-items-end gap-3 pb-2">
          {currentCount}
          <span className={`${averageCount >= 0 ? "value-up" : "value-down"}`}>
            {averageCount >= 0 ? <i className="fa fa-arrow-up"></i> : <i className="fa fa-arrow-down"></i>} {Math.abs(averageCount)}%
          </span>
        </h2>
        <h4 className="fs-12">vs last Month: {previousMonthCounts}</h4>
      </div>
    </div>
  );
};

export default Counter;
