import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useRoles from "../../../libs/queries/profile/useRoles";
import useUpdateUser from "../../../libs/mutation/auth/useUpdateUser";

const UserGenerationEdit = ({ data, index, onHide }) => {
  const { id, fullname, email, phone, team, role, designation, targets } =
    data || {};
  const {
    accepted_leads_generated,
    proposal_submission,
    meeting_organized,
    assignment_recieved,
    onboarding_renewal,
  } = targets || {};

  const [roleValueName, setRoleValueName] = useState('');
  const { data: rolesData } = useRoles();
  const { mutate: updateuser, isLoading } = useUpdateUser(id, onHide);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
    watch
  } = useForm();

  const roleValue = watch('role');

  useEffect(()=>{
    if(roleValue){
      setRoleValueName(rolesData?.filter((item)=>item?.id==watch('role'))[0]?.name)
    }
  },[roleValue])

  // const roleValueName = rolesData?.filter((item)=>item?.id===watch('role'))[0]?.name;
  // console.log('rolevalname__', roleValueName)
  useEffect(() => {
    if (data) {
      reset({
        fullname,
        email,
        phone,
        role:rolesData?.filter((item)=>item?.name===role)[0]?.id,
        team,
        designation,
        accepted_leads_generated,
        proposal_submission,
        meeting_organized,
        assignment_recieved,
        onboarding_renewal,
      });
    }
  }, []);

  // console.log('djdss', JSON.stringify(rolesData.filter((item)=>item.name===role), null, 4))

  return (
    <div className="bg-white p-4">
      <form className="add-form" onSubmit={handleSubmit(updateuser)}>
        <div className="d-flex">
          <div className="action-first">
            <span className="number d-flex align-items-center justify-content-center rounded-circle fs-24 semibold">
              {index + 1}
            </span>
          </div>
          <div>
            <div className="row gx-lg-5 align-items-center">
              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-field"
                  placeholder=""
                  name="fullname"
                  id="fullname"
                  {...register("fullname", { required: true })}
                />
                <span className="fs-12 text-red">
                  {errors.fullname?.type === "required" && "Name is Required"}
                </span>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label className="d-block text-sky fs-12 pb-2">Email</label>
                <input
                  type="email"
                  className="form-field"
                  placeholder=""
                  name="email"
                  {...register("email", { required: true })}
                />
                <span className="fs-12 text-red">
                  {errors.email?.type === "required" && "Email is required"}
                </span>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label className="d-block text-sky fs-12 pb-2">Phone</label>
                <input
                  type="tel"
                  className="form-field"
                  name="phone"
                  placeholder=""
                  {...register("phone", { required: true })}
                />
                <span className="fs-12 text-red">
                  {errors.phone?.type === "required" &&
                    "Phone number is required"}
                </span>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label className="d-block text-sky fs-12 pb-2">Password</label>
                <input
                  type="password"
                  className="form-field"
                  name="password"
                  placeholder=""
                  {...register("password")}
                />
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label className="d-block text-sky fs-12 pb-2">Role</label>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => {
                    return (
                      <select
                      defaultValue={role}
                      {...field}>
                        {rolesData?.map((item, index) => {
                          return (
                            <option
                              className="form-field"
                              key={index}
                              value={item.id}
                            >
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    );
                  }}
                />
                {/* <select>
                  {rolesData?.map((item, index) => {
                    return (
                      <option
                        id="role"
                        className="form-field"
                        key={index}
                        value={item.id}
                        {...register("role", {
                          required: true,
                        })}
                      >
                        {item?.name}
                      </option>
                    );
                  })}
                </select> */}
                <span className="fs-12 text-red">
                  {errors.role?.type === "required" && "Role is required"}
                </span>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                  Designation
                </label>
                <input
                  type="text"
                  className="form-field"
                  placeholder=""
                  name="designation"
                  {...register("designation", { required: true })}
                />
                <span className="fs-12 text-red">
                  {errors.designation?.type === "required" &&
                    "Designation is Required"}
                </span>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                  Team
                </label>
                <input
                  type="text"
                  className="form-field"
                  placeholder=""
                  name="team"
                  id="team"
                  {...register("team", { required: true })}
                />
                <span className="fs-12 text-red">
                  {errors.team?.type === "required" && "Team is Required"}
                </span>
              </div>

              {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                  Team
                </label>
                <input
                  type="text"
                  className="form-field"
                  placeholder=""
                  name="team"
                  id="team"
                  {...register("team", { required: true })}
                />
                <span className="fs-12 text-red">
                  {errors.team?.type === "required" &&
                    "Team is Required"}
                </span>
              </div> */}
            </div>

            {roleValueName.toLowerCase()!="enabling function" && <h4 className="pt-4 pb-3">Targets</h4>}
            {roleValueName.toLowerCase()!="enabling function" && <div className="row gx-lg-5 align-items-center">
              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                  Accepted Leads Generated
                </label>
                <input
                  defaultValue={10}
                  min={0}
                  type="number"
                  className="form-field"
                  placeholder=""
                  name="accepted_leads_generated"
                  id="accepted_leads_generated"
                  {...register("accepted_leads_generated", {
                    required: true,
                  })}
                />
                <span className="fs-12 text-red">
                  {errors.accepted_leads_generated?.type === "required" &&
                    "Accepted Leads Generated is Required"}
                </span>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label className="d-block text-sky fs-12 pb-2">
                  Proposal Submission
                </label>
                <input
                  defaultValue={10}
                  min={0}
                  type="number"
                  className="form-field"
                  placeholder=""
                  name="proposal_submission"
                  {...register("proposal_submission", { required: true })}
                />
                <span className="fs-12 text-red">
                  {errors.proposal_submission?.type === "required" &&
                    "Proposal Submission is required"}
                </span>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label className="d-block text-sky fs-12 pb-2">
                  Meeting/E-meeting Organised
                </label>
                <input
                  defaultValue={10}
                  min={0}
                  type="mumber"
                  className="form-field"
                  name="meeting_organized"
                  placeholder=""
                  {...register("meeting_organized", { required: true })}
                />
                <span className="fs-12 text-red">
                  {errors.meeting_organized?.type === "required" &&
                    "Meeting Organised is required"}
                </span>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label className="d-block text-sky fs-12 pb-2">
                  Pilot Assignment Received
                </label>
                <input
                  defaultValue={10}
                  min={0}
                  type="number"
                  className="form-field"
                  name="assignment_recieved"
                  placeholder=""
                  {...register("assignment_recieved", { required: true })}
                />
                <span className="fs-12 text-red">
                  {errors.assignment_recieved?.type === "required" &&
                    "Pilot Assignment Received is required"}
                </span>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                  Onboarding & Agreement & Renewal
                </label>
                <input
                  defaultValue={10}
                  min={0}
                  type="number"
                  className="form-field"
                  placeholder=""
                  name="onboarding_renewal"
                  id="onboarding_renewal"
                  {...register("onboarding_renewal", { required: true })}
                />
                <span className="fs-12 text-red">
                  {errors.onboarding_renewal?.type === "required" &&
                    "Onboarding is Required"}
                </span>
              </div>
            </div>}
          </div>
        </div>

        <div className="d-flex border-0 butn-group justify-content-center gap-3 pt-4">
          <button
            type="submit"
            className="custom-btn border-0 py-2 rounded-3 raleway medium"
          >
            Save
          </button>

          <button
            type="button"
            onClick={onHide}
            // onClick={() => navigate("/userslist")}
            className="custom-btn close-modal py-2  rounded-3 border-bl-btn px-4 raleway semibold"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserGenerationEdit;
