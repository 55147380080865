import React from "react";
import DonutChart from "../../ui/graphs/DonutChart";
import useGetAgreementGraph from "../../libs/queries/graphs/useGetAgreementGraph";

const AgreementService = ({agreementdata}) => {

    // const {data} = useGetAgreementGraph()
  const {service_offering_data, service_offering_label, service_offering_color} = agreementdata || {}
//   console.log(data)

  return (
    <div className="bg-white py-4 px-4 border w-50">
      <div className="card-body">
        <h3 className="mb-3">Service Offering</h3>
        <div className="d-flex justify-content-center">
        {!service_offering_data?.length ?
        <div className='my-4'>
          No Data
        </div>
        :
        <DonutChart
          datalabel={service_offering_label}
          datacolor={service_offering_color}
          data={service_offering_data}
        />
        }
        </div>
      </div>
    </div>
  );
};

export default AgreementService;
