import React, { useState } from "react";
import CrmLogHeader from "../../Components/crm/CrmLogHeader";
import Heading from "../../ui/modals/Heading";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import PlusButton from "../../ui/buttons/PlusButton";
import LastLogTable from "../../Components/crm/LastLogTable";
import { useParams } from "react-router-dom";
import { Endpoints } from "../../api/Endpoints";
import Search from "../../ui/search/Search";
import Pagination from "../../ui/pagination";
import useGetContactLog from "../../libs/queries/crm/useGetContactLog";

const LastContactLog = () => {
  const [checkBoxAdd, setCheckBoxAdd] = useState([]);
  const [search, setSearch] = useState("");
  const [pageToLoad, SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { id } = useParams();
  // console.log("this is log",checkBoxAdd);
  const { data } = useGetContactLog({
    search: search,
    page: pageToLoad,
    itemsPerPage: itemsPerPage,
  });

  return (
    <>
      <div className="main py-4 position-relative">
        <div className="container">
          <Subheader
            leftsideshow={<div className="col-sm-6 pb-sm-0 pb-3"></div>}
            rightsideshow={
              <PlusButton
                name="New Communication"
                navto={`/crm/newcommunication/${id}`}
              />
            }
          />

          <Heading
            search={<Search onSearch={setSearch} />}
            heading="CRM"
            onSearch={setSearch}
            url={Endpoints.contactLogCsv}
          />

          <CrmLogHeader
            heading="Last Contact Logs"
            setCheckBoxAdd={setCheckBoxAdd}
            checkBoxAdd={checkBoxAdd}
          />
          <Pagination
            onPageChange={SetPageToLoad}
            onItemsChanged={setItemsPerPage}
            totalPages={Math.ceil(data?.count / itemsPerPage)}
            defaultCount={itemsPerPage}
          />
          <LastLogTable
            crmid={id}
            data={data}
            setCheckBoxAdd={setCheckBoxAdd}
            checkBoxAdd={checkBoxAdd}
            search={search}
            setSearch={setSearch}
          />
        </div>
      </div>
    </>
  );
};

export default LastContactLog;
