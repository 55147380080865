import React, { useEffect, useRef, useState } from "react";
import RadioFilters from "./RadioFilters";
import useFilterData from "../../libs/queries/manage/useFilterData";
import { Typeahead } from "react-bootstrap-typeahead";
import useLocationList from "../../libs/queries/location/useLocation";
import UserRadioFilter from "./UserRadioFilter";
import useUsers from "../../libs/queries/profile/useUsers";

const CampaignFilter = ({
  showSort,
  onIndustrySectorSelect,
  onBusinessModalSelect,
  onContactDepSelect,
  onLocationSelect,
  onServiceOfferSelect,
  onBusinessPotentialSelect,
  onUserSelect,
  onFortuneSelect
}) => {
  const { data: industryoptions } = useFilterData(1, null, true, true);
  const { data: businessmodeloptions } = useFilterData(8, null, true, true);
  const { data: contactDepOptions } = useFilterData(12, null, true, true);
  const { data: locations } = useLocationList(null, null);
  const { data: serviceoffoptions } = useFilterData(9, null, true, true);
  const { data: businesspotential } = useFilterData(3, null, true, true);
  const { data: userList } = useUsers();
  const locationList = locations?.results;
  const userlist = userList?.results;
  const [isOpen, setIsOpen] = useState(showSort);
  const [industrySectorId, setIndustrySectorId] = useState("");
  const [businessModalId, setBusinessModalId] = useState("");
  const [contactDepId, setContactDepId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [serviceOffer, setServiceOffer] = useState([]);
  const [businessPotentail, setBusinessPotential] = useState("");
  const [userId, setUserId] = useState([]);
  const [isChecked, setIsChecked] = useState(null);
  const CampaignDropdownRef = useRef(null);

  

  const handleClickOutside = (event) => {
    if (
      CampaignDropdownRef.current &&
      !CampaignDropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    setIsOpen(showSort);
  }, [showSort]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    onIndustrySectorSelect?.(industrySectorId);
  }, [industrySectorId]);

  useEffect(() => {
    onBusinessModalSelect?.(businessModalId);
  }, [businessModalId]);

  useEffect(() => {
    onContactDepSelect?.(contactDepId);
  }, [contactDepId]);

  useEffect(() => {
    onLocationSelect?.(locationId);
  }, [locationId]);

  useEffect(() => {
    onServiceOfferSelect?.(serviceOffer);
  }, [serviceOffer]);

  useEffect(() => {
    onBusinessPotentialSelect?.(businessPotentail);
  }, [businessPotentail]);

  useEffect(() => {
    onUserSelect?.(userId);
  }, [userId]);

  useEffect(() => {
    onFortuneSelect?.(isChecked);
  }, [isChecked]);

  return (
    <div
      ref={CampaignDropdownRef}
      style={{
        position: "absolute",
        right: 0,
        bottom: "auto",
        left: "auto",
        // inset:'left'
      }}
      className={`dropdown-menu filter-menu p-0 rounded-10 dropdown-menu-end ${
        isOpen ? "show" : ""
      }`}
    >
      <h6 className="text-white bg-sky p-3 filter-title semibold mb-1">
        Sort by
      </h6>
      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#status"
          role="button"
          aria-expanded="false"
          aria-controls="status"
        >
          Industry/Sector
        </a>
        <div className="collapse" id="status">
          <div style={{ overflowY: "scroll", height: "110px" }}>
            <RadioFilters
              options={industryoptions?.data}
              value={industrySectorId}
              onChange={setIndustrySectorId}
              name="industry_id"
            />
          </div>
        </div>
      </div>
      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#role"
          role="button"
          aria-expanded="false"
          aria-controls="role"
        >
          Business Model
        </a>
        <div className="collapse" id="role">
          <RadioFilters
            options={businessmodeloptions?.data}
            value={businessModalId}
            onChange={setBusinessModalId}
            name="businessmodel_id"
          />
        </div>
      </div>
      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#dep"
          role="button"
          aria-expanded="false"
          aria-controls="dep"
        >
          Contact Department
        </a>
        <div className="collapse" id="dep">
          <RadioFilters
            options={contactDepOptions?.data}
            value={contactDepId}
            onChange={setContactDepId}
            name="contact_dep"
          />
        </div>
      </div>

      <div className="border-bottom">
        <a
          className="collapse-butn semibold fs-15"
          data-bs-toggle="collapse"
          href="#location"
          role="button"
          aria-expanded="false"
          aria-controls="location"
        >
          Location
        </a>
        <div className="collapse" id="location">
          <Typeahead
            inputProps={{
              style: { padding: "10px" },
            }}
            id=""
            labelKey="city"
            options={locationList}
            placeholder="Search Location"
            onChange={(list) => {
              let value = list.map((items) => {
                return items.id;
              });

              setLocationId(value?.[0]);
            }}
          />
        </div>
      </div>

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#mana"
          role="button"
          aria-expanded="false"
          aria-controls="mana"
        >
          Service Based
        </a>
        <div className="collapse" id="mana">
          <div style={{ overflowY: "scroll", height: "110px" }}>
            <RadioFilters
              options={serviceoffoptions?.data}
              value={serviceOffer}
              onChange={setServiceOffer}
              name="service_offer"
            />
          </div>
        </div>
      </div>

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#potential"
          role="button"
          aria-expanded="false"
          aria-controls="potential"
        >
          Business Potential
        </a>
        <div className="collapse" id="potential">
          <RadioFilters
            options={businesspotential?.data}
            value={businessPotentail}
            onChange={setBusinessPotential}
            name="businessmodel_id"
          />
        </div>
      </div>

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#user"
          role="button"
          aria-expanded="false"
          aria-controls="user"
        >
          Lead Owner
        </a>
        <div className="collapse" id="user">
          <div style={{ overflowY: "scroll", height: "110px" }}>
            {userlist?.map((item) => {
              return (
                <div className="d-flex justify-content-between align-items-center py-1 px-3">
                  <label className="medium fs-14" for="potential">
                    {item?.fullname}
                  </label>
                  <input
                    checked={userId?.includes(item.id)}
                    onClick={() => {
                      if (userId.includes(item?.id)) {
                        setUserId(userId.filter((ite) => ite != item?.id));
                      } else {
                        setUserId([...userId, item?.id]);
                      }
                    }}
                    type="radio"
                    id="potential"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#fortune"
          role="button"
          aria-expanded="false"
          aria-controls="fortune"
        >
          Fortune 1000
        </a>
        <div className="collapse" id="fortune">
          <div>
            <div className="my-2 d-flex justify-content-between align-items-center py-1 px-3">
              <label className="medium fs-14" for="fortune">
                {"Fortune 1000"}
              </label>
              <input
                onChange={handleCheckboxChange}
                checked={isChecked}
                type="checkbox"
                id="fortune"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignFilter;
