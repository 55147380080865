import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useFilterData from "../../libs/queries/manage/useFilterData";
import useRoles from "../../libs/queries/profile/useRoles";
import { Typeahead } from "react-bootstrap-typeahead";
import useGetLead from "../../libs/queries/leads/useGetLead";
import useUsers from "../../libs/queries/profile/useUsers";
import RadioFilters from "./RadioFilters";
import UserRadioFilter from "./UserRadioFilter";

const AgreementSort = ({
  onContactSelect,
  onCvSelect,
  onAgreementTenureSelect,
  onAgreementApplicableSelect,
  onServiceOfferSelect,
  onClientCategorySelect,
  showSort,
  onRoleSelect,
  onSigningSelect,
  onClientSelect,
  onStartDateSelect,
  onEndDateSelect,
  type,
  onUserSelect,
  onContactDepSelect,
  onOwnerSelect
}) => {
  // const { showSort } = useSelector((state) => state.sortSlice);
  const { data: contactLevelOptions } = useFilterData(13, null, true, true);
  const { data: contactDepOptions } = useFilterData(12, null, true, true);
  const { data: cvdepoptions } = useFilterData(14, null, true, true);
  const { data: agreementtenureoptions } = useFilterData(18, null, true, true);
  const { data: serviceoffoptions } = useFilterData(24, null, true, true);
  const { data: categoryofclientoptions } = useFilterData(27, null, true, true);
  const { data: signingOptions } = useFilterData(22, null, true, true);
  const { data: roleOptions } = useRoles();
  const { data: clientOptions } = useGetLead(null, null, null, true);
  const {data: userList} = useUsers();
  const [isOpen, setIsOpen] = useState(showSort);
  const [contactId, setContactId] = useState('');
  const [contactDepId, setContactDepId] = useState('');
  const [cvId, setCvId] = useState('');
  const [tenureId, setTenureId] = useState('');
  const [agreementApplicable, setAgreementApplicable] = useState('');
  const [clientCategory, setClientCategory] = useState('');
  const [serviceOffer, setServiceOffer] = useState([]);
  const [roleId, setRoleId] = useState('');
  const [signingId, setSigningId] = useState('');
  const [clientName, setClientName] = useState('');
  const [startDate, setstartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [userId, setUserId] = useState('');
  const dropdownRef = useRef(null);
  const isReport = window.location.pathname.includes("report");


  useEffect(()=>{
    setIsOpen(showSort)
  },[showSort])

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(()=>{
    onContactSelect?.(contactId);
  },[contactId])

  useEffect(()=>{
    onContactDepSelect?.(contactDepId);
  },[contactDepId])

  useEffect(()=>{
    onCvSelect?.(cvId);
  },[cvId])

  useEffect(()=>{
    onAgreementTenureSelect?.(tenureId);
  },[tenureId])

  useEffect(()=>{
    onAgreementApplicableSelect?.(agreementApplicable);
  },[agreementApplicable])

  useEffect(()=>{
    onServiceOfferSelect?.(serviceOffer);
  },[serviceOffer])

  useEffect(()=>{
    onClientCategorySelect?.(clientCategory);
  },[clientCategory])

  useEffect(() => {
    onRoleSelect?.(roleId);
  }, [roleId]);

  useEffect(() => {
    onSigningSelect?.(signingId);
  }, [signingId]);

  useEffect(() => {
    onClientSelect?.(clientName);
  }, [clientName]);

  useEffect(() => {
    onUserSelect?.(userId);
  }, [userId]);

  useEffect(() => {
    onOwnerSelect?.(userId);
  }, [userId]);

  useEffect(() => {
    // if(startDate && endDate){
      onStartDateSelect?.(startDate);
      onEndDateSelect?.(endDate);
    // }
  }, [startDate, endDate]);

  return (
    <div
    ref={dropdownRef}
    style={{
      position: 'absolute',
      right: 0,
      bottom: 'auto',
      left: 'auto'
    }}
    className={`dropdown-menu filter-menu p-0 rounded-10 dropdown-menu-end ${
      isOpen?"show":""}`}
      onclick="event.stopPropagation()"
    >
      <h6 className="text-white bg-sky p-3 filter-title semibold mb-1">Sort by</h6>
      {isReport && <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#status"
          role="button"
          aria-expanded="false"
          aria-controls="status"
        >
          Contact Level
        </a>
        <div className="collapse" id="status">
          <RadioFilters options={contactLevelOptions?.data} value={contactId} onChange={setContactId} name="contact_id" />
          {/* {contactLevelOptions &&
            contactLevelOptions?.data?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center py-1 px-3"
                >
                  <label className="medium fs-14" htmlFor="active">
                    {item?.name}
                  </label>
                  <input 
                  checked={contactId===item.id} 
                  onChange={()=>{
                    setContactId(item?.id)
                  }}
                  type="radio" 
                  id="active" 
                  />
                </div>
              );
            })} */}
        </div>
      </div>}

      {!isReport && <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#dep"
          role="button"
          aria-expanded="false"
          aria-controls="dep"
        >
          Contact Department
        </a>
        <div className="collapse" id="dep">
        <RadioFilters options={contactDepOptions?.data} value={contactDepId} onChange={setContactDepId} name="contact_dep" />
          {/* {contactDepOptions &&
            contactDepOptions?.data?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center py-1 px-3"
                >
                  <label className="medium fs-14" htmlFor="dep">
                    {item?.name}
                  </label>
                  <input 
                  checked={contactDepId===item.id} 
                  onChange={()=>{
                    setContactDepId(item?.id)
                  }}
                  type="radio" 
                  id="active" 
                  />
                </div>
              );
            })} */}
        </div>
      </div>}

      {!isReport && <div className="border-bottom">
        <a
          className="collapse-butn semibold fs-15"
          data-bs-toggle="collapse"
          href="#role"
          role="button"
          aria-expanded="false"
          aria-controls="role"
        >
          CV Validity and Ownership
        </a>
        <div className="collapse" id="role">
        <RadioFilters options={cvdepoptions?.data} value={cvId} onChange={setCvId} name="cv_id" />
          {/* {cvdepoptions &&
            cvdepoptions?.data?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center py-1 px-3"
                >
                  <label className="medium fs-14" htmlFor="active">
                    {item?.name}
                  </label>
                  <input 
                  checked={cvId===item.id} 
                  onChange={()=>{
                    setCvId(item?.id)
                  }}
                  type="radio" 
                  id="active" />
                </div>
              );
            })} */}
        </div>
      </div>}

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#manager"
          role="button"
          aria-expanded="false"
          aria-controls="manager"
        >
          Agreement Tenure
        </a>
        <div className="collapse" id="manager">
        <RadioFilters options={agreementtenureoptions?.data} value={tenureId} onChange={setTenureId} name="tenure_id" />
          {/* {agreementtenureoptions &&
            agreementtenureoptions?.data?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center py-1 px-3"
                >
                  <label className="medium fs-14" htmlFor="active">
                    {item?.name}
                  </label>
                  <input 
                  checked={tenureId===item.id} 
                  onChange={()=>{
                    setTenureId(item?.id)
                  }}
                  type="radio" 
                  id="active" 
                  />
                </div>
              );
            })} */}
        </div>
      </div>

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#mana"
          role="button"
          aria-expanded="false"
          aria-controls="mana"
        >
          Service Offering
        </a>
        <div className="collapse" id="mana">
        <div style={{ overflowY: "scroll", height: "110px" }}>
        <RadioFilters options={serviceoffoptions?.data} value={serviceOffer} onChange={setServiceOffer} name="service_offer" />
          {/* {serviceoffoptions &&
            serviceoffoptions?.data?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center py-1 px-3"
                >
                  <label className="medium fs-14" htmlFor="active">
                    {item?.name}
                  </label>
                  <input 
                  checked={serviceOffer?.includes(item.id)} 
                  // checked={serviceOffer==item.id} 
                  onChange={()=>{
                    setServiceOffer([...serviceOffer, item?.id])
                    // setServiceOffer(item?.id)
                  }}
                  type="radio" 
                  id="active" 
                  />
                </div>
              );
            })} */}
            </div>
        </div>
      </div>

      {!isReport && <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#client"
          role="button"
          aria-expanded="false"
          aria-controls="client"
        >
          Category Of Client
        </a>
        <div className="collapse" id="client">
          <RadioFilters options={categoryofclientoptions?.data} value={clientCategory} onChange={setClientCategory} name="client_category" />
          {/* {categoryofclientoptions &&
            categoryofclientoptions?.data?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center py-1 px-3"
                >
                  <label className="medium fs-14" htmlFor="active">
                    {item?.name}
                  </label>
                  <input 
                  checked={clientCategory===item.id} 
                  onChange={()=>{
                    setClientCategory(item?.id)
                  }}
                  type="radio" 
                  id="active" 
                  />
                </div>
              );
            })} */}
        </div>
      </div>}

      {/* {isReport && <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#status"
          role="button"
          aria-expanded="false"
          aria-controls="status"
        >
          Role
        </a>
        <div className="collapse" id="status">
          {roleOptions &&
            roleOptions?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center py-1 px-3"
                >
                  <label className="medium fs-14" htmlFor="active">
                    {item?.name}
                  </label>
                  <input
                  checked={roleId===item.id} 
                  onChange={()=>{
                    setRoleId(item?.id)
                  }}
                  type="radio" id="active" 
                  />
                </div>
              );
            })}
        </div>
        </div>} */}

        {isReport && <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#signing"
          role="button"
          aria-expanded="false"
          aria-controls="signing"
        >
          Agreement Signing
        </a>
        <div className="collapse" id="signing">
          {signingOptions &&
            signingOptions?.data?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center py-1 px-3"
                >
                  <label className="medium fs-14" htmlFor="active">
                    {item?.name}
                  </label>
                  <input
                  checked={signingId===item.id} 
                  onChange={()=>{
                    setSigningId(item?.id)
                  }}
                  type="radio" id="active" 
                  />
                </div>
              );
            })}
        </div>
        </div>}

        {<div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#sourcedate"
          role="button"
          aria-expanded="false"
          aria-controls="sourcedate"
        >
          Date
        </a>
        <div className="collapse" id="sourcedate">
        <div style={{ overflowY: "scroll", height: "140px" }}>
            <div className="my-2 align-items-center py-1 px-3">
              <label className="medium fs-14" for="sourcedate">
                {'Start Date'}
              </label>
              <input
              onChange={(e)=>{
                setstartDate(e.target.value)
              }}
              style={{
                borderRadius: 0,
                padding: '5px',
              }}
              className="mt-1"
              type="date"/>
              <label className="medium fs-14 mt-2" for="sourcedate">
                {'End Date'}
              </label>
              <input
              onChange={(e)=>{
                setEndDate(e.target.value)
              }}
              style={{
                borderRadius: 0,
                padding: '5px',
              }}
              className="mt-1"
              type="date"/>
            </div>
          </div>
        </div>
        </div>}

        {
          type=='organisation' && <div className="border-bottom">
          <a
            className="collapse-butn semibold"
            data-bs-toggle="collapse"
            href="#user"
            role="button"
            aria-expanded="false"
            aria-controls="user"
          >
            {window.location.pathname.includes('agreement')? "Agreement Owner":"User"}
          </a>
          <div className="collapse" id="user">
            <div style={{ overflowY: "scroll", height: "110px" }}>
              <UserRadioFilter options={userList?.results} value={userId} onChange={setUserId} name="user_id"  />
              {/* {userList &&
                userList?.results?.map((item, index) => {
                  return (
                    <div className="d-flex justify-content-between align-items-center py-1 px-3">
                      <label className="medium fs-14" for="TA-Sourcing">
                        {item.fullname}
                      </label>
                      <input
                      onChange={()=>{
                          setUserId(item.id)
  
                      }}
                      checked={userId==item.id} 
                      type="radio" 
                      id="TA-Sourcing" 
                      />
                    </div>
                  );
                })} */}
            </div>
          </div>
        </div>
        }

        {isReport && <div className="border-bottom">
        <a
          className="collapse-butn semibold fs-15"
          data-bs-toggle="collapse"
          href="#client"
          role="button"
          aria-expanded="false"
          aria-controls="client"
        >
          Client Name
        </a>
        <div className="collapse" id="client">
          <Typeahead
          inputProps={{
            style: {padding: '10px'}
          }}
          id=""
          labelKey="legal_entity_name"
          options={clientOptions?.results}
          placeholder="Search Client Name"
          onChange={(list) => {
            let value = list.map((items) => {
              return items.legal_entity_name;
            });
            setClientName(value?.[0])
          }}
          />
        </div>
      </div>}

    </div>
  );
};

export default AgreementSort;
