import React, { useEffect, useState } from 'react'
import StageRow from '../../../Components/Manage/NewStage/StageRow'
import { Endpoints } from '../../../api/Endpoints'
import api from '../../../api'
import CommonFilterRow from '../../../CommonComponents/CommonFilterRow'
import StageContainer from '../../../Components/Manage/Stages/StageContainer'

const StagesManage = () => {
  return (
    <div className="main py-4 position-relative">
    <div className="container">
      <CommonFilterRow
      name={'Stages'}
      navTo={"/manage/stages/new"}
      />
      <StageRow 
      heading="Stages"
      url={Endpoints.stageCsv}
      />
      
      <StageContainer />
    </div>
  </div>
  )
}

export default StagesManage;