import React, { useState } from "react";
import { Link } from "react-router-dom";
import CrmListEdit from "./CrmListEdit";

const CrmListModal = ({ data }) => {
  // console.log(data)
  const {id, relationship_manager_display, account_manager_display } = data || {};
  const [edit, setEdit] = useState(false);

  return (
    <>
      {!edit && (
        <div
          className="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3 acco-info-table"
          //   id="acco_info"
        >
          <table className="action-table w-100">
            <tbody>
              <tr>
                <td className="action-first">
                  <div className="d-flex align-items-center gap-lg-4 gap-2">
                    <input type="checkbox" />
                    {/* <img
                      src="assets/img/hdfc.png"
                      width="48"
                      height="48"
                      className="rounded-3"
                      alt=""
                    /> */}
                  </div>
                </td>

                <td className="action-mid">
                  {/* <div className="pb-2">
              <h6 className="fs-12 text-sky regular  pb-md-1">
                Create client on ATS
              </h6>
              <h5 className="regular">Yes</h5>
            </div> */}

                  <div className="pb-2">
                    <Link
                      to={`/crm/escalationmatrix/${id}`}
                      className="text-sky fs-16 medium text-decoration-underline"
                    >
                      Escalation Matrix
                    </Link>
                  </div>
                </td>

                <td className="action-mid">
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Relationship Manager
                    </h6>
                    <h5 className="regular">{relationship_manager_display}</h5>
                  </div>
                </td>

                <td className="action-mid">
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Account Manager
                    </h6>
                    <h5 className="regular">{account_manager_display}</h5>
                  </div>
                </td>
                <td className="action-last">
                  <div className="d-md-flex justify-content-between">
                    <div>
                      <div className="pb-2">
                        <Link
                          to={`/crm/lastcontactlogs/${id}`}
                          className="text-sky fs-16 medium text-decoration-underline"
                        >
                          Last Contact Logs
                        </Link>
                      </div>
                    </div>

                    <div>
                      <a
                        // href="#"
                        className="action-icon selectContainer"
                      
                        onClick={() => setEdit(true)}
                      >
                        <img src="assets/img/icon/edit-white.svg" />
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {edit && (
        <CrmListEdit data={data} onHide={() => setEdit(false)} />
      )}
    </>
  );
};

export default CrmListModal;
