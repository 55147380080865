import { Endpoints } from "../../../api/Endpoints";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useGetAgreement = ({
    page, 
    itemsPerPage, 
    search, 
    type,
    contactDepartment,
    cvValidity,
    agreementTenure,
    agreementApplicable,
    serviceOffer,
    clientCategory,
    startDate,
    endDate,
    contactLevelId,
    owner
    }) => {

        console.log(page)
        const queryParams = {
            page: page || 1,
            items_per_page: itemsPerPage || 500,
            search_key: search || '',
            organisation: type==='organisation'?1:'',
            contact_department: contactDepartment || '',     
            cv_validity: cvValidity || '',
            agreement_tenure: agreementTenure || '',
            agreement_applicability: agreementApplicable || '',
            service_offering: serviceOffer || '',
            category_of_client: clientCategory  || '',
            start_date: startDate  || '',
            end_date: endDate || '',
            contact_level_id: contactLevelId || '',
            owner: owner || ''
        };
        const queryString = Object.keys(queryParams)
            .filter(key => queryParams[key] !== '')  // Filter out keys with no value
            .map(key => `${key}=${queryParams[key]}`)
            .join('&');
    const queryresult = useQuery({
        queryKey: [`agreementlist`, queryString],
        queryFn: async () => {
            const res = await api.get(Endpoints.getAgreementList+ "?"+queryString);
            return res?.data;
        }
    })
  return queryresult
}

export default useGetAgreement