import React, { useState } from "react";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import Search from "../../ui/search/Search";
import Heading from "../../ui/modals/Heading";
import Header from "../../Components/alerts/Header";
import AgreementPendingTableContainer from "../../Components/alerts/AgreementPendingTableContainer";
import useAgreementPending from "../../libs/queries/alerts/useAgreementPending";
import Pagination from "../../ui/pagination";
import { Endpoints } from "../../api/Endpoints";
import AlertFilter from "../../ui/dropdown/AlertFilter";

const AgreementRenewalViewmore = () => {
  const [type, setType] = useState("my");
  const [search, setSearch] = useState("");
  const [pageToLoad, SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [alertStartDate, setAlertStartDate] = useState("");
  const [alertEnd, setAlertEnd] = useState("");
  const [isOpen, setIsOpen] = useState(false)
  const { data: agreementalert } = useAgreementPending({
    agreementtype: type,
    page: pageToLoad,
    search: search,
    itemsPerPage: itemsPerPage,
    start_date: alertStartDate,
    end_date: alertEnd,
  });

  const queryParams = {
    search_key: search || '',
    download: 1,
    start_date: alertStartDate || "",
    end_date: alertEnd || "",
    organisation: type === "organisation" ? 1 : "",
  };

  const queryString = Object.keys(queryParams)
  .filter((key) => queryParams[key] !== "")
  .map((key) => `${key}=${queryParams[key]}`)
  .join("&");

  return (
    <div className="main py-4 position-relative">
      <div className="container">
      <Subheader
          leftsideshow={
            <TwoButtonHolder label={"Alerts"} onSelect={setType} type={type} />
          }
        />
        <Heading
          search={<Search onSearch={setSearch} />}
          heading="Alerts"
          url={Endpoints.agreementalertCSV + '?' + queryString
          // `download=1&organisation=${type=="organisation"?1:''}` 
        }
          showSortt={setIsOpen}
          sortComponent={
            <AlertFilter
              onAlertStart={setAlertStartDate}
              onAlertEnd={setAlertEnd}
              showSort={isOpen}
            />
          }
        />
        <Header
          heading="Agreement Pending / Renewal"
          backgroundcolor="green"
          url={"/alerts"}
        
        />
        <Pagination
          onPageChange={SetPageToLoad}
          onItemsChanged={setItemsPerPage}
          totalPages={Math.ceil(agreementalert?.count / itemsPerPage)}
          defaultCount={itemsPerPage}
        />
        <AgreementPendingTableContainer data={agreementalert} type={type} />
      </div>
    </div>
  );
};

export default AgreementRenewalViewmore;
