import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currpage: "",
    pageitems: null,
    pageToLoad: 1
  };

  const locationSlice = createSlice({
    name: "locationSlice",
    initialState,
    reducers: {
      setpage: (state, action) => {
        state.currpage = action.payload;
      },
      setpageitems: (state, action) => {
        state.pageitems = action.payload;
      },
      setPageToLoad: (state, action) => {
        state.pageToLoad = action.payload;
      },
    },
  });
  
  export const { setpage, setpageitems, setPageToLoad } = locationSlice.actions;
  export default locationSlice.reducer;