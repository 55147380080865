import React from "react";
import Onboard from "../../../img/onboard.png";
import TabItem from "../../../ui/threetabs/TabItem";
import { useDispatch, useSelector } from "react-redux";
import { setDateType } from "../../../slice/dashboardSlice";
import useOnborded from "../../../libs/queries/dashboard/useOnborded";
import OnboardedMonth from "../OnboardedMonth";
import OnboardingDay from "../OnboardingDay";
import TabContent from "../../../ui/threetabs/TabContent";
import OnBoardingWeek from "../OnBoardingWeek";

const Onboarded = ({type}) => {
  const dispatch = useDispatch();

  const { dateType } = useSelector((store) => store.dashboardSlice);

  const { data: onboarded } = useOnborded({
    onboardedServiceType: type,
    date_filter: dateType,
  });

  return (
    <div
      className="py-3 px-3 rounded-3  collapse multi-collapse"
      id="onboarded"
    >
      <div className="d-flex align-items-center justify-content-between pb-3">
        <h3 className="fs-24 medium text-black">Onboarded</h3>

        <div className="sub-tab nav border-0 gap-2 pb-3 justify-content-end">
          <TabItem
            title="Days"
            isActive={dateType === "day"}
            setActiveTab={() => dispatch(setDateType("day"))}
          />
          <TabItem
            title="Week"
            isActive={dateType === "week"}
            setActiveTab={() => dispatch(setDateType("week"))}
          />
          <TabItem
            title="Month"
            isActive={dateType === "month"}
            setActiveTab={() => dispatch(setDateType("month"))}
          />
        </div>
      </div>

      <div className="outlet">
        <TabContent isActive={dateType === "day"}>
          <OnboardingDay dayData={onboarded} />
        </TabContent>
        <TabContent isActive={dateType === "week"}>
          <OnBoardingWeek weekData={onboarded} />
        </TabContent>
        <TabContent isActive={dateType === "month"}>
          <OnboardedMonth monthData={onboarded} />
        </TabContent>
      </div>
    </div>
  );
};

export default Onboarded;
