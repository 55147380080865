import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import useCreateEvent from '../../libs/mutation/Calendar/useCreateEvent';
import useAllUsers from '../../libs/queries/profile/useAllUsers';
import { Typeahead } from 'react-bootstrap-typeahead';
import BadgeInput from '../inputs/BadgeInput';

function AddMeetingModal(props) {
  const [meetingType, setMeetingType] = useState('online');
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    control,
    setError,
    clearErrors,
    // getValues,
    watch,
    reset,
    getValues,
  } = useForm({ mode: "all" });

  const { mutateAsync: createEvent, isLoading } = useCreateEvent({
    onHide: props?.onHide,
    meetingType: meetingType
  });
  const { data: allUsers } = useAllUsers();

  const handleRadio=(e)=>{
    console.log('e', e.target.value);
    setMeetingType(e.target.value);
  }

  const onSubmit = async(data) => {
    await createEvent(data);
    props?.onSuccess?.();
    console.log('dataevent__', data)
  }

  console.log('wt__', watch('start_date_time'))
  console.log('reminder_____', watch('reminderMinutesBeforeStart'))

  return (
      <Modal
        size='lg'
        {...props}
      >
        <Modal.Header >
          <Modal.Title
            className='fs-16'
          >New Event - Calendar - {props?.email}
          </Modal.Title>
          <button
            onClick={props?.onHide}
            type="button"
            className="bg-red border-0 rounded fs-12"
          >
            <span aria-hidden="true" style={{ color: 'white' }}>
              &times;
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Button
              disabled={isLoading}
              type='submit'
              className={'bg-sky px-4 fs-14 py-1'}
            //   onClick={props.onHide}
            >
              Save
            </Button>
            <Form.Group className="mb-3">
              <Form.Control
                name='title'
                className='shadow-none py-2 mt-3 border-0 border-bottom'
                type="text"
                placeholder="Add a title"
                {...register("title", { required: true })}
              />
              {errors.title?.type === "required" && <span className='text-danger fs-12'>This field is required</span>}
            </Form.Group>

            <div className='d-flex py-2 px-0'>
              <div className='me-2'>
                <img
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                  src='assets/img/invite.png'
                />
              </div>

              <div style={{ width: '80%' }}>
                <Controller
                  name="required_attendees"
                  control={control}
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: "Attendees are Required"
                  //   },
                  // }}
                  render={({ field }) => (

                    <BadgeInput
                      isCalendar={true}
                      placeholder={'Invite Required Attendees'}
                      value={field?.value}
                      onChange={field?.onChange}
                    />
                  )}
                />
                
                {errors.required_attendees && <span className="fs-12 text-red">
                  {errors.required_attendees.message}
                </span>}


                <div className='pt-2'>
                  <Controller
                    name="optional_attendees"
                    control={control}
                    render={({ field }) => (

                      <BadgeInput
                        isCalendar={true}
                        placeholder={'Invite Optional Attendees'}
                        value={field?.value}
                        onChange={field?.onChange}
                      />
                    )}
                  />
                </div>

                
              </div>
            </div>

            <div className='d-flex py-2 px-0'>
              <div className='me-2'>
                <img
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                  src='assets/img/clock.png'
                />
              </div>

              <div>
                <div className=''>
                  <input
                    name='start_date_time'
                    className='rounded-2'
                    type='datetime-local'
                    // value={getValues().start_date_time? getValues().start_date_time?.split(' ')?.[0]: ''}
                    {...register("start_date_time", { required: true })}
                  />
                  {errors.start_date_time?.type === "required" && <span className='text-danger fs-12'>This field is required</span>}
                </div>

                <div className='pt-2'>
                  <input
                    name='end_date_time'
                    className='rounded-2'
                    type='datetime-local'
                    // value={getValues().end_date_time? getValues().end_date_time?.split(' ')?.[0]: ''}
                    {...register("end_date_time", { required: true })}
                  />
                  {errors.end_date_time?.type === "required" && <span className='text-danger fs-12'>This field is required</span>}
                </div>
              </div>
            </div>

            <div className='d-flex py-2 px-0'>
              <div className='me-2'>
                <img
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                  src='assets/img/reminder.png'
                />
              </div>
              <select
              name='reminderMinutesBeforeStart'
              style={{
                width: '30%'
              }}
                className='rounded-2'
              {...register("reminderMinutesBeforeStart", { required: true })}
              >
                <option value={""}>Remind me</option>
                <option value={"5"}>5 minutes before</option>
                <option value={"15"}>15 minutes before</option>
                <option value={"20"}>20 minutes before</option>
                <option value={"30"}>30 minutes before</option>
                <option value={"60"}>1 hour before</option>
                <option value={"120"}>2 hour before</option>
                <option value={"720"}>12 hour before</option>
                <option value={"1440"}>1 day before</option>
                <option value={"10080"}>1 week before</option>
              </select>
              </div>
              {errors.reminderMinutesBeforeStart?.type === "required" && <span className='text-danger fs-12 ms-4'>This field is required</span>}

              <div className='d-flex py-2 px-0'>
              <div className='me-2'>
                <img
                  style={{
                    width: '18px',
                    height: '18px',
                  }}
                  src='assets/img/clock.png'
                />
              </div>
              <div className='d-flex'>
                <div className='d-flex align-items-center'>
                  <input
                  // name='meeting_type'
                  type='radio'
                  style={{
                    width: '30%'
                  }}
                  value={'online'}
                  onClick={(e) => {
                    handleRadio(e);
                  }}
                  checked={meetingType=='online'?true:false}
                    className='rounded-2'
                  // {...register("meeting_type", { required: true })}
                  />
                  <span className='ms-1 me-3'>Online</span>
                </div>
                <div className='d-flex align-items-center'>
                  <input
                  // name='meeting_type'
                  type='radio'
                  onClick={(e) => {
                    handleRadio(e);
                  }}
                  value={'offline'}
                  checked={meetingType=='offline'?true:false}
                  style={{
                    width: '30%'
                  }}
                    className='rounded-2'
                  // {...register("meeting_type", { required: true })}
                  />
                  <span className='ms-1'>Offline</span>
                </div>
              </div>
              </div>
              {errors.meeting_type?.type === "required" && <span className='text-danger fs-12 ms-4'>This field is required</span>}

            {
            meetingType=='offline' &&  
            <Form.Group className="mb-3 ms-4">
            <Form.Control
              name='location'
              className='shadow-none py-2 mt-3 border-0 border-bottom'
              type="text"
              placeholder="Add Location"
              {...register("location", { required: meetingType=='offline'? true : false })}
            />
            {errors.title?.type === "required" && <span className='text-danger fs-12'>This field is required</span>}
            </Form.Group>}
            <div className='d-flex py-2 px-0'
              style={{
                height: '200px'
              }}>
              <div className='me-2'>
                <img
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                  src='assets/img/description.png'
                />
              </div>
              <Controller
                name="description"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Description is required"
                  },
                }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <ReactQuill
                      placeholder='Add a Description'
                      theme="snow"
                      // value={value}
                      onChange={(body) => onChange(body)}
                      style={{ height: "80%", width: '80%' }}
                    />
                  );
                }}
              />
            </div>
            {errors.description?.type === "required" && <span className='text-danger fs-12 ms-4'>This field is required</span>}

          </Form>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
  );
}

export default AddMeetingModal;