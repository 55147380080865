import React from "react";
import { Doughnut } from "react-chartjs-2";

const HalfDonut = ({data=[], datalabel=[], datacolor=[]}) => {
  const options = {
    labels: datalabel?.map((item) => item),
    datasets: [
      {
        data: data?.map((item) => item),
        backgroundColor: datacolor?.map((item) => item) ,
      },
    ],
  };
  const add = {
    maintainAspectRatio: false,
    circumference: 180,
    rotation: 270
  };

  return (
    <div style={{ width: "300px", height: '300px' }}>
      <Doughnut data={options} options={add} />
    </div>
  );
};

export default HalfDonut;
