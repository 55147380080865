import React from "react";
import RoleRestriction from "../../utils/roleRestriction";

const MultiRelocateButton = ({ type, setMultiRelocateVisible }) => {
  const relocateOn = ["admin", "practice", "researcher"];

  return (
    <>
     
      {type !== "my" && relocateOn.includes(RoleRestriction()) && (
        <a
          href="#"
          className="custom-btn medium sm-btn fs-16"
          onClick={() => setMultiRelocateVisible(true)}
        >
          Reallocate Lead
        </a>
      )}
    </>
  );
};

export default MultiRelocateButton;
