import React from 'react'
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useGetAccountManager = () => {
  
    const queryresult = useQuery({
        queryKey: [`accountmanager`],
        keepPreviousData: true,
        queryFn: async () => {
          const res = await api.get(`account/accountmanagers`);
          return res?.data?.data;
        },
      });
    
      return queryresult;
  
}

export default useGetAccountManager