import React, { useEffect, useState } from "react";
import ClientSegment from "./ClientSegment";
import ServiceOffering from "./ServiceOffering";
import SourceChart from "./SourceChart";
import Monthwise from "./Monthwise";
import LocationofClient from "./LocationofClient";
import ContactLevel from "./ContactLevel";
import BDowner from "./BDowner";
import CRMmonthwise from "./CRMmonthwise";
import DocumentUpload from "./DocumentUpload";
import RoleRestriction from "../../utils/roleRestriction";

const DocumentUploadReport = ({data, type, onUserSelect}) => {
  const [bdUserId, setBdUserId] = useState('');
  const restrict = RoleRestriction();

  useEffect(()=>{
    if(bdUserId){
      onUserSelect?.(bdUserId)
    }
  },[bdUserId])

  return (
    <div>
      <h1>Document Upload Report</h1>
      <div 
      // className="row"
      className="d-flex flex-column gap-3"
      >
        {(restrict === "researcher" || restrict === 'practice') && <DocumentUpload data={data}  />}
      </div>
    </div>
  );
};

export default DocumentUploadReport;
