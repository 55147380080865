import React from 'react'
import FilterRow from '../../../Components/Manage/EmailTemplate/FilterRow'
import StageRow from '../../../Components/Manage/NewStage/StageRow'
import NewEmailTemplateContainer from '../../../Components/Manage/EmailTemplate/NewEmailTemplateContainer'
import CommonFilterRow from '../../../CommonComponents/CommonFilterRow'
import NewStageForm from '../../../Components/Manage/Stages/NewStageForm'

const NewStage = () => {
  return (
    <div className="main py-4 position-relative">
    <div className="container">
      <CommonFilterRow
      name={'Stages'}
      navTo={"/manage/stages/new"}
      />
      <StageRow 
      heading="Stages"
      />
      <NewStageForm />
    </div>
  </div>
  )
}

export default NewStage;