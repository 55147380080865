import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import dayjs from "dayjs";
import useLogout from '../libs/mutation/auth/useLogout';

const RootLayout = () => {

    const { mutate: logout } = useLogout();

    useEffect(() => {
        const key = setInterval(() => {
          let expire_time = localStorage.getItem("expire_time");
          const date1 = dayjs();
    
          const date2 = expire_time ? dayjs(expire_time) : null;
    
          if (date2) {
            let ms = date2.diff(date1);
            if (ms <= 0) {
              logout();
            }
            // 
          }
        }, 1000);
      }, []);

  return (
    
        <Outlet />

  )
}

export default RootLayout