import api, { Imageapi } from "../../../api/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Endpoints } from "../../../api/Endpoints";
import useLastCampaign from "../market/useLastCampaign";

const useSendEmail = (onClose) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {mutate: lastCampaign} = useLastCampaign()
  const SendEmailMutation = useMutation({
    mutationFn: async (data) => {
      console.log(data);
      if (data?.to_emails?.length <= 0) {
        throw new Error("To emails is required");
      }
      let emailData = new FormData();
      emailData.append("to_emails", data.to_emails);
      emailData.append("subject", data.subject);
      emailData.append("body", data.body);
      if (data?.attachments) {
        emailData.append("attachments", data.attachments);
      }

      console.log(emailData);
      const res = await Imageapi.post(Endpoints.sendEmail, emailData);
      return res.data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
      lastCampaign();
      if (onClose) onClose();
      //   if (data.status === true) {
      //   }
    },
    onError: (error) => {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error(`${error["response"].data.message}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
      // toast.error(`${error["response"].data.message}`, {
      //   position: "top-right",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   theme: "colored",
      // });
    },
  });

  return SendEmailMutation;
};

export default useSendEmail;
