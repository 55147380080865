import React from "react";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";
import { Endpoints } from "../../../api/Endpoints";

const useGetStageList = (page, itemsPerPage, page_name) => {
  const GetStageList = useQuery({
    queryKey: ["stagelist", page, itemsPerPage, page_name],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await api.get(
        `management/stages/?page=${page || 1}&items_per_page=${itemsPerPage || 50}&page_name=${page_name || 'lead'}`
      );

      return res.data;
    },
  });

  return GetStageList;
};

export default useGetStageList;
