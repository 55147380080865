import React, { useState } from "react";
import EditIcon from "../../../img/icon/edit-white.svg";
import StageEdit from "./StageEdit";

const StageModal = ({ index, data, dataCopy }) => {
  const {id ,name, order_no, created_at, template_name, stage_background_color} = data || {}
  const [edit, setEdit] = useState(false);

  const date = created_at.substring(0,16)
  const datefinal = date.split("T").join(" ")
  const time = datefinal.substring(11,13)

  return (
    <>
      {!edit && (
        <div className="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3  acco-info-table">
          <div className="d-flex">
            <div>
              <td className="action-first" style={{ width: "90px" }}>
                <span className="number d-flex align-items-center justify-content-center rounded-circle fs-24 semibold">
                  {index + 1}
                </span>
              </td>
            </div>
            <div className="d-flex flex-column w-100">
              <div className="d-flex">
                <div className="pb-2" style={{ width: "290px" }}>
                  <h6 className="fs-12 text-sky regular  pb-md-1">
                    Name
                  </h6>
                  <h5 className="regular">{name}</h5>
                </div>
                <div className="pb-2" style={{ width: "290px" }}>
                  <h6 className="fs-12 text-sky regular  pb-md-1">Order No.</h6>
                  <h5 className="regular">{order_no}</h5>
                </div>
                <div className="pb-2" style={{ width: "200px" }}>
                  <h6 className="fs-12 text-sky regular  pb-md-1">
                    Template Name
                  </h6>
                  <h5 className="regular">{template_name}</h5>
                </div>
                <div className="pb-2" style={{ width: "210px" }}>
                  <h6 className="fs-12 text-sky regular  pb-md-1">
                    Created At
                  </h6>
                  <h5 className="regular">{datefinal} {time >= 12 ? "PM" : "AM"}</h5>
                </div>
              </div>
              <div>
                <div className="pb-2 pt-2 w-100">
                  <h6 className="fs-12 text-sky regular  pb-md-1">Stage Background Color</h6>
                  <h5 className="regular">{stage_background_color}</h5>
                </div>
              </div>
            </div>
            <tr>
              <td className="action-last" style={{ width: "100px" }}>
                <div>
                {/* {(dataCopy?.filter((item)=>item.id==id)[0]?.id==id) &&<a
                    // href="#"
                    className="selectContainer action-icon ms-auto"
                    data-bs-toggle="collapse"
                    data-bs-target="#edit_info_1"
                    onClick={() => setEdit(true)}
                  >
                    <img src={EditIcon} />
                  </a>} */}
                  <a
                    // href="#"
                    className="selectContainer action-icon ms-auto"
                    data-bs-toggle="collapse"
                    data-bs-target="#edit_info_1"
                    onClick={() => setEdit(true)}
                  >
                    <img src={EditIcon} />
                  </a>
                </div>
              </td>
            </tr>
          </div>
        </div>
      )}

      {edit && (
        <StageEdit
          data={data}
          index={index}
          onHide={() => setEdit(false)}
        />
      )}
    </>

  );
};

export default StageModal;
