import React, { useState } from "react";
import PlusIcon from "../../img/icon/plus.svg";
import { useNavigate } from "react-router-dom";
import BulkDeleteButton from "../../ui/buttons/BulkDeleteButton";
import useDeleteLocation from "../../libs/mutation/location/useDeleteLocation";
import { toast } from "react-toastify";

const CommonFilterRow = ({ name, navTo , bulkdelete }) => {
 



  const navigate = useNavigate();
  return (
    <div className="row pb-4">
      <div className="col-sm-6 pb-sm-0 pb-3"></div>
      <div className="col-sm-6 pb-sm-0 pb-3">
        <div className="col-sm-12 text-sm-end">
        
        {bulkdelete}
          
          <div
            className="custom-btn medium sm-btn"
            style={{ cursor: "pointer" }}
          >
            <img src={PlusIcon} className="me-2" />
            <a
             
              onClick={() => {
                navigate(navTo);
              }}
              style={{ cursor: "pointer" }}
            >
              New {name}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonFilterRow;
