import React from "react";
import DoubleBarChart from "../../ui/graphs/DoubleBarChart";
import useGetProductivityGraph from "../../libs/queries/graphs/useGetProductivityGraph";

const PilotReceived = ({productivitydata}) => {
  // const { data: productivity } = useGetProductivityGraph();
  
  const {pilot_received_data} = productivitydata || {}

  const pilotdata = pilot_received_data?.map((item) => item?.pilot_received)
  const pilotlabel = pilot_received_data?.map((item) => item?.name)
  const pilottarget = pilot_received_data?.map((item) => item?.pilot_received_target)
 

  return (
    <div className="bg-white py-4 px-4 border " style={{ width: "100%" }}>
      <div className="card-body">
        <h3 className="mb-3">Pilots Received</h3>
        <DoubleBarChart data={pilotdata} label={pilotlabel} leadtarget={pilottarget} />
      </div>
    </div>
  );
};

export default PilotReceived;
