import React from "react";
import api from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useCreateEscalationmembers = ({ id }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data) => {
      data.crm = id;
      const res = await api.post("crm/escalationmember/create/", data);
      return res.data;
    },

    onSuccess: (data) => {
      queryClient.invalidateQueries([`crm`]);
      toast.success(data.message);
      navigate(`/crm/escalationmatrix/` + data?.data?.crm);
    },

    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });
  return mutation;
};

export default useCreateEscalationmembers;
