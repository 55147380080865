import React from "react";
import PlusIcon from "../../img/icon/plus.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Plusbutton = () => {
  const { filtername } = useSelector((state) => state.leadSlice);

  const navigate = useNavigate();
  const { filternamechange } = useSelector((state) => state.leadSlice);

  return (
    <div className="col-sm-2 ">
      <div
        onClick={() => {
          navigate("/manage/leadgeneration/new");
          
        }}
        className="custom-btn medium sm-btn"
        style={{ paddingTop: "12px", paddingBottom: "12px" , cursor: "pointer"}}
      >
        <img src={PlusIcon} className="me-2" style={{ marginLeft: "5px" }} />

        <a
          style={{ color: "#fff", width: "160px", display: "flex" , cursor: "pointer"}}
        >
          {filternamechange}
        </a>
      </div>
    </div>
  );
};

export default Plusbutton;
