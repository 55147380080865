import React from "react";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useGetLeadListContact = ({
  id, 
  page, 
  itemsPerPage, 
  leadType, 
  search,
  filterBy,
  businessModel ,
  industrySector,
  sourceId,
  potentialId,
  isFortune,
  startDate,
  endDate,
  leadOwner,
  month,
  year,
  proposal_date_start,
  proposal_date_end,
  follow_up_date_start,
  follow_up_date_end,
  isLeadAchieved,
  userId,
  prs_data,
  enabled
  }) => {
  const queryParams = {
    stage_id: id || '',     
    page: page || 1,
    items_per_page: itemsPerPage || 500,
    organisation: leadType==='organisation'?1:'',
    filter_by: filterBy || '',
    business_model: businessModel || '',
    industry_sector: industrySector || '',
    source: sourceId || '',
    potential_service_offering: potentialId || '',
    fortune_1000: isFortune || '',
    source_date_start: startDate  || '',
    source_date_end: endDate || '',
    lead_owner: leadOwner || '',
    month: month || '',
    year: year || '',
    search_key: search || '',
    proposal_date_start: proposal_date_start || '',
    proposal_date_end: proposal_date_end || '',
    follow_up_date_start: follow_up_date_start || '',
    follow_up_date_end: follow_up_date_end || '',
    bd_prs: isLeadAchieved? 1 : '',
    user_id: userId || '',
    prs_data: prs_data || ''
  };

  // console.log('enn2__', enabled)

  const queryString = Object.keys(queryParams)
      .filter(key => queryParams[key] !== '')
      .map(key => `${key}=${queryParams[key]}`)
      .join('&');

  const queryresult = useQuery({
    queryKey: [`leads`, {queryString}],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // enabled: enabled? true : false,
    queryFn: async () => {
     console.log('enn__', enabled)
      const res = await api.get(`management/lead-contacts?${queryString}`);
      
      return res?.data;
    },
  });

  return queryresult;
};

export default useGetLeadListContact;