import React from 'react'
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";
import { Endpoints } from '../../../api/Endpoints';

const usePracticeHead = () => {
  
    const queryresult = useQuery({
        queryKey: [`practicehead`],
        keepPreviousData: true,
        queryFn: async () => {
          const res = await api.get(Endpoints.getPracticeHead);
          return res?.data?.data;
        },
      });
    
      return queryresult;
  
}

export default usePracticeHead;