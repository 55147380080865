import getInputError from "../../utils/getInputError";

const InputBox = ({
  label,
  errors,
  value,
  defaultValue,
  onChange,
  placeholder,
  onFocus,
  name,
  disabled,
  isUpdateMobile,
  isUpdateEmail,
  ...res
}) => {
  let isError = getInputError(errors, name);

  return (
    <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
      <label className="d-block text-sky fs-12 pb-2">{label}</label>
      <input
      defaultValue={defaultValue}
      maxLength={label=='Mobile'? 10 : null}
        disabled={disabled}
        className="form-field"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        onFocus={onFocus}
        {...res}
      />
      <div style={{ height: "10px" }}>
        {isError && <span className="fs-12 text-red">{isError?.message}</span>}
        {errors?.contact?.mobile?.message && isUpdateMobile &&
        <span className="fs-12 text-red">{errors?.contact?.mobile?.message}</span>}
        {errors?.contact?.email?.message && isUpdateEmail &&
        <span className="fs-12 text-red">{errors?.contact?.email?.message}</span>}
      </div>
    </div>
  );
};

export default InputBox;
