import React from "react";

const CheckboxInput = ({
  label,
  value,
  onChange,
  onFocus,
  name,
  ...res
}) => {
  return (
    <>
      <label className="d-block text-sky fs-12 pb-2">{label}</label>
      <input
        type="checkbox"
        className="form-field"
        onChange={onChange}
        value={value}
        onFocus={onFocus}
        {...res}
      />
    </>
  );
};

export default CheckboxInput;
