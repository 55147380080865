import { Outlet } from "react-router-dom";
import CRM from "../Pages/Crm/CRM";
import LastContactLog from "../Pages/Crm/LastContactLog";
import NewCommunication from "../Pages/Crm/NewCommunication";
import Escalation from "../Pages/Crm/Escalation";
import NewMember from "../Pages/Crm/NewMember";


const crmRouter = {
  path: "/crm/",
  element: <Outlet />,

  children: [
    {
      path: "",
      element: <CRM />,
    },
    {
      path: "lastcontactlogs/:id",
      element: <LastContactLog />,
    },
    {
      path: "newcommunication/:id",
      element: <NewCommunication />,
    },
    {
      path: `lastcontactlogs/view/:id`,
      element: <NewCommunication />,
    },
    {
      path: "escalationmatrix/:id",
      element: <Escalation />,
    },
    {
      path: "newmember/:id",
      element: <NewMember />,
    },
    {
      path: `escalationmatrix/view/:id`,
      element: <NewMember />,
    },
   
  ],
};

export default crmRouter;
