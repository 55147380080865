import React from 'react'
import FilterRow from '../../../Components/Manage/EmailTemplate/FilterRow'
import StageRow from '../../../Components/Manage/NewStage/StageRow'
import NewEmailTemplateContainer from '../../../Components/Manage/EmailTemplate/NewEmailTemplateContainer'
import CommonFilterRow from '../../../CommonComponents/CommonFilterRow'
import NewStageForm from '../../../Components/Manage/Stages/NewStageForm'
import CreateLocationForm from '../../../Components/Manage/LocationManage/CreateLocationForm'

const NewLocation = () => {
  return (
    <div className="main py-4 position-relative">
    <div className="container">
        <CommonFilterRow
        name={'Location'}
        navTo={"/manage/locations/new"}
        />
        <StageRow
        heading={`Locations`}
        // url={Endpoints.leadGeneratedCsv}
        />
        <CreateLocationForm />
    </div>
  </div>
  )
}

export default NewLocation;