import React, { useEffect, useState } from "react";
/// hooks used ///
import { useSelector } from "react-redux";

/// Imports of Component ///
import StageRow from "../../../Components/Manage/NewStage/StageRow";
import FilterRow from "../../../Components/Manage/Leadgeneration/FilterRow";
import LeadGenerationContainer from "../../../Components/Manage/Leadgeneration/LeadGenerationContainer";
import { Endpoints } from "../../../api/Endpoints";
import UserContainer from "../../../Components/Manage/UsersManage/UserContainer";
import UserPlusButton from "../../../Components/Manage/UsersManage/UserPlusButton";
import LocationContainer from "../../../Components/Manage/LocationManage/LocationContainer";
import CommonFilterRow from "../../../CommonComponents/CommonFilterRow";
import BulkDeleteButton from "../../../ui/buttons/BulkDeleteButton";
import useDeleteLocation from "../../../libs/mutation/location/useDeleteLocation";
import { toast } from "react-toastify";
import Heading from "../../../ui/modals/Heading";
import useLocationList from "../../../libs/queries/location/useLocation";
import DeleteModal from "../../../ui/modals/DeleteModal";

const LocationList = () => {
  const { currpage } = useSelector((state) => state.locationSlice);
  const [checkboxadd, setCheckboxAdd] = useState([]);
  const [dele, setdele] = useState(false);
  const { mutate: deletelocation, isLoading } = useDeleteLocation(checkboxadd, currpage);
  // const [isChecked, setIsChecked] = useState(false);

  // console.log('checked__', isChecked);
  // console.log('checkboxadd__', checkboxadd);

  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  // };

  

  const deleteCheckboxitem = () => {
    console.log("this is checkbox", checkboxadd);
    if (checkboxadd.length > 0) {
      deletelocation();
      setdele(false)
    } else {
      toast.error("Please select a location");
      setdele(false)
    }
  };

  const handleModal = () => {
    setdele(true)
  }


  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <CommonFilterRow
          navTo="/manage/locations/new"
          name="location"
          bulkdelete={<BulkDeleteButton onClick={() => handleModal()} />}
        />
        {/* <div 
        className="d-flex align-items-center pt-3 pb-4"
        >
        <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        />
        <label className="d-block text-sky fs-12 mx-2">
        Select all
        </label>
        </div> */}
        <LocationContainer
          checkboxstate={checkboxadd}
          checkboxstatechange={setCheckboxAdd}
        />
      </div>
      {dele && (
        <DeleteModal
          disabled={isLoading}
          deletename="location"
          onDeleteClick={deleteCheckboxitem}
          onNoClick={() => setdele(false)}
        />
      )}
    </div>
  );
};

export default LocationList;
