import React from 'react'
import FilterRow from '../../../Components/Manage/AgreementForm/FilterRow'
import StageRow from '../../../Components/Manage/NewStage/StageRow'
import { useSelector } from 'react-redux'
import NewAgreementGeneratedContainer from '../../../Components/Manage/AgreementForm/NewAgreementGeneratedContainer'

const NewAgreementGenerated = () => {

  const { agreementnamechange } = useSelector((state) => state.agreementSlice)

  return (
    <div className="main py-4 position-relative">
    <div className="container">
      <FilterRow />
      <StageRow
      heading={agreementnamechange}
      />
      <NewAgreementGeneratedContainer />
    </div>
  </div>
  )
}

export default NewAgreementGenerated