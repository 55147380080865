import React from 'react'
import DonutChart from '../../ui/graphs/DonutChart'
import useGetAgreementGraph from '../../libs/queries/graphs/useGetAgreementGraph';

const AgreementSigning = ({agreementdata}) => {

    // const { data } = useGetAgreementGraph();
    const { signing_mode_data, signing_mode_label, signing_mode_color } = agreementdata || {};

  return (
    <div className="bg-white py-4 px-4 border">
    <div className="card-body">
      <h3 className="mb-3">Mode of Agreement Signing</h3>
      <div className="d-flex justify-content-center">
        {!signing_mode_data?.length ?
        <div className='my-4'>
          No Data
        </div>
        :
        <DonutChart
          datalabel={signing_mode_label}
          datacolor={signing_mode_color}
          data={signing_mode_data}
        />}
      </div>
    </div>
  </div>
  )
}

export default AgreementSigning