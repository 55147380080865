import React, { useState } from "react";
import TrashIcon from "../../../img/icon/trash.svg";
import useToggle from "../../../hooks/useToggle";
import UserGeneratedModal from "./UserGeneratedModal";
import DeleteModal from "../../../ui/modals/DeleteModal";
import useDeleteUser from "../../../libs/mutation/auth/useDeleteUser";

const UserGenerated = ({ data, index }) => {
  const { id, fullname, phone, email } = data || {};
  const { mutate: deleteuser, isLoading } = useDeleteUser(id);
  const [detail, toggleDetail] = useToggle(false);
  const [dele, setdele] = useState(false);

  const handleDelete = () => {
    deleteuser();
    setdele(false);
  };

  return (
    <>
      <div className="bg-white rounded-10 px-md-4 px-3 py-3 mb-3 shadow-sm">
        <table className=" w-100  ">
          <tr>
            <td className="action-first">
              <span className="number d-flex align-items-center justify-content-center rounded-circle fs-24 semibold">
               
                {index + 1}
              </span>
            </td>

            <td className="action-mid" style={{ width: "300px", textAlign: "left" }}>
              <h6 className="fs-12 text-sky regular  pb-md-2">Full Name</h6>
              <h5>{fullname}</h5>
            </td>
            <td className="action-mid" style={{ width: "200px", textAlign: "left" }}>
              <h6 className="fs-12 text-sky regular  pb-md-2">Phone No.</h6>
              <h5>{phone}</h5>
            </td>
            <td className="action-mid" style={{ width: "250px", textAlign: "left" }}>
              <h6 className="fs-12 text-sky regular  pb-md-2">Email ID</h6>
              <h5>{email}</h5>
            </td>
            <td className="action-last">
              <div className="d-flex align-items-center gap-xl-3 gap-2 justify-content-end">
                <a className="selectContainer action-icon bg-red" onClick={() => setdele(true)}>
                  <img src={TrashIcon} />
                </a>
                <a onClick={() => toggleDetail()}>
                  <i className="selectContainer fa-solid fa-angle-down fs-20 align-middle"></i>
                </a>
              </div>
            </td>
          </tr>
        </table>
      </div>
      {/* <!--END ROW 1 --> */}
      {detail && <UserGeneratedModal data={data} index={index} />}
      {dele && (
        <DeleteModal
          disabled={isLoading}
          deletename="user"
          onDeleteClick={handleDelete}
          onNoClick={() => setdele(false)}
        />
      )}
    </>
  );
};

export default UserGenerated;
