import React from "react";
import LeadGenerated from "./LeadGenerated";
import PilotReceived from "./PilotReceived";
import ProposalSent from "./ProposalSent";
import MeetingOrg from "./MeetingOrg";
import PRS from "./PRS";
import RoleRestriction from "../../utils/roleRestriction";
import ResearcherPrs from "./ResearcherPrs";

const ProductivityReport = ({productivitydata, isPrs, prsData, roleId, type}) => {
  const restrict = RoleRestriction();
  // console.log('roll__', roleId)
 
 
  return (
    <div>
      {
        isPrs && (roleId!=4)?
        <PRS prsData={prsData} type={type} key={prsData} />
        :
        isPrs && (roleId==4)?
        <ResearcherPrs prsData={prsData} type={type}/>
        :
        <div id={'pdf-productivity'} className="d-flex flex-column gap-3">
      <LeadGenerated productivitydata={productivitydata} />
      <PilotReceived productivitydata={productivitydata} />
      <ProposalSent productivitydata={productivitydata} />
      <MeetingOrg productivitydata={productivitydata} />
      </div>}
    </div>
  );
};

export default ProductivityReport;
