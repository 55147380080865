import React from "react"
import { useSelector } from "react-redux";
import AgreementGeneratedForm from "./AgreementGeneratedForm";


const NewAgreementGeneratedContainer = () => {

  const { agreementnamechange} = useSelector((state) => state.agreementSlice)

  return (
    <div className="pt-4">
      <div className="rounded-10 overflow-hidden">
        <div className="widget-header px-3 py-3 bg-sky">
          <h3 className="bold text-white fs-18">{agreementnamechange}</h3>
        </div>
        
        <AgreementGeneratedForm/>
      </div>
    </div>
  );
};

export default NewAgreementGeneratedContainer;
