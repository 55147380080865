import React from "react";
import api from "../../../api/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const useDeleteEmailTemplate = (emailtemplate_id) => {
  const queryClient = useQueryClient();
  const deleteEmailTemplate = useMutation({
    mutationFn: async () => {
      const res = await api.get(
        `record/email-template/delete/${emailtemplate_id}`
      );
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("createdemailtemplate");
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
    enabled: emailtemplate_id ? true : false,
  });

  return deleteEmailTemplate;
};

export default useDeleteEmailTemplate;
