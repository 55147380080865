import React from "react";

const AgreementPendingTableContainer = ({ data, type }) => {
  return (
    <div className="bg-white rounded-10 overflow-hidden shadow">
      <table className="w-100 alert-table">
        <thead>
          <tr>
            <th className="fs-20 medium">Sr. no.</th>
            <th className="fs-20 medium">Client Name</th>
            <th className="fs-20 medium">Lead Name</th>
            <th className="fs-20 medium">Contact</th>
            <th className="fs-20 medium">Last Update</th>
            {type === "organisation" ? (
              <th className="fs-20 medium">Username</th>
            ) : (
              ""
            )}
          </tr>
        </thead>
        <tbody>
          {data?.results.map((item, index) => {
              const date = item?.updated_at?.substring(0, 10);
       
                 const datefinal = date?.split("T").join(" ");
                 const time = datefinal?.substring(11,13)
            return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item?.name}</td>
                  <td>{item?.lead_detail?.legal_entity_name}</td>
                  <td>{item?.mobile}</td>
                  <td>{datefinal}</td>
                  {type === "organisation" ? (
                    <td>{item?.lead_detail?.lead_generator_display}</td>
                  ) : (
                    ""
                  )}
                </tr>
            );
          })}

          {!data?.results?.length && (
            <div
              style={{
                textAlign: "center",
              }}
              className="mt-5"
            >
              {" "}
              No Data Found{" "}
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AgreementPendingTableContainer;
