import React from "react";
import Logo from "../../img/logo.svg";
import Emoji from "../../img/icon/emoji.svg";
import { useNavigate } from "react-router-dom";

const GoToLogin = () => {

    const navigate = useNavigate()

  return (
    <div className="position-relative">
      <div className="wrapper login-bg position-relative blur-body">
        <div className="header py-3">
          <div className="container">
            <div className="site-logo">
              <a href="#">
                <img src={Logo} />
              </a>
            </div>
          </div>
        </div>

        <div className="container py-5">
          <div className="row">
            <div className="col-md-6 login-left">
              <img src="assets/img/login.png" alt="" />
            </div>
            <div className="col-md-6 login-right">
              <div className="py-5 px-lg-5 d-flex align-items-center w-100 justify-content-center h-100">
                <div className="w-100">
                  <h1 className="text-center bold pb-3">
                    Login to Better Solutions
                  </h1>
                  <h2 className="text-center fs-20 pb-5">Welcome Back!</h2>

                  <form action="" className="signup-form px-lg-5">
                    <div className="form-field mb-3">
                      <label for="" className="fs-12 d-block pb-1">
                        E-mail
                      </label>
                      <div className="position-relative">
                        <img
                          src="assets/img/icon/email.svg"
                          alt=""
                          className="position-absolute ms-3 mt-3 pt-1"
                        />
                        <input
                          type="text"
                          placeholder="Mobile Number or Email Address"
                          className="ps-5"
                          value="abc123@gmail.com"
                        />
                      </div>
                    </div>

                    <div className="form-field mb-3">
                      <label for="" className="fs-12 d-block pb-1">
                        Password
                      </label>
                      <div className="position-relative">
                        <img
                          src="assets/img/icon/key.svg"
                          alt=""
                          className="position-absolute ms-3 mt-3 pt-1"
                        />
                        <input
                          type="password"
                          placeholder="Mobile Number or Email Address"
                          
                          value="btrhtyjy"
                          className="valid-password ps-5"
                        />
                      </div>
                    </div>

                    <div className="form-field mb-4">
                      <div className="d-flex align-items-center justify-content-between ">
                        <div className="custom-checkbox">
                          <input
                            type="checkbox"
                            id="remember"
                            className="align-middle"
                            checked
                          />
                          <label for="remember" className="fs-12 ">
                            Remember me
                          </label>
                        </div>
                        <a
                          href="#"
                          className="forgot-link fs-10 text-decoration-underline"
                        >
                          Forgot Password?
                        </a>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="custom-btn fs-20 border-0 rounded-pill w-100 py-3 mt-3 mb-3"
                    >
                      Log In
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pwd-success position-absolute top-50 start-50 translate-middle">
        <div className="d-flex align-items-center py-4 px-md-5 px-3 gap-4">
          <img src={Emoji} alt="" />
          <div>
            <h3 className="fs-25 bold text-black pb-3">
              Password Changed Successfully!
            </h3>
            <h4 className="fs-20 text-black pb-4 regular">
              Try Log In with your new password.
            </h4>
            <a
            //   href="#"
            onClick={() => navigate("/login")}
              className="custom-btn br-rad"
              style={{ "--br": "10px" }}
            >
              Go to Log In
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoToLogin;
