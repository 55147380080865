import React from "react";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useGetDocument = ({ 
  page, 
  itemsPerPage, 
  marketType, 
  search,
  forClient,
  docType,
  skill,
  paid,
  uploadStart,
  UploadEnd,
  viewStart,
  viewEnd,
  campaignStart,
  campaignEnd,
  researcherId
}) => {

  const queryParams = {
    page: page || 1,
    items_per_page: itemsPerPage || 500,
    organisation: marketType === "organisation" ? 1 : "",
    search_key: search || "",
    for_client: forClient || "",
    doc_type: docType || "",
    skills: skill || "",
    paid: paid || "",
    researcher: researcherId || "",
    upload_start_date: uploadStart || "",
    upload_end_date: UploadEnd || "",
    view_start_date: viewStart || "",
    view_end_date: viewEnd || "",
    campaign_start_date: campaignStart || "",
    campaign_end_date: campaignEnd || ""
  };

  
  const queryString = Object.keys(queryParams)
  .filter((key) => queryParams[key] !== "")
  .map((key) => `${key}=${queryParams[key]}`)
  .join("&");
  
  const queryresult = useQuery({
    queryKey: [`market`, {queryString}],
    keepPreviousData: true,
    queryFn: async () => {
     
      const res = await api.get(`market/documentfiles?${queryString}`);
      // console.log(res?.data);
      return res?.data;
    },
  });
  return queryresult;
};

export default useGetDocument;
