import React from "react";
import FilterRow from "../../../Components/Manage/AgreementForm/FilterRow";
import StageRow from "../../../Components/Manage/NewStage/StageRow";
import { useSelector } from "react-redux";
import AgreementGeneratedContainer from "../../../Components/Manage/AgreementForm/AgreementGeneratedContainer";
import { Endpoints } from "../../../api/Endpoints";

const AgreementGenerated = () => {
  const { agreementnamechange } = useSelector((state) => state.agreementSlice);
  const { agreementid } = useSelector((state) => state.agreementSlice);
  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <FilterRow />
        <StageRow heading={agreementnamechange} url={Endpoints.dropdownCsv(agreementid)} />
        <AgreementGeneratedContainer />
      </div>
    </div>
  );
};

export default AgreementGenerated;
