import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../api/index";
import { toast } from "react-toastify";
import { Endpoints } from "../../../api/Endpoints";
import { useSelector } from "react-redux";

const useLastCampaign = () => {
  const { documentId } = useSelector((state) => state.campaignSlice);
  let data = {
    document_ids: documentId,
  };
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await api.post(Endpoints.lastCampaign, data);
      return res.data;
    },
    onSuccess: (data) => {
    //   queryClient.invalidateQueries([`market`]);
    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });

  return mutation
};

export default useLastCampaign;
