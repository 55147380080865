import React, { useState } from "react";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import Heading from "../../ui/modals/Heading";
import Search from "../../ui/search/Search";
import Header from "../../Components/alerts/Header";
import NoFollowUpTableContainer from "../../Components/alerts/NoFollowUpTableContainer";
import useNextFollowUp from "../../libs/queries/alerts/useNextFollowUp";
import { Endpoints } from "../../api/Endpoints";
import Pagination from "../../ui/pagination";
import AlertFilter from "../../ui/dropdown/AlertFilter";

const NoFollowUpViewmore = () => {
  const [type, setType] = useState("my");
  const [search, setSearch] = useState("");
  const [alertStartDate, setAlertStartDate] = useState("");
  const [alertEnd, setAlertEnd] = useState("");
  const [isOpen, setIsOpen] = useState(false)
  const [pageToLoad, SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { data: nofollowupdata } = useNextFollowUp({
    followupType: type,
    page: pageToLoad,
    search:  search,
    itemsPerPage: itemsPerPage,
    start_date: alertStartDate,
    end_date: alertEnd,
  });

  const queryParams = {
    page: pageToLoad || 1,
    download: 1,
    search_key: search || "",
    start_date: alertStartDate || "",
    end_date: alertEnd || "",
    organisation: type === "organisation" ? 1 : "",
  };

  const queryString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");

  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <Subheader
          leftsideshow={
            <TwoButtonHolder label={"Alerts"} onSelect={setType} type={type} />
          }
        />
        <Heading
          search={<Search onSearch={setSearch} />}
          heading="Alerts"
          url={Endpoints.nofollowupCSV + "?" + queryString
          // `download=1&organisation=${type=="organisation"?1:''}`
        }
          showSortt={setIsOpen}
          sortComponent={
            <AlertFilter
              onAlertStart={setAlertStartDate}
              onAlertEnd={setAlertEnd}
              showSort={isOpen}
            />
          }
        />
        <Header
          heading="No . of Follow Up In a Day"
          backgroundcolor="brown"
          url={"/alerts"}
        />

        <Pagination
          onPageChange={SetPageToLoad}
          onItemsChanged={setItemsPerPage}
          totalPages={Math.ceil(nofollowupdata?.count / itemsPerPage)}
          defaultCount={itemsPerPage}
        />

        <NoFollowUpTableContainer data={nofollowupdata} type={type} />
      </div>
    </div>
  );
};

export default NoFollowUpViewmore;
