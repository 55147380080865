import React from "react";
/// hooks used ///
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/// Imports of Component ///
import useCreateFilterData from "../../../libs/mutation/manage/useCreateFilterData";
import InputBox from "../../../ui/inputs/InputBox";
import CancelButton from "../../../ui/buttons/CancelButton";
import SaveButton from "../../../ui/buttons/SaveButton";

const LeadGenerationForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { filterid } = useSelector((state) => state.leadSlice);
  const { mutate: createdropdown, isLoading } = useCreateFilterData(
    filterid,
    "/manage/leadgeneration"
  );

  const navigate = useNavigate();

  return (
    <>
      <div className="bg-white p-4">
        <form className="add-form" onSubmit={handleSubmit(createdropdown)}>
          <div className="row  gx-lg-5 align-items-center">
            <Controller
              name="name"
              control={control}
              rules={{ required: "Name is Required" }}
              render={({ field: { value, onChange } }) => {
                return (
                  <InputBox
                    label="Name"
                    name="name"
                    errors={errors}
                    type="text"
                    value={value || ""}
                    onChange={onChange}
                  />
                );
              }}
            />
          </div>
          <div className="d-flex border-0 butn-group justify-content-center gap-3 pt-4">
            <SaveButton type="submit" />
            <CancelButton onClick={() => navigate("/manage/leadgeneration")} />
          </div>
        </form>
      </div>
    </>
  );
};

export default LeadGenerationForm;
