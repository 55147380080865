import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const BoxChart = ({ data, onUserSelect, isClient}) => {
  const { reportType } = useSelector((store) => store.reportSlice);

  const [bdUserId, setBdUserId] = useState('');

  useEffect(()=>{
    if(bdUserId){
      onUserSelect?.(bdUserId)
    }
  },[bdUserId])
  return (
    <div
    onClick={()=>{
      setBdUserId(data?.id)
    }}
    className="selectContainer"
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "80px",
        height: "60px",
        border: "5px solid yellow",
        padding: "10px",
        justifyContent: "center",
        alignContent: "center",
        fontSize: '13px',
      }}
    >
      <p style={{
        maxWidth: "100px"
      }}
      className="text-nowrap text-truncate"
      >
        {isClient?data: data?.name}
      </p>
     {/* {data?.split(" ")?.[0]?.toString()} */}
    </div>
  );
};

export default BoxChart;
