import React, { useEffect, useState } from "react";
import StageList from "./StageList";
import useGetStageList from "../../../libs/queries/StageApi/useGetStageList";
import Pagination from "../../../ui/pagination";

const StageContainer = () => {
  const [pageToLoad,SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const { data: stageData } = useGetStageList(
    pageToLoad, itemsPerPage
  );
  const { data: stageDataCopy } = useGetStageList();
  const [IdState, setIdState] = useState([]);

  useEffect(()=>{

  },[])

  return (
    <div>
      <Pagination
        onPageChange={SetPageToLoad}
        onItemsChanged={setItemsPerPage}
        totalPages={Math.ceil(stageData?.count/itemsPerPage)}
        defaultCount={itemsPerPage}
        />
      {stageData &&
        stageData?.results?.map((data, index) => {
          return (
            <StageList 
            key={index} 
            index={index} 
            data={data} 
            dataCopy={stageDataCopy?.results?.filter((item, index)=>index<6)}
            />
          );
        })}
    </div>
  );
};

export default StageContainer;
