import axios from "axios";
import { Endpoints } from "./Endpoints";

let token =  window.localStorage.getItem("token")


export const baseURL = Endpoints.baseUrl + "/api/v1/";

// export const backendURL = Endpoints.baseUrl + (Endpoints.baseUrl?.endsWith('/')?"":'/');
export const backendURL = Endpoints.baseUrl + (Endpoints.baseUrl?.endsWith('/')? Endpoints.baseUrl?.slice(0, -1):'');


const api = axios.create({
  withCredentials: false,
  baseURL,
 
  headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
});

export const Imageapi = axios.create({
  withCredentials: false,
  baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'multipart/formdata'
  },
}) 

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // 401 Unauthorized
    const originalRequest = error?.config;
    if (error.response.status === 401 && !originalRequest?.sent) {
      originalRequest.sent = true;
      localStorage.clear()
      window.location.href = "/login";
      return api(originalRequest);
    }
    return Promise.reject(error);
  }
);

Imageapi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // 401 Unauthorized
    const originalRequest = error?.config;
    if (error.response.status === 401 && !originalRequest?.sent) {
      originalRequest.sent = true;
      localStorage.clear()
      window.location.href = "/login";
      return Imageapi(originalRequest);
    }
    return Promise.reject(error);
  }
);


export default api