import React from "react";
import PendingReminder from "./PendingReminder";
import ProductivityAlert from "./ProductivityAlert";
import NoFollowUp from "./NoFollowUp";
import AgreementPending from "./AgreementPending";

const AlertContainer = ({datareminder, type}) => {
  return (
    <>
      <PendingReminder data={datareminder} type={type} />

      <div className="col-md-4">
        <ProductivityAlert />
        <NoFollowUp />
        <AgreementPending />
      </div>
    </>
  );
};

export default AlertContainer;
