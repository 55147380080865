import React from 'react'
import FilterRow from '../../../Components/Manage/EmailTemplate/FilterRow'
import StageRow from '../../../Components/Manage/NewStage/StageRow'
import NewEmailTemplateContainer from '../../../Components/Manage/EmailTemplate/NewEmailTemplateContainer'

const NewEmailTemplate = () => {
  return (
    <div className="main py-4 position-relative">
    <div className="container">
      <FilterRow />
      <StageRow 
      heading="Email Template"
      />
      <NewEmailTemplateContainer />
    </div>
  </div>
  )
}

export default NewEmailTemplate