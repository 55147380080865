import React from "react";
import DeleteButton from "../buttons/DeleteButton";
import NoButton from "../buttons/NoButton";
import DeactivateButton from "../buttons/DeactivateButton";

const DeactivateModal = ({ deletename, onDeleteClick, onNoClick, disabled }) => {
  return (
    <div className="modaldelete">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content rounded-10 border-0">
          <div className="modal-header border-0 p-3 rounded-10 bg-red">
            <h4 className="bold text-white">Deactivate {deletename} Generated</h4>
          </div>
          <div className="modal-body px-4 py-3">
            <p className="pe-xl-5">
              Are you sure you want to deactivate the selected {deletename}?
            </p>
          </div>
          <div className="modal-footer border-0 pt-0 butn-group">
            <DeactivateButton onClick={onDeleteClick} disabled={disabled} />
            {/* <DeleteButton onClick={onDeleteClick} disabled={disabled} /> */}
            <NoButton onClick={onNoClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeactivateModal;
