import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Search = ({onSearch, defaultValue}) => {
  
  const [searchText, setSearchText] = useState("");
  const { searchvalue } = useSelector((state) => state.globalSearchSlice);

    
console.log(defaultValue)
  useEffect(() => {
    onSearch?.(searchText);
  }, [searchText]);

  return (
    <form action="" className="search-form d-flex align-items-center gap-1">
      <i className="fa fa-search"></i>
      <input
        onChange={(e) => {
          setSearchText(e.target?.value);
        }}
        type="text"
        // defaultValue={searchvalue}
        placeholder="Search"
        className="bg-transparent p-1 w-100"
      />
    </form>
  );
};

export default Search;
