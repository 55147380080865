import { Outlet } from "react-router-dom"
import AgreementForm from "../Pages/Agreement/AgreementForm"
import AgreementList from "../Pages/Agreement/AgreementList"
import NewAgreement from "../Pages/Agreement/NewAgreement"

const agreementRouter = {
    path: "/agreement/",
    element: <Outlet />,
    children: [
        {
            path: "", 
            element: <AgreementList />,
        },
        {
            path: 'new/',
            element: <NewAgreement />
        },
        {
            path: 'view/',
            element: <NewAgreement />
        },
        {
            path: 'new/contact',
            element: <NewAgreement/>
        }
    ]

}

export default agreementRouter;