import React, { useState } from "react";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import PlusButton from "../../ui/buttons/PlusButton";
import Heading from "../../ui/modals/Heading";
import SingleCrmList from "../../Components/crm/SingleCrmList";
import useGetCrm from "../../libs/queries/crm/useGetCrm";
import Pagination from "../../ui/pagination";
import CrmSort from "../../ui/dropdown/CrmSort";
import { Endpoints } from "../../api/Endpoints";
import Search from "../../ui/search/Search";
import { useSelector } from "react-redux";
import useLocalStorage from "../../hooks/useLocalStorage";

const CRM = () => {
  const [type, setType] = useState("my");
  const [search, setSearch] = useState("");
  const [pageToLoad, SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [businessModalId, SetBusinessModalId] = useState('');
  const [industrySectorId, SetIndustrySectorId] = useState('');
  const [relationId, setRelationId] = useState('');
  const [potentialId, setPotentialId] = useState('');
  const [communicationId, setCommunicationId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { searchvalue } = useSelector((state) => state.globalSearchSlice);

  const [ searchData ] = useLocalStorage("globalsearch");
  const { searchSlice } = useSelector((state) => state.searchSlice);

//  const searchkey = JSON.parse(localStorage.getItem("search"))
  // console.log("this is",JSON.parse(localStorage.getItem("search")))

  
  const { data } = useGetCrm({
    pageToLoad, 
    itemsPerPage, 
    crmType: type,
    businessModel: businessModalId,
    industrySector: industrySectorId,
    potentialId,
    relationId,
    search: search?.length > 0  ? search : searchSlice?.source == "crm" ? searchSlice?.search : "" ,
    communicationId,
    startDate: startDate,
    endDate: endDate
  });

  const queryParams = {
    page: pageToLoad || 1,
    download: 1,
    organisation: type==='organisation'?1:'',
    business_model: businessModalId || '',
    industry_sector: industrySectorId || '',
    search_key: search || '',
    potential_service_offering: potentialId || '',
    relationship_manager: relationId || '',
    communication_type: communicationId ||'',
    start_date: startDate || '',
    end_date: endDate || '',
  };

  const queryString = Object.keys(queryParams)
      .filter(key => queryParams[key] !== '')  // Filter out keys with no value
      .map(key => `${key}=${queryParams[key]}`)
      .join('&');


  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <Subheader leftsideshow={<TwoButtonHolder label={"CRM"} onSelect={setType} type={type} />} />
        <Heading 
        search={<Search onSearch={setSearch}  />}
        url={Endpoints.getCrmList + "?"+ queryString
        // `download=1&organisation=${type=="organisation"?1:''}`
        }
        heading="CRM"
        showSortt={setIsOpen}
        // onSearch={setSearch}
        sortComponent={
          <CrmSort 
          onBusinessModalSelect={SetBusinessModalId}
          onIndustrySectorSelect={SetIndustrySectorId}
          onPotentialSelect={setPotentialId}
          onRelationSelect={setRelationId}
          onCommunicationSelect={setCommunicationId}
          onStartDateSelect={setStartDate}
          onEndDateSelect={setEndDate}
          showSort={isOpen}
          type={type}
          /> 
          }
        />
        <Pagination
          onPageChange={SetPageToLoad}
          onItemsChanged={setItemsPerPage}
          totalPages={Math.ceil(data?.count / itemsPerPage)}
          defaultCount={itemsPerPage}
        />
        {data &&
          data?.results.map((item, index) => {
            return <SingleCrmList key={index} data={item} />;
          })}

        {
          !data?.results?.length && 
          <div style={{
            textAlign: 'center'
          }} className="mt-5"> No Data Found </div>
        }
      </div>
    </div>
  );
};

export default CRM;
