import React from 'react'
import api from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const useUpdateCrm = ({id, onHide}) => {

    const queryClient = useQueryClient();
    const mutation = useMutation({
      mutationFn: async (data) => {
        const res = await api.post(`management/crm/update/${id}`, data);
  
        return res.data;
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries([`crm`]);
        toast.success(data.message);
        if (onHide) onHide();
      },
  
      onError: (error) => {
        toast.error(`${error["response"].data.message}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      },
    });
    return mutation;

}

export default useUpdateCrm