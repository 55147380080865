import React from 'react'
import api from "../../../api/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const useUpdateLeadContact = (id, onHide) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: async (data) => {
          // if(!data?.email){
          //   delete data?.email
          // }
          // if(!data?.mobile){
          //   delete data?.mobile
          // }
          const updatedData = {
            ...data,
            lead: data?.lead?.id,
            
          };
          const res = await api.post(`management/update-contact/${id}`, updatedData);
          return res.data;  
        },
        onSuccess: (data) => {
          queryClient.invalidateQueries([`leads`]);
          toast.success(data.message);
          if (onHide) onHide();
        },
        onError: (error) => {
          toast.error(`${error["response"].data.message}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        },
      });
  return mutation
}

export default useUpdateLeadContact