import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import InputBox from "../../ui/inputs/InputBox";
import { useNavigate } from "react-router-dom";
import useCreateEscalationmembers from "../../libs/mutation/crm/useCreateEscalationmembers";
import useGetCrm from "../../libs/queries/crm/useGetCrm";
import useGetEscalationMember from "../../libs/queries/crm/useGetEscalationMember";
import { useSelector } from "react-redux";
import useUpdateEscalationmember from "../../libs/mutation/crm/useUpdateEscalationmember";
import useFilterData from "../../libs/queries/manage/useFilterData";

const NewMemberForm = ({ id }) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  const { memberid, membertype } = useSelector((state) => state.crmSlice);
  const {data: contactlevel} = useFilterData(13)
  

  const isView = window.location.pathname.includes("view/");
  const { mutate: createescalationmember } = useCreateEscalationmembers({
    id: id,
  });
  const { data: getmember } = useGetEscalationMember({
    id: id,
    memberType: membertype,
  });
  const { mutate: updateescalationmember } = useUpdateEscalationmember({
    memberid,
  });
  const memberdata = getmember?.results?.filter(
    (item) => item?.id === memberid
  );

  const navigate = useNavigate();
  const order = [
    { id: 1, name: "level1" },
    { id: 2, name: "level2" },
    { id: 3, name: "level3" },
  ];
  const type = [
    { id: 1, name: "external" },
    { id: 2, name: "internal" },
  ];

  const onSubmit = (data) => {
    if (isView) {
      console.log(isView)
      updateescalationmember(data)
    } else {
      createescalationmember(data)
    }
  };

  useEffect(() => {
    if (memberdata && isView) {
      reset({
        name: memberdata[0]?.name,
        designation: memberdata[0]?.designation,
        email: memberdata[0]?.email,
        order: memberdata[0]?.order,
        member_type: memberdata[0]?.member_type,
      });
    }
  }, []);

  return (
    <div className="pt-4">
      <div className="">
        <div className="rounded-10 overflow-hidden">
          <div className="widget-header px-3 py-3 bg-sky">
            <h3 className="bold text-white fs-18">
              {isView ? "Edit Escalation Member" : "New Escalation Member"}
            </h3>
          </div>

          <div className="bg-white p-4">
            <form
              action=""
              onSubmit={handleSubmit(onSubmit)}
              className="add-form"
            >
              <div className="row  gx-lg-5">
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Name is Required" }}
                  render={({ field: { value, onChange, name } }) => {
                    return (
                      <InputBox
                        label="Name"
                        name={name}
                        placeholder=""
                        errors={errors}
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                <Controller
                  name="designation"
                  control={control}
                  rules={{ required: "Designation is Required" }}
                  render={({ field: { value, onChange, name } }) => {
                    return (
                      <InputBox
                        label="Designation"
                        name={name}
                        placeholder=""
                        errors={errors}
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                <Controller
                  name="email"
                  control={control}
                  rules={{ required: "Email is Required" }}
                  render={({ field: { value, onChange, name } }) => {
                    return (
                      <InputBox
                        label="Email"
                        name={name}
                        placeholder=""
                        errors={errors}
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                    Order
                  </label>
                  <select
                    name="order"
                    {...register("order", { required: true })}
                  >
                    <option value="">Please Select a Order</option>
                    {contactlevel?.results?.map((item, index) => {
                      return (
                        <option key={index} 
                        value={item?.name.split(" ").join("").toLowerCase()}
                        // value={item?.name}
                        >
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                  <span className="fs-12 text-red">
                    {errors.order?.type === "required" && "Order is Required"}
                  </span>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                    Type
                  </label>
                  <select
                    name="member_type"
                    {...register("member_type", { required: true })}
                  >
                    <option value="">Please Select a Type</option>
                    {type.map((item, index) => {
                      return (
                        <option key={index} value={item?.name}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                  <span className="fs-12 text-red">
                    {errors.member_type?.type === "required" &&
                      "Type is Required"}
                  </span>
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex gap-3 border-0 px-md-5 px-4 pb-4 butn-group justify-content-center">
                  <button
                    type="submit"
                    className="custom-btn border-0 py-2 rounded-3 raleway medium"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="custom-btn close-modal py-2  rounded-3 border-bl-btn px-4 raleway medium"
                    onClick={() => navigate(`/crm/escalationmatrix/${id}`)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMemberForm;
