import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import InputBox from "../../ui/inputs/InputBox";
import DateInput from "../../ui/inputs/DateInput";
import DropdownInput from "../../ui/inputs/DropdownInput";
import useUsers from "../../libs/queries/profile/useUsers";
import Multiselect from "multiselect-react-dropdown";
import UserlistdropdownInput from "../../ui/inputs/UserlistdropdownInput";
import AsyncPaginationExample from "../leads/LeadFormInput";
import FilePreview from "./FilePreview";
import useLocationList from "../../libs/queries/location/useLocation";
import LocationDropdown from "../../ui/inputs/LocationDropdown";
import CheckboxInput from "../../ui/inputs/CheckboxInput";
import useProfile from "../../libs/queries/profile/useProfile";
import useFilterData from "../../libs/queries/manage/useFilterData";
import useAllUsers from "../../libs/queries/profile/useAllUsers";
import DateInputBox from "../../ui/inputs/DateInputBox";

const LeadForm = ({
  control,
  errors,
  register,
  watch,
  setValue,
  setError,
  reset,
  clearErrors,
  selectedLead,
  setSelectedLead,
}) => {
  const [fileupload, setFileUpload] = useState(null);
  const [fortune, setFortune] = useState(false);

  const { data: users } = useUsers();
  const { data: allusers } = useAllUsers();
  const { data: userprofile } = useProfile();
  const { data: locations } = useLocationList(null, null);
  const { data: potential } = useFilterData(9, null, true, true);
  const userlist = users?.results;
  const potentialData = potential?.data;

  const locationlist = locations?.results;

  const handleChange = (event, onChange) => {
    setFileUpload(event.target.files[0]);
    onChange(event.target.files[0]);
  };

  const handleLegalChange = (value, onChange) => {
    if (value?.customOption) {
      setSelectedLead(null);
    } else {
      setSelectedLead(value);
    }
    onChange(value);
    // console.log(value);
  };

  const handlevalue = (value) => {
    // console.log(value)
    let obj = [];
    if (allusers) {
      value?.forEach((element) => {
        // console.log(element)
        // let found = [...(userlist || []), {id: userprofile?.id, fullname: userprofile?.fullname}].find((etv) => etv.id === element);
        let found = allusers.find((etv) => etv.id === element);
        // console.log(found)
        if (found) {
          obj.push(found);
        }
      });
    }
    return obj;
  };

  const handlelocationvalue = (value) => {
    let obj = [];
    if (locationlist) {
      value?.forEach((element) => {
        let found = locationlist.find((etv) => etv.id === element);
        if (found) {
          obj.push(found);
        }
      });
    }
    return obj;
  };

  const handlePotentialvalue = (value) => {
    let obj = [];
    if (potentialData) {
      value?.forEach((element) => {
        let found = potentialData.find((etv) => etv.id === element);
        if (found) {
          obj.push(found);
        }
      });
    }
    return obj;
  };

  // console.log("leg__", watch("legal_entity_name"));

  useEffect(() => {
    if (selectedLead) {
      // console.log(selectedLead.lead_owner);
      const leadValues = {
        legal_entity_name: selectedLead?.legal_entity_name,
        global_hq: selectedLead.global_hq,
        incepted: selectedLead?.incepted || "",
        lead_owner: [
          ...selectedLead?.lead_owner?.filter(
            (ownerId) => ownerId != userprofile?.id
          ),
          userprofile?.id,
        ],
        // lead_owner: selectedLead.lead_owner,
        revenue: selectedLead.revenue,
        lead_generator: selectedLead.lead_generator,
        source: selectedLead.source,
        company_name: selectedLead.company_name,
        source_date: selectedLead?.source_date || "",
        stages_of_ops_india: selectedLead.stages_of_ops_india,
        india_operations: selectedLead?.india_operations || "",
        indian_presence: selectedLead.indian_presence,
        indian_hq: selectedLead.indian_hq,
        business_model: selectedLead.business_model,
        industry_sector: selectedLead.industry_sector,
        lead_type: selectedLead.lead_type,
        fortune_1000: selectedLead.fortune_1000,
        potential_service_offering: selectedLead.potential_service_offering,
        business_potential: selectedLead.business_potential,
        news_research_repo: selectedLead?.news_research_repo || "",
        compnay_forecast_remark: selectedLead?.compnay_forecast_remark || "",
      };

      // reset(leadValues);
      reset({ leads: leadValues });
    } else {
      // reset({ lead_owner: [userprofile?.id], legal_entity_name:  });
    }
  }, [selectedLead, userprofile]);
  // const inputValue = watch('global_hq') || watch('leads.global_hq');
  // useEffect(()=>{
  //   if(inputValue?.length>4){
  //     reset()
  //   }
  // },[inputValue])

  useEffect(() => {
    setValue("news_research_file", fileupload);
  }, [fileupload]);

  return (
    <div className="bg-white p-4">
      <h4 className="medium fs-18 text-black pb-4 ">Lead Information</h4>
      <div className="add-form">
        <div className="row  gx-lg-5">
          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">
              legal Entity Name
            </label>
            <Controller
              name={
                selectedLead ? "leads.legal_entity_name" : "legal_entity_name"
              }
              control={control}
              rules={{ required: "Legal Entity Name is Required" }}
              render={({ field: { value, onChange, name } }) => {
                // console.log("see value", value);
                return (
                  <AsyncPaginationExample
                    value={value}
                    placeholder={
                      selectedLead?.legal_entity_name ||
                      "Enter legal entity name"
                    }
                    onChange={(value) => handleLegalChange(value, onChange)}
                    errors={errors}
                    name={name}
                  />
                );
              }}
            />
          </div>

          <Controller
            name={selectedLead ? "leads.global_hq" : "global_hq"}
            control={control}
            rules={{ required: "Global HQ is Required" }}
            render={({ field: { value, onChange, name } }) => {
              return (
                <InputBox
                  label="Global HQ"
                  name={name}
                  placeholder="Enter Global HQ"
                  errors={errors}
                  type="text"
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          />

          <Controller
            name={selectedLead ? "leads.revenue" : "revenue"}
            control={control}
            rules={{ required: "Revenue is Required" }}
            render={({ field: { value, onChange, name } }) => {
              return (
                <InputBox
                  label="Revenue"
                  name={name}
                  placeholder="Enter Revenue"
                  errors={errors}
                  type="text"
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          />

          <Controller
            name={selectedLead ? "leads.incepted" : "incepted"}
            control={control}
            render={({ field: { value, onChange, name } }) => {
              return (
                <DateInputBox
                  label="Incepted"
                  name={name}
                  errors={errors}
                  value={value || ""}
                  onChange={onChange}
                />

                // <DateInput
                //   value={value}
                //   onChange={onChange}
                //   name={name}
                //   clearErrors={clearErrors}
                //   label="Incepted"
                //   errors={errors}
                // />
              );
            }}
            rules={{
              required: {
                value: true,
                message: "Please select a date.",
              },
            }}
          />

          <Controller
            name={selectedLead ? "leads.india_operations" : "india_operations"}
            control={control}
            render={({ field: { value, onChange, name } }) => {
              return (
                <DateInputBox
                  label="India operations"
                  name={name}
                  errors={errors}
                  value={value || ""}
                  onChange={onChange}
                />

                // <DateInput
                //   value={value}
                //   onChange={onChange}
                //   name={name}
                //   clearErrors={clearErrors}
                //   label="India operations"
                //   errors={errors}
                // />
              );
            }}
            rules={{
              required: {
                value: true,
                message: "Please select enter a date.",
              },
            }}
          />

          {/* //multi entry */}
          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <label className="d-block text-sky fs-12 pb-2">
              Indian Presence
            </label>
            <Controller
              name={selectedLead ? "leads.indian_presence" : "indian_presence"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Indian presence is required",
                },
              }}
              render={({ field: { value, onChange } }) => {
                return (
                  <Multiselect
                    options={locationlist}
                    displayValue="city"
                    selectedValues={handlelocationvalue(value)}
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={(list) => {
                      let value = list.map((items) => {
                        return items.id;
                      });
                      if (value.length <= 0) {
                        setError("indian_presence", {
                          type: "customerr",
                          message: "Indian presence is required",
                        });
                      }
                      onChange(value);
                    }}
                    onSelect={(list) => {
                      let value = list.map((items) => {
                        return items.id;
                      });
                      if (value.length > 0) {
                        clearErrors("indian_presence");
                      }

                      onChange(value);
                    }}
                  />
                );
              }}
            />
            {errors.indian_presence && (
              <span className="fs-12 text-red">
                {errors.indian_presence.message}
              </span>
            )}
          </div>

          {/* <Controller
            name={selectedLead ? "leads.indian_hq" : "indian_hq"}
            control={control}
            rules={{ required: "Indian HQ is Required" }}
            render={({ field: { value, onChange, name } }) => {
              return (
                <InputBox
                  label="Indian HQ"
                  name={name}
                  placeholder="New Delhi"
                  errors={errors}
                  type="text"
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          /> */}
          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <LocationDropdown
              name={selectedLead ? "leads.indian_hq" : "indian_hq"}
              label={"Indian HQ"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <DropdownInput
              id={8}
              name={selectedLead ? "leads.business_model" : "business_model"}
              label={"Business Model"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <DropdownInput
              id={1}
              name={selectedLead ? "leads.industry_sector" : "industry_sector"}
              label={"Industry Sector"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <DropdownInput
              id={6}
              name={selectedLead ? "leads.lead_type" : "lead_type"}
              label={"Lead Type"}
              register={register}
              errors={errors}
            />
          </div>

          {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <UserlistdropdownInput
              name={selectedLead ? "leads.lead_generator" : "lead_generator"}
              label={"Lead Generator"}
              register={register}
              errors={errors}
            />
          </div> */}

          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <label className="d-block text-sky fs-12 pb-2">Lead Owner</label>
            <Controller
              name={selectedLead ? "leads.lead_owner" : "lead_owner"}
              control={control}
              defaultValue={selectedLead?.lead_owner || []}
              rules={{
                required: {
                  value: true,
                  message: "Lead Owner is required",
                },
              }}
              render={({ field: { value, onChange } }) => {
                //  console.log(allusers)
                return (
                  <Multiselect
                    // options={[...(allusers || []), {id: userprofile?.id, fullname: userprofile?.fullname}]}
                    options={allusers}
                    displayValue="fullname"
                    selectedValues={handlevalue(value)}
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={(list) => {
                      let value = list.map((items) => {
                        return items.id;
                      });
                      if (value.length <= 0) {
                        setError("lead_owner", {
                          type: "customerr",
                          message: "LeadOwner is required",
                        });
                      }
                      onChange(value);
                    }}
                    onSelect={(list) => {
                      let value = list.map((items) => {
                        return items.id;
                      });
                      if (value.length > 0) {
                        clearErrors("lead_owner");
                      }

                      onChange(value);
                    }}
                  />
                );
              }}
            />
            {errors.lead_owner && (
              <span className="fs-12 text-red">
                {errors.lead_owner.message}
              </span>
            )}
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <DropdownInput
              id={2}
              name={selectedLead ? "leads.source" : "source"}
              label={"Source"}
              register={register}
              errors={errors}
            />
          </div>

          <Controller
            name={selectedLead ? "leads.source_date" : "source_date"}
            control={control}
            render={({ field: { value, onChange, name } }) => {
              return (
                // <DateInput
                //   value={value}
                //   onChange={onChange}
                //   name={name}
                //   clearErrors={clearErrors}
                //   label="Source Date"
                //   errors={errors}
                // />
                <DateInputBox
                  label="Source Date"
                  name={name}
                  errors={errors}
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
            rules={{
              required: {
                value: true,
                message: "Please select enter a date.",
              },
            }}
          />

          {/* checkbox */}
          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <Controller
              name={selectedLead ? "leads.fortune_1000" : "fortune_1000"}
              control={control}
              // rules={{ required: "Company Name is Required" }}
              render={({ field: { value, onChange, name } }) => {
                // console.log(value);
                return (
                  <CheckboxInput
                    label="Fortune 1000"
                    name={name}
                    checked={value}
                    // value={value || ""}
                    onChange={onChange}
                  />
                );
              }}
            />
            {/* <input
              name={
                selectedLead
                  ? "leads.fortune_1000"
                  : "fortune_1000"
              }
              type="checkbox"
              defaultChecked={false}
              onClick={() => setFortune(!fortune)}
            /> */}
          </div>

          {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <DropdownInput
              id={9}
              name={
                selectedLead
                  ? "leads.potential_service_offering"
                  : "potential_service_offering"
              }
              label={"Potential Service Offerings"}
              register={register}
              errors={errors}
            />
          </div> */}

          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <label className="d-block text-sky fs-12 pb-2">
              Potential Service Offerings
            </label>
            <Controller
              name={
                selectedLead
                  ? "leads.potential_service_offering"
                  : "potential_service_offering"
              }
              control={control}
              defaultValue={selectedLead?.potential_service_offering || []}
              rules={{
                required: {
                  value: true,
                  message: "Potential Service Offerings is required",
                },
              }}
              render={({ field: { value, onChange } }) => {
                return (
                  <Multiselect
                    options={potentialData}
                    displayValue="name"
                    selectedValues={handlePotentialvalue(value)}
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={(list) => {
                      let value = list.map((items) => {
                        return items.id;
                      });
                      if (value.length <= 0) {
                        setError("potential_service_offering", {
                          type: "customerr",
                          message: "Potential Service Offerings is required",
                        });
                      }
                      onChange(value);
                    }}
                    onSelect={(list) => {
                      let value = list.map((items) => {
                        return items.id;
                      });
                      if (value.length > 0) {
                        clearErrors("potential_service_offering");
                      }

                      onChange(value);
                    }}
                  />
                );
              }}
            />
            {errors.potential_service_offering && (
              <span className="fs-12 text-red">
                {errors.potential_service_offering.message}
              </span>
            )}
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <DropdownInput
              id={3}
              name={
                selectedLead ? "leads.business_potential" : "business_potential"
              }
              label={"Business Potential"}
              register={register}
              errors={errors}
            />
          </div>

          <Controller
            name={selectedLead ? "leads.company_name" : "company_name"}
            control={control}
            rules={{ required: "Company Name is Required" }}
            render={({ field: { value, onChange, name } }) => {
              return (
                <InputBox
                  label="Company Name"
                  name={name}
                  placeholder="Enter Company Name"
                  errors={errors}
                  type="text"
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          />

          <div className="col-lg-9 col-md-12 col-sm-12 pb-4">
            {/* <label className="d-block text-sky fs-12 pb-2">
              News/ Research Repository
            </label> */}
            <div className="col-md-11 d-flex align-items-center gap-2">
              {/* <input type="text" className="form-field " /> */}
              <Controller
                name={
                  selectedLead
                    ? "leads.news_research_repo"
                    : "news_research_repo"
                }
                control={control}
                // rules={{
                //   required: {
                //     value: !watch("news_research_file"),
                //     message: "New/Research required",
                //   },
                // }}
                // shouldUnregister={watch("news_research_file")}
                render={({ field: { value, onChange, name } }) => {
                  return (
                    <InputBox
                      label="  News/ Research Repository"
                      name={name}
                      placeholder="Enter Website Link"
                      disabled={watch("news_research_file")}
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              />
              <span className="ps-4 pe-3">or</span>
              <span>
                <Controller
                  name="news_research_file"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <input
                        type="file"
                        id="upload"
                        disabled={watch("news_research_repo")}
                        className="d-none"
                        files={value}
                        accept=".doc, .pdf"
                        onChange={(event) => handleChange(event, onChange)}
                        multiple
                      />
                    );
                  }}
                />
                <label
                  htmlFor="upload"
                  className="px-4 py-2 rounded-2 text-nowrap attach-btn "
                  style={{ fontWeight: "bolder" }}
                >
                  Attach a File
                </label>
              </span>
              {(fileupload || selectedLead?.news_research_file) && (
                <FilePreview
                  fileupload={fileupload}
                  data={selectedLead?.news_research_file}
                  setFileUpload={setFileUpload}
                />
              )}
            </div>
          </div>

          {/* <Controller
            name={selectedLead ? "leads.company_name" : "company_name"}
            control={control}
            rules={{ required: "Company Name is Required" }}
            render={({ field: { value, onChange, name } }) => {
              return (
                <InputBox
                  label="Company Name"
                  name={name}
                  placeholder="BT"
                  errors={errors}
                  type="text"
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          /> */}

          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <DropdownInput
              id={5}
              name={
                selectedLead
                  ? "leads.stages_of_ops_india"
                  : "stages_of_ops_india"
              }
              label={"Stage of Ops in India"}
              register={register}
              errors={errors}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadForm;
