import React, { useState } from "react";
import EditIcon from "../../img/icon/edit-white.svg";
import DeleteIcon from "../../img/icon/trash.svg";
import useGetEscalationMember from "../../libs/queries/crm/useGetEscalationMember";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setmemberId, setmemberType } from "../../slice/CrmSlice";
import DeleteModal from "../../ui/modals/DeleteModal";
import useDeleteEscalationMember from "../../libs/mutation/crm/useDeleteEscalationMember";

const InternalEscTableBody = ({ crmid, data, levelId, setCheckBoxAdd, checkBoxAdd, search, setSearch }) => {
  const [dele, setdele] = useState(false);
  // const { data } = useGetEscalationMember({
  //   memberType: "internal",
  //   id: crmid,
  //   level: levelId,
  //   search: search,
  // });
  const dispatch = useDispatch();
  const { memberid } = useSelector((state) => state.crmSlice);
 
  const { mutate: deletemember, isLoading } = useDeleteEscalationMember({
    id: memberid,
  });

  const handleAddCheckbox = (multiemail) => {
  
    const index = checkBoxAdd.indexOf(multiemail);
    if (index === -1) {
      checkBoxAdd.push(multiemail);
    }
    else {
      checkBoxAdd.splice(index, 1);
    }
    setCheckBoxAdd([...checkBoxAdd]);
    // console.log(checkBoxAdd)
  
  };

  const handleDelete = () => {
    deletemember();
    setdele(false);
  };

  return (
    <>
      {data &&
        data?.results?.map((item, index) => {
          return (
            <tr key={index}>
              <td>
                <input type="checkbox" onClick={() => handleAddCheckbox(item?.email)} />
              </td>
              <td>{index + 1}</td>
              <td>{item?.name}</td>
              <td>{item?.designation}</td>
              <td>{item?.email}</td>
              <td>{item?.order}</td>
              <td>
                <div className="d-flex align-items-center gap-xl-3 gap-2">
                  <a
                    href="#"
                    className="action-icon bg-red"
                    onClick={() =>
                      dispatch(setmemberId(item?.id)) && setdele(true)
                    }
                  >
                    <img src={DeleteIcon} />
                  </a>
                  <Link
                    onClick={() =>
                      dispatch(setmemberId(item?.id)) &&
                      dispatch(setmemberType(item?.member_type))
                    }
                    to={`/crm/escalationmatrix/view/${crmid}`}
                    className="action-icon bg-sky"
                  >
                    <img src={EditIcon} />
                  </Link>
                </div>
              </td>
            </tr>
          );
        })}
      {dele && (
        <DeleteModal
          disabled={isLoading}
          deletename="campaign"
          onDeleteClick={handleDelete}
          onNoClick={() => setdele(false)}
        />
      )}
    </>
  );
};

export default InternalEscTableBody;
