import React from "react";
import BarChart from "../../ui/graphs/BarChart";
import useGetLeadsAllGraph from "../../libs/queries/graphs/useGetLeadsAllGraph";

const Monthwise = ({data}) => {
  // const { data } = useGetLeadsAllGraph();
  const { source_date_data, source_date_label } = data || {};
  const datacolor = ["rgba(54, 162, 235, 1)"];

  return (
    <div  className="bg-white py-4 px-4 border "
    style={{ width: "100%" }}
    >
      <div className="card-body">
        <h3 className="mb-3">Monthwise Lead</h3>
        {
          source_date_data?.length?
          <BarChart
          data={source_date_data}
          datalabel={source_date_label}
          datacolor={datacolor}
          />
          :
          <div className='my-4 d-flex justify-content-center'>
          No Data
          </div>
        }
      </div>
    </div>
  );
};

export default Monthwise;
