import React, { useState } from "react";
import useToggle from "../../hooks/useToggle";
import useDeleteLead from "../../libs/mutation/leads/useDeleteLead";
import useDeleteContact from "../../libs/mutation/leads/useDeleteContact";
import useGetLeadLogs from "../../libs/queries/leads/useGetLeadLogs";
import moment from "moment";

const ViewLogsModal = ({ leadid, maindata, setLogsVisible }) => {

    const {data, isLoading} = useGetLeadLogs(leadid);
    return (
        <div
            className="modaldelete"
        >
            <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
                className="modal-dialog modal-460 modal-dialog-centered"
            >
                <div
                    style={{
                        // width: '450px',
                        backgroundColor: 'white',
                        overflowY: "scroll", maxHeight: "350px",
                        minWidth: '70vw',
                    }}
                    // className="rounded-10 border-0 raleway "
                    className="modal-content rounded-10 border-0"
                >
                    <div className="modal-header pt-4 pb-0 px-4">
                        <h4 className="semibold fs-14 ">Logs</h4>
                        <button
                            onClick={()=> setLogsVisible(false)}
                            type="button"
                            className="selectContainer custom-btn close-modal py-1  rounded-3 border-bl-btn px-2 raleway medium"
                        >
                            Close
                        </button>
                    </div>
                    <hr></hr>

                    <div
                        className="bg-white px-md-4 px-3 mb-3"
                    >
                        <table
                            style={{
                                // border: ;
                            }}
                            className="action-table w-100 table table-bordered"
                        >
                            <tbody>
                                <tr style={{
                                    display: 'flex'
                                }}>
                                    <td style={{
                                        textAlign: "left",
                                        // width: "100%",
                                        width: '40%'
                                    }}>
                                        <h6 className="fs-12 text-sky regular  pb-md-2">
                                            Lead Name
                                        </h6>
                                    </td>
                                    <td style={{
                                        textAlign: "left",
                                        // width: "100%",
                                        width: '40%'
                                    }}>
                                        <h6 className="fs-12 text-sky regular  pb-md-2">
                                            Stage Name
                                        </h6>
                                    </td>
                                    <td style={{
                                        textAlign: "left",
                                        // width: "100%",
                                        width: '40%'
                                    }}>
                                        <h6 className="fs-12 text-sky regular  pb-md-2">
                                            Done at
                                        </h6>
                                    </td>
                                    <td style={{
                                        textAlign: "left",
                                        // width: "100%",
                                        width: '40%'
                                    }}>
                                        <h6 className="fs-12 text-sky regular  pb-md-2">
                                            Done by
                                        </h6>
                                    </td>
                                </tr>
                                {
                                    data?.map((data, index) => {
                                        const { lead_contact, stage_of_contact, updated_by, created_at } = data || {};
                                        return (
                                            <tr style={{
                                                display: 'flex'
                                            }} key={index}>
                                                <td className="action-mid"
                                                    // width={'50%'}
                                                    style={{
                                                        textAlign: "left",
                                                        // width: "100%",
                                                        width: '40%'
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            wordBreak: 'break-all'
                                                        }}
                                                        className="fs-12">{lead_contact}</p>
                                                </td>
                                                <td className="action-mid"
                                                    // width={'50%'}
                                                    style={{
                                                        textAlign: "left",
                                                        // width: "100%",
                                                        width: '40%'
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            wordBreak: 'break-all'
                                                        }}
                                                        className="fs-12">{stage_of_contact}</p>
                                                </td>
                                                <td
                                                    // width={'25%'} 
                                                    className="action-mid"
                                                    style={{
                                                        textAlign: "left",
                                                        // width: "100%",
                                                        width: '40%'
                                                    }}
                                                >

                                                    <p
                                                        style={{
                                                            wordBreak: 'break-all'
                                                        }}
                                                        className="fs-14">{moment(created_at).format('DD-MM-YYYY, h:mm a')}</p>
                                                </td>
                                                <td
                                                    className="action-mid"
                                                    style={{
                                                        textAlign: "left",
                                                        width: '40%'
                                                    }}>
                                                    <p
                                                        style={{
                                                            wordBreak: 'break-all'
                                                        }}
                                                        className="fs-12">{updated_by}</p>
                                                </td>

                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    </div>

                    <div
                        className="modal-footer border-0 px-md-5 px-4 pb-2 butn-group justify-content-center"
                    >

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewLogsModal;
