import React, { useState } from "react";
import TrashIcon from '../../../img/icon/trash.svg'
import EmailGeneratedModal from "./EmailGeneratedModal";
import useToggle from '../../../hooks/useToggle'
import DeleteModal from "../../../ui/modals/DeleteModal";
import useDeleteEmailTemplate from "../../../libs/mutation/emailtemplate/useDeleteEmailTemplate";

const EmailTemplateGenerated = ({index ,data}) => {

  const {id ,name, subject, created_at} = data || {}
  const { mutate: deleteemailtemplate, isLoading } = useDeleteEmailTemplate(id);
  const [detail, toggleDetail] = useToggle(false)
  const [dele, setdele] = useState(false)

  const date = created_at.substring(0,16)
  const datefinal = date.split("T").join(" ")
  const time = datefinal.substring(11,13)
 

  const handleDelete = () => {
    deleteemailtemplate();
    setdele(false);
  };
  

  return (
    <>
      <div className="bg-white rounded-10 px-md-4 px-3 py-3 mb-3 shadow-sm">
        <table className="action-table w-100  manage-table">
          <tr>
            <td className="action-first">
              <span className="number d-flex align-items-center justify-content-center rounded-circle fs-24 semibold">
                {index + 1}
              </span>
            </td>

            <td className="action-mid" style={{width: "290px"}}>
              <h6 className="fs-12 text-sky regular  pb-md-2"> Template Name</h6>
              <h5>{name}</h5>
            </td>
            <td className="action-mid" style={{width: "350px"}}>
              <h6 className="fs-12 text-sky regular  pb-md-2" >Subject</h6>
              <h5>{subject}</h5>
            </td>
            <td className="action-mid">
              <h6 className="fs-12 text-sky regular  pb-md-2" style={{ width: "200px" }}>Created At</h6>
              <h5>{datefinal} {time >= 12 ? "PM" : "AM"}</h5>
            </td>
            <td className="action-last">
              <div className="d-flex align-items-center gap-xl-3 gap-2 justify-content-end">
                <a
                  href="#"
                  className="selectContainer action-icon bg-red"
                  // data-bs-toggle="modal"
                  // data-bs-target="#delete"
                  onClick={() => setdele(true)}
                >
                  <img src={TrashIcon} />
                </a>
                <a
                  className="selectContainer acco-info-btn"
                  onClick={() => toggleDetail()}
                >
                  <i className="fa-solid fa-angle-down fs-20 align-middle"></i>
                </a>
              </div>
            </td>
          </tr>
        </table>
      </div>
      {/* <!--END ROW 1 --> */}
      {detail && ( <EmailGeneratedModal data={data} index={index} />)}
  
      {dele && (
        <DeleteModal
          disabled={isLoading}
          deletename="email template"
          onDeleteClick={handleDelete}
          onNoClick={() => setdele(false)}
        />
      )}
     
    </>
  );
};

export default EmailTemplateGenerated;
