import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchvalue: "",
};

const globalSearchSlice = createSlice({
  name: "globalSearchSlice",
  initialState,
  reducers: {
    setsearchvalue: (state, action) => {
      state.searchvalue = action.payload;
    },
  },
});

export const { setsearchvalue } = globalSearchSlice.actions;
export default globalSearchSlice.reducer;
