import React from "react";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";
import useLocalStorage from "../../../hooks/useLocalStorage";

const useGetCrm = ({ 
  page, 
  itemsPerPage, 
  crmType, 
  businessModel,
  industrySector,
  potentialId,
  relationId,
  search,
  communicationId,
  month,
  year,
  startDate,
  endDate,
  userId
}) => {

  const [globalData] = useLocalStorage("globalsearch");




  const queryParams = {
    page: page || 1,
    items_per_page: itemsPerPage || 500,
    organisation: crmType==='organisation'?1:'',
    business_model: businessModel || '',
    industry_sector: industrySector || '',
    search_key: search || '',
    potential_service_offering: potentialId || '',
    relationship_manager: relationId || '',
    communication_type: communicationId ||'',
    month: month || '',
    year: year ||'',
    start_date: startDate || '',
    end_date: endDate || '',
    user_id: userId || ''
  };

  const queryString = Object.keys(queryParams)
      .filter(key => queryParams[key] !== '')  // Filter out keys with no value
      .map(key => `${key}=${queryParams[key]}`)
      .join('&');
  const queryresult = useQuery({
    queryKey: [`crm`, {queryString}],
    keepPreviousData: true,
    queryFn: async () => {
      const res = await api.get(`management/crm/list?${queryString}`);

      return res?.data;
    },
  });

  return queryresult;
};

export default useGetCrm;
