import React from "react";
import api from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Endpoints } from "../../../api/Endpoints";

const useCreateEvent = ({onHide, meetingType}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data) => {
      let newData= {
        ...data,
        optional_attendees: data?.optional_attendees?.toString(),
        required_attendees: data?.required_attendees?.toString(),
        start_date_time: data?.start_date_time?.replace('T', " "),
        end_date_time: data?.end_date_time?.replace('T', " "),
        meeting_type: meetingType
      }
      const res = await api.post(Endpoints.addEventCalendar, newData);
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([`calendar`]);
      if(!data?.status){
        toast.error(data?.message)
      }
      else if(data?.status && data?.message=='Description is required'){
        toast.error(data?.message);
        return
      }
      else{
        toast.success('Event Created Successfully');
        if (onHide) onHide();
      }
    },

    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });
  return mutation;
};

export default useCreateEvent;
