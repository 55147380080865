import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { toast } from "react-toastify";
import api from "../../../api";
import { useSelector, useDispatch } from "react-redux";
import { setPageToLoad } from "../../../slice/locationSlice";
import axios from "axios";

const useDeleteLocation = (checkboxadd, currpage) => {
  let token =  window.localStorage.getItem("token")
  const queryClient = useQueryClient();
  const { pageitems } = useSelector((state) => state.locationSlice);
  const dispatch = useDispatch()
  const DeleteLocationmutation = useMutation({
    mutationFn: async () => {
      let url = `/record/location/delete/`;
      let data = {
        locations:checkboxadd
      }
      // for (let index = 0; index < checkboxadd.length; index++) {
      //   if (index == 0) {
      //     url = url + `location_ids[]=` + checkboxadd[index];
      //   } else {
      //     url = url + `&location_ids[]=` + checkboxadd[index];
      //   }
      // }
      // console.log('check',checkboxadd);
      // console.log('checkdt',data);
      // return
      const res = await api.post(url, data);
      // const res = await axios({
      //   method: 'get',
      //   data: data,
      //   url: url,
      //   headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }, 
      // });
      console.log(res.data);
      return res.data;
    },
    onSuccess: (data) => {
      // if (checkboxadd.length < pageitems) {
      //   queryClient.invalidateQueries(["locationlist", { page: 120 }]);
      // } else {
      //   queryClient.invalidateQueries(["locationlist"]);
      //   dispatch(setPageToLoad(119))
      // }
      queryClient.invalidateQueries(["locationlist"]);
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });

  return DeleteLocationmutation;
};

export default useDeleteLocation;
