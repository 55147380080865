import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../slice/authSlice";
import leadSlice from "../slice/leadSliceform";
import checkInSlice from "../slice/checkInSlice";
import agreementSlice from "../slice/agreementformSlice";
import marketIntGenerationSlice from "../slice/marketIntGenerationSlice";
import locationSlice from "../slice/locationSlice";
import sortSlice from "../slice/sortSlice";
import reportSlice from "../slice/reportSlice";
import crmSlice from "../slice/CrmSlice";
import campaignSlice from '../slice/campaignSlice'
import dashboardSlice from '../slice/dashboardSlice'
import globalSearchSlice from '../slice/globalSearchSlice'
import alertSlice from "../slice/alertSlice";
import searchSlice from "../slice/searchSlice";
import leadviewSlice from "../slice/leadviewSlice";

const store = configureStore({
  reducer: {
    authSlice: authSlice,
    leadSlice: leadSlice,
    checkInSlice: checkInSlice,
    agreementSlice: agreementSlice,
    marketIntGenerationSlice: marketIntGenerationSlice,
    locationSlice: locationSlice,
    sortSlice: sortSlice,
    reportSlice: reportSlice,
    crmSlice: crmSlice,
    campaignSlice: campaignSlice,
    dashboardSlice: dashboardSlice,
    globalSearchSlice: globalSearchSlice,
    alertSlice: alertSlice,
    searchSlice: searchSlice,
    leadviewSlice: leadviewSlice
  },
});

export default store;
