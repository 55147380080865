import React from "react";
import { useForm, Controller } from "react-hook-form";
// import useCreateAgreementForm from "../../../libs/mutation/agreementform/useCreateAgreementForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useCreateAgreement from "../../libs/mutation/agreements/useCreateAgreement";
import DropdownInput from "../../ui/inputs/DropdownInput";
import Multiselect from "multiselect-react-dropdown";
import useFilterData from "../../libs/queries/manage/useFilterData";
import useUsers from "../../libs/queries/profile/useUsers";
import { useState } from "react";
import moment from "moment/moment";
import { useEffect } from "react";
import AsyncPaginationExample from "../leads/LeadFormInput";
import useUpdateAgreement from "../../libs/mutation/agreements/useUpdateAgreement";
import dayjs from "dayjs";
import AgreementSearchInput from "./AgreementFormInput";
import { Typeahead } from "react-bootstrap-typeahead";
import useLocationList from "../../libs/queries/location/useLocation";
import useGetLeadListContact from "../../libs/queries/leads/useGetLeadListContact";
import RoleRestriction from "../../utils/roleRestriction";
import useGetLeadListContactTypeahead from "../../libs/queries/leads/useGetLeadTypeahead";

const CreateAgreement = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
    setError,
    clearErrors,
  } = useForm();

  const restrict = RoleRestriction();
  const [searchKey, setSearchKey] = useState('');
  const [isLoading2, setIsLoading2] = useState(false);
  const { agreementid } = useSelector((state) => state.agreementSlice);
  const { mutate: createagreement, isLoading } = useCreateAgreement();
  const { data: options } = useFilterData(24, null, true, true);
  const { data: contactDep } = useFilterData(12, null, true, true);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [showPdf, setShowPdf] = useState(true);
  const [pdfUrl, setPdfUrl] = useState('');
  const { data: users } = useUsers();
  const navigate = useNavigate();
  const isView = window.location.pathname.includes('view');
  const isContact = window.location.pathname.includes('contact');
  const contactDetails = JSON.parse(localStorage?.getItem('contactdata'));
  const updateData = localStorage?.getItem('viewagreement')? JSON.parse(localStorage?.getItem('viewagreement')) : null;
  const { mutate: updateAgreement, isLoading: updateLoading } = useUpdateAgreement(updateData?.id);
  const { data: locations } = useLocationList(null, null);
  const { data: contacts } = useGetLeadListContactTypeahead({
    leadType: (restrict === "admin" || restrict == 'practice')? 'organisation' : '',
    search: searchKey,
    enabled: searchKey?.length>1 ? true : false,
    setIsLoading: setIsLoading2
  });
  const [juriState, setJuriState] = useState('');

  const locationList = locations?.results;
  const contactList = contacts?.results;
  // console.log('juri__', watch('jurisdiction'))

  // const handleSelect = (selectedOptions) => {
  //   if (selectedOptions.length > 0) {
  //     const selectedOption = selectedOptions[0];
  //     console.log('selectedopt__', selectedOption)
  //     setSelectedLead(selectedOption);
  //   } else {
  //     console.log('selectedopt2__', selectedOptions)
  //     setSelectedLead(null);
  //   }
  // };


  const onSubmit = (data) => {    
    if (selectedLead) {
      // updateleads(data);
      if(isView && updateData){
        if(!data?.record_of_signed_copy?.length){
          delete data?.record_of_signed_copy
        }
        if(data?.ats_non_ats){
          delete data?.ats_non_ats
        }
        updateAgreement(data);
      }
      else{
        if(!data?.ats_non_ats){
          delete data?.ats_non_ats
        }
        createagreement(data);
      }
    } else {
      // console.log('leadcon4__', watch('lead_contact'))
      // console.log('dtleadna__', data?.lead_contact?.name)
      // console.log('dt__', data?.lead_contact)
      // data.lead_contact = data?.lead_contact?.name;
      if(isView && updateData){
        if(!data?.record_of_signed_copy?.length){
          delete data?.record_of_signed_copy
        }
        if(data?.ats_non_ats){
          delete data?.ats_non_ats
        }
        updateAgreement(data);
      }
      else{
        if(!data?.ats_non_ats){
          delete data?.ats_non_ats
        }
        createagreement(data);
      }
    }
  };

  console.log('leadcon__', watch('lead_contact'))

  useEffect(() => {
    if (updateData && isView) {
      setPdfUrl(updateData?.record_of_signed_copy)
      reset({
        contact_name: updateData?.contact_name,
        mobile_contact: updateData?.mobile_contact,
        signatory_authority_name: updateData?.signatory_authority_name,
        // client_execution_owner_name: updateData?.client_execution_owner_name,
        lead_contact: updateData?.lead_contact,
        email: updateData?.email,
        pricing: updateData?.pricing,
        remarks: updateData?.remarks,
        designation: updateData?.designation,
        from_date: updateData?.from_date,
        to_date: updateData?.to_date,
        // record_of_signed_copy: updateData?.record_of_signed_copy,
        signatory_authority_designation: updateData?.signatory_authority_designation,
        signatory_authority_number: updateData?.signatory_authority_number,
        ats_non_ats: updateData?.ats_non_ats,
        jurisdiction: updateData?.jurisdiction,
        client_portal: updateData?.client_portal,
        client_portal_url: updateData?.client_portal_url,
        client_portal_login: updateData?.client_portal_login,
        client_portal_password: updateData?.client_portal_password,
        cv_validity: updateData?.cv_validity,
        replacement_clause: updateData?.replacement_clause,
        client_category: updateData?.client_category,
        owner: updateData?.owner,
        contact_department: updateData?.contact_department,
        contact_level: updateData?.contact_level,
        payment_credit_period: updateData?.payment_credit_period,
        executed_on: updateData?.executed_on,
        executed_from: updateData?.executed_from,
        tenure: updateData?.tenure,
        invoice_raising_period: updateData?.invoice_raising_period,
        invoice_basis: updateData?.invoice_basis,
        invoicing_type: updateData?.invoicing_type,
        signing_mode: updateData?.signing_mode,
        applicability: updateData?.applicability,
        renewal_necessity: updateData?.renewal_necessity,
        renewal_possibility: updateData?.renewal_possibility,
        service_offering: updateData?.service_offering
      });
    }
  }, []);

  useEffect(()=>{
    if(locationList?.length){
      let displayValue = (locationList?.filter((ite)=> ite?.id == watch('jurisdiction')))?.[0]?.city;
      setJuriState(displayValue);
    }
  },[locationList?.length])


  useEffect(() => {
    if (contactDetails && isContact) {
      reset({
        contact_name: contactDetails?.name,
        email: contactDetails?.email,
        mobile_contact: contactDetails?.mobile,
        title: contactDetails?.title,
        remarks: contactDetails?.last_remark,
        ats_non_ats: moment(contactDetails?.created_at).format('YYYY-MM-DD'),
        lead_contact: contactDetails?.id
      });
    }
  }, []);

  useEffect(() => {
    if (selectedLead) {
      reset({
        lead_contact: selectedLead?.id,
        email: selectedLead?.email,
        mobile_contact: selectedLead?.mobile,
        title: selectedLead?.title,
        remarks: selectedLead?.last_remark,
        // ats_non_ats: moment(selectedLead?.created_at).format('YYYY-MM-DD'),
      });
    }
  }, [selectedLead]);

  // console.log('selected', JSON.stringify(selectedLead,null,4))

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSearch = (query) => {
    console.log('quer_____', query)
    // setIsLoading2(true);

    setSearchKey(query)
    
    // setIsLoading2(false);
};

  return (
    <div className="bg-white p-4">
      <div className="d-flex pb-4 pt-2">
        <span className="">Didn't find any contacts?</span>
        <span
        onClick={()=>{
          navigate('/leads/new');
        }}
        className="mx-1 text-sky selectContainer">Create New One</span>
      </div>
      <form className="add-form" 
      onSubmit={
        // isView && updateData?
        // handleSubmit(updateAgreement):
        // handleSubmit(createagreement)
        handleSubmit(onSubmit)
        }
      >
        <div className="row  gx-lg-5">

          {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
          <label className="d-block text-sky fs-12 pb-2">
              Client Execution Owner Name
          </label>
          <Controller
            name={'lead_contact'}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange, name } }) => {
              // console.log("see value", value);
              return (
                <AgreementSearchInput
                  value={value}
                  placeholder={''}
                  onChange={(value) => handleLegalChange(value, onChange)}
                  errors={errors}
                  name={name}
                />
              );
            }}
          />
          <span className="fs-12 text-red">
            {errors.lead_contact?.type === "required" &&
              "Client Execution Owner Name is required"}
          </span>
          </div> */}

          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <label className="d-block text-sky fs-12 pb-2">Client Execution Owner</label>
            <Controller
              defaultValue={isView? updateData?.lead_contact : isContact? contactDetails?.id: ''}
              name={"lead_contact"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Select Owner From The Given Options"
                },
              }}
              render={({ field: { value, onChange } }) => {
            
                return (
                  <Typeahead
                  id=""
                  labelKey="name"
                  options={contactList || []}
                  defaultInputValue={isView? updateData?.lead_contact_display: isContact? contactDetails?.name : ""}
                  placeholder="Search"
                  // maxResults={2}
                  // paginate={true}
                  onInputChange={handleSearch}
                  isLoading={isLoading2}
                  
                  emptyLabel={searchKey.length>1 ? 'No Matches Found': searchKey.length==1? "Keep Searching" : 'Start Searching'}
                  onChange={(list) => {
                    let value = list.map((items) => {
                      return items.id;
                    });
                    if(!value.length){
                      setError("lead_contact", { type: 'customerr', message: 'Select Owner From The Given Options' })
                    }
                    if(value.length > 0){
                      clearErrors("lead_contact")
                      setSelectedLead(list[0])
                    }
                  }}
                  />
                );
              }}
            />
            {errors.lead_contact && <span className="fs-12 text-red">
             {errors.lead_contact.message}
            </span>}
          </div>


          
          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={12}
              name="contact_department"
              label={"Contact Department"}
              register={register}
              errors={errors}
              required={true}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">Client Execution Owner Designation</label>
            <input
              type="text"
              className="form-field"
              name="designation"
              {...register("designation", { required: true })}
            />
            <span className="fs-12 text-red">
              {errors.designation?.type === "required" &&
                "designtation name is required"}
            </span>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={15}
              name="replacement_clause"
              label={"Replacement Clause"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">Client Execution Owner Email</label>
            <input
              type="text"
              className="form-field"
              name="email"
              // value={'abcd@gmail.com'}
              {...register("email", { required: true })}
            />
            <span className="fs-12 text-red">
              {errors.email?.type === "required" && "email name is required"}
            </span>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">
              Client Execution Owner Mobile
            </label>
            <input
              maxLength={10}
              type='tel'
              className="form-field"
              name="mobile_contact"
              {...register("mobile_contact", { required: true })}
            />
            <span className="fs-12 text-red">
              {errors.mobile_contact?.type === "required" &&
                "mobile contact is required"}
            </span>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={13}
              name="contact_level"
              label={"Contact Level"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">Pricing</label>
            <input
              type="text"
              className="form-field"
              name="pricing"
              {...register("pricing", { required: true })}
            />
            <span className="fs-12 text-red">
              {errors.pricing?.type === "required" && "Pricing is required"}
            </span>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={16}
              name="payment_credit_period"
              label={"Payment Credit Period"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">
              Agreement From
            </label>
            <input
              type="date"
              // min={moment(new Date()).format("YYYY-MM-DD")}
              className="form-field"
              name="from_date"
              {...register("from_date", { required: true })}
            />
            <span className="fs-12 text-red">
              {errors.from_date?.type === "required" &&
                "Agreement From is required"}
            </span>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">Agreement To</label>
            <input
              type="date"
              // min={moment(watch("from_date")).format("YYYY-MM-DD")}
              className="form-field"
              name="to_date"
              {...register("to_date", { required: true })}
            />
            <span className="fs-12 text-red">
              {errors.to_date?.type === "required" &&
                "Agreement To is required"}
            </span>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={32}
              name="executed_on"
              label={"Agreement Executed On"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={17}
              name="executed_from"
              label={"Agreement Executed From"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={18}
              name="tenure"
              label={"Agreement Tenure"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={19}
              name="invoice_raising_period"
              label={"Invoice Raising Period"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={20}
              name="invoice_basis"
              label={"Invoicing Basis"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={21}
              name="invoicing_type"
              label={"Invoicing Type"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={22}
              name="signing_mode"
              label={"Agreement Signing"}
              register={register}
              errors={errors}
            />
          </div>


          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={23}
              name="applicability"
              label={"Agreement Applicability"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">Service Offering</label>
            <Controller
              rules={{required: true}}
              // defaultValue={isView? updateData?.service_offering: []}
              name="service_offering"
              control={control}
              render={({ field: { value, onChange } }) => {
                let selectedValues = value?.map?.((item)=>{
                  return options?.data?.find((ite)=> ite?.id === item)
                })?.filter((item)=> item);
                return (
                  <Multiselect
                    options={options?.data}
                    displayValue="name"
                    selectedValues={selectedValues}
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={(list) => {
                      let value = list.map((items) => {
                        return items.id;
                      });
                      onChange(value);
                    }}
                    onSelect={(list) => {
                      let value = list.map((items) => {
                        return items.id;
                      });
                      onChange(value);
                    }}
                  />
                );
              }}
            />
            <span className="fs-12 text-red">
              {errors.service_offering &&
                "Service Offering is required"}
            </span>
          </div>

          {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={24}
              name="service_offering"
              label={"Service Offering"}
              register={register}
              errors={errors}
            />
          </div> */}

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              isUsers={true}
              name="owner"
              label={"Agreement Owner"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
            id={25}
              name="renewal_necessity"
              label={"Renewal Necessity"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={26}
              name="renewal_possibility"
              label={"Renewal Possibility"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">
              Record of Signed Copy
            </label>
            <input
              // placeholder="abc"
              title="hcnb"
              type="file"
              className="form-field"
              name="record_of_signed_copy"
              accept=".pdf"
              {...register("record_of_signed_copy", { required: !isView? true: false })}
            />
            <span className="fs-12 text-red">
              {errors.record_of_signed_copy?.type === "required" &&
                "Record of Signed Copy is required"}
            </span>
            {/* <span className="fs-12">
              {isView && updateData &&
                updateData?.record_of_signed_copy
                .split("/")?.[3]?.toString()}
            </span> */}
            {isView && updateData && showPdf &&<div className="fs-12 align-items-center">
                <button className="border-0 bg-white" 
                onClick={() => setShowPdf(false)}>X</button>
                {pdfUrl?.split("/")?.[3]?.toString()}
            </div>}
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">
              Signatory Authority Name
            </label>
            <input
              type="text"
              className="form-field"
              name="signatory_authority_name"
              {...register("signatory_authority_name", { required: true })}
            />
            <span className="fs-12 text-red">
              {errors.signatory_authority_name?.type === "required" &&
                "Signatory Authority Name is required"}
            </span>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">
              Signatory Authority Designation{" "}
            </label>
            <input
              type="text"
              className="form-field"
              name="signatory_authority_designation"
              {...register("signatory_authority_designation", {
                required: true,
              })}
            />
            <span className="fs-12 text-red">
              {errors.signatory_authority_designation?.type === "required" &&
                "Signatory Authority Designation is required"}
            </span>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">
              Signatory Authority Number
            </label>
            <input
              maxLength={10}
              // type="number"
              className="form-field"
              name="signatory_authority_number"
              {...register("signatory_authority_number", { required: true })}
            />
            <span className="fs-12 text-red">
              {errors.signatory_authority_number?.type === "required" &&
                "Signatory Authority Number is required"}
            </span>
          </div>

          {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">
              Client Execution Owner Name
            </label>
            <input
              type="text"
              className="form-field"
              name="client_execution_owner_name"
              {...register("client_execution_owner_name", { required: true })}
            />
            <span className="fs-12 text-red">
              {errors.client_execution_owner_name?.type === "required" &&
                "Client Execution Owner Name is required"}
            </span>
          </div> */}

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={14}
              name="cv_validity"
              label={"CV Validity and Ownership"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">ATS/Non ATS</label>
            <input
            defaultValue={''}
              disabled={true}
              type="text"
              className="form-field"
              name="ats_non_ats"
              {...register("ats_non_ats")}
            />
            <span className="fs-12 text-red">
              {errors.ats_non_ats?.type === "required" &&
                "ATS/Non ATS is required"}
            </span>
          </div>

          {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">Arbitrary Jurisdiction</label>
            <input
              type="text"
              className="form-field"
              name="jurisdiction"
              {...register("jurisdiction", { required: true })}
            />
            <span className="fs-12 text-red">
              {errors.jurisdiction?.type === "required" &&
                "jurisdiction is required"}
            </span>
          </div> */}

          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <label className="d-block text-sky fs-12 pb-2">Arbitrary Jurisdiction</label>
            <Controller
            name={"jurisdiction"}
              control={control}
              // defaultValue={"jurisdiction" || []}
              rules={{
                required: {
                  value: true,
                  message: "Select Jurisdiction From The Given Options"
                },
              }}
              render={({ field: { value, onChange } }) => {
                // let displayValue = (locationList?.filter((ite)=> ite?.id == value))?.[0]?.city;
                if(!juriState && isView){
                  return <div></div>
                }
                return (
                  <Typeahead
                  id=""
                  labelKey="city"
                  options={locationList}
                  placeholder="Choose Location"
                  defaultInputValue={juriState}
                  onChange={(list) => {
                    let value = list.map((items) => {
                      return items.id;
                    });
                    if(!value.length){
                      setError("jurisdiction", { type: 'customerr', message: 'Select Jurisdiction From The Given Options' })
                    }
                    if(value.length > 0){
                      clearErrors("jurisdiction")
                    }
                    onChange(value?.[0]);
                  }}
                  // onChange={(event) => handleCityChange(event, onChange)}

                  />
                );
              }}
            />
            {errors.jurisdiction && <span className="fs-12 text-red">
             {errors.jurisdiction.message}
            </span>}
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={27}
              name="client_category"
              label={"Category Of Client"}
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">Remarks</label>
            <input
              type="text"
              className="form-field"
              name="remarks"
              {...register("remarks", { required: true })}
            />
            <span className="fs-12 text-red">
              {errors.remarks?.type === "required" && "remarks is required"}
            </span>
          </div>

          <div 
          // className="d-flex align-items-center col-lg-3 col-md-4 col-sm-6"
          className="d-flex align-items-center pt-3 pb-4"
          >
          <input
          name="client_portal"
          type="checkbox"
          // value={isChecked? "1" : 0}
          checked={isChecked}
          {...register("client_portal", {onChange:handleCheckboxChange})}
          />
          <label className="d-block text-sky fs-12 mx-2">
          Client Portal
          </label>
          </div>

          {isChecked && 
            <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
              <label className="d-block text-sky fs-12 pb-2">Client Portal Url</label>
              <input
                type="text"
                className="form-field"
                name="client_portal_url"
                {...register("client_portal_url", { required: isChecked? true : false })}
              />
              <span className="fs-12 text-red">
                {errors.client_portal_url?.type === "required" && isChecked && "Client Portal URL is required"}
              </span>
            </div>}

            {isChecked && <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">Client Portal Login</label>
            <input
              type="text"
              className="form-field"
              name="client_portal_login"
              {...register("client_portal_login", { required: isChecked? true : false })}
            />
            <span className="fs-12 text-red">
              {errors.client_portal_login?.type === "required" && isChecked && "Client Portal Login is required"}
            </span>
            </div>}

            {isChecked && <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">Client Portal Password</label>
            <input
              type="password"
              className="form-field"
              name="client_portal_password"
              {...register("client_portal_password", { required: isChecked? true : false })}
            />
            <span className="fs-12 text-red">
              {errors.client_portal_password?.type === "required" && isChecked && "Client Portal Password is required"}
            </span>
            </div>}
          {/* </div>} */}

          <div className="d-flex border-0 butn-group justify-content-center gap-3">
            <button
            style={{
              backgroundColor: (isLoading || updateLoading)? '#90d5fd' : '',
            }}
            disabled={isLoading || updateLoading}
              type="submit"
              className="custom-btn border-0 py-2 rounded-3 raleway medium"
            >
              {(isLoading || updateLoading)? "Processing..." : "Save"}
            </button>
            <button
              type="button"
              onClick={() => navigate("/agreement")}
              className="custom-btn py-2  rounded-3 border-bl-btn px-4 raleway semibold "
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default React.memo(CreateAgreement);
