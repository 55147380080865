import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../api/index";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { setEmailData } from "../../../slice/campaignSlice";
import useLastCampaign from "./useLastCampaign";

const useDocumentEmail = () => {
  const { documentId } = useSelector((state) => state.campaignSlice);
  // const {mutate: lastCampaign} = useLastCampaign()
  const dispatch = useDispatch()

  const queryClient = useQueryClient();
  let data = {
    document_ids: documentId,
  };

  const mutation = useMutation({
    mutationFn: async () => {
      const res = await api.post(`market/document-email`, data);
      console.log(res?.data?.status);

      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([`emailmarket`]);
      // lastCampaign();
      if (data?.status !== false) {
        localStorage.setItem("marketemaildata", JSON.stringify(data));
       
      }else {
        localStorage.setItem("marketemaildata", JSON.stringify([]))
      }
      // toast.success(data.message);
    },
    onError: (error) => {
      // toast.error(`${error["response"].data.message}`, {
      //   position: "top-right",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   theme: "colored",
      // });
    },
  });

  return mutation;
};

export default useDocumentEmail;
