import React, { useEffect, useState } from "react";
import useUpdateEmailTemplate from "../../../libs/mutation/emailtemplate/useUpdateEmailTemplate";
import { useForm, Controller } from "react-hook-form";
import Multiselect from "multiselect-react-dropdown";
import useGetEmailVariables from "../../../libs/queries/emailtemplate/ useGetEmailVariables";
import ReactQuill from "react-quill";
import useSendTo from "../../../libs/queries/emailtemplate/useSendTo";
import useDeleteEmailAttachment from "../../../libs/mutation/emailtemplate/useDeleteAttachment";
// import TextEditor from "./TextEditor";

const EmailGeneratedEdit = ({ data, index, onHide }) => {
  const [files, setFiles] = useState([]);
  const {
    id,
    name,
    subject,
    send_to,
    variables_display,
    variables,
    email_body,
    email_files,
  } = data || {};
  const { mutate: updateemailtemplate, isLoading } = useUpdateEmailTemplate(
    id,
    onHide
  );
  const { data: emailtemplatevariable } = useGetEmailVariables();
  const { mutate: deleteemailfile, isLoading: loading2 } = useDeleteEmailAttachment();

  const { data: sendToData } = useSendTo();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm();

  const removeFile = (ind) => {
    setFiles((previous) => {
      let UpdateFiles = [...previous];
      UpdateFiles.splice(ind, 1);
      return UpdateFiles;
    });
  };

  console.log('files___', files)
  console.log('filesemail___', email_files)
  console.log('ind__', index)
  console.log('errosss__', errors)
  

  const handleChange = (event, onChange) => {
    let upload_files = [];

    for (let i = 0; i < event?.target?.files?.length; i++) {
      upload_files.push(event.target.files[i]);
    }

    // setFiles(upload_files);
    setFiles((previous) => {
      let UpdateFiles = [...upload_files, ...previous];
      return UpdateFiles;
    });
    onChange([...upload_files, ...files]);
  };

  const handlevariablevalue = (value) => {
    let obj = [];
    if (emailtemplatevariable) {
      value?.forEach((element) => {
        let found = emailtemplatevariable.find((etv) => etv.id === element);
        if (found) {
          obj.push(found);
        }
      });
    }
    return obj;
  };
  

  useEffect(() => {
    if (data) {
      reset({
        name,
        subject,
        send_to,
        files,
        variables,
        email_body,
        email_files,
      });
    }
  }, []);

  return (
    <form
      onSubmit={handleSubmit(updateemailtemplate)}
      className="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3 edit-info-table "
      // id="edit_info_1"
    >
      <table className="action-table w-100 manage-table">
        <tr>
          <td className="action-first">
            <span className="number d-flex align-items-center justify-content-center rounded-circle fs-24 semibold">
              {index + 1}
            </span>
          </td>

          <td className="action-mid">
            <div className="row pe-xl-5">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="pb-3">
                  <h6 className="fs-12 text-sky regular  pb-1">
                    Template Name
                  </h6>
                  <input
                    type="text"
                    placeholder="Process Mail- Zepto"
                    {...register("name", { required: true })}
                  />
                  <span className="fs-12 text-red">
                    {errors.name?.type === "required" && "Name is required"}
                  </span>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="pb-3">
                  <h6 className="fs-12 text-sky regular  pb-1">Subject</h6>
                  <input
                    type="text"
                    placeholder="Candidature Processed"
                    {...register("subject", { required: true })}
                  />
                  <span className="fs-12 text-red">
                    {errors.subject?.type === "required" && "Subject is required"}
                  </span>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="pb-3">
                  <h6 className="fs-12 text-sky regular  pb-1">Send To</h6>
                  <select
                  defaultValue={send_to}
                  name="send_to"
                  {...register("send_to", {
                    required: true,
                  })}
                  >
                    <option value="">Select</option>
                    {sendToData?.map(([key, value]) => {
                      return (
                        <option
                          className="form-field"
                          key={key}s
                          value={key}
                        >
                          {value}
                        </option>
                      );
                    })}
                  </select>
                  <span className="fs-12 text-red">
                    {errors.send_to?.type === "required" && "Send To is required"}
                  </span>
                </div>
              </div>

              <div className="col-lg-9 ">
                <div className="pb-3">
                  <h6 className="fs-12 text-sky regular  pb-1">
                    Select Variable
                  </h6>
                  {/* <input
                    type="text"
                    placeholder="Candidate Name,JobOpening Posting Title,Client Name,Recruiter Name,Recruiter Email"
                    {...register("variables", { required: true })}
                  /> */}
                  <Controller
                    name="variables"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      
                      return (
                        <Multiselect
                          options={emailtemplatevariable}
                          displayValue="name"
                          groupBy="group"
                          // selectedValues={variables_display.split(",").map((item, index) => ({id: variables[index], name: item})) }
                          selectedValues={handlevariablevalue(value)}
                          onKeyPressFn={function noRefCheck() {}}
                          onRemove={(list) => {
                            let value = list.map((items) => {
                              return items.id;
                              // return items.name;
                            });
                            onChange(value);
                            // onChange(value.join(","));
                          }}
                          onSelect={(list) => {
                            let value = list.map((items) => {
                              return items.id;
                              // return items.name;
                            });

                            onChange(value);
                            // onChange(value.join(","));
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="mail-body border rounded-4" style={{ height: "500px" }}>
                  <div className="">
                    <h6 className="fs-16 semibold p-3 border-bottom">
                      Mail Body
                    </h6>
                    <Controller
                      name="email_body"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <ReactQuill
                            theme="snow"
                            value={value}
                            onChange={(email_body) => onChange(email_body)}
                            style={{ height: "400px" }}
                          />
                        );
                      }}
                    />
                  </div>
                  
                </div>
                

                <div className="send-mail py-3 d-flex gap-3 justify-content-center align-items-center my-4">
                  <a
                    href="#"
                    className="text-sky medium rounded-10 semibold fs-12"
                  >
                    Upload Attachment
                  </a>
                  <span>
                    {/* <input type="file" id="upload" className="d-none" /> */}
                    <Controller
                      name="files"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <input
                            type="file"
                            id="upload"
                            className="d-none"
                            files={value}
                            accept=".doc, .pdf"
                            // onChange={(event) => handleFile(event, onChange)}
                            onChange={(event) => handleChange(event, onChange)}
                            multiple
                          />
                        );
                      }}
                    />
                    <label
                      htmlFor="upload"
                      className="px-4 py-2 rounded-2 text-nowrap attach-btn selectContainer"
                    >
                      Attach a File
                    </label>
                  </span>
                </div>
                {files &&
                  files.map((items, ind) => {
                    
                    return (
                      <div key={items.lastModified}>
                        <button className="selectContainer" onClick={(e) => {
                          removeFile(ind);
                          e.preventDefault();
                          console.log('kjhjgcx')
                          }}>X</button>
                        {items?.name}
                      </div>
                    );
                  })}
                {email_files &&
                  email_files.map((items) => {
                    
                    return (
                      <div key={items.lastModified}>
                        <button className="selectContainer" onClick={(e) => {
                          // removeFile(index);
                          deleteemailfile(items?.id);
                          e.preventDefault();
                          console.log('kjhjgcx')
                          }}>X</button>
                        {items.file}
                      </div>
                    );
                  })}
                <div className="d-flex border-0 butn-group justify-content-center  gap-3">
                  <button
                    type="submit"
                    className="custom-btn border-0 py-2 rounded-3 raleway medium"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="custom-btn  py-2  rounded-3 border-bl-btn px-4 raleway semibold "
                    onClick={onHide}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </form>
  );
};

export default EmailGeneratedEdit;
