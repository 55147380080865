import React, { useEffect, useState } from "react";
/// hooks used ///
import { useForm, Controller } from "react-hook-form";

/// StyleSheet import ///
import "../../Styles/auth.css";

/// Imports of Component ///
import useEnterotp from "../../libs/mutation/auth/useEnterotp";
import OtpInput from "./OtpInput";
import useForgotpassword from "../../libs/mutation/auth/useForgotpassword";

/// Icons Or Images import ///
import Emailicon from "../../img/icon/email.svg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import OTPInput from 'react-otp-input';

const EnterOtpForm = () => {
  const {
    register,
    reset,
    getValues,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const { mutate: enterotp, isLoading } = useEnterotp();
  const { mutate: forgotpassword } = useForgotpassword();
  const [otp, setOtp] = useState("");

  const { forgotemail } = useSelector((state) => state.authSlice);
  // 

  const handleotp = () => {
    const email = getValues("email");
    // 
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email === "") {
      return;
    }

    if (regex.test(email)) {
      forgotpassword({ email });
      toast.success("Otp is send!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    if (forgotemail) {
      reset({ email: forgotemail });
    }
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmit(enterotp)}
        action=""
        className="signup-form px-lg-5"
      >
        {/***  email ***/}
        <div className="form-field mb-3">
          <label className="fs-12 d-block pb-1">Enter Email ID</label>
          <div className="position-relative">
            <img
              src={Emailicon}
              alt=""
              className="position-absolute ms-3 mt-3 pt-1"
            />

            <input
              id="email"
              name="email"
              type="email"
              placeholder="Enter Your Email Address"
              className="ps-5"
              {...register("email", {
                required: true,
                pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
              })}
            />
            <span className="fs-12 text-red">
              {errors.email?.type === "required" && "Email is Required"}
              {errors.email?.type === "pattern" && "Enter Valid Email"}
            </span>
          </div>
        </div>
        {/***  email ***/}

        <div 
        className="form-field mb-4"
        >
          <label 
          className="fs-10 d-block pb-4"
          >
            Please Enter the OTP sent to given email ID
          </label>

          <Controller
            name="code"
            control={control}
            rules={{ required: "true" }}
            render={({ field: { value, onChange } }) => {
              // return <OtpInput value={value || ""} onChange={onChange} />;
              return <div 
              className="otp-field d-flex gap-3 align-items-center pb-2"
              >
                <OTPInput
                onChange={onChange}
                value={value || ''}
                numInputs={6}
                inputType='tel'
                inputStyle={{
                  marginLeft: '10px',
                  marginRight: '10px'
                }}
                renderSeparator={(index)=> <span>{index==2 ? '-' : ''}</span>}
                renderInput={(props) => <input {...props} />}
                />
                </div>
            }}
          />
          
          <span className="fs-12 text-red">
            {errors.code?.type === "required" && "Otp is Required"}
          </span>

          <p
            className="forgot-link fs-10 text-decoration-underline text-black"
            onClick={handleotp}
            style={{ cursor: "pointer" }}
          >
            Send OTP Again
          </p>
        </div>

        {/***  buttons ***/}
        <button
          type="submit"
          disabled={isLoading}
          style={{ paddingTop: "15px", paddingBottom: "15px" }}
          className="custom-btn fs-20  rounded-pill w-100 "
        >
          Continue
        </button>
        {/***  buttons ***/}
      </form>
    </>
  );
};

export default EnterOtpForm;
