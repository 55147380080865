import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import InputBox from "../../ui/inputs/InputBox";
import DateInput from "../../ui/inputs/DateInput";
import DropdownInput from "../../ui/inputs/DropdownInput";
import useGetLead from "../../libs/queries/leads/useGetLead";
import useGetStageList from "../../libs/queries/StageApi/useGetStageList";
import DateInputBox from "../../ui/inputs/DateInputBox";
import useGetLeadListContact from "../../libs/queries/leads/useGetLeadListContact";
import { toast } from "react-toastify";
import useMobileSearch from "../../libs/mutation/leads/useMobileSearch";

const LeadFormContact = ({
  control,
  errors,
  watch,
  register,
  selectedLead,
  clearErrors,
  setError,
  reset,
}) => {
  const { data: getstage } = useGetStageList();
  const [mobile, setMobile] = useState("");

  const { data: checkEmailMobile } = useGetLeadListContact({
    search: mobile,
    leadType: "organisation",
  });

  const { mutateAsync: searchMobile } = useMobileSearch({
    search: mobile,
    leadType: "organisation",
  });

  let mobileNumber = watch("mobile") || watch("contact.mobile");
  let emailEntered = watch("email") || watch("contact.email");

  // console.log('wtmob__', watch("contact.mobile"))
  // console.log('wtmob2__', watch("mobile"))
  // console.log('wtemail__', watch("email"))
  // console.log('wtemail2__', watch("contact.email"))

  const handleChange = (event, onChange) => {
    const value = event.target.value;
    const mobileregex = /^[1-9]{1}[0-9]{9}$/;
    const emailregex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // console.log(value?.match(mobileregex));
    if (mobileregex.test(value)) {
      checkContactExist(value, "contact");

      // setError("contact.mobile", {
      //   type: "customerr",
      //   message: "Enter any other mobile number",
      // });
      // setMobile(mobilNumber);
    }
    if (emailregex.test(value)) {
      checkContactExist(value, "email");
      // setMobile(mobilNumber);
    }
    onChange(value);
  };

  // useEffect(()=>{
  //   let regex = /^[1-9]{1}[0-9]{9}$/
  //   if(mobileNumber?.match(regex)){
  //     checkContactExist(mobileNumber);

  //       // setError("contact.mobile", {
  //       //   type: "customerr",
  //       //   message: "Enter any other mobile number",
  //       // });
  //     // setMobile(mobilNumber);
  //   }
  // },[mobileNumber]);

  // useEffect(()=>{
  //   let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  //   if(emailEntered?.match(regex)){
  //     checkContactExist(emailEntered);
  //     // setMobile(mobilNumber);
  //   }
  // },[emailEntered]);

  const checkContactExist = async (value, type) => {
    try {
      let response = await searchMobile({
        search: value,
        leadType: "organisation",
      });
      if (response?.results?.length > 0) {
        // console.log('resposne',response);

        toast.error("you cannot create another lead by using this " + type + ".");
        // reset();
      }
    } catch (e) {
      console.log("eeee", e);
    }
  };

  return (
    <div className="bg-white p-4" style={{ marginTop: "30px" }}>
      <h4 className="medium fs-18 text-black pb-4 ">Contact Information</h4>
      <div action="" className="add-form">
        <div className="row  gx-lg-5">
          <Controller
            name={selectedLead ? "contact.name" : "name"}
            control={control}
            rules={{ required: "Name is Required" }}
            render={({ field: { value, onChange, name } }) => {
              return (
                <InputBox
                  label="Name"
                  name={name}
                  errors={errors}
                  placeholder="Enter Name"
                  type="text"
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          />

          <Controller
            name={selectedLead ? "contact.title" : "title"}
            control={control}
            rules={{ required: "Title is Required" }}
            render={({ field: { value, onChange, name } }) => {
              return (
                <InputBox
                  label="Title"
                  name={name}
                  errors={errors}
                  placeholder="Enter Title"
                  type="text"
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          />

          <Controller
            name={selectedLead ? "contact.mobile" : "mobile"}
            control={control}
            rules={{
              required: (watch("email") || watch("contact.email"))? false : "Mobile/Email is Required",
              maxLength: { value: 10, message: "Number is more than 10 digit" },
              pattern: { value: /^[0-9]+$/, message: "Number is required" },
            }}
            render={({ field: { value, onChange, name } }) => {
              return (
                <>
                  <InputBox
                    label="Mobile"
                    name={name}
                    errors={errors}
                    placeholder="Enter Mobile"
                    type="text"
                    value={value || ""}
                    onChange={(event) => handleChange(event, onChange)}
                  />
                </>
              );
            }}
          />

          <Controller
            name={selectedLead ? "contact.email" : "email"}
            control={control}
            rules={{ required: (watch("mobile") || watch("contact.mobile"))? false :"Email/Mobile is Required" }}
            render={({ field: { value, onChange, name } }) => {
              return (
                <InputBox
                  label="Email"
                  name={name}
                  errors={errors}
                  placeholder="Enter Email"
                  type="email"
                  value={value || ""}
                  onChange={(event) => handleChange(event, onChange)}
                />
              );
            }}
          />

          <Controller
            name={selectedLead ? "contact.linkedin_url" : "linkedin_url"}
            control={control}
            rules={{ required: "Linkedin URL is Required" }}
            render={({ field: { value, onChange, name } }) => {
              return (
                <InputBox
                  label="Linkedin URL"
                  name={name}
                  errors={errors}
                  placeholder="Enter URL"
                  type="text"
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          />

          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <label className="d-block text-sky fs-12 pb-2">
              Stage of Contact
            </label>
            <select
              name="stage_of_contact"
              watch
              {...register(
                selectedLead ? "contact.stage_of_contact" : "stage_of_contact",
                { required: true }
              )}
            >
              <option value="">Select a Stage of Contact</option>

              {getstage &&
                getstage?.results?.map((item, index) => {
                  return (
                    <>
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    </>
                  );
                })}
            </select>
            <span className="fs-12 text-red">
              {errors.stage_of_contact?.type === "required" &&
                "Stage of Contact is Required"}
            </span>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <DropdownInput
              id={10}
              name={selectedLead ? "contact.first_pitch" : "first_pitch"}
              label={"First Pitch"}
              register={register}
              errors={errors}
            />
          </div>

          {/* calender */}
          {watch("contact.stage_of_contact") == 1 ||
          watch("contact.stage_of_contact") == 2 ||
          watch("stage_of_contact") == 1 ||
          watch("stage_of_contact") == 2 ? (
            ""
          ) : (
            <>
              <Controller
                name={selectedLead ? "contact.proposal_date" : "proposal_date"}
                control={control}
                render={({ field: { value, onChange, name } }) => {
                  // console.log(value);
                  return (
                    <DateInputBox
                      // disabled={((watch("contact.stage_of_contact") == 1 || watch("contact.stage_of_contact") == 2 ) || (watch("stage_of_contact") == 1 || watch("stage_of_contact") == 2)) }
                      label="Proposal Date"
                      name={name}
                      errors={errors}
                      value={value || ""}
                      onChange={onChange}
                    />
                    // <DateInput
                    //   value={value}
                    //   onChange={onChange}
                    //   name={name}
                    //   label="Proposal Date"
                    //   clearErrors={clearErrors}
                    //   errors={errors}
                    // />
                  );
                }}
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Please select enter a date.",
                //   },
                // }}
              />
            </>
          )}

          {/* calender */}

          <Controller
            name={
              selectedLead ? "contact.next_followup_date" : "next_followup_date"
            }
            control={control}
            render={({ field: { value, onChange, name } }) => {
              return (
                <DateInputBox
                  label="Next Followup Date"
                  name={name}
                  errors={errors}
                  value={value || ""}
                  onChange={onChange}
                />
                // <DateInput
                //   value={value}
                //   onChange={onChange}
                //   name={name}
                //   clearErrors={clearErrors}
                //   label="Next Followup Date"
                //   errors={errors}
                // />
              );
            }}
            rules={{
              required: {
                value: true,
                message: "Please select enter a date.",
              },
            }}
          />

          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <DropdownInput
              id={11}
              name={
                selectedLead
                  ? "contact.timeline_to_aquire"
                  : "timeline_to_aquire"
              }
              label={"Timeline to Aquired"}
              register={register}
              errors={errors}
            />
          </div>

          <Controller
            name={selectedLead ? "contact.last_remark" : "last_remark"}
            control={control}
            // rules={{ required: "Last Follow Up Remarks" }}
            render={({ field: { value, onChange, name } }) => {
              return (
                <InputBox
                  label="Last Follow Up Remarks"
                  name={name}
                  errors={errors}
                  placeholder="Enter Remarks"
                  type="text"
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          />

          <Controller
            name={
              selectedLead
                ? "leads.compnay_forecast_remark"
                : "compnay_forecast_remark"
            }
            control={control}
            // rules={{ required: "Company Forecast Remark Remarks" }}
            render={({ field: { value, onChange, name } }) => {
              return (
                <InputBox
                  label="Company Forecast Remark"
                  name={name}
                  errors={errors}
                  placeholder="Enter Company Forecast Remark"
                  type="text"
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LeadFormContact;
