import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checkedIn: ''
};

const checkInSlice = createSlice({
  name: "checkInSlice",
  initialState,
  reducers: {
    setCheckedIn: (state, action) => {
      state.checkedIn = action.payload
  },
  },
});

export const { setCheckedIn } = checkInSlice.actions;
export default checkInSlice.reducer;
