import React from "react";
import api, { Imageapi } from "../../../api/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const useUpdateEmailTemplate = (id, onHide) => {
  const queryClient = useQueryClient();
  const updateEmailTemplate = useMutation({
    mutationFn: async (data) => {
      let postForm = new FormData();
      postForm.append("name", data.name);
      postForm.append("subject", data.subject);
      postForm.append("send_to", data.send_to);
      postForm.append("email_body", data.email_body);
      let variabless = data.variables;
      if (variabless?.length) {
        variabless?.map((i) => {
          postForm.append("variables", i);
        });
      }
      let file = data.files;
      if (file.length) {
        file.map((i) => {
          postForm.append("files", i);
        });
      }
      const res = await Imageapi.post(
        `record/email-template/update/${id}`,
        postForm
      );
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(`createdemailtemplate`, id);
      toast.success(data.message);
      if (onHide) onHide();
    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });
  return updateEmailTemplate;
};

export default useUpdateEmailTemplate;
