import React, { useState } from "react";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import PlusButton from "../../ui/buttons/PlusButton";
import Heading from "../../ui/modals/Heading";
import CrmLogHeader from "../../Components/crm/CrmLogHeader";
import EscalationTableContainer from "../../Components/crm/EscalationTableContainer";
import { useParams } from "react-router-dom";
import { Endpoints } from "../../api/Endpoints";
import EscalationSort from "../../ui/dropdown/EscalationSort";
import Search from "../../ui/search/Search";
import useGetEscalationMember from "../../libs/queries/crm/useGetEscalationMember";
import Pagination from "../../ui/pagination";

const Escalation = () => {
  const [checkBoxAdd, setCheckBoxAdd] = useState([]);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [pageToLoad, SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [externalEscalation, setExternalEscalation] = useState("external");
  const [levelId, setLevelId] = useState("");
  const [type, setType] = useState("my");
  // console.log("this is log", checkBoxAdd)

  const { id } = useParams();

  const { data } = useGetEscalationMember({
    page: pageToLoad,
    itemsPerPage: itemsPerPage,
    // ...(externalEscalation && {memberType: "external"}),
    // ...(!externalEscalation && {memberType: "internal"}),
    memberType: externalEscalation,
    id: id,
    search: search,
    level: levelId,
  });

  // console.log("this is crm",data)

  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <Subheader
          leftsideshow={<div className="col-sm-6 pb-sm-0 pb-3"></div>}
          rightsideshow={
            <PlusButton name="Add Member" navto={`/crm/newmember/${id}`} />
          }
        />
        <Heading
          search={<Search onSearch={setSearch} />}
          heading="CRM"
          // onSearch={setSearch}
          showSortt={setIsOpen}
          url={Endpoints.escalationMemberCsv(id)}
          sortComponent={
            <EscalationSort
              showSort={isOpen}
              onLevelSelect={setLevelId}
              type={type}
            />
          }
        />

        <CrmLogHeader
        data={data}
          id={id}
          heading="Escalation Matrix"
          setCheckBoxAdd={setCheckBoxAdd}
          checkBoxAdd={checkBoxAdd}
          onLevelSelect={setLevelId}
          externalEscalation={externalEscalation}
        />
        <Pagination
          onPageChange={SetPageToLoad}
          onItemsChanged={setItemsPerPage}
          totalPages={Math.ceil(data?.count / itemsPerPage)}
          defaultCount={itemsPerPage}
        />
        <EscalationTableContainer
          id={id}
          data={data}
          setCheckBoxAdd={setCheckBoxAdd}
          checkBoxAdd={checkBoxAdd}
          search={search}
          setSearch={setSearch}
          levelId={levelId}
          externalEscalation={externalEscalation}
          setExternalEscalation={setExternalEscalation}
        />
      </div>
    </div>
  );
};

export default Escalation;
