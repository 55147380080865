import React, { useEffect } from "react";
import SaveButton from "../../ui/buttons/SaveButton";
import CancelButton from "../../ui/buttons/CancelButton";
import hdfc from "../../img/hdfc.png";
import { useForm, Controller } from "react-hook-form";
// import EditInputBox from "../../ui/inputs/EditInputBox";
import useUpdateLead from "../../libs/mutation/leads/useUpdateLead";
import DateInput from "../../ui/inputs/DateInput";
import DropdownInput from "../../ui/inputs/DropdownInput";
import UserlistdropdownInput from "../../ui/inputs/UserlistdropdownInput";
import InputBox from "../../ui/inputs/InputBox";
import Multiselect from "multiselect-react-dropdown";
import useUsers from "../../libs/queries/profile/useUsers";
import useUpdateAgreement from "../../libs/mutation/agreements/useUpdateAgreement";
import { Typeahead } from "react-bootstrap-typeahead";
import useGetLeadListContact from "../../libs/queries/leads/useGetLeadListContact";

const AgreeementListEdit = ({ data, onHide }) => {
  const {
    id,
    contact_name,
    contact_department,
    mobile_contact,
    signatory_authority_name,
    client_execution_owner_name,
    cv_validity,
    email,
    pricing,
    replacement_clause,
    ats_non_ats,
    remarks,
    client_category,
    service_offering,
    lead_contact_display,
    lead_contact
  } = data || {};

  const { mutate: updateAgreement, isLoading } = useUpdateAgreement(id, onHide);
  const { data: contacts } = useGetLeadListContact({});
  const contactList = contacts?.results;

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
    clearErrors
  } = useForm();

  const { data: users } = useUsers();

  const userlist = users?.results;

  useEffect(() => {
    if (data) {
      reset({
        contact_name,
        contact_department,
        mobile_contact,
        signatory_authority_name,
        client_execution_owner_name,
        cv_validity,
        email,
        pricing,
        replacement_clause,
        ats_non_ats,
        remarks,
        client_category,
        service_offering
      });
    }
  }, []);

  return (
    <form
      onSubmit={handleSubmit(updateAgreement)}
      class="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3 edit-info-table pt-5"
    >
      <table class="action-table w-100">
        <tr>
          <td class="action-first">
            <div class="d-flex align-items-center gap-lg-4 gap-2">
              {/* <img src={hdfc} width="48" height="48" class="rounded-3" alt="" /> */}
            </div>
          </td>

          <td class="action-mid">
            <div class="row pe-xl-5">

              {/* <Controller
                name="client_execution_owner_name"
                control={control}
                rules={{ required: "Client Execution Owner is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <InputBox
                      label="Client Execution Owner Name"
                      name="client_execution_owner_name"
                      placeholder=""
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              /> */}

              <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
              <label className="d-block text-sky fs-12 pb-2">Client Execution Owner</label>
              <Controller
                defaultValue={lead_contact}
                name={"lead_contact"}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Client Execution Owner Name is Required"
                  },
                }}
                render={({ field: { value, onChange } }) => {
              
                  return (
                    <Typeahead
                    id={''}
                    labelKey="name"
                    options={contactList}
                    defaultInputValue={lead_contact_display}
                    placeholder="Search"
                    onChange={(list) => {
                      let value = list.map((items) => {
                        return items.id;
                      });
                      if(!value.length){
                        setError("lead_contact", { type: 'customerr', message: 'Client Execution Owner Name is required' })
                      }
                      if(value.length > 0){
                        clearErrors("lead_contact")
                      }
                      onChange(value?.[0]);
                    }}
                    />
                  );
                }}
              />
              {errors.lead_contact && <span className="fs-12 text-red">
              {errors.lead_contact.message}
              </span>}
              </div>


              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <DropdownInput
                  id={12}
                  name="contact_department"
                  label={"Contact Department"}
                  register={register}
                  errors={errors}
                  isUpdate={true}
                />
              </div>

              <Controller
                name="mobile_contact"
                control={control}
                rules={{ required: "Contact is required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <InputBox
                      label="Client Execution Owner Mobile"
                      name="mobile_contact"
                      placeholder=""
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              />

              <Controller
                name="signatory_authority_name"
                control={control}
                rules={{ required: "Signatory Authority is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <InputBox
                      label="Signatory Authority"
                      name="signatory_authority_name"
                      placeholder=""
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              />

              
              {/* <Controller
                name="client_execution_owner_name"
                control={control}
                rules={{ required: "Indian Presence is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <InputBox
                      label="Indian Presence"
                      name="indian_presence"
                      errors={errors}
                      placeholder="New Delhi, Bangalore, Mumbai, Pune, Gurgaon,Chennai"
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              /> */}
              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <DropdownInput
                  id={14}
                  name="cv_validity"
                  label={"CV Validity and Ownership"}
                  register={register}
                  errors={errors}
                  isUpdate={true}
                />
              </div>

              <Controller
                name="email"
                control={control}
                rules={{ required: "Email is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <InputBox
                      label="Client Execution Owner Email"
                      name="email"
                      placeholder=""
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              />

              <Controller
                name="pricing"
                control={control}
                rules={{ required: "Pricing is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <InputBox
                      label="Pricing"
                      name="pricing"
                      placeholder=""
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              />

              <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                <DropdownInput
                  id={15}
                  name="replacement_clause"
                  label={"Replacement Clause"}
                  register={register}
                  errors={errors}
                  isUpdate={true}
                />
              </div>

              <Controller
                name="ats_non_ats"
                control={control}
                rules={{ required: "ATS / Non ATS is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <InputBox
                      disabled={true}
                      label="ATS / Non ATS"
                      name="ats_non_ats"
                      placeholder=""
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              />

              <Controller
                name="remarks"
                control={control}
                rules={{ required: "Remarks is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <InputBox
                      label="Remarks"
                      name="remarks"
                      placeholder=""
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              />

              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <DropdownInput
                  id={27}
                  name="client_category"
                  label={"Category Of Client"}
                  register={register}
                  errors={errors}
                  isUpdate={true}
                />
              </div>

            </div>
          </td>
        </tr>
      </table>

      <div class="d-flex border-0 butn-group justify-content-end gap-3">
        <SaveButton type="submit" disabled={isLoading} />
        <CancelButton onClick={onHide} />
      </div>
    </form>
  );
};

export default AgreeementListEdit;
