import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useCreateEmailTemplate from "../../../libs/mutation/emailtemplate/useCreateEmailTemplate";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import useGetEmailVariables from "../../../libs/queries/emailtemplate/ useGetEmailVariables";
import SaveButton from "../../../ui/buttons/SaveButton";
import CancelButton from "../../../ui/buttons/CancelButton";
import InputBox from "../../../ui/inputs/InputBox";
import useSendTo from "../../../libs/queries/emailtemplate/useSendTo";

const EmailTemplateForm = () => {
  const {
    register,
    formState: { errors },

    setValue,
    handleSubmit,
    control,
    setError,
    clearErrors,
    getValues,
    watch,
  } = useForm({mode: "all"});
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const { data: emailtemplatevariable } = useGetEmailVariables();
  const [files, setFiles] = useState([]);

  const { mutate: createemailtemplate, isLoading } = useCreateEmailTemplate();
  const { data: sendToData } = useSendTo();

  // const sendToArr = Object.entries(sendToData);

  // console.log('arr_', JSON.stringify(sendToArr, null, 4))

  const navigate = useNavigate();

  const firstUpdate = useRef(1);
  const removeFile = (index) => {
    setFiles((previous) => {
      let UpdateFiles = [...previous];
      UpdateFiles.splice(index, 1);
      return UpdateFiles;
    });
  };

  const handleChange = (event, onChange) => {
    let upload_files = [];

    for (let i = 0; i < event?.target?.files?.length; i++) {
      upload_files.push(event.target.files[i]);
    }

    // setFiles(upload_files);
    setFiles((previous) => {
      let UpdateFiles = [...upload_files, ...previous];
      return UpdateFiles;
    });
    onChange([...upload_files, ...files]);
  };

  useEffect(() => {
    setValue("files", uploadedPhotos);
  }, [uploadedPhotos]);

  // let value = watch("variables")
  // useEffect(() => {
  //
  //   if (firstUpdate.current <= 2) {
  //     firstUpdate.current++;
  //     return;
  //   }
  //   if(!value || value?.length <= 0){
  //     setError("variables", {type: "custom",message: "Please select one value"})
  //   } else clearErrors("variables")
  // },[value, firstUpdate])

  return (
    <>
      <div className="bg-white p-4">
        <form
          action=""
          className="add-form"
          onSubmit={handleSubmit(createemailtemplate)}
        >
          <div className="row  gx-lg-5 align-items-center">
            {/*** name ***/}

            <Controller
              name="name"
              control={control}
              rules={{ required: "Template name is Required" }}
              render={({ field: { value, onChange } }) => {
                return (
                  <InputBox
                    label="Template Name"
                    placeholder="Enter Template Name"
                    name="name"
                    errors={errors}
                    type="text"
                    value={value || ""}
                    onChange={onChange}
                  />
                );
              }}
            />
            {/*** name ***/}

            {/*** subject ***/}

            <Controller
              name="subject"
              control={control}
              rules={{ required: "Subject is Required" }}
              render={({ field: { value, onChange } }) => {
                return (
                  <InputBox
                    label="Subject"
                    placeholder="Enter Subject"
                    name="subject"
                    errors={errors}
                    type="text"
                    value={value || ""}
                    onChange={onChange}
                  />
                );
              }}
            />
            {/*** subject ***/}

            {/*** sentto ***/}
            {/* <div style={{
              // backgroundColor: 'red'
            }} className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">Send To</label>
            <Controller
              name="send_to"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Send To is Required",
                },
              }}
              render={({ field: { value, onChange, name } }) => {
                return (
                  <select
                  // name="send_to"
                  // {...register("send_to", {
                  //   required: true,
                  // })}
                  >
                    <option value="">Client Spoc</option>
                    {sendToData?.map(([key, value]) => {
                      return (
                        <option
                          // className="form-field"
                          key={key}
                          value={key}
                        >
                          {value}
                        </option>
                      );
                    })}
                  </select>
                      );
                    }}
                  />
                  </div> */}
            <div style={{
              // backgroundColor: 'red'
            }} className="col-lg-3 col-md-4 col-sm-6 pb-3">
             <label className="d-block text-sky fs-12 pb-2">Send To</label>
            <select
            name="send_to"
            {...register("send_to", {
              required: true,
            })}
            >
              <option value="">Select</option>
              {sendToData?.map(([key, value]) => {
                return (
                  <option
                    className="form-field"
                    key={key}s
                    value={key}
                  >
                    {value}
                  </option>
                );
              })}
            </select>
            <span className="fs-12 text-red">
              {errors.send_to?.type === "required" && "Send To is required"}
            </span>
           </div>
           

            {/*** sentto ***/}

            {/*** variable ***/}
            <div className="col-lg-9 col-md-9 pb-3">
              <label className="d-block text-sky fs-12 pb-2">
                Select Variable
              </label>

              <Controller
                name="variables"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Multiselect
                    placeholder="Select Variable"
                      options={emailtemplatevariable}
                      displayValue="name"
                      groupBy="group"
                      onKeyPressFn={function noRefCheck() {}}
                      onRemove={(list) => {
                        let value = list.map((items) => {
                          return items.id;
                          // return items.name;
                        });
                        // onChange(value.join(","));
                        onChange(value);
                      }}
                      onSelect={(list) => {
                        let value = list.map((items) => {
                          return items.id;
                        });
                        // onChange(value.join(","));
                        onChange(value);
                      }}
                      // selectedValues={value}
                      // selectedValueDecorator={[value.name]}
                    />
                  );
                }}
                rules={{
                  validate: (value) => {
                    if (!value || value?.length <= 0) {
                      setError("variables", {
                        type: "custom",
                        message: "Please select one value",
                      });
                    } else clearErrors("variables");
                  },
                }}
              />
              <div style={{ height: "10px" }}>
                <span className="fs-12 text-red">
                  {errors.variables?.type === "custom" &&
                    "Please select a value"}
                </span>
              </div>
            </div>
            {/*** variable ***/}

            {/* <!-- END MAIL BOX -->   */}
            <div className="pb-3">
              <div
                className="mail-body border rounded-4"
                style={{ height: "500px" }}
              >
                <div>
                  <h6 className="fs-16 semibold p-3 border-bottom">
                    Mail Body
                  </h6>
                  <Controller
                    name="email_body"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <ReactQuill
                          theme="snow"
                          value={value}
                          onChange={(email_body) => onChange(email_body)}
                          style={{ height: "400px" }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              {/* <!-- END MAIL BOX -->   */}

              <div className="send-mail py-3 d-flex gap-3 justify-content-center my-4">
                <p className="text-sky medium align-self-center m-0 rounded-10 semibold fs-12">
                  Upload Attachment
                </p>
                <span style={{ display: "flex", alignSelf: "center" }}>
                  <Controller
                    name="files"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <input
                          type="file"
                          id="upload"
                          className="d-none"
                          files={value}
                          accept=".doc, .pdf"
                          // onChange={(event) => handleFile(event, onChange)}
                          onChange={(event) => handleChange(event, onChange)}
                          multiple
                        />
                      );
                    }}
                  />

                  <label
                    htmlFor="upload"
                    className="px-4 py-2 rounded-2 text-nowrap attach-btn"
                  >
                    Attach a File
                  </label>
                </span>
                <div>
                  {files &&
                    files.map((item, index) => {
                      //
                      return (
                        <div key={item.lastModified}>
                          <button onClick={() => removeFile(index)}>X</button>
                          {item.name}
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="d-flex border-0 butn-group justify-content-center gap-3">
                <SaveButton
                  type="submit"
                  disabled={isLoading}
                  onClick={() => {
                    firstUpdate.current++;
                  }}
                />
                <CancelButton onClick={() => navigate("/manage/emailtemplate")} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EmailTemplateForm;
