import React from 'react'
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../api/index";
import { toast } from "react-toastify";

const useDeleteContact = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data) => {
      const res = await api.post(`management/delete-contact/`, data);
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([`leads`]);
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
    // enabled: id ? true : false,
  });
  return mutation; 
}

export default useDeleteContact