import React from "react";
import SaveButton from "../../ui/buttons/SaveButton";
import CancelButton from "../../ui/buttons/CancelButton";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import CreateAgreement from "./CreateAgreement";

const AgreementContainer = () => {
  const navigate = useNavigate();
  const isView = window.location.pathname.includes('view');
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // console.log("this is formdata", data);
  };

  return (
    <div className="pt-4">
      <div style={{
        borderTopLeftRadius:'15px',
        borderTopRightRadius:'15px',
      }} className="widget-header px-3 py-3 bg-sky">
          <h3 className="bold text-white fs-18">
            {isView? "View Agreement Form" : "New Agreement"}
          </h3>
      </div>
      <CreateAgreement/>
      {/* <form
        onSubmit={handleSubmit(onSubmit)}
        className="rounded-10 overflow-hidden"
      >
        <div className="widget-header px-3 py-3 bg-sky">
          <h3 className="bold text-white fs-18">New Agreement</h3>
        </div>
         <LeadForm control={control} errors={errors} register={register} />
        <LeadFormContact control={control} errors={errors} /> 
        <div className="d-flex border-0 butn-group justify-content-center gap-3">
          <SaveButton type="submit" />

          <CancelButton onClick={() => navigate("/leads/")} />
        </div>
      </form> */}
    </div>
  );
};

export default AgreementContainer;
