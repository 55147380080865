import React, { useEffect, useState } from 'react'
import Target from "../../../img/target.png";
import { set, useForm } from 'react-hook-form';
import useChangePassword from '../../../libs/mutation/security/useChangePassword';

const Security = () => {
    const {register, formState:{errors}, handleSubmit } = useForm();
    const {mutate: changePassword, isLoading} = useChangePassword();
    const [isModalOpen, setIsModalOpen] = useState(true);

    useEffect(() => {
        const closeModal = () => {
          
          setIsModalOpen(false);
        };
    
        window.addEventListener('apiSuccess', closeModal);
    
        return () => {
          window.removeEventListener('apiSuccess', closeModal);
        };
      }, []);
  return (
    <>
    <div>
    <a 
    style={{cursor: "pointer"}}
    onClick={()=>{
        setIsModalOpen(true)
    }} 
    className="collapse-butn medium p-4 d-block selectContainer" 
    data-bs-toggle="collapse" 
    data-bs-target="#security">
        Security
    </a>
    {isModalOpen && 
    <div className="collapse open-collapse" id="security">
        <div className="bg-sky py-3 px-4 d-flex align-items-center gap-3">
            <img src={Target} width="52" height="52" alt="" />
            <h4 className="semibold text-white fs-20">Security</h4>
        </div>
        <form 
        action="" 
        className=" px-4 py-3"
        onSubmit={handleSubmit(changePassword)}
        >
            <div className="pb-3">
                <label htmlFor="" className="d-block medium fs-14 pb-2">Current Password</label>
                <input 
                id="current_password"
                name="current_password"
                type="password" 
                className="form-field" 
                placeholder="" 
                {...register("current_password", {
                    required: true
                })}
                />
                <span className="fs-12 text-red">
                {errors.current_password?.type === "required" && "Current Password is Required"}
                </span>
            </div>

            <div className="pb-3">
                <label htmlFor="" className="d-block medium fs-14 pb-2">New Password</label>
                <input 
                id="new_password"
                name="new_password"
                type="password" 
                className="form-field" 
                placeholder="" 
                {...register("new_password", {
                    required: true,
                    minLength: 6
                })}
                />
                <span className="fs-12 text-red">
                {errors.new_password?.type === "required" && "New Password is Required"}
                {errors.new_password?.type === "minLength" &&
                    "Entered Password is less than 6 digit"}
                </span>
            </div>

            <div className="pb-3">
                <label htmlFor="" className="d-block medium fs-14 pb-2">Confirm Password</label>
                <input 
                id="confirm_password"
                name="confirm_password"
                type="password" 
                className="form-field" 
                placeholder="" 
                {...register("confirm_password", {
                    required: true,
                    minLength: 6
                })}
                />
                <span className="fs-12 text-red">
                {errors.confirm_password?.type === "required" && "Confirm Password is Required"}
                {errors.confirm_password?.type === "minLength" &&
                    "Entered Password is less than 6 digit"}
                </span>
            </div>

            <div className="d-flex border-0 butn-group gap-3 pt-2">
                <button 
                type="submit" 
                disabled={isLoading}
                
                className="custom-btn border-0 py-2 rounded-4 raleway medium">
                    Save
                </button>

                <button 
                type="button" 
                className="custom-btn close-modal py-2 rounded-4 bg-white border-bl-btn px-4 raleway semibold" 
                data-bs-toggle="collapse" 
                data-bs-target="#security">
                    Cancel
                </button>
            </div>

        </form>
    </div>}
    </div>
    </>
  )
}

export default Security