import React from 'react'
import { useSelector } from 'react-redux';
import MarketGenerationForm from './MarketGenerationForm';

const NewMarketGenerationContainer = () => {

    const { marketgennamechange } = useSelector((state) => state.marketIntGenerationSlice);

  return (
    <div className="pt-4">
    <div className="rounded-10 overflow-hidden">
      <div className="widget-header px-3 py-3 bg-sky">
        <h3 className="bold text-white fs-18">New {marketgennamechange} Generation</h3>
      </div>

      <MarketGenerationForm />
    </div>
  </div>
  )
}

export default NewMarketGenerationContainer