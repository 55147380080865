import React from "react";
import api from "../../../api";
import { Endpoints } from "../../../api/Endpoints";
import { useMutation, useQuery } from "@tanstack/react-query";

const useCheckIn = () =>{

    const getCheckIn = useMutation({
        mutationKey: ['checkin'],
        mutationFn : async () =>{
            const resp = await api.get(Endpoints.checkin);
            if(resp && resp.status){
                localStorage.setItem('isCheckedIn', JSON.stringify('usercheckedin'))
            }
            return resp.data
        }
    })

    return getCheckIn;
}

export default useCheckIn;