import React from "react";
import BoxChart from "../../ui/graphs/BoxChart";
import useGetAgreementGraph from "../../libs/queries/graphs/useGetAgreementGraph";

const ClientName = ({agreementdata}) => {
  // const { data } = useGetAgreementGraph();
  const { client_name } = agreementdata || {};
  // console.log(data);

  return (
    <div className="bg-white py-4 px-4 border w-30">
      <div className="card-body">
        <h3 className="mb-3">Client Names</h3>
        <div 
        className="d-flex w-100 gap-2" style={{display: "flex", flexWrap: "wrap"}}
        // className="d-flex gap-3 justify-content-center"
        >
        {!client_name?.length &&
        <div className='my-4'>
          No Data
        </div>
        }
          {client_name &&
            client_name?.map((item, index) => {
              return <BoxChart key={index} data={item} isClient={true} />;
            })}
            
        </div>
      </div>
    </div>
  );
};

export default ClientName;
