import React from "react";
import api from "../../../api";
import { Endpoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";

const useUpdateTarget = (onSuccess) => {
  const UpdateTargetMutation = useMutation({
    mutationKey: [`userprofile`],
    mutationFn: async (data) => {
      let resp = await api.post(Endpoints.updateTarget, data);
      return resp.data;
    },
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Target updated successfully");
        if (onSuccess) {
          onSuccess();
        }
      }
    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`);
    },
  });

  return UpdateTargetMutation;
};

export default useUpdateTarget;
