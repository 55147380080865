import api from "../../../api/index";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setforgotpasswordemail } from "../../../slice/authSlice";

const useForgotpassword = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const ForgotPasswordMutation = useMutation({
    mutationFn: async (data) => {
      const res = await api.post("account/forgot-password/", data);
      dispatch(setforgotpasswordemail(data.email))
      return res.data;
    },
    onSuccess: (data) => {
      if(data.status == true){
        navigate('/enterotp')
      }
    },

    onError: (error) => {
      toast.error(`${error['response'].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });

  return ForgotPasswordMutation;
};

export default useForgotpassword;
