import React, { useEffect, useState } from "react";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import Heading from "../../ui/modals/Heading";
import ReportsTabs from "../../Components/report/ReportsTabs";
import LeadSort from "../../ui/dropdown/LeadSort";
import useGetLeadsAllGraph from "../../libs/queries/graphs/useGetLeadsAllGraph";
import PlusButton from "../../ui/buttons/PlusButton";
import { useSelector } from "react-redux";
import useGetProductivityGraph from "../../libs/queries/graphs/useGetProductivityGraph";
import useGetAgreementGraph from "../../libs/queries/graphs/useGetAgreementGraph";
import AgreementSort from "../../ui/dropdown/AgreementSort";
import ProductivityReportSort from "../../ui/dropdown/ProductivityReportSort";
import RoleRestriction from "../../utils/roleRestriction";
import usePrs from "../../libs/queries/graphs/usePrs";
import usePrsList from "../../libs/queries/graphs/usePrsList";
import { Endpoints } from "../../api/Endpoints";
import useResearcherPrs from "../../libs/queries/graphs/useReseacherPrs";
import PrsFilter from "../../ui/dropdown/PrsFilter";
import Search from "../../ui/search/Search";

const Report = () => {
  const { reportType } = useSelector((store) => store.reportSlice);
  const [type, setType] = useState("my");
  const [businessModalId, SetBusinessModalId] = useState("");
  const [industrySectorId, SetIndustrySectorId] = useState("");
  const [sourceId, setSourceId] = useState("");
  const [potentialId, setPotentialId] = useState("");
  const [isFortune, setIsFortune] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [locationid, setlocationId] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [contactId, setContactId] = useState("");
  const [cvId, setCvId] = useState("");
  const [tenureId, setTenureId] = useState("");
  const [agreementApplicable, setAgreementApplicable] = useState("");
  const [clientCategory, setClientCategory] = useState("");
  const [serviceOffer, setServiceOffer] = useState();
  const [isOpen2, setIsOpen2] = useState(false);
  const [userIdLead, setUserIdLead] = useState("");
  const [startDateLead, setstartDateLead] = useState("");
  const [endDateLead, setEndDateLead] = useState("");

  const [roleId, setRoleId] = useState("");
  const [userId, setUserId] = useState("");
  const [startDateProductivity, setstartDateProductivity] = useState("");
  const [endDateProductivity, setEndDateProductivity] = useState("");
  const [signingId, setSigningId] = useState("");
  const [clientName, setClientName] = useState("");
  const [isOpen3, setIsOpen3] = useState(false);
  const [userIdAgreement, setUserIdAgreement] = useState("");
  const [startDateAgreement, setstartDateAgrrement] = useState("");
  const [endDateAgrrement, setEndDateAgrrement] = useState("");

  const [isOpen4, setIsOpen4] = useState(false);
  const [isPrs, setIsPrs] = useState(false);
  const [prsRoleId, SetPrsRoleId] = useState("");
  const [prsUserId, SetPrsUserId] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [downloadPdf, setDownloadPdf] = useState();

  const [researcherMonth, setResearcherMonth] = useState("");
  const [researcherYear, setResearcherYear] = useState("");
  const restrict = RoleRestriction();

  const [bdUserId, setBdUserId] = useState("");

  // console.log("this is data",isFortune, industrySectorId, sourceId, startDate, endDate, type, potentialId)
  const { data } = useGetLeadsAllGraph({
    businessModel: businessModalId,
    industrySector: industrySectorId,
    sourceId: sourceId,
    potentialId: potentialId,
    isFortune: isFortune,
    startDate: startDate,
    endDate: endDate,
    graphType: type,
    location: locationid,
    role: roleId,
    user_id: userIdLead || bdUserId,
  });

  const queryLeadGraph = {
    download: 1,
    business_model: businessModalId || "",
    industry_sector: industrySectorId || "",
    source: sourceId || "",
    organisation: type === "organisation" ? 1 : "",
    potential_service_offering: potentialId || "",
    fortune_1000: isFortune || "",
    start_date: startDate || "",
    end_date: endDate || "",
    location: locationid || "",
    role: roleId || "",
    user_id: userIdLead || bdUserId || '',
  };
  const queryStringLeads = Object.keys(queryLeadGraph)
    .filter((key) => queryLeadGraph[key] !== "") // Filter out keys with no value
    .map((key) => `${key}=${queryLeadGraph[key]}`)
    .join("&");

  const { data: productivitydata } = useGetProductivityGraph({
    graphType: type,
    role: roleId,
    user_id: userId,
    start_date: startDateProductivity,
    end_date: endDateProductivity,
  });

  const queryProductivityGraph = {
    download: 1,
    organisation: type === "organisation" ? 1 : "",
    user_id: userId || '',
    start_date: startDateProductivity || '',
    end_date: endDateProductivity || ''
  };
  
  const queryStringProductivity = Object.keys(queryProductivityGraph)
    .filter((key) => queryProductivityGraph[key] !== "")
    .map((key) => `${key}=${queryProductivityGraph[key]}`)
    .join("&");

  const { data: agreementdata } = useGetAgreementGraph({
    graphType: type,
    contactDepartment: contactId,
    cvValidity: cvId,
    agreementTenure: tenureId,
    agreementApplicable: agreementApplicable,
    serviceOffer,
    clientCategory,
    role: roleId,
    signing: signingId,
    clientName: clientName,
    start_date: startDateAgreement,
    end_date: endDateAgrrement,
    user_id: userIdAgreement || bdUserId,
  });

  const queryAgrrementGraph = {
    download: 1,
    organisation: type === "organisation" ? 1 : '',
    contact_level: contactId || '',     
    cv_validity: cvId || '',
    agreement_tenure: tenureId || '',
    agreement_applicability: agreementApplicable || '',
    service_offering: serviceOffer || '',
    category_of_client: clientCategory  || '',
    role: roleId || "",
    signing_mode: signingId || "",
    client_name: clientName || "",
    user_id: userIdAgreement || '',
    start_date: startDateAgreement || '',
    end_date: endDateAgrrement || ''
  };

  const queryStringAgreement = Object.keys(queryAgrrementGraph)
    .filter((key) => queryAgrrementGraph[key] !== '') // Filter out keys with no value
    .map((key) => `${key}=${queryAgrrementGraph[key]}`)
    .join("&");

  const { data: prsList } = usePrsList({
    type: type,
    user: prsRoleId == 1 ? prsUserId : "",
    month: month,
    year: year,
  });

  const queryPrsList = {
    download: 1,
    organisation: type === "organisation" ? 1 : '',
    user: prsRoleId == 1 ? prsUserId : "",
    month: month || '',
    year: year || ''
  };

  const queryStringPrs = Object.keys(queryPrsList)
    .filter((key) => queryPrsList[key] !== '')
    .map((key) => `${key}=${queryPrsList[key]}`)
    .join("&");

  const { data: reseacherPrs } = useResearcherPrs({
    type: type,
    user: prsRoleId == 4 ? prsUserId : "",
    month: month,
    year: year,
  });

  const queryResearcher = {
    download: 1,
    organisation: type === "organisation" ? 1 : '',
    user: prsRoleId == 4 ? prsUserId : "",
    month: month || '',
    year: year || ''
  };

  const queryStringResearcher = Object.keys(queryResearcher)
    .filter((key) => queryResearcher[key] !== '')
    .map((key) => `${key}=${queryResearcher[key]}`)
    .join("&");

  // useEffect(()=>{
  //   if(type){
  //     setMonth('');
  //   }
  // },[type])

  
  useEffect(() => {
    if (reportType == "agreement" || reportType == "leads" || reportType == "document") {
      setIsPrs(false);
    }
  }, [reportType]);
  // console.log(reportType)
  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <Subheader
          leftsideshow={
            <TwoButtonHolder
              isPrs={isPrs}
              onSelect={setType}
              type={type}
              label="Reports"
            />
          }
          {...(reportType !== "leads" &&
            reportType != "agreement" &&
            reportType !== "document" && 
            restrict != "relation" && {
              //  { (restrict=='BD' || restrict=='researcher') &&
              rightsideshow: (
                <PlusButton
                  name="PRS"
                  navto={
                    reportType == "productivity"
                      ? "productivity"
                      : "/agreement/new"
                  }
                  onPrs={setIsPrs}
                />
              ),
            })}
        />
        <Heading
        downloadPdf={downloadPdf}
          //  showDownload={isPrs? false:true}
          url={
            reportType === "leads"
              ? Endpoints.leadReportCSV + "?"+queryStringLeads
              // download=1&organisation=${type === "organisation" ? 1 : ""}`
              : (reportType == "productivity" && !isPrs)
              ? Endpoints.productivityReportCSV + "?"+queryStringProductivity
              // `?download=1&organisation=${type === "organisation" ? 1 : ""}`
              : reportType === "agreement"
              ? Endpoints.agreementReportCSV + "?"+queryStringAgreement
              // `?download=1&organisation=${type === "organisation" ? 1 : ""}`
              : (restrict === "researcher" || prsRoleId == 4)
              ? Endpoints.reseacherPrs + "?"+queryStringResearcher
                // `?download=1&organisation=${type === "organisation" ? 1 : ""}`
              : Endpoints.getPrsList + "?"+queryStringPrs
                // `?download=1&organisation=${type === "organisation" ? 1 : ""}`
          }
          // url={handleURL
          //   // reportType === "leads"
          //   //   ? Endpoints.leadReportCSV
          //   //   : restrict === "researcher" || prsRoleId == 4
          //   //   ? Endpoints.reseacherPrs +
          //   //     "?" +
          //   //     `download=1&organisation=${type == "organisation" ? 1 : ""}`
          //   //   : Endpoints.getPrsList +
          //   //     "?" +
          //   //     `download=1&organisation=${type == "organisation" ? 1 : ""}`
          // }
          heading="Reports"
          // url={Endpoints.prsCsv}
          showSortt={
            reportType == "leads"
              ? setIsOpen
              : reportType == "productivity" && !isPrs
              ? setIsOpen3
              : reportType == "productivity" && isPrs
              ? setIsOpen4
              : setIsOpen2
          }
          sortComponent={
            reportType == "leads" ? (
              <LeadSort
                onBusinessModalSelect={SetBusinessModalId}
                onIndustrySectorSelect={SetIndustrySectorId}
                onPotentialSelect={setPotentialId}
                onSourceSelect={setSourceId}
                onFortuneSelect={setIsFortune}
                onStartDateSelect={setstartDate}
                onEndDateSelect={setEndDate}
                onLocationSelect={setlocationId}
                onRoleSelect={setRoleId}
                onUserSelect={setUserIdLead}
                showSort={isOpen}
                type={type}
              />
            ) : reportType == "productivity" && !isPrs ? (
              <ProductivityReportSort
                onRoleSelect={setRoleId}
                onStartDateSelect={setstartDateProductivity}
                onEndDateSelect={setEndDateProductivity}
                onUserSelect={setUserId}
                showSort={isOpen3}
                type={type}
              />
            ) : reportType == "productivity" && isPrs ? (
              <PrsFilter
                onRoleSelect={SetPrsRoleId}
                onUserSelect={SetPrsUserId}
                onMonthSelect={setMonth}
                onYearSelect={setYear}
                // month2={month}
                showSort={isOpen4}
                type={type}
              />
            ) : (
              <AgreementSort
                onContactSelect={setContactId}
                onAgreementApplicableSelect={setAgreementApplicable}
                onAgreementTenureSelect={setTenureId}
                onClientCategorySelect={setClientCategory}
                onCvSelect={setCvId}
                onServiceOfferSelect={setServiceOffer}
                onRoleSelect={setRoleId}
                onSigningSelect={setSigningId}
                onClientSelect={setClientName}
                showSort={isOpen2}
                onStartDateSelect={setstartDateAgrrement}
                onEndDateSelect={setEndDateAgrrement}
                onUserSelect={setUserIdAgreement}
                type={type}
              />
            )
          }
        />
        <ReportsTabs
        setDownloadPdf={setDownloadPdf}
          onUserSelect={setBdUserId}
          data={data}
          productivitydata={productivitydata}
          agreementdata={agreementdata}
          prsData={
            restrict === "researcher" || prsRoleId == 4 ? reseacherPrs : prsList
          }
          // prsData={reseacherPrs}
          type={type}
          setType={setType}
          isPrs={isPrs}
          setIsPrs={setIsPrs}
          roleId={prsRoleId}
        />
      </div>
    </div>
  );
};

export default Report;
