import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CheckboxEmailModal from "../../ui/modals/CheckboxEmailModal";

const CrmLogHeader = ({ heading, data, checkBoxAdd, setCheckBoxAdd, externalEscalation, id }) => {
  const [emailView, setEmailView] = useState(false);
  // const location = useLocation();

  return (
    <div className="pt-4">
      <div className="widget-header py-3 px-3 d-flex align-items-center justify-content-between  bg-sky rounded-10 overflow-hidden mb-3">
        <Link to="/crm" className="text-white medium py-1 fs-16">
          <i className="fa fa-chevron-left pe-3"></i> {heading}
        </Link>
        {window.location.pathname.includes("lastcontactlogs") ? (
          ""
        ) : (
          <Link
            onClick={() => {
              setEmailView(true);
            }}
            className="custom-btn bg-white text-sky medium sm-btn fs-16"
          >
            Mail
          </Link>
        )}
      </div>
      {emailView && (
        <CheckboxEmailModal
        data={data}
        id={id}
          to={checkBoxAdd}
          externalEscalation={externalEscalation}
          onClose={() => setEmailView(false)}
        />
      )}
    </div>
  );
};

export default CrmLogHeader;
