import React from "react";
import api from "../../../api/index";
import { useMutation, useQuery } from "@tanstack/react-query";

const useMobileSearch = ({
  page, 
  itemsPerPage
  }) => {
    
  const queryresult = useMutation({
    mutationKey: [`search-mobile`],
    mutationFn: async (variables) => {
     console.log('respnose',variables);
     const queryParams = {
        page: page || 1,
        items_per_page: itemsPerPage || 500,
        organisation: variables?.leadType==='organisation'?1:'',
        search_key: variables?.search || '',
    };

     const queryString = Object.keys(queryParams)
     .filter(key => queryParams[key] !== '')
     .map(key => `${key}=${queryParams[key]}`)
     .join('&');

     console.log('query_Striiing',queryString);
      const res = await api.get(`management/lead-contacts?${queryString}`);
      
      return res?.data;
    },
  });

  return queryresult;
};

export default useMobileSearch;
