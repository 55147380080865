import React, { useEffect, useState } from "react";
import SaveButton from "../../ui/buttons/SaveButton";
import CancelButton from "../../ui/buttons/CancelButton";
import hdfc from "../../img/hdfc.png";
import { useForm, Controller } from "react-hook-form";
// import EditInputBox from "../../ui/inputs/EditInputBox";
import useUpdateLead from "../../libs/mutation/leads/useUpdateLead";
import DateInput from "../../ui/inputs/DateInput";
import DropdownInput from "../../ui/inputs/DropdownInput";
import UserlistdropdownInput from "../../ui/inputs/UserlistdropdownInput";
import InputBox from "../../ui/inputs/InputBox";
import Multiselect from "multiselect-react-dropdown";
import useUsers from "../../libs/queries/profile/useUsers";
import useUpdateLeadContact from "../../libs/mutation/leads/useUpdateLeadContact";
import useGetStageList from "../../libs/queries/StageApi/useGetStageList";
import dayjs from "dayjs";
import useFilterData from "../../libs/queries/manage/useFilterData";
import LocationDropdown from "../../ui/inputs/LocationDropdown";
import useLocationList from "../../libs/queries/location/useLocation";
import CheckboxInput from "../../ui/inputs/CheckboxInput";
import FilePreview from "./FilePreview";
import useAllUsers from "../../libs/queries/profile/useAllUsers";
import DateInputBox from "../../ui/inputs/DateInputBox";

const LeadListEdit = ({ contactdata = {}, leaddata = {}, onHide }) => {
  const { isLoading: L1 } = useFilterData(8, null, true, true);
  const { isLoading: L2 } = useFilterData(1, null, true, true);
  const { isLoading: L3 } = useFilterData(6, null, true, true);
  const { isLoading: L4 } = useFilterData(2, null, true, true);
  const { isLoading: L5 } = useFilterData(9, null, true, true);
  const { isLoading: L6 } = useFilterData(3, null, true, true);
  const { isLoading: L7 } = useFilterData(5, null, true, true);
  const { isLoading: L8 } = useFilterData(10, null, true, true);
  const { mutate: updatelead, isLoading } = useUpdateLead(leaddata.id, onHide);
  const [isMobileVisible, setMobileVisible] = useState(false);
  const [isEmailVisible, setEmailVisible] = useState(false);
  const { mutate: updatecontact } = useUpdateLeadContact(
    contactdata.id,
    onHide
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm();

  const { data: users, isLoading: userLoading } = useUsers();
  const { data: locations } = useLocationList(null, null);
  const [fileupload, setFileUpload] = useState(null);
  const { data: potential } = useFilterData(9, null, true, true);
  const { data: allusers } = useAllUsers();

  console.log("email__", watch("contact.email"));

  console.log('visemail__', isEmailVisible)

  const userlist = users?.results;
  const locationlist = locations?.results;
  const potentialData = potential?.data;

  // useEffect(()=>{
  //   if(watch("contact.email")){
  //     setEmailVisible(true);
  //   }
  //   if(watch("contact.mobile")){
  //     setMobileVisible(true)
  //   }
  // },[watch("contact.email"), watch("contact.mobile")])

  const handlevalue = (value) => {
    let obj = [];
    if (allusers) {
      value?.forEach((element) => {
        let found = allusers.find((etv) => etv.id == element);
        // console.log(typeof element);
        if (found) {
          obj.push(found);
        }
      });
    }
    // console.log(value, userlist)

    return obj;
  };

  const handlelocationvalue = (value) => {
    let obj = [];
    if (locationlist) {
      value?.forEach((element) => {
        let found = locationlist.find((etv) => etv.id === element);
        if (found) {
          obj.push(found);
        }
      });
    }
    return obj;
  };

  const handlePotentialValue = (value) => {
    let obj = [];
    if (potentialData) {
      value?.forEach((element) => {
        let found = potentialData.find((etv) => etv.id === element);
        if (found) {
          obj.push(found);
        }
      });
    }
    return obj;
  };

  const handleChange = (event, onChange) => {
    setFileUpload(event.target.files[0]);
    onChange(event.target.files[0]);
  };

  const onSubmit = (data) => {
    console.log('chck___',JSON.stringify(data, null,4));
    data.leads.incepted = data?.leads?.incepted
      ? dayjs(data?.leads.incepted).format("YYYY-MM-DD")
      : null;
    data.leads.source_date = data?.leads?.source_date
      ? dayjs(data?.leads.source_date).format("YYYY-MM-DD")
      : null;
    data.leads.india_operations = data?.leads?.india_operations
      ? dayjs(data?.leads.india_operations).format("YYYY-MM-DD")
      : null;
    data.contact.proposal_date = data?.contact?.proposal_date
      ? dayjs(data?.contact?.proposal_date).format("YYYY-MM-DD")
      : null;
    data.contact.next_followup_date = data?.contact?.next_followup_date
      ? dayjs(data?.contact?.next_followup_date).format("YYYY-MM-DD")
      : null;
    // console.log(data)
    updatelead(data);
    updatecontact(data.contact);
  };

  useEffect(() => {
    if (!L1 && !L2 && !L3 && !L4 && !L5 && !L6 && !L7 && !L8 && !userLoading) {
      if (leaddata && contactdata) {
        let {
          id,
          incepted,
          news_research_file,
          source_date,
          india_operations,
          updated_at,
          ...rest
        } = leaddata;

        let {
          id: contactid,
          proposal_date,
          updated_at: contactupdated_at,
          next_followup_date,
          ...contactrest
        } = contactdata;

        reset({
          leads: {
            ...rest,
            incepted: incepted,
            source_date: source_date,
            india_operations: india_operations,
          },
          contact:
            {
              ...contactrest,
              // compnay_forecast_remark: rest.compnay_forecast_remark,
              proposal_date: proposal_date,
              next_followup_date: next_followup_date,
            } || {},
        });
      }
    }
  }, [leaddata, contactdata, L1, L2, L3, L4, L5, L6, L7, L8, userLoading]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3 edit-info-table "
    >
      <table className="action-table w-100">
        <tbody>
          <tr>
            <td className="action-first">
              <div className="d-flex align-items-center gap-lg-4 gap-2">
                {/* <input type="checkbox" /> */}
                {/* <img
                  src={hdfc}
                  width="48"
                  height="48"
                  className="rounded-3"
                  alt=""
                /> */}
              </div>
            </td>

            <td className="action-mid">
              <div className="row pe-xl-5">
                <Controller
                  name="leads.legal_entity_name"
                  control={control}
                  rules={{ required: "Legal Entity Name is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputBox
                        label="Legal Entity Name"
                        name="legal_entity_name"
                        errors={errors}
                        placeholder="British Telecom India Private Limited"
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                <Controller
                  name="leads.global_hq"
                  control={control}
                  rules={{ required: "Global HQ is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputBox
                        label="Global HQ"
                        name="global_hq"
                        placeholder="UK"
                        errors={errors}
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                <Controller
                  name="leads.revenue"
                  control={control}
                  rules={{ required: "Revenue is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputBox
                        label="Revenue"
                        name="revenue"
                        placeholder="2,084.5 crores GBP"
                        errors={errors}
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                {/* <Controller
                name="incepted"
                control={control}
                rules={{ required: "Incepted is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <EditInputBox
                      name="incepted"
                      label="Incepted"
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              /> */}
                <Controller
                  name="leads.incepted"
                  control={control}
                  rules={{ required: "Incepted is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      // <DateInput
                      //   label="Incepted"
                      //   name="incepted"
                      //   errors={errors}
                      //   // placeholder="'18 June 1846"
                      //   // id="1"
                      //   value={value}
                      //   onChange={onChange}
                      // />
                      <DateInputBox
                        label="Incepted"
                        name="incepted"
                        errors={errors}
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                {/* <Controller
                name="india_operations"
                control={control}
                rules={{ required: "India Operations is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <EditInputBox
                      name="india_operations"
                      label="India Operations"
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              /> */}
                <Controller
                  name="leads.india_operations"
                  control={control}
                  rules={{ required: "India Operations is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      // <DateInput
                      //   label="India Operations"
                      //   name="india_operations"
                      //   errors={errors}
                      //   // placeholder="1995"
                      //   // id="2"
                      //   value={value}
                      //   onChange={onChange}
                      // />
                      <DateInputBox
                        label="India Operations"
                        name="india_operations"
                        errors={errors}
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                {/* <Controller
                  name="leads.indian_presence"
                  control={control}
                  rules={{ required: "Indian Presence is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputBox
                        label="Indian Presence"
                        name="indian_presence"
                        errors={errors}
                        placeholder="New Delhi, Bangalore, Mumbai, Pune, Gurgaon,Chennai"
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                /> */}
                <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <label className="d-block text-sky fs-12 pb-2">
                    Indian Presence
                  </label>
                  <Controller
                    name={"leads.indian_presence"}
                    control={control}
                    rules={{ required: "Indian Presence is Required" }}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Multiselect
                          options={locationlist}
                          displayValue="city"
                          selectedValues={handlelocationvalue(value)}
                          onKeyPressFn={function noRefCheck() {}}
                          onRemove={(list) => {
                            let value = list.map((items) => {
                              return items.id;
                            });
                            // if (value.length <= 0) {
                            //   setError("lead_owner", {
                            //     type: "customerr",
                            //     message: "LeadOwner is required",
                            //   });
                            // }
                            onChange(value);
                          }}
                          onSelect={(list) => {
                            let value = list.map((items) => {
                              return items.id;
                            });
                            // if (value.length > 0) {
                            //   clearErrors("lead_owner");
                            // }

                            onChange(value);
                          }}
                        />
                      );
                    }}
                  />
                </div>

                {/* <Controller
                  name="leads.indian_hq"
                  control={control}
                  rules={{ required: "Indian HQ is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputBox
                        label="Indian HQ"
                        name="indian_hq"
                        placeholder="New Delhi"
                        errors={errors}
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                /> */}
                <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <LocationDropdown
                    name={"leads.indian_hq"}
                    label={"Indian HQ"}
                    register={register}
                    errors={errors}
                  />
                </div>

                {/* <Controller
                name="business_model"
                control={control}
                rules={{ required: "Business Model is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <EditInputBox
                      name="business_model"
                      label="Business Model"
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              /> */}
                <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <DropdownInput
                    id={8}
                    reset={reset}
                    data={leaddata}
                    name="leads.business_model"
                    label={"Business Model"}
                    register={register}
                    errors={errors}
                  />
                </div>

                {/* <Controller
                name="industry_sector"
                control={control}
                rules={{ required: "Industry Sector is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <EditInputBox
                      name="industry_sector"
                      label="Industry Sector"
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              /> */}
                <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <DropdownInput
                    id={1}
                    name="leads.industry_sector"
                    label={"Industry Sector"}
                    register={register}
                    errors={errors}
                  />
                </div>

                {/* <Controller
                name="lead_type"
                control={control}
                rules={{ required: "Name is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <EditInputBox
                      name="lead_type"
                      label="Lead Type"
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              /> */}
                <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <DropdownInput
                    id={6}
                    name="leads.lead_type"
                    label={"Lead Type"}
                    register={register}
                    errors={errors}
                  />
                </div>

                {/* <Controller
                name="lead_generator"
                control={control}
                rules={{ required: "Name is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <EditInputBox
                      name="lead_generator"
                      label="Lead Generator"
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              /> */}
                {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <UserlistdropdownInput
                    name="leads.lead_generator"
                    label={"Lead Generator"}
                    register={register}
                    errors={errors}
                  />
                </div> */}

                <Controller
                  name="leads.lead_generator_display"
                  control={control}
                  // rules={{ required: "Lead Generator is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputBox
                        disabled={true}
                        label="Lead Generator"
                        name="leads.lead_generator_display"
                        placeholder=""
                        errors={errors}
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                <label className="d-block text-sky fs-12 pb-2">
                    Lead Generator
                </label>
                <input
                name="leads.lead_generator_display"
                disabled="true"
                />
                </div> */}

                <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <DropdownInput
                    id={2}
                    name="leads.source"
                    label={"Source"}
                    register={register}
                    errors={errors}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <label className="d-block text-sky fs-12 pb-2">
                    Lead Owner
                  </label>
                  <Controller
                    name="leads.lead_owner"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Multiselect
                          options={allusers}
                          displayValue="fullname"
                          selectedValues={handlevalue(value)}
                          onKeyPressFn={function noRefCheck() {}}
                          onRemove={(list) => {
                            let value = list.map((items) => {
                              return items.id;
                            });
                            onChange(value);
                          }}
                          onSelect={(list) => {
                            let value = list.map((items) => {
                              return items.id;
                            });
                            onChange(value);
                          }}
                        />
                      );
                    }}
                  />
                </div>

                {/* <Controller
                name="source"
                control={control}
                rules={{ required: "Name is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <EditInputBox
                      name="source"
                      label="Source"
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              /> */}

                {/* <Controller
                name="source_date"
                control={control}
                rules={{ required: "Source Date is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <EditInputBox
                      name="source_date"
                      label="Source Date"
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              /> */}
                <Controller
                  name="leads.source_date"
                  control={control}
                  rules={{ required: "Source Date is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      // <DateInput
                      //   label="Source Date"
                      //   name="source_date"
                      //   errors={errors}
                      //   // placeholder="45057"
                      //   // id="3"
                      //   value={value}
                      //   onChange={onChange}
                      // />
                      <DateInputBox
                        label="Source Date"
                        name="source_date"
                        errors={errors}
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <Controller
                    name={"leads.fortune_1000"}
                    control={control}
                    // rules={{ required: "Company Name is Required" }}
                    render={({ field: { value, onChange, name } }) => {
                      return (
                        <CheckboxInput
                          label="Fortune 1000"
                          name={name}
                          checked={value}
                          // value={value || ""}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                </div>

                {/* <Controller
                name="potential_service_offering"
                control={control}
                rules={{ required: "Potential Service Offerings is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <EditInputBox
                      name="potential_service_offering"
                      label="Potential Service Offerings"
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              /> */}
                {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <DropdownInput
                    id={9}
                    name="leads.potential_service_offering"
                    label={"Potential Service Offerings"}
                    register={register}
                    errors={errors}
                  />
                </div> */}

                <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <label className="d-block text-sky fs-12 pb-2">
                    Potential Service Offerings
                  </label>
                  <Controller
                    name={"leads.potential_service_offering"}
                    control={control}
                    rules={{
                      required: "Potential Service Offerings is Required",
                    }}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Multiselect
                          options={potentialData}
                          displayValue="name"
                          selectedValues={handlePotentialValue(value)}
                          onKeyPressFn={function noRefCheck() {}}
                          onRemove={(list) => {
                            let value = list.map((items) => {
                              return items.id;
                            });
                            onChange(value);
                          }}
                          onSelect={(list) => {
                            let value = list.map((items) => {
                              return items.id;
                            });
                            onChange(value);
                          }}
                        />
                      );
                    }}
                  />
                </div>

                <div className="col-lg-9 col-md-12 col-sm-12 pb-4">
                  <div className="col-md-11 d-flex align-items-center gap-2">
                    {/* <input type="text" className="form-field " /> */}
                    <Controller
                      name={"leads.news_research_repo"}
                      control={control}
                      // rules={{
                      //   required: {
                      //     value: !watch("leads.news_research_file"),
                      //     message: "New/Research required",
                      //   },
                      // }}
                      // shouldUnregister={watch("news_research_file")}
                      render={({ field: { value, onChange, name } }) => {
                        return (
                          <InputBox
                            label="  News/ Research Repository"
                            name={name}
                            placeholder="https://telecom.economictimes.indiatimes.com/news/bt-plans-to-expand-presence-in-india-to-build-products-for-digital-transformation-harmeen-mehta/88612013"
                            disabled={watch("leads.news_research_file")}
                            errors={errors}
                            type="text"
                            value={value || ""}
                            onChange={onChange}
                          />
                        );
                      }}
                    />
                    <span className="ps-4 pe-3">or</span>
                    <span>
                      <Controller
                        name="leads.news_research_file"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <input
                              type="file"
                              id="upload"
                              disabled={watch("leads.news_research_repo")}
                              className="d-none"
                              files={value}
                              accept=".doc, .pdf"
                              onChange={(event) =>
                                handleChange(event, onChange)
                              }
                              multiple
                            />
                          );
                        }}
                      />
                      <label
                        htmlFor="upload"
                        className="px-4 py-2 rounded-2 text-nowrap attach-btn "
                        style={{ fontWeight: "bolder" }}
                      >
                        Attach a File
                      </label>
                    </span>
                    {(fileupload || leaddata?.news_research_file) && (
                      <FilePreview
                        fileupload={fileupload}
                        data={leaddata?.news_research_file}
                        setFileUpload={setFileUpload}
                      />
                    )}
                  </div>
                </div>

                {/* <Controller
                name="business_potential"
                control={control}
                rules={{ required: "Business Potential is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <EditInputBox
                      name="business_potential"
                      label="Business Potential"
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              /> */}
                <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <DropdownInput
                    id={3}
                    name="leads.business_potential"
                    label={"Business Potential"}
                    register={register}
                    errors={errors}
                  />
                </div>

                {/* <Controller
                name="company_name"
                control={control}
                rules={{ required: "Company Name is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <EditInputBox
                      name="company_name"
                      label="Company Name"
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              /> */}
                <Controller
                  name="leads.company_name"
                  control={control}
                  rules={{ required: "Company Name is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputBox
                        label="Company Name"
                        name="company_name"
                        placeholder="BT"
                        errors={errors}
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                {/* <Controller
                name="stages_of_ops_india"
                control={control}
                rules={{ required: "Stage of Ops in India is Required" }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <EditInputBox
                      name="stages_of_ops_india"
                      label="Stage of Ops in India"
                      errors={errors}
                      type="text"
                      value={value || ""}
                      onChange={onChange}
                    />
                  );
                }}
              /> */}
                <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <DropdownInput
                    id={5}
                    name="leads.stages_of_ops_india"
                    label={"Stage of Ops in India"}
                    register={register}
                    errors={errors}
                  />
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td className="action-first">
              <div className="d-flex align-items-center gap-lg-4 gap-2">
                {/* <input type="checkbox" /> */}
                {/* <img src={hdfc} width="48" height="48" className="rounded-3" alt="" /> */}
              </div>
            </td>

            <td className="action-mid">
              <div className="row pe-xl-5">
                <Controller
                  name="contact.name"
                  control={control}
                  rules={{ required: "Name is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputBox
                        label="Name"
                        name="name"
                        errors={errors}
                        placeholder="Kaamini Jha"
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                <Controller
                  name="contact.title"
                  control={control}
                  rules={{ required: "Title is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputBox
                        label="Title"
                        name="title"
                        errors={errors}
                        placeholder="TA- Head"
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                {/* {(watch("contact.mobile") || isMobileVisible) &&  */}
                <Controller
                  name="contact.mobile"
                  control={control}
                  rules={{
                    required:  (watch("contact.email"))? false : "Mobile/Email is Required",
                    maxLength: { value: 10, message: "Number is more than 10 digit" },
                    pattern: { value: /^[0-9]+$/, message: "Number is required" },
                    minLength: { value: 10, message: "10 digits is required" }
                  }}
                  // rules={{ required: "Mobile is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputBox
                        label="Mobile"
                        name="mobile"
                        errors={errors}
                        placeholder="Enter Mobile Number"
                        type="number"
                        value={value || ""}
                        onChange={onChange}
                        isUpdateMobile={true}
                      />
                    );
                  }}
                />
                {/* } */}

                {/* {(watch("contact.email") || isEmailVisible) &&  */}
                <Controller
                  name="contact.email"
                  control={control}
                  rules={{ required: watch("contact.mobile")? false :"Email/Mobile is Required" }}
                  // rules={{ required: "Email is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputBox
                        label="Email"
                        name="email"
                        errors={errors}
                        placeholder="Enter Email"
                        type="email"
                        value={value || ""}
                        isUpdateEmail={true}
                        onChange={onChange}
                      />
                    );
                  }}
                />
                {/* } */}

                <Controller
                  name="contact.linkedin_url"
                  control={control}
                  rules={{ required: "Linkedin URL is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputBox
                        label="Linkedin URL"
                        name="linkedin_url"
                        errors={errors}
                        placeholder="https://www.linkedin.com/feed/"
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <label className="d-block text-sky fs-12 pb-2">
                    Stage of Contact
                  </label>
                  <select
                    name="contact.stage_of_contact"
                    {...register("stage_of_contact")}
                  >
                    <option disabled value="">
                      Select a Stage of Contact
                    </option>
                    {getstage?.results?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </div> */}

                <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <DropdownInput
                    id={10}
                    name="contact.stage_of_contact"
                    label={"Select a Stage of Contact"}
                    register={register}
                    errors={errors}
                    isStage={true}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <DropdownInput
                    id={10}
                    name="contact.first_pitch"
                    label={"First Pitch"}
                    register={register}
                    errors={errors}
                  />
                </div>
                {watch("contact.stage_of_contact") == 1 ||
                watch("contact.stage_of_contact") == 2 ||
                watch("stage_of_contact") == 1 ||
                watch("stage_of_contact") == 2 ? (
                  ""
                ) : (
                  <>
                    <Controller
                      name="contact.proposal_date"
                      control={control}
                      // rules={{ required: "Proposal Date is Required" }}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <DateInputBox
                            // disabled={
                            //   watch("contact.stage_of_contact") == 1 ||
                            //   watch("contact.stage_of_contact") == 2
                            // }
                            label="Proposal Date"
                            name="proposal_date"
                            errors={errors}
                            value={value || ""}
                            onChange={onChange}
                          />
                          // <DateInput
                          // disabled={(watch("contact.stage_of_contact") == 1 || watch("contact.stage_of_contact") == 2 ) }
                          //   label="Proposal Date"
                          //   name="proposal_date"
                          //   errors={errors}
                          //   // placeholder="'45059"
                          //   // id="4"
                          //   value={value}
                          //   onChange={onChange}
                          // />
                        );
                      }}
                    />
                  </>
                )}

                <Controller
                  name="contact.next_followup_date"
                  control={control}
                  rules={{ required: "Next Follow Up Date is Required" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <DateInputBox
                        label="Next Follow Up Date"
                        name="next_followup_date"
                        errors={errors}
                        value={value || ""}
                        onChange={onChange}
                      />
                      // <DateInput
                      //   label="Next Follow Up Date"
                      //   name="next_followup_date"
                      //   errors={errors}
                      //   // placeholder="'45059"
                      //   // id="5"
                      //   value={value}
                      //   onChange={onChange}
                      // />
                    );
                  }}
                />

                <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
                  <DropdownInput
                    id={11}
                    name="contact.timeline_to_aquire"
                    label={"Timeline to Aquired"}
                    register={register}
                    errors={errors}
                  />
                </div>

                <Controller
                  name="contact.last_remark"
                  control={control}
                  // rules={{ required: "Last Follow Up Remarks" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputBox
                        label="Last Follow Up Remarks"
                        name="last_remark"
                        errors={errors}
                        placeholder="1234567890"
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                <Controller
                  name="leads.compnay_forecast_remark"
                  control={control}
                  // rules={{ required: "Company Forecast Remark Remarks" }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputBox
                        label="Company Forecast Remark"
                        name="compnay_forecast_remark"
                        errors={errors}
                        placeholder="1234567890"
                        type="text"
                        value={value || ""}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="d-flex border-0 butn-group justify-content-end gap-3">
        <SaveButton type="submit" disabled={isLoading} />
        <CancelButton onClick={onHide} />
      </div>
    </form>
  );
};

export default LeadListEdit;
