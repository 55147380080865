import React from "react";
/// Imports of Component ///
import StageRow from "../../../Components/Manage/NewStage/StageRow";
import FilterRow from "../../../Components/Manage/Leadgeneration/FilterRow";
import NewLeadGenerationContainer from "../../../Components/Manage/Leadgeneration/NewLeadGenerationContainer";

/// StyleSheet import ///
import "../../../Styles/dashboard.css";
import "../../../Styles/responsive.css";

/// hooks used ///
import { useSelector } from "react-redux";

const NewLeadGeneration = () => {

  const { filternamechange } = useSelector((state) => state.leadSlice);

  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <FilterRow />
        <StageRow
        heading={filternamechange}      
        />
        <NewLeadGenerationContainer />
      </div>
    </div>
  );
};

export default NewLeadGeneration;
