import React from "react";
import useDeleteStage from "../../libs/mutation/Stage/useDeleteStage";

const CommonDelete = (props) => {
  const {id, type} = props;
  const { mutate: deleteStage, isLoading } = useDeleteStage(id);

  const deleteInitiate = () => {
    if(type=='stage'){
        deleteStage();
    }
    props.onClose()

  };

  return (
    <div className="modaldelete">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content rounded-10 border-0">
          <div className="modal-header border-0 p-3 rounded-10 bg-red">
            <h4 className="bold text-white">Delete {type}</h4>
          </div>
          <div className="modal-body px-4 py-3">
            <p className="pe-xl-5">
              Are you sure you want to delete the selected {type}
            </p>
          </div>
          <div className="modal-footer border-0 pt-0 butn-group">
            <button
              type="button"
              style={{ color: "#fff" }}
              className="selectContainer custom-btn close-modal bg-red border-0 py-2 mx-2 rounded-11"
              onClick={deleteInitiate}
            >
              Delete
            </button>
            <button
              type="button"
              className="custom-btn close-modal py-2 border-bl-btn px-4 rounded-11"
              // data-bs-dismiss="modal"
              onClick={props.onClose}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonDelete;
