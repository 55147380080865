import React from 'react'
import FunnelChart from '../../ui/graphs/FunnelChart';

const BDDay = ({dayData}) => {

  const { clients_onboarded } = dayData?.data || {};
  const datamap = clients_onboarded?.map((item) => ({
    label: item?.Stage_Name,
    value: item?.count,
  }));

 

  return (
    <div>
      {
        datamap?.filter((item)=>item?.value != 0)?.length?
        <FunnelChart 
        datamap={datamap} 
        // datamap={datamap?.filter((item)=>item?.value != 0)} 
        />
        :
        <p className='d-flex text-align-center justify-content-center'>No Data</p>
        
      }
      </div>
  )
}

export default BDDay