import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import api from "../../../api";
import { Endpoints } from "../../../api/Endpoints";


const useResearcherPrs = ({ 
    type,
    user,
    month,
    year
  }) => {
    const queryParams = {
      organisation: type === "organisation" ? 1 : '',
      user: user || '',
      month: month || '',
      year: year || ''
    };
  
    const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key] !== '')
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");
  
    const getResearcherPrs = useQuery({
        queryKey: ['reseacherPrs', { queryString }],
        queryFn: async()=>{
            let resp = await api.get(Endpoints.reseacherPrs + "?"+queryString);
            return resp?.data
        }
    })

    return getResearcherPrs;
}


export default useResearcherPrs;