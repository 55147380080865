import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import api from "../../../api";
import { Endpoints } from "../../../api/Endpoints";


const useRoles = () =>{
    const getUserRoles = useQuery({
        queryKey: ['userroles'],
        queryFn: async()=>{
            let resp = await api.get(Endpoints.accountRoles);
            if(resp && resp.status){
            }
            return resp?.data?.data
        }
    })

    return getUserRoles;
}


export default useRoles;
