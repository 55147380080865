import React from 'react'
import PieChart from '../../ui/graphs/PieChart'

const IndustryWeek = ({weekData}) => {

    const {proposal_sent_contacts_by_industry} = weekData?.data || {}
    const datalabel = proposal_sent_contacts_by_industry?.map((item) => item?.name)
    const data = proposal_sent_contacts_by_industry?.map((item) => item?.count)
    const color = proposal_sent_contacts_by_industry?.map((item) => item?.color)

  return (
    <div>
      {proposal_sent_contacts_by_industry?.length<1 && 
      <div className='d-flex justify-content-center mt-2'>
        No Data
      </div>
      }
      <PieChart datalabel={datalabel} data={data} datacolor={color} /></div>
  )
}

export default IndustryWeek