import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";


const useGetLead = (page, itemsPerPage, leadType, searching) => {

    const queryresult = useQuery({
        queryKey: [`leads`, { page, itemsPerPage, leadType}],
        keepPreviousData:true,
        queryFn: async () => {
            const res = await api.get(`management/leads/?page=${page || 1}&items_per_page=${itemsPerPage || 1000}&organisation=${leadType==='organisationleads'?1:''}&searching=${searching?1:''}`);
            // console.log(res?.data)
            return res?.data;
        }
    })
  return queryresult
}

export default useGetLead