import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterid: 1,
  filtername: "",
  filternamechange: "Industry/Sector",
  
};

const leadSlice = createSlice({
  name: "leadSlice",
  initialState,
  reducers: {
    setfilterId: (state, action) => {
      state.filterid = action.payload;
    },
    setfiltername: (state, action) => {
      state.filtername = action.payload;
    },
    setfilternamechange: (state, action) => {
      state.filternamechange = action.payload;
    },
  },
});

export const { setfilterId, setfiltername, setfilternamechange } =
  leadSlice.actions;
export default leadSlice.reducer;
