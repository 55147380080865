import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { manageMenu } from "../../utils/menus";

/// StyleSheet import ///
import "../../Styles/header.css";
import "../../Styles/responsive.css";
import RoleRestriction from "../../utils/roleRestriction";
import ManageButton from "./ManageButton";
import { setAttachment } from "../../slice/campaignSlice";
import { useDispatch, useSelector } from "react-redux";
import { setSearchSlice } from "../../slice/searchSlice";

const users = ["/users/", "/users/new"];




const dropdownList = [
  "/leadgeneration",
  "/emailtemplate",
  "/locations",
  "/agreementgeneratedform",
  "/marketgeneration",
  "/managestages",
  "/createstage",
  "/createnewdropdown",
  "/newemailtemplate",
  "/createnewagreement",
  "/createmarketgeneration",
];

const Categories = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [makeactive, setmakeactive] = useState("");
  const restricted = RoleRestriction();
  const handleMouseLeave = (event) => {
    // console.log(event.currentTarget.classList)
    if (event.currentTarget.classList.contains("show")) {
      event.currentTarget.classList.remove("show");
    }
  };

  const { attachments } = useSelector((state) => state.campaignSlice);

  const dispatch = useDispatch()
  // console.log('restrict_', RoleRestriction())
  useEffect(() => {
    setmakeactive(pathname);
  }, [pathname]);

  return (
    <div className="container">
      <div className="nav_area collapse" id="nav_area">
        <ul className="menu">
          <li
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            className={makeactive === "/" ? "active" : ""}
            id="dashboard"
          >
            <a>Dashboard</a>
          </li>
          {(restricted=='admin' || restricted=="practice") && 
          <li
            onClick={() => navigate("/users/")}
            style={{ cursor: "pointer" }}
            className={users.includes(makeactive) ? "active" : ""}
            id="users"
          >
            <a>Users</a>
          </li>}
          <li className={`p-0 ${pathname.startsWith("/alerts") && "active"}`}>
            <Link className="d-block" to="/alerts" style={{ padding: "18px" }}>Alerts</Link>
          </li>
          <li className={`p-0 ${pathname.startsWith("/leads") && "active"}`}>
            <Link 
            onClick={()=>{
              dispatch(setSearchSlice({}));
            }}
            className="d-block" to="/leads/" style={{ padding: "18px" }}>
              Leads
            </Link>
          </li>
          <li
            className={`${pathname.startsWith("/calendar") && "active"}`}
            style={{ cursor: "pointer", padding: "0px" }}
          >
            <Link to="/calendar" className="d-block" style={{ padding: "18px" }}>
              Calendar
            </Link>
          </li>
          <li
            className={`${pathname.startsWith("/reports") && "active"}`}
            style={{ cursor: "pointer", padding: "0px" }}
          >
            <Link to="/reports" className="d-block" style={{ padding: "18px" }}>
              Reports
            </Link>
          </li>
          <li
            className={`${pathname.startsWith("/agreement") && "active"}`}
            style={{ cursor: "pointer", padding: "0px" }}
          >
            <Link
            onClick={()=>{
              dispatch(setSearchSlice({}));
            }}
              to="/agreement"
              className="d-block"
              style={{ padding: "18px" }}
            >
              Agreement
            </Link>
          </li>
          <li
            className={`${pathname.startsWith("/crm") && "active"}`}
            style={{ cursor: "pointer", padding: "0px" }}
          >
            <Link 
            onClick={()=>{
              dispatch(setSearchSlice({}));
            }}
            to="/crm" className="d-block" style={{ padding: "18px" }}>
              CRM
            </Link>
          </li>
          <li
            className={`${pathname.startsWith("/documentrepository") && "active"}`}
            style={{ cursor: "pointer", padding: "0px" }}
          >
            <Link
            onClick={()=>{
              dispatch(setSearchSlice({}));
            }}
              to="/documentrepository"
              className="d-block"
              style={{ padding: "18px" }}
          
            >
              Document Repository
            </Link>
          </li>
          <li
            className={`p-0 menu-item-has-children position-relative ${
              pathname.startsWith("/manage") && "active"
            }`}
          >
            <ManageButton />

            <ul
              className="sub-menu dropdown-menu left-0 border-0 p-0 rounded-0"
              aria-labelledby="manage"
              onMouseLeave={(event) => handleMouseLeave(event)}
              onClick={(event) => {
                event.stopPropagation();
                handleMouseLeave(event);
              }}
            >
              {manageMenu &&
                manageMenu.map((menu, index) => {
                  return (
                    <li
                      className={`${pathname === menu.path && " active"}`}
                      key={index}
                    >
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(menu.path)}
                      >
                        {menu.label}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Categories;
