import React, { useState } from "react";
import ReactQuill from "react-quill";
import AttachementIcon from "../../img/icon/attachment.svg";
import { Controller, useForm } from "react-hook-form";
import useSendEmail from "../../libs/mutation/emailtemplate/useSendEmail";
import useGetEscalationMember from "../../libs/queries/crm/useGetEscalationMember";
import BadgeInput from "../inputs/BadgeInput";

const CheckboxEmailModal = ({ onClose, data, to, externalEscalation, id }) => {
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    control,
    setError,
    clearErrors,
    getValues,
    watch,
    reset,
  } = useForm({ mode: "all" });

  const [fileupload, setFileUpload] = useState(null);
  const { mutate: sendEmail, isLoading } = useSendEmail(onClose);
  const { data: internaldata } = useGetEscalationMember({
    memberType: "internal",
    id: id,
  });

  const { data: externaldata } = useGetEscalationMember({
    memberType: "external",
    id: id,
  });

  const handleChange = (event, onChange) => {
    setFileUpload(event.target.files[0]);
    onChange(event.target.files[0]);
  };

  const emails = data?.results.map((item) => item.email);
  // const internal = internaldata?.results.map((item) => item.email);
  // const todata = to && [...to]?.map((item) => item);
  // console.log(internaldata)

  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit(sendEmail)}
          style={{
            backgroundColor: "red",
          }}
          className="col-lg-5 mail-box py-3 rounded-10 shadow bg-white collapse show"
          id="mailbox"
        >
          <div className="px-4">
            <div className="d-flex gap-2 justify-content-end pb-2 align-items-end mail-action">
              <a className="full-screen pb-1">
                <img src="assets/img/icon/full-screen.svg" alt="" />
              </a>
              <a
                onClick={onClose}
                className="mail-close bg-red fs-12 d-flex align-items-center justify-content-center text-white selectContainer"
                aria-expanded="true"
              >
                <i className="fa-solid fa-xmark"></i>
              </a>
            </div>
            <div className="d-flex gap-2 pb-2 border-bottom">
              <span className="text-gray pt-2">To: </span>
              <Controller
                name="to_emails"
                control={control}
                defaultValue={
                  to?.length > 0
                    ? [...(to || [])]
                    : [...(emails || [])]
                }
                render={({ field }) => (
                  <BadgeInput value={field?.value} onChange={field?.onChange} />
                )}
              />
              {/* <input
                type="text"
                defaultValue={to.length > 0 ? to && to?.map((item) => item) : !externalEscalation ? externaldata?.results.map((item) => item.email).join(",") : internaldata?.results.map((item) => item.email).join(",")}
                className="semibold fs-16 raleway border-0"
                placeholder=""
                name="to_emails"
                {...register("to_emails", { required: true })}
              /> */}
            </div>
            <div className="py-2 border-bottom">
              <input
                defaultValue={""}
                type="text"
                name="subject"
                className="semibold fs-14 raleway border-0"
                placeholder="Subject"
                {...register("subject", { required: true })}
              />
            </div>
          </div>
          <div className="choose-heading px-4 pb-5 pt-2">
            <Controller
              // defaultValue={emailContent?.body}
              name="body"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={(body) => onChange(body)}
                    style={{ height: "200px" }}
                  />
                );
              }}
            />
            {/* <ReactQuill theme="snow" value={""} style={{ height: "200px" }} /> */}
          </div>
          <div className="send-mail pt-3 px-4 d-flex gap-3 align-items-center">
            <button
              type="submit"
              className="selectContainer custom-btn medium rounded-10 semibold fs-16 py-1 px-4"
            >
              Send
            </button>
            <div>
              <Controller
                name="attachments"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <input
                      type="file"
                      id="upload"
                      className="d-none"
                      files={value}
                      accept="image/*"
                      onChange={(event) => handleChange(event, onChange)}
                      multiple
                    />
                  );
                }}
              />
              <label
                htmlFor="upload"
                className="attachment text-center pe-auto"
              >
                <img src={AttachementIcon} alt="" />
              </label>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CheckboxEmailModal;
