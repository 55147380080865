import React, { useEffect, useRef, useState } from "react";
import useFilterData from "../../libs/queries/manage/useFilterData";
import { useSelector } from "react-redux";
import useGetRelationshipManager from "../../libs/queries/crm/useGetRelationshipManager";
import RadioFilters from "./RadioFilters";

const CrmSort = ({
  onBusinessModalSelect,
  onIndustrySectorSelect,
  onPotentialSelect,
  onRelationSelect,
  onCommunicationSelect,
  showSort,
  onStartDateSelect,
  onEndDateSelect,
  type,
}) => {
  // const { showSort } = useSelector((state) => state.sortSlice);
  const { data: businessmodeloptions } = useFilterData(8, null, true, true);
  const { data: industryoptions } = useFilterData(1, null, true, true);
  // const { data: sourceoptions } = useFilterData(2, null, true, true);
  const { data: potentialserviceoptions } = useFilterData(9, null, true, true);
  const { data: communicationOptions } = useFilterData(33, null, true, true);
  const { data: relationOptions } = useGetRelationshipManager();
  const [isOpen, setIsOpen] = useState(showSort);
  const [businessModalId, setBusinessModalId] = useState("");
  const [industrySectorId, setIndustrySectorId] = useState("");
  const [relationId, setRelationId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [potentialId, setPotentialId] = useState([]);
  const [communicationId, setCommunicationId] = useState([]);

  const CrmDropdownRef = useRef(null);

  useEffect(() => {
    setIsOpen(showSort);
  }, [showSort]);

  const handleClickOutside = (event) => {
    if (
      CrmDropdownRef.current &&
      !CrmDropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    onBusinessModalSelect?.(businessModalId);
  }, [businessModalId]);

  useEffect(() => {
    onIndustrySectorSelect?.(industrySectorId);
  }, [industrySectorId]);

  useEffect(() => {
    onRelationSelect?.(relationId);
  }, [relationId]);

  useEffect(() => {
    onPotentialSelect?.(potentialId);
  }, [potentialId]);

  useEffect(() => {
    onCommunicationSelect?.(communicationId);
  }, [communicationId]);

  useEffect(() => {
    if (startDate && endDate) {
      onStartDateSelect?.(startDate);
      onEndDateSelect?.(endDate);
    }
  }, [startDate, endDate]);

  return (
    <div
      ref={CrmDropdownRef}
      style={{
        position: "absolute",
        right: 0,
        bottom: "auto",
        left: "auto",
        // inset:'left'
      }}
      className={`dropdown-menu filter-menu p-0 rounded-10 dropdown-menu-end ${
        isOpen ? "show" : ""
      }`}
    >
      <h6 className="text-white bg-sky p-3 filter-title semibold mb-1">
        Sort by
      </h6>
      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#status"
          role="button"
          aria-expanded="false"
          aria-controls="status"
        >
          Business Model
        </a>
        <div className="collapse" id="status">
          <RadioFilters
            options={businessmodeloptions?.data}
            value={businessModalId}
            onChange={setBusinessModalId}
            name="businessmodel_id"
          />
          {/* {businessmodeloptions &&
            businessmodeloptions?.data?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center py-1 px-3"
                >
                  <label className="medium fs-14" htmlFor="active">
                    {item?.name}
                  </label>
                  <input
                    checked={businessModalId === item.id}
                    onChange={() => {
                      setBusinessModalId(item?.id);
                    }}
                    type="radio"
                    id="active"
                    // value={item?.id}
                  />
                </div>
              );
            })} */}
        </div>
      </div>

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#role"
          role="button"
          aria-expanded="false"
          aria-controls="role"
        >
          Industry/Sector
        </a>
        <div className="collapse" id="role">
          <div style={{ overflowY: "scroll", height: "110px" }}>
            <RadioFilters
              options={industryoptions?.data}
              value={industrySectorId}
              onChange={setIndustrySectorId}
              name="industry_id"
            />
            {/* {industryoptions &&
              industryoptions?.data?.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between align-items-center py-1 px-3">
                    <label className="medium fs-14" for="TA-Sourcing">
                      {item.name}
                    </label>
                    <input
                      onChange={() => {
                        setIndustrySectorId(item.id);
                      }}
                      // value={item.id}
                      checked={industrySectorId === item.id}
                      type="radio"
                      id="TA-Sourcing"
                    />
                  </div>
                );
              })} */}
          </div>
        </div>
      </div>

      {type == "organisation" && (
        <div className="border-bottom">
          <a
            className="collapse-butn semibold"
            data-bs-toggle="collapse"
            href="#manager"
            role="button"
            aria-expanded="false"
            aria-controls="manager"
          >
            Relationship Manager
          </a>
          <div className="collapse" id="manager">
            <div style={{ overflowY: "scroll", height: "110px" }}>
              <RadioFilters
                options={relationOptions}
                value={relationId}
                onChange={setRelationId}
                name="relation_id"
              />
              {/* {relationOptions &&
                relationOptions?.map((item, index) => {
                  return (
                    <div className="d-flex justify-content-between align-items-center py-1 px-3">
                      <label className="medium fs-14" for="Dilip-Yadav">
                        {item.name}
                      </label>
                      <input
                        checked={relationId === item.id}
                        onChange={() => {
                          setRelationId(item?.id);
                        }}
                        type="radio"
                        id="Dilip-Yadav"
                      />
                    </div>
                  );
                })} */}
            </div>
          </div>
        </div>
      )}

      <div className="border-bottom">
        <a
          className="collapse-butn semibold fs-15"
          data-bs-toggle="collapse"
          href="#mana"
          role="button"
          aria-expanded="false"
          aria-controls="mana"
        >
          Potential Service Offerings
        </a>
        <div className="collapse" id="mana">
          <div style={{ overflowY: "scroll", height: "110px" }}>
            {potentialserviceoptions &&
              potentialserviceoptions?.data?.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between align-items-center py-1 px-3">
                    <label className="medium fs-14" htmlFor="potential">
                      {item.name}
                    </label>
                    <input
                      checked={potentialId?.includes(item.id)}
                      onClick={() => {
                        if (potentialId.includes(item?.id)) {
                          setPotentialId(
                            potentialId.filter((ite) => ite != item?.id)
                          );
                        } else {
                          setPotentialId([...potentialId, item?.id]);
                        }
                        // setPotentialId(item?.id)
                      }}
                      // onChange={() => {
                      //   setPotentialId([...potentialId, item?.id]);
                      //   // setPotentialId(item?.id)
                      // }}
                      type="radio"
                      id="potential"
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="border-bottom">
        <a
          className="collapse-butn semibold fs-15"
          data-bs-toggle="collapse"
          href="#communication"
          role="button"
          aria-expanded="false"
          aria-controls="communication"
        >
          Communication Type
        </a>
        <div className="collapse" id="communication">
          <div style={{ overflowY: "scroll", height: "110px" }}>
            {communicationOptions &&
              communicationOptions?.data?.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between align-items-center py-1 px-3">
                    <label className="medium fs-14" for="potential">
                      {item.name}
                    </label>
                    <input
                      checked={communicationId?.includes(item.id)}
                      onClick={()=>{
                        if(communicationId.includes(item?.id)){
                          setCommunicationId(communicationId.filter((ite)=>ite != item?.id));
                        }
                        else{
                          setCommunicationId([...communicationId, item?.id])
                        }
                        // setPotentialId(item?.id)
                      }}
                      // onChange={() => {
                      //   setCommunicationId([...communicationId, item?.id]);
                      // }}
                      type="radio"
                      id="communication"
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#date"
          role="button"
          aria-expanded="false"
          aria-controls="date"
        >
          Last Contacted Date
        </a>
        <div className="collapse" id="date">
          <div style={{ overflowY: "scroll", height: "140px" }}>
            <div className="my-2 align-items-center py-1 px-3">
              <label className="medium fs-14" for="date">
                {"Start Date"}
              </label>
              <input
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                style={{
                  borderRadius: 0,
                  padding: "5px",
                }}
                className="mt-1"
                type="date"
              />
              <label className="medium fs-14 mt-2" for="sourcedate">
                {"End Date"}
              </label>
              <input
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                style={{
                  borderRadius: 0,
                  padding: "5px",
                }}
                className="mt-1"
                type="date"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrmSort;
