import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import FilterIcon from "../../img/icon/filter.svg";
import DownloadIcon from "../../img/icon/download.svg";
import { useDispatch, useSelector } from "react-redux";
import { setShowSort } from "../../slice/sortSlice";
import api from "../../api";
import { useLocation } from "react-router-dom";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'; 
import Loading from "../../CommonComponents/Loading";
const Heading = ({
  heading,
  onSearch,
  sortComponent,
  isLocation,
  showSortt,
  isDele,
  url,
  search,
  showDownload,
  // downloadPdf
}) => {
  const [searchText, setSearchText] = useState("");
  // const { showSort } = useSelector((state) => state.sortSlice);
  const [showSort, setShowSort] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const location = useLocation();

  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {reportType} = useSelector(store => store.reportSlice)

  const getDownloadData = async()=>{
    try{
      setIsLoading(true);
      let resp = await api.get(url);
      if(resp && resp.data){
        // setData(resp?.data)
        download_csv(resp?.data)
      }
    }
    catch(e){ setIsLoading(false) }
  }

  const dropdownRef = useRef(null);

  // useEffect(()=>{
  //   setIsDownload()
  // },[])

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDownload(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const download_csv = function (data) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', 'download.csv');
    a.click()
    setIsLoading(false);
  }

  useEffect(() => {
    onSearch?.(searchText);
  }, [searchText]);

  useEffect(() => {
    showSortt?.(isOpen);
  }, [isOpen]);

  const downloadLeadPDF = () => {
    setIsLoading(true);
    const input = document.getElementById('pdf-content'); 
    html2canvas(input)
      .then((canvas) => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 211, 298);
        pdf.save('leadreport.pdf');
      })
      .catch((error) => {
        console.log('Error generating PDF: ', error);
      });
    setIsLoading(false);
  };

  const downloadAgreementPDF = () => {
    setIsLoading(true);
    const input = document.getElementById('pdf-agreement'); 
    html2canvas(input)
      .then((canvas) => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 211, 298);
        pdf.save('agreementreport.pdf');
      })
      .catch((error) => {
        console.log('Error generating PDF: ', error);
      });
    setIsLoading(false);
  };

  const downloadProductivityPDF = () => {
    setIsLoading(true);
    const input = document.getElementById('pdf-productivity'); 
    html2canvas(input)
      .then((canvas) => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 211, 298);
        pdf.save('productivityreport.pdf');
      })
      .catch((error) => {
        console.log('Error generating PDF: ', error);
      });
    setIsLoading(false);
  };

  const downloadDocumentPDF = () => {
    setIsLoading(true);
    const input = document.getElementById('pdf-document'); 
    html2canvas(input)
      .then((canvas) => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 211, 298);
        pdf.save('documentreport.pdf');
      })
      .catch((error) => {
        console.log('Error generating PDF: ', error);
      });
      setIsLoading(false)
  };
  return (
    <>
      <div className="d-sm-flex align-items-center justify-content-between">
        <h1 className="page-title fs-24 bold px-3 py-2 w-100">{heading}</h1>
        <div className="d-flex align-items-center gap-2 mt-sm-0 mt-4">
         {/* <form
            action=""
            className="search-form d-flex align-items-center gap-1"
          >
            <i className="fa fa-search"></i>
            <input
              onChange={(e) => {
                setSearchText(e.target?.value);
              }}
              type="text"
              placeholder="Search"
              className="bg-transparent p-1 w-100"
            />
          </form> */}
          {search}

          {!isLocation && (
            <div className={isDele ? "" : "dropdown"}>
              <a
                onClick={() => {
                  setShowSort(!showSort);
                  setIsopen(!isOpen);
                  // dispatch(setShowSort(!showSort))
                }}
                aria-expanded={`${showSort === true ? "true" : "false"}`}
                className={`filter-bar-icon selectContainer ${
                  showSort === true ? "show" : ""
                }`}
              >
                <img src={FilterIcon} />
              </a>
              {sortComponent}
              {/* {showSort && <>{sortComponent}</>} */}
            </div>
          )}
          {!isLocation && !window.location.pathname.includes("leads/viewall") && !showDownload &&(
            <div>
              <a
                onClick={() => {
                  if(location.pathname=='/reports'){
                    setShowDownloadOptions(!showDownloadOptions);
                    setIsDownload(!isDownload);
                  }
                  else{
                    getDownloadData();
                  }
                }}
                className="filter-bar-icon selectContainer"
              >
                {isLoading? <Loading isButton={true}/> : <img src={DownloadIcon} />}
              </a>
              {
                <div
                ref={dropdownRef}
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: 'auto',
                  left: 'auto'
                }}
                  className={`dropdown-menu filter-menu p-0 rounded-10 dropdown-menu-end ${
                    isDownload?"show":""}`}
                >
                  <h6 className="text-white bg-sky p-3 filter-title semibold mb-1">Select Format</h6>
                  <div className="border-bottom">
                      <div onClick={()=>{
                          getDownloadData();
                          setIsDownload(false);
                        }}  className="my-2 medium fs-14 selectContainer align-items-center py-1 px-3">
                          {'CSV'}
                      </div>
                  </div>
            
                  <div className="border-bottom">
                      <div 
                      onClick={()=>{
                        if(reportType=='agreement'){
                          downloadAgreementPDF();
                        }
                        else if(reportType=='productivity'){
                          downloadProductivityPDF();
                        }
                        else if(reportType=='leads'){
                          downloadLeadPDF();
                        }
                        else{
                          downloadDocumentPDF();
                        }
                        setIsDownload(false);
                      }}
                      className="my-2 medium fs-14 selectContainer align-items-center py-1 px-3">
                        {/* <labe className="medium fs-14 selectContainer"> */}
                          {'PDF'}
                        {/* </label> */}
                      </div>
                  </div>
            
                </div>
              }
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Heading;
