import { useMutation } from "@tanstack/react-query";
import React from "react";
import api from "../../../api";
import { Endpoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";

const useChangePassword=()=>{
    const changePasswordMutation = useMutation({
        mutationKey: ['changepassword'],
        mutationFn: async(data) => {
            let resp = await api.post(Endpoints.changePassword, data);
            return resp.data
        },
        onSuccess: (data) => {
            if(data.status == true){
                toast.success(data.message);
                window.dispatchEvent(new CustomEvent('apiSuccess'));
            }
          },
      
          onError: (error) => {
            toast.error(`${error['response'].data.message}`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });
          },
    })

    return changePasswordMutation;
}

export default useChangePassword;