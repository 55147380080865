import React from "react";
import api from "../../../api/index";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const useCreateContact = (id) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data) => {
      // console.log("thisis createcontact", data);
      if(!data?.email){
        delete data?.email
      }
      if(!data?.mobile){
        delete data?.mobile
      }
      const res = await api.post("management/create-contact/", {
        ...data,
        lead: id,
      });
      // console.log(data?.stage_of_contact)
      return { 
        contact: res?.data,
        stage_id: data?.stage_of_contact
        // stage_id: data?.contact?.stage_of_contact
       };
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([`leads`]);
      toast.success(data.message);
      navigate("/leads/"+data.stage_id);
    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });

  return mutation;
};

export default useCreateContact;
