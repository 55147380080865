import React from "react";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useBDFunnel = ({ bdFunnelType, date_filter }) => {
  const queryParams = {
    organisation: bdFunnelType === "organisation" ? 1 : "",
    date_filter: date_filter || "",
  };

  const queryString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "") // Filter out keys with no value
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");

    const queryresult = useQuery({
        queryKey: [`bdfunneldata`, { queryString }],
        keepPreviousData: true,
        queryFn: async () => {
          const res = await api.get(`market/bdfunnel?${queryString}`);
    
          return res?.data;
        },
      });

      return queryresult;
};

export default useBDFunnel;
