import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import Dashboard from '../Pages/Dashboard/Dashboard';
import Login from '../Pages/Login/Login';
import ForgotPassword from '../Pages/ForgotPassword/ForgotPassword';
import EnterOtp from '../Pages/EnterOtp/EnterOtp';
import Createpwd from '../Pages/CreatePassword/Createpwd';
import GoToLogin from '../Pages/GoToLogin/GoToLogin';
import manageRouter from './manage.router';
import userRouter from './user.router';
import RootLayout from '../layouts/RootLayout';
import leadsRouter from './leads.router';
import agreementRouter from './agreement.router';
import crmRouter from './crm.router';
import reportRouter from './report.router';
import BadgeInput from '../ui/inputs/BadgeInput';
import marketRouter from './marketintelligence.router';
import calendarRouter from './calendar.router';
import Test from '../Pages/Test';
import alertsRouter from './alerts.router';

const router = createBrowserRouter([
    {
        path: "/",
        element: <RootLayout />,
        children: [
            {
                path: "/",
                element: <MainLayout />,
                children: [
                    {
                        path: "/",
                        element: <Dashboard />
                    },
                    {
                        ...manageRouter
                    },
                    {
                        ...userRouter
                    },
                    {
                        ...leadsRouter
                    },
                    {
                        ...agreementRouter
                    },
                    {
                        ...crmRouter
                    },
                    {
                        ...reportRouter
                    },
                    {
                        ...marketRouter
                    },
                    {
                        ...calendarRouter
                    },
                    {
                        ...alertsRouter
                    }
                ]
            },
            {
                path: "/login",
                element: <Login />
            },
            {
                path: "/forgotpassword",
                element: <ForgotPassword />
            },
            {
                path: "/enterotp",
                element: <EnterOtp />
            },
            {
                path: "/createpwd",
                element: <Createpwd />
            },
            {
                path: "/gotologin",
                element: <GoToLogin />
            },
            {
                path: "/test",
                element: <Test />
            },

            
        ]
    }

]);


export default router;