import React from "react";
import { Link } from "react-router-dom";
import useNextFollowUp from "../../libs/queries/alerts/useNextFollowUp";
import { useSelector } from "react-redux";

const NoFollowUp = () => {

  const { alerttype } = useSelector((state) => state.alertSlice);
  const { data: nofollowupdata } = useNextFollowUp({ followupType: alerttype });

  return (
    <div className="widget-box rounded-10 overflow-hidden shadow mb-4">
      <div className="widget-header d-flex align-items-center justify-content-between  py-2 px-3  bg-brown">
        <h3 className="text-white medium fs-16">{"No . of Follow Up In a Day"}</h3>
        <h6 className="result-number text-brown">
          {nofollowupdata?.results?.length || 0}
        </h6>
      </div>
      <div className="bg-white p-3">
        <ol className="deafult-list ps-3">
          {nofollowupdata?.results?.slice(0, 3).map((item) => {
            return (
              <>
                <li>{item?.lead_detail?.legal_entity_name}</li>
              </>
            );
          })}

          {!nofollowupdata?.results?.length && (
            <div
              // style={{
              //   textAlign: "center",
              // }}
              // className="mt-5"
            >
              No Data Found
            </div>
          )}
        </ol>

        <div className="text-end">
          <Link to="/alerts/nofollowup" className="text-decoration-underline">
            View More...
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoFollowUp;
