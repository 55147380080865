import React from 'react'
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useGetRelationshipManager = () => {

    const queryresult = useQuery({
        queryKey: [`relationshipmanager`],
        keepPreviousData: true,
        queryFn: async () => {
          const res = await api.get(`account/relationshipmanagers`);
    
          return res?.data?.data;
        },
      });
    
      return queryresult;

}

export default useGetRelationshipManager