import React from "react";
import AgreementTenure from "./AgreementTenure";
import AgreementService from "./AgreementService";
import ContactLevel from "./ContactLevel";
import AgreementSigning from "./AgreementSigning";
import ClientName from "./ClientName";
import BDowner from "./BDowner";
import { useState } from "react";
import { useEffect } from "react";
import LocationofClient from "./LocationofClient";

const AgreementReport = ({agreementdata, type, onUserSelect}) => {
  const [bdUserId, setBdUserId] = useState('');

  useEffect(()=>{
    if(bdUserId){
      onUserSelect?.(bdUserId)
    }
  },[bdUserId])
  
  return (
    <div>
      <h1>Client Onboarded Report</h1>
      <div id={'pdf-agreement'} className="d-flex flex-column gap-3">
        <AgreementTenure agreementdata={agreementdata} />
        <div className="d-flex gap-4">
          <AgreementService agreementdata={agreementdata} />
          <ContactLevel agreementdata={agreementdata} />
        </div>
        <div className="d-flex gap-4">
          <AgreementSigning agreementdata={agreementdata} />
          <ClientName agreementdata={agreementdata} />
          {type === "organisation"? <BDowner data={agreementdata} onUserSelect={setBdUserId} title={'BD Manager'}/> : ""}
        </div>
        <LocationofClient data={agreementdata}/>
      </div>
    </div>
  );
};

export default AgreementReport;
