import React, { useEffect } from "react";
import useGetRelationshipManager from "../../libs/queries/crm/useGetRelationshipManager";
import RelationshipManagerDropdown from "../../ui/inputs/RelationshipManagerDropdown";
import { useForm, Controller } from "react-hook-form";
import AccountManagerDropdown from "../../ui/inputs/AccountManagerDropdown";
import useUpdateCrm from "../../libs/mutation/crm/useUpdateCrm";
import { Link } from "react-router-dom";

const CrmListEdit = ({ onHide, data }) => {
  const { id, account_manager, relationship_manager } = data || {};

  const {
    control,
    register,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  const { mutate: updateCrm } = useUpdateCrm({ id, onHide });

  useEffect(() => {
    if (data) {
      reset({ account_manager, relationship_manager });
    }
  }, [data]);

  return (
    <form
      onSubmit={handleSubmit(updateCrm)}
      className="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3 edit-info-table "
    >
      <table className="action-table w-100">
        <tbody>
          <tr>
            <td className="action-first">
              <div className="d-flex align-items-center gap-lg-4 gap-2">
                <input type="checkbox" />
                {/* <img
                  src="assets/img/hdfc.png"
                  width="48"
                  height="48"
                  className="rounded-3"
                  alt=""
                /> */}
              </div>
            </td>

            <td className="action-mid">
              <div className="row pe-xl-5">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  {/* <div className="pb-3">
                  <h6 className="fs-12 text-sky regular  pb-1">
                    Create client on ATS
                  </h6>
                  <select name="" id="" className="field-select w-100">
                    <option value="">Yes</option>
                    <option value="">No</option>
                  </select>
                </div> */}

                  <div className="pb-3">
                    <h6 className="fs-12 text-sky regular  pb-1">
                      Escalation Matrix
                    </h6>
                    <Link
                      to={`/crm/escalationmatrix/${id}`}
                      className="text-sky fs-16 medium text-decoration-underline"
                    >
                      View More...
                    </Link>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <RelationshipManagerDropdown
                    name="relationship_manager"
                    label="Relationship Manager"
                    register={register}
                    errors={errors}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <AccountManagerDropdown
                    name="account_manager"
                    label="Account Manager"
                    register={register}
                    errors={errors}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="pb-3">
                    <h6 className="fs-12 text-sky regular  pb-1">
                      Last Contact Logs
                    </h6>
                    <Link
                      to={`/crm/lastcontactlogs/${id}`}
                      className="text-sky fs-16 medium text-decoration-underline"
                    >
                      View More...
                    </Link>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="d-flex border-0 butn-group justify-content-end gap-3">
        <button
          type="submit"
          className="custom-btn border-0 py-2 rounded-3 raleway medium"
        >
          Save
        </button>
        <button
          type="button"
          className="custom-btn close-modal py-2  rounded-3 border-bl-btn px-4 raleway semibold"
          onClick={onHide}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default CrmListEdit;
