import React, { useEffect, useRef, useState } from "react";

const AlertFilter = ({ showSort, onAlertStart, onAlertEnd }) => {
  const [isOpen, setIsOpen] = useState(showSort);
  const [alertStartDate, setAlertStartDate] = useState("");
  const [alertEnd, setAlertEnd] = useState("");
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setIsOpen(showSort);
  }, [showSort]);

  useEffect(() => {
    if (alertStartDate && alertEnd) {
      onAlertStart?.(alertStartDate);
      onAlertEnd?.(alertEnd);
    }
  }, [alertStartDate, alertEnd]);

  return (
    <>
      <div
        ref={dropdownRef}
        style={{
          position: "absolute",
          // top: 10,
          right: 0,
          bottom: "auto",
          left: "auto",
          // inset:'left'
        }}
        className={`dropdown-menu filter-menu p-0 rounded-10 dropdown-menu-end ${
          isOpen ? "show" : ""
        }`}
        // onClick="event.stopPropagation()"
      >
        {" "}
        <div className="border-bottom">
          <a
            className="collapse-butn semibold"
            data-bs-toggle="collapse"
            href="#proposaldate"
            role="button"
            aria-expanded="false"
            aria-controls="proposaldate"
          >
            Date
          </a>
          <div className="collapse" id="proposaldate">
            <div style={{ overflowY: "scroll", height: "140px" }}>
              <div className="my-2 align-items-center py-1 px-3">
                <label className="medium fs-14" for="proposaldate">
                  {"Start Date"}
                </label>
                <input
                  onChange={(e) => {
                    setAlertStartDate(e.target.value);
                  }}
                  style={{
                    borderRadius: 0,
                    padding: "5px",
                  }}
                  className="mt-1"
                  type="date"
                />
                <label className="medium fs-14 mt-2" for="sourcedate">
                  {"End Date"}
                </label>
                <input
                  onChange={(e) => {
                    setAlertEnd(e.target.value);
                  }}
                  style={{
                    borderRadius: 0,
                    padding: "5px",
                  }}
                  className="mt-1"
                  type="date"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertFilter;
