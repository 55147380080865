import React, { useState } from "react";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import PlusButton from "../../ui/buttons/PlusButton";
import Heading from "../../ui/modals/Heading";
import useGetAgreement from "../../libs/queries/agreements/useGetAgreement";
import SingleAgreementList from "../../Components/agreement/SingleAgreementList";
import Pagination from "../../ui/pagination";
import useCreateCrm from "../../libs/mutation/crm/useCreateCrm";
import useProfile from "../../libs/queries/profile/useProfile";
import BulkDeleteButton from "../../ui/buttons/BulkDeleteButton";
import DeleteModal from "../../ui/modals/DeleteModal";
import useDeleteAgreement from "../../libs/mutation/agreements/useDeleteAgreement";
import { toast } from "react-toastify";
import AgreementSort from "../../ui/dropdown/AgreementSort";
import RoleRestriction from "../../utils/roleRestriction";
import { Endpoints } from "../../api/Endpoints";
import Search from "../../ui/search/Search";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useSelector } from "react-redux";

const AgreementList = () => {
  const [pageToLoad, SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [type, setType] = useState("my");
  const [search, setSearch] = useState("");
  const [checkBoxAdd, setCheckBoxAdd] = useState([]);
  const [dele, setdele] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  //filter states
  const [contactId, setContactId] = useState('');
  const [contactDepId, setContactDepId] = useState('');
  const [cvId, setCvId] = useState('');
  const [tenureId, setTenureId] = useState('');
  const [agreementApplicable, setAgreementApplicable] = useState('');
  const [clientCategory, setClientCategory] = useState('');
  const [serviceOffer, setServiceOffer] = useState();
  const [startDate, setstartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [ownerId, setOwnerId] = useState('');

  // const searchkey = JSON.parse(localStorage.getItem("search"))
  const [searchData] = useLocalStorage("globalsearch");
  const { searchSlice } = useSelector((state) => state.searchSlice);
  console.log('searchslice', searchSlice)


  const { data } = useGetAgreement({
    page:pageToLoad, 
    itemsPerPage:itemsPerPage, 
    search: search?.length > 0  ? search : searchSlice?.source == "agreement" ? searchSlice?.search : "",
    type:type,
    // contactId,
    cvValidity:cvId,
    agreementTenure:tenureId,
    agreementApplicable:agreementApplicable,
    serviceOffer:serviceOffer,
    clientCategory:clientCategory,
    startDate:startDate,
    endDate:endDate,
    contactDepartment: contactDepId,
    owner: ownerId
  });
  const { mutate: deleteAgreement, isLoading } = useDeleteAgreement();
  const { data: profiledata } = useProfile();

  console.log(checkBoxAdd);

  const handleModal = () => {
    setdele(true)
  }

  const deleteCheckboxitem = () => {
    console.log("this is checkbox", checkBoxAdd);
    if (checkBoxAdd.length > 0) {
      let payload = { agreements: checkBoxAdd };
      deleteAgreement(payload);
      setdele(false);
    } else {
      toast.error("Please select a agreement");
      setdele(false);
    }
  };

  const queryParams = {
    search_key: search || '',
    download: 1,
    organisation: type==='organisation'?1:'',
    contact_department: contactDepId || '',     
    cv_validity: cvId || '',
    agreement_tenure: tenureId || '',
    agreement_applicability: agreementApplicable || '',
    service_offering: serviceOffer || '',
    category_of_client: clientCategory  || '',
    start_date: startDate  || '',
    end_date: endDate || '',
    owner: ownerId || ''
  };
  const queryString = Object.keys(queryParams)
      .filter(key => queryParams[key] !== '')  // Filter out keys with no value
      .map(key => `${key}=${queryParams[key]}`)
      .join('&');

  return (
    <div className="main py-4 position-relative">
      <div class="container">
        <Subheader
          leftsideshow={
            <TwoButtonHolder 
            onSelect={setType} 
            label={'Agreements'}
            />
          }
          additionalbutton={
            RoleRestriction()!="BD" && <BulkDeleteButton onClick={() => handleModal()} />
          }
          rightsideshow={
            <PlusButton name="New Agreement" navto="/agreement/new" />
          }
        />

        {dele && (
          <DeleteModal
            disabled={isLoading}
            deletename="agreement"
            onDeleteClick={deleteCheckboxitem}
            onNoClick={() => setdele(false)}
          />
        )}
        <Heading 
        search={<Search onSearch={setSearch} />}
        url={Endpoints.getAgreementList + "?"+ queryString
        // `download=1&organisation=${type=="organisation"?1:''}`
      }
        isDele={dele}
        // onSearch={setSearch} 
        heading="Agreement" 
        showSortt={setIsOpen}
        sortComponent={
        <AgreementSort 
        // onContactSelect={setContactId}
        onAgreementApplicableSelect={setAgreementApplicable}
        onAgreementTenureSelect={setTenureId}
        onClientCategorySelect={setClientCategory}
        onCvSelect={setCvId}
        onServiceOfferSelect={setServiceOffer}
        onStartDateSelect={setstartDate}
        onEndDateSelect={setEndDate}
        onContactDepSelect={setContactDepId}
        onOwnerSelect={setOwnerId}
        type={type}
        showSort={isOpen}
        />
        } />
        <div className="d-sm-flex align-items-center justify-content-between">
          <Pagination
            onPageChange={SetPageToLoad}
            onItemsChanged={setItemsPerPage}
            totalPages={Math.ceil(data?.count / itemsPerPage)}
            defaultCount={itemsPerPage}
          />
          {data?.results?.length>0 && <PlusButton
            crm={true}
            checkBoxAdd={checkBoxAdd}
            navto={""}
            name={"Add to CRM"}
          />}
        </div>

        {data &&
          data?.results?.map((item, index) => {
            return (
              <SingleAgreementList
                data={item}
                key={index}
                checkBoxAdd={checkBoxAdd}
                setCheckBoxAdd={setCheckBoxAdd}
              />
            );
          })}

          {
            !data?.results?.length && 
            <div style={{
              textAlign: 'center'
            }} className="mt-5"> No Data Found </div>
          }
      </div>
    </div>
  );
};

export default AgreementList;
