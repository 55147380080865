const EditInputBox = ({ name, label, onChange,  errors, value, placeholder, ...res }) => {

  const isError = errors[name];

  return (
    <div className="col-lg-3 col-md-4 col-sm-6">
      <div className="pb-3">
        <h6 className="fs-12 text-sky regular  pb-1">{label}</h6>
        <input
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          {...res}
        />

        {isError && <span className="fs-12 text-red">{isError?.message}</span>}
      </div>
    </div>
  );
};

export default EditInputBox;
