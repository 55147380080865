import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import useUsers from "../../libs/queries/profile/useUsers";
import useUpdateOwner from "../../libs/mutation/leads/useUpdateOwner";
import useAllUsers from "../../libs/queries/profile/useAllUsers";

const RelocateLeadModal = ({ onCancel, data, contactId, leadid }) => {
  const { control, reset, handleSubmit, watch } = useForm();
  const [newAllocation, setNewAllocation] = useState([]);
  const newOwnerId =  watch('new_owner_ids')

  const { lead } = data || {};
  const { mutate: changeOwner } = useUpdateOwner(
    contactId,
    newOwnerId,
    leadid
  );

 

  const { data: users } = useUsers();
  const { data: allusers } = useAllUsers();

  const userlist = users?.results;

  const handlevalue = (value) => {
    let obj = [];
    if (userlist) {
      value?.forEach((element) => {
        let found = userlist.find((etv) => etv.id === element);
        if (found) {
          obj.push(found);
        }
      });
    }

    return obj;
  };

  const handlevaluetwo = (value) => {
    let obj = [];
    if (allusers) {
      value?.forEach((element) => {
        let found = allusers.find((etv) => etv.id === element);
        if (found) {
          obj.push(found);
        }
      });
    }
    return obj;
  };

  const onSubmit = (data) => {
    // setNewAllocation(data?.new_owner_ids);
    // console.log(newAllocation);
    changeOwner();
    onCancel()
  };

  useEffect(() => {
    let { ...rest } = lead;
    reset({
      leads: {
        ...rest,
      },
    });
  }, [lead]);

  return (
    <div className="modaldelete">
      <div className="modal-dialog modal-460 modal-dialog-centered">
        <div className="modal-content rounded-10 border-0 raleway">
          <div className="modal-header  py-4  px-md-5 px-4">
            <h4 className="semibold fs-14 text-sky ">Reallocate Lead</h4>
          </div>
          <div className="modal-body px-md-5 px-4  ">
            <form
              onSubmit={handleSubmit(onSubmit)}
              action=""
              className="site-form"
            >
              <div className="pb-3">
                <label for="" className="fs-14 medium text-black d-block pb-2">
                  Previous Allocation
                </label>

                <Controller
                  name="leads.lead_owner"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Multiselect
                        options={userlist}
                        displayValue="fullname"
                        selectedValues={handlevaluetwo(value)}
                        onKeyPressFn={function noRefCheck() {}}
                        onRemove={(list) => {
                          let value = list.map((items) => {
                            return items.id;
                          });
                          onChange(value);
                        }}
                        onSelect={(list) => {
                          let value = list.map((items) => {
                            return items.id;
                          });
                          onChange(value);
                        }}
                      />
                    );
                  }}
                />
              </div>

              <div className="pb-3">
                <label for="" className="fs-14 medium text-black d-block pb-2">
                  New Allocation
                </label>

                <Controller
                  name="new_owner_ids"
                  control={control}
                  // defaultValue={selectedLead?.lead_owner || []}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Multiselect
                        options={userlist}
                        displayValue="fullname"
                        selectedValues={handlevalue(value)}
                        onKeyPressFn={function noRefCheck() {}}
                        onRemove={(list) => {
                          let value = list.map((items) => {
                            // setNewAllocation([...newAllocation, items.id])
                            return items.id;
                          });
                          onChange(value);
                        }}
                        onSelect={(list) => {
                          let value = list.map((items) => {
                            // setNewAllocation([...newAllocation, items.id])
                            return items.id;
                          });
                          onChange(value);
                        }}
                      />
                    );
                  }}
                />
              </div>
              <div className="d-flex modal-footer border-0 pb-4 butn-group justify-content-start">
                <button
                  type="submit"
                  className="custom-btn border-0 py-2 rounded-3 raleway medium"
                >
                  Update
                </button>
                <button
                  type="button"
                  onClick={onCancel}
                  className="custom-btn close-modal py-2 mx-3  rounded-3 border-bl-btn px-4 raleway medium"
                  //   data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelocateLeadModal;
