import React, { useState } from "react";
import CheckboxEmailModal from "../../ui/modals/CheckboxEmailModal";
import { Link } from "react-router-dom";
import CampaignEmailModal from "../../ui/modals/CampaignEmailModal";

const CampaignHeader = ({data, checkBoxAdd}) => {
  const [emailView, setEmailView] = useState(false);

  return (
    <div className="pt-4">
      <div class="widget-box   mb-4">
        <div class="widget-header py-3 px-3 d-flex align-items-center justify-content-between  bg-sky rounded-10 overflow-hidden mb-3">
          <Link to="/documentrepository" class="text-white medium py-1 fs-16">
            <i class="fa fa-chevron-left pe-3"></i> New Campaigns
          </Link>
          <Link
            onClick={() => {
              setEmailView(true);
            }}
            className="custom-btn bg-white text-sky medium sm-btn fs-16"
          >
            Mail
          </Link>
        </div>
        {emailView && (
          <CampaignEmailModal
          data={data}
          to={checkBoxAdd}
          onClose={() => setEmailView(false)}
          />
          // <CheckboxEmailModal
          //   data={data}
          //   // id={id}
          //   // to={checkBoxAdd}
          //   // externalEscalation={externalEscalation}
          //   onClose={() => setEmailView(false)}
          // />
        )}
      </div>
    </div>
  );
};

export default CampaignHeader;
