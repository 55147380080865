import api from "../../../api/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const useUpdateFilterData = (id, onHide, filterid) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data) => {
      const res = await api.post(`record/dropdown-value/update/${id}`, data);
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([`filters/single`, { id: filterid }]);
      toast.success(data.message);
      if (onHide) onHide();
    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });
  return mutation;
};

export default useUpdateFilterData;
