import React, { useState } from "react";
import useGetCalendarEvents from "../../libs/queries/calendar/useGetCalendarEvents";
import moment from "moment";


const MeetingTable = () => {
  const [selectedTab, setSelectedTab] = useState('day');
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const profile = JSON.parse(localStorage.getItem("profileData"))
  // console.log('pro______', JSON.stringify(profile,null,4))

  const presentDay = moment(new Date()).format('YYYY-MM-DD')

  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  const startDate = new Date(year, month, 1);

  const endDate = new Date(year, month + 1, 0);

  const startDate2 = new Date(currentDate);
  startDate2.setDate(currentDate.getDate() - currentDate.getDay());

// end date of current week Saturday
  const endDate2 = new Date(currentDate);
  endDate2.setDate(currentDate.getDate() + (6 - currentDate.getDay()));

  function formatDateToCustomFormat(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  console.log('seltab__', selectedTab)

  const {data: eventList} = useGetCalendarEvents({
    filter_by_users: profile?.id,
    // startDate: '2023-01-01 00:00:00',
    // endDate: '2023-01-31 23:59:59',
    startDate: selectedTab=='day'? (presentDay + " " + "00:00:00") : selectedTab=='week'? formatDateToCustomFormat(startDate2) : formatDateToCustomFormat(startDate),
    endDate: selectedTab=='day'? (presentDay + " " + "23:59:00") : selectedTab=='week'? formatDateToCustomFormat(endDate2) : formatDateToCustomFormat(endDate),
  });
  return (
    <div className="py-3 px-3 rounded-3 bg-purple-grad ">
      <div className="d-flex align-items-center justify-content-between pb-3">
        <h3 className="fs-24 medium text-black">Meetings</h3>
        <div className="sub-tab nav border-0 gap-2 pb-3 justify-content-end">
          <a
          onClick={()=>{
            setSelectedTab('day')
          }}
            className={selectedTab=='day'?"custom-btn  sm-btn selectContainer active":"custom-btn  sm-btn selectContainer"}
          >
            Day
          </a>
          <a
          onClick={()=>{
            setSelectedTab('week')
          }}
            className={selectedTab=='week'?"custom-btn  sm-btn selectContainer active":"custom-btn  sm-btn selectContainer"}
          >
            Week
          </a>
          <a
          onClick={()=>{
            setSelectedTab('month')
          }}
            className={selectedTab=='month'?"custom-btn  sm-btn selectContainer active":"custom-btn  sm-btn selectContainer"}
          >
            Month
          </a>
        </div>
      </div>
      <div className="tab-content">
        <div
          className="tab-pane fade show active"
          id="meetings_day"
          aria-labelledby="meetings_day-tab"
        >
          <table className="bg-white rounded-10 w-100 default-table">
            <thead>
              <tr>
                <th className="fs-12 bold">Title</th>
                <th className="fs-12 bold">Date & Time</th>
                <th className="fs-12 bold">Status</th>
                <th></th>
                <th className="fs-12 bold">Platform</th>
              </tr>
            </thead>
            <tbody 
            // style={{ overflowY: "scroll", maxHeight: "110px",display:'block', backgroundColor: 'red' }}
            >
              {
                (eventList?.data?.[0]?.length<1 || !eventList?.data) &&
                <td className="d-flex justify-content-center my-4">
                  No Meetings Scheduled
                </td>
              }
              {
                eventList?.data?.map((item, index)=>{
                  return(
                    <tr>
                      <td style={{width: '150px', maxLines:2}} className="fs-12 text-truncate text-wrap">{item?.subject}</td>
                      <td className="fs-12">
                        <span>{moment(item?.start?.dateTime).format('DD-MM-YYYY')}</span>
                        <br />
                        <span>{moment(item?.start?.dateTime).format('HH:mm')}</span>
                      </td>
                      <td className="fs-12 text-blue">{item?.status}</td>
                      <td className="fs-12 text-center pe-3">
                        <a href="#" data-bs-toggle="dropdown" id="item3">
                          <i className="fa fa-ellipsis-vertical"></i>
                        </a>
                        <ul
                          className="dropdown-menu fs-12 dropdown-menu-end"
                          aria-labelledby="item3"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              Action
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Action two
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Action three
                            </a>
                          </li>
                        </ul>
                      </td>
                      <td className="fs-12">{item?.location}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>

      </div>
    </div>
  );
};

export default MeetingTable;
