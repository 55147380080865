import React from "react";
/// Imports of Component ///
import Header from "../../Components/auth/Header";
import AuthContainer from "../../Components/auth/AuthContainer";
import ForgotPasswordForm from "../../Components/auth/ForgotPasswordForm";

const ForgotPassword = () => {
  return (
    <div className="wrapper login-bg position-relative">
      <Header />
      <AuthContainer
        mainheading="Forgot password?"
        subheading="Reset it Now!"
        componentform={<ForgotPasswordForm />}
      />
    </div>
  );
};

export default ForgotPassword;
