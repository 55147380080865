import React from 'react'
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const usePendingReminder = ({ pendingtype, page, itemsPerPage, search, start_date, end_date  }) => {

  console.log(itemsPerPage, page)
    const queryParams = {
        organisation: pendingtype === "organisation" ? 1 : "",
        search_key: search || '',
        start_date: start_date || '',
        end_date: end_date || '',
        page: page || 1,
        items_per_page: itemsPerPage || 500,
      };


      const queryString = Object.keys(queryParams)
      .filter((key) => queryParams[key] !== "")
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");

      const queryresult = useQuery({
        queryKey: [`pendingreminder`, { queryString }],
        keepPreviousData: true,
        queryFn: async () => {
          const res = await api.get(`market/pendingreminders?${queryString}`);
    
          return res?.data;
        },
      });

      return queryresult;
}

export default usePendingReminder