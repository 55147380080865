import api, { Imageapi } from "../../../api/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useCreateEmailTemplate = () => {
  
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const CreateEmailTemplateMutation = useMutation({
    mutationFn: async (data) => {
      let postForm = new FormData();
      postForm.append("name", data.name)
      postForm.append("subject", data.subject)
      postForm.append("send_to", data.send_to)
      postForm.append("email_body", data.email_body)
      let variabless = data.variables
      if(variabless.length){
        variabless.map((i) => {
          postForm.append("variables", i)
        })
      }
      let file = data.files
      if(file.length){
        file.map((i) => {
          postForm.append("files", i)
        })
      }
      // data.files.forEach((file, index) => {
      //   postForm.append(`files${index}`, file)
      // } )
      // postForm.append("files", JSON.stringify(data.files))
      
      
      const res = await Imageapi.post("record/email-template/create/", postForm);
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["createdemailtemplate"]);
      toast.success(data.message);
      if (data.status === true) {
        navigate("/manage/emailtemplate/");
      }
    },
    onError: (error) => {
      toast.error(`${error['response'].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });

  return CreateEmailTemplateMutation;
};

export default useCreateEmailTemplate;
