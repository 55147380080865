import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../api/index";
import { toast } from "react-toastify";

const useDeleteFilterData = (id, filterid) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: async () => {
            const res = await api.get(`record/dropdown-value/delete/${id}`)
            return res.data;
        },
        onSuccess: data => {
           
            queryClient.invalidateQueries([`filters/single`, { id: filterid }])   
            toast.success(data.message);
        },
        onError: (error) => {
            toast.error(`${error["response"].data.message}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
              }); 
          },
          enabled: id ? true : false
    });
    return mutation;
}

export default useDeleteFilterData;