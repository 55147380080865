import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import api, {Imageapi} from "../../../api";
import { Endpoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";

const useUpdateProfile = (onSuccess) => {
  const profile = localStorage.getItem("profileData")? JSON.parse(localStorage.getItem("profileData")) : '';
  const queryClient = useQueryClient();
  const updateProfileMutation = useMutation({
    mutationKey: ["userprofile"],
    mutationFn: async (data) => {
      console.log(data.image)
     
      let postForm = new FormData();
      postForm.append("fullname", data.fullname);
      postForm.append("email", data.email);
      postForm.append("phone", data.phone);
      if(!data?.image){
        delete data.image
      } else {
        postForm.append("image", data.image)
      }
      
      // let image = data.image
      // if(image.length){
      //   image.map((i) => {
      //     postForm.append("image", i)
      //   })
      // }
      let resp = await Imageapi.post(Endpoints.updateProfile, postForm);
      return resp.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("userprofile");
      if (data.status) {
        toast.success("Profile updated successfully");
        if(onSuccess){
          onSuccess()
        }
        const image = {
            image : data?.data?.image       
        };
       
        localStorage.setItem("profileimage", JSON.stringify(image))
        const newData = {
          ...profile,
          fullname: data.data.fullname,
          phone: data?.data?.phone,
          email: data?.data?.email,
        };
        localStorage.setItem("profileData", JSON.stringify(newData));
      }
    },

    onError: (error) => {
      toast.error(`${error["response"].data.message}`);
    },
  });

  return updateProfileMutation;
};

export default useUpdateProfile;
