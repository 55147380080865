import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import api from "../../../api";
import { Endpoints } from "../../../api/Endpoints";


const useProfile = () =>{
    const getUserProfile = useQuery({
        queryKey: ['userprofile'],
        refetchOnWindowFocus: false,
        queryFn: async()=>{
            let resp = await api.get(Endpoints.getProfile);
            localStorage.setItem('profileData', JSON.stringify(resp.data?.data))
            return resp?.data?.data
        }
    })

    return getUserProfile;
}


export default useProfile;
