import { useNavigate } from "react-router-dom";
import PlusIcon from "../../img/icon/plus.svg";
import useCreateCrm from "../../libs/mutation/crm/useCreateCrm";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";

const PlusButton = ({ name, navto, crm, checkBoxAdd, onPrs, onMeeting, meetingState }) => {
  const [isPrs, setIsPrs] = useState(false);
  const [isMeeting, setIsMeeting] = useState(meetingState);

  const { mutate: changetocrm } = useCreateCrm(checkBoxAdd);

  const navigate = useNavigate();
  const handleCrm = () => {
    changetocrm();
  };

  useEffect(()=>{
    onPrs?.(isPrs)
  },[isPrs])

  useEffect(()=>{
    if(!meetingState){
      setIsMeeting(meetingState);
    }
  },[meetingState])

  useEffect(()=>{
    onMeeting?.(isMeeting)
  },[isMeeting])
  return (
    <div
      onClick={() => {
        if(navto=='productivity'){
          setIsPrs(true);
          return
        }
        if(navto=='meeting'){
          setIsMeeting(true);
          return
        }
        navigate(navto);
        if (crm && checkBoxAdd) {
          if(!checkBoxAdd?.length){
            toast.error('Please Select Some Agreement');
          }
          else{
            handleCrm();
          }
        }
      }}
      className="custom-btn medium sm-btn"
      style={{ paddingTop: "12px", paddingBottom: "12px", cursor: "pointer" }}
    >
      {!crm && (
        <img src={PlusIcon} className="me-2" style={{ marginLeft: "5px" }} />
      )}

      <a
        style={{
          color: "#fff",
          width: "100%",
          display: "flex",
          cursor: "pointer",
        }}
      >
        {name}
      </a>
    </div>
  );
};

export default PlusButton;
