import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DropdownInput from "../../ui/inputs/DropdownInput";
import { Controller, useForm } from "react-hook-form";
import CheckboxInput from "../../ui/inputs/CheckboxInput";
import useGetCampaignSearch from "../../libs/queries/market/useGetCampaignSearch";
import useCampaignSearch from "../../libs/mutation/market/useCampaignSearch";
import { useDispatch, useSelector } from "react-redux";
import {
  setContactDep,
  setIndustry,
  setService,
  setLeadOwner,
  setbusinessModel,
  setFortune,
  setCompleteData,
  setCampaignParams,
  setBusinessPotential,
} from "../../slice/campaignSlice";
import LocationDropdown from "../../ui/inputs/LocationDropdown";
import useLocalStorage from "../../hooks/useLocalStorage";

const NewCampaignForm = () => {
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({ mode: "all" });

  const [data, setData] = useLocalStorage("campaign");
  const dispatch = useDispatch();
 

 

  const onSubmit = (data) => {

    // const { fortune_1000, ...rest } = data || {};
    // data = {
    //   ...rest,
    //   ...(fortune_1000 !== undefined && {
    //     fortune_1000:
    //       fortune_1000
    //         ?.toString()
    //         ?.split("")
    //         ?.map((char, index) => (index === 0 ? char.toUpperCase() : char))
    //         ?.join("") || "True",
    //   }),
    // };
    setData(data);
    // dispatch(setBusinessPotential(data?.potential_service))

    
    navigate("/documentrepository/campaign");
    // campaignSearch(data);
  };

  return (
    <div className="bg-white p-4">
      <form onSubmit={handleSubmit(onSubmit)} className="add-form">
        <div className="row  gx-lg-5 align-items-center">
          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={1}
              name="industry"
              label="Industry"
              register={register}
              rules={{}}
              // errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={8}
              name="business_model"
              label="Business Model"
              register={register}
              rules={{}}
              // errors={errors}
            />
          </div>

          {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label htmlFor="" className="d-block text-sky fs-12 pb-2">
              Business Model
            </label>
            <select name="" id="">
              <option value="">Unicorns/ Soonicorns​</option>
              <option value="">Start Up</option>
              <option value="">Captive/ GIC/ SSC</option>
              <option value="">Outsourcing</option>
              <option value="">Consulting​</option>
              <option value="">R&D Engineering​</option>
              <option value="">Others </option>
            </select>
          </div> */}

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={12}
              name="contact_department"
              label="Contact Department​"
              register={register}
              rules={{}}
              // errors={errors}
            />
          </div>
          {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label htmlFor="" className="d-block text-sky fs-12 pb-2">
              Contact Department​
            </label>
            <select name="" id="">
              <option value="">Yes</option>
              <option value="">No</option>
            </select>
          </div> */}

          {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label htmlFor="" className="d-block text-sky fs-12 pb-2">
              Location
            </label>
            <select name="" id="">
              <option value=""></option>
              <option value=""></option>
              <option value=""></option>
            </select>
          </div> */}

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={9}
              name="potential_service"
              label="Service Based"
              register={register}
              rules={{}}
              // errors={errors}
            />
          </div>
          {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label htmlFor="" className="d-block text-sky fs-12 pb-2">
              Service Based​
            </label>
            <select name="" id="">
              <option value=""></option>
              <option value=""></option>
              <option value=""></option>
            </select>
          </div> */}

          {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={3}
              name="doc_type"
              label="Potential Category"
              register={register}
              errors={errors}
            />
          </div> */}
          {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label htmlFor="" className="d-block text-sky fs-12 pb-2">
              Potential Category
            </label>
            <select name="" id="">
              <option value=""></option>
              <option value=""></option>
              <option value=""></option>
            </select>
          </div> */}

          <div className="col-lg-3 col-md-4 col-sm-6 pb-4">
            <LocationDropdown
              name="location"
              label="Location"
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              isUsers={true}
              name="lead_owner"
              label="Lead Owner"
              register={register}
              rules={{}}
              // errors={errors}
            />
          </div>
          {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label htmlFor="" className="d-block text-sky fs-12 pb-2">
              Lead Owner
            </label>
            <select name="" id="">
              <option value=""></option>
              <option value=""></option>
              <option value=""></option>
            </select>
          </div> */}

          {/* <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label htmlFor="" className="d-block text-sky fs-12 pb-2">
              Fortune 1000 /Not
            </label>
            <select name="" id="">
              <option value=""></option>
              <option value=""></option>
              <option value=""></option>
            </select>
          </div> */}
          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <Controller
              name="fortune_1000"
              control={control}
              // rules={{ required: "Company Name is Required" }}
              render={({ field: { value, onChange, name } }) => {
                // console.log(value);
                return (
                  <CheckboxInput
                    label="Fortune 1000 /Not"
                    name={name}
                    checked={value}
                    // value={value || ""}
                    onChange={onChange}
                  />
                );
              }}
            />
            {/* <label for="" className="d-block text-sky fs-12 pb-2">
              Paid/Unpaid
            </label>
            <input type="text" className="form-field" placeholder="" /> */}
          </div>
        </div>
        <div className="d-flex border-0 butn-group justify-content-center gap-3 pt-4">
          <button
            type="onSubmit"
            className="custom-btn border-0 py-2 rounded-3 raleway medium"
          >
            Search
          </button>
          <button
            type="button"
            onClick={() => navigate("/documentrepository")}
            className="custom-btn py-2  rounded-3 border-bl-btn px-4 raleway semibold"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewCampaignForm;
