import React, { useState } from "react";
import EditIcon from "../../../img/icon/edit-white.svg";
import LocationEdit from "./LocationEdit";

const LocationModal = ({ index, data }) => {
  const { id, city, state, created_at } = data || {};
  const [edit, setEdit] = useState(false);

  const date = created_at.substring(0, 16);
  const datefinal = date.split("T").join(" ");
  const time = datefinal.substring(11, 13);

  return (
    <>
      {!edit && (
        <div className="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3  acco-info-table">
          <div className="d-flex" style={{marginLeft: "70px", marginRight: "70px"}}>
            <div style={{marginRight: "20px"}}>
              <td className="action-first" >
                <span className="number d-flex align-items-center justify-content-center rounded-circle fs-24 semibold">
                  {index + 1}
                </span>
              </td>
            </div>
            <div className="d-flex flex-column w-100">
              <div className="d-flex">
                <div className="pb-2" style={{ width: "300px" }}>
                  <h6 className="fs-12 text-sky regular  pb-md-1">State</h6>
                  <h5 className="regular">{state}</h5>
                </div>
                <div className="pb-2" style={{ width: "200px" }}>
                  <h6 className="fs-12 text-sky regular  pb-md-1">City</h6>
                  <h5 className="regular">{city}</h5>
                </div>

                <div className="pb-2" style={{ width: "450px" }}>
                  <h6 className="fs-12 text-sky regular  pb-md-1">
                    Created At
                  </h6>
                  <h5 className="regular">
                    {datefinal} {time >= 12 ? "PM" : "AM"}
                  </h5>
                </div>
              </div>
            </div>
            <tr>
              <td className="action-last" style={{ width: "100px" }}>
                <div>
                  <a
                    // href="#"
                    className="selectContainer action-icon ms-auto"
                    data-bs-toggle="collapse"
                    data-bs-target="#edit_info_1"
                    onClick={() => setEdit(true)}
                  >
                    <img src={EditIcon} />
                  </a>
                </div>
              </td>
            </tr>
          </div>
        </div>
      )}

      {edit && (
        <LocationEdit data={data} index={index} onHide={() => setEdit(false)} />
      )}
    </>
  );
};

export default LocationModal;
