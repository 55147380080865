import React from 'react'
import PlusIcon from "../../../img/icon/plus.svg";
import { useNavigate } from "react-router-dom";

const UserPlusButton = () => {

    const navigate = useNavigate();

  return (
    <div className="col-sm-12 text-sm-end">
      <div className='custom-btn medium sm-btn' onClick={() => navigate("/users/new")} style={{cursor: "pointer"}}>
      <img src={PlusIcon} className="me-2" />
      <a 
       
        onClick={() => {
          navigate("/users/new"); 
        }}
        style={{cursor: "pointer"}}
      >
          New User
        
      </a>
      </div>
     
    </div>
  )
}

export default UserPlusButton