import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useFilterData from "../../libs/queries/manage/useFilterData";
import useRoles from "../../libs/queries/profile/useRoles";
import { Typeahead } from "react-bootstrap-typeahead";
import useGetLead from "../../libs/queries/leads/useGetLead";
import useUsers from "../../libs/queries/profile/useUsers";
import RadioFilters from "./RadioFilters";
import UserRadioFilter from "./UserRadioFilter";
import usePracticeHead from "../../libs/queries/calendar/usePracticeHead";
import useProfile from "../../libs/queries/profile/useProfile";

const CalendarFilter = ({
  showSort,
  onPracticeHeadSelect,
  type,
}) => {
  // const { showSort } = useSelector((state) => state.sortSlice);
  const { data: signingOptions } = useFilterData(22, null, true, true);
  const {data: practiceHead} = usePracticeHead();
  const [isOpen, setIsOpen] = useState(showSort);
  const [practiceId, setPracticeId] = useState([]);
  const dropdownRef = useRef(null);
  const {data: profile} = useProfile();

  useEffect(()=>{
    if(profile?.id){
        setPracticeId([profile?.id])
    }
  },[profile])

   useEffect(() => {
    onPracticeHeadSelect?.(practiceId);
  }, [practiceId]);

  return (
    <div
    style={{
        width: '300px',
        // backgroundColor: 'red'
    }}
    className={`dropdown-menu p-0 rounded-10 dropdown-menu-end show`}
      onclick="event.stopPropagation()"
    >

        {<div>
        <div className='d-flex py-2 px-0 align-items-center ms-3'>
            <div className='me-2'>
                <img
                style={{
                    width: '15px',
                    height: '8px',
                }}
                src='assets/img/drop_down.png'
                />
            </div>

            <a
            className="collapse-butn semibold fs-14"
            role="button"
            >
            My Calendar
            </a>
        </div>
        <div className="">
            <div
                className="d-flex align-items-center py-1 px-3 ms-3"
            >
                 <input
                checked={practiceId?.includes(profile?.id)} 
                onClick={()=>{
                    if(practiceId.includes(profile?.id)){
                        setPracticeId(practiceId.filter((ite)=>ite != profile?.id));
                    }
                    else{
                        setPracticeId([...practiceId, profile?.id])
                    }
                    }}
                type="radio" id="active" 
                />
                <label className="fs-14 ms-2 font-weight-normal text-lowercase text-dark medium text-truncate" >
                {"Calendar - "+profile?.email}
                </label>
               
            </div>
        </div>
        </div>}

        {
          <div className="border-bottom">
            <div className='d-flex py-2 px-0 align-items-center ms-3'>
            <div className='me-2'>
                <img
                style={{
                    width: '15px',
                    height: '8px',
                }}
                src='assets/img/drop_down.png'
                />
            </div>
            <a
                className="collapse-butn semibold fs-14"
                role="button"
                aria-expanded="false"
                aria-controls="user"
            >
                Directors
            </a>
          </div>
          <div className="" id="user">
            <div style={{ overflowY: "scroll", height: "60px" }}>
            {practiceHead &&
                  practiceHead?.map((item, index) => {
                  return (
                    <div className="d-flex align-items-center py-1 px-3 ms-3">
                     <input
                    //   onChange={()=>{
                    //       setPracticeId(item.id);
                    //   }}
                        checked={practiceId?.includes(item.id)} 
                        onClick={()=>{
                        if(practiceId.includes(item?.id)){
                            setPracticeId(practiceId.filter((ite)=>ite != item?.id));
                        }
                        else{
                            setPracticeId([...practiceId, item?.id])
                        }
                        }}
                      type="radio" 
                      id="TA-Sourcing" 
                      />
                      <label className="font-weight-normal ms-2 text-lowercase medium text-dark fs-14 text-truncate" for="TA-Sourcing">
                        {item.name + " - " + item.email}
                      </label>
                      
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        }

    </div>
  );
};

export default CalendarFilter;
