import React, { useEffect, useRef, useState } from "react";
import usePendingReminder from "../../libs/queries/alerts/usePendingReminder";
import useNotification from "../../libs/queries/notification/useNotification";
import useNotificationread from "../../libs/mutation/notification/useNotificationread";

const Notification = ({showSort}) => {
  const {data: notificationdata, isLoading} = useNotification()
  const [clickedDivIds, setClickedDivIds] = useState([]);
  const [pendingReminderData, setPendingReminderData] = useState([]);
  const [isOpen, setIsOpen] = useState(showSort);
  const dropdownRef = useRef(null);

 

  const {mutate: notificationread} = useNotificationread({
    notification_ids: clickedDivIds
  })



  const toggleDivBackground = (itemId) => {
    if (!clickedDivIds?.includes(itemId)) {
      // Add the itemId to the array
      const updatedClickedDivIds = [...clickedDivIds, itemId];
      setClickedDivIds(updatedClickedDivIds);
      notificationread()

   
    }
  };

  const markAllAsRead = () => {
  
    const unreadItemIds = pendingReminderData
      .filter((item) => !item?.is_read)
      .map((item) => item.id);

    // Add the unread item IDs to the clickedDivIds array
    const updatedClickedDivIds = [...clickedDivIds, ...unreadItemIds];
    setClickedDivIds(updatedClickedDivIds);

   
    notificationread()
    // Mark all items as read locally (if needed)
    const updatedPendingReminderData = pendingReminderData?.map((item) => ({
      ...item,
      is_read: true,
    }));
    setPendingReminderData(updatedPendingReminderData);
  };


  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(()=>{
    setIsOpen(showSort)
  },[showSort])


  useEffect(() => {
    // Check if pendingreminder?.data exists and set it, or initialize as an empty array
    if (notificationdata?.data && !isLoading ) {
      setPendingReminderData(notificationdata?.data);
    } else {
      setPendingReminderData([]);
    }
  }, [notificationdata?.data, isLoading]);

  return (
    <div
    ref={dropdownRef}
      style={{
        // backgroundColor: "black",
        position: "absolute",
        color: "white",
        overflowY: "scroll",
        cursor: "pointer",
        // top: 10,
        // right: 0,
        bottom: "auto",
        zIndex: "200",
        height: "90vh",
        width: "20%",
        
        // left: "auto",
      }}
      className={`dropdown-menu filter-menu p-0 rounded-10 dropdown-menu-end ${
        isOpen?"show":""}`}
    >
      {notificationdata?.data?.length>0 && <button onClick={markAllAsRead}>Mark all as Read</button>}
      {notificationdata?.data?.map((item, index) => {
          const date = item?.created_at?.substring(0, 10);
          const datefinal = date?.split("T").join(" ");
          const timedate = item?.created_at?.substring(11, 16)
         
          const time = parseInt(timedate); // Convert to an integer
        
          // Determine whether it's AM or PM based on the time
          const period = time >= 12 ? "PM" : "AM";
          const formattedTime =
            time > 12 ? (time - 12).toString() : time.toString();
        return (
          <div
          key={index}
            style={{
              backgroundColor: item?.is_read === true ? 'white' : '#007bff',
              padding: "20px 30px",
              borderTop: "1px solid gray",
              cursor: "pointer",
              color: item?.is_read === true ? 'black' : 'white',
            }}
            onClick={() => toggleDivBackground(item?.id)}
          >
            {item?.message}
            <div style={{fontSize: "12px", marginTop: "5px"}}>{datefinal} {formattedTime}:{time} {period}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Notification;
