import React from 'react'
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useNotification = () => {

    const queryresult = useQuery({
        queryKey: ["notification"],
        refetchOnWindowFocus: false,
        queryFn: async () => {
          const res = await api.get(
            `market/notifications`
          );
    
          return res.data;
        },
      });


      return queryresult;
}

export default useNotification