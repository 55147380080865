import React from "react";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useGetEscalationMember = ({
  memberType,
  id,
  search,
  level,
  page,
  itemsPerPage,
}) => {
  const queryParams = {
    member_type: memberType || "",
    search_key: search || "",
    level: level || "",
    page: page || 1,
    items_per_page: itemsPerPage || 500,
  };
  // console.log("this is get", memberType);

  const queryString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "") // Filter out keys with no value
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");

  console.log(queryString);

  const queryresult = useQuery({
    queryKey: [`crm`, { queryString }],
    keepPreviousData: true,
    queryFn: async () => {
      const res = await api.get(`crm/escalationmembers/${id}?${queryString}`);

      return res?.data;
    },
    enabled: id ? true : false,
  });

  return queryresult;
};

export default useGetEscalationMember;
