import React, { useEffect, useRef, useState } from "react";
import useFilterData from "../../libs/queries/manage/useFilterData";

const EscalationSort = ({showSort, type, onLevelSelect}) => {

    const {data: contactlevel} = useFilterData(13)
    const [isOpen, setIsOpen] = useState(showSort);
    const [levelId, setLevelId] = useState("");
    console.log(levelId)

  const CrmDropdownRef = useRef(null);

  useEffect(() => {
    setIsOpen(showSort);
  }, [showSort]);

  useEffect(() => {
    onLevelSelect?.(levelId);
  }, [levelId]);


  return (
    <div
      ref={CrmDropdownRef}
      style={{
        position: "absolute",
        right: 0,
        bottom: "auto",
        left: "auto",
        // inset:'left'
      }}
      className={`dropdown-menu filter-menu p-0 rounded-10 dropdown-menu-end ${
        isOpen ? "show" : ""
      }`}
    >
      <h6 className="text-white bg-sky p-3 filter-title semibold mb-1">
        Sort by
      </h6>
      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#status"
          role="button"
          aria-expanded="false"
          aria-controls="status"
        >
          Level
        </a>
        <div className="collapse" id="status">
         {contactlevel?.results?.map((item, index) => {
          
          console.log(levelId === item?.name)
            return (
                <div
                key={index}
                className="d-flex justify-content-between align-items-center py-1 px-3"
              >
                <label className="medium fs-14" htmlFor="active">
                  {item?.name}
                </label>
                <input
                  checked={levelId === item?.name.toLowerCase().split(" ").join("")}
                  onChange={() => {
                    setLevelId(item?.name.toLowerCase().split(" ").join(""));
                  }}
                  type="radio"
                  id="active"
                  // value={item?.id}
                />
              </div>
            )
         })}
        </div>
      </div>
    </div>
  );
};

export default EscalationSort;
