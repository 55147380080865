import React from "react";
import api from "../../../api/index";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useDeleteUser = (user_id) => {
  const queryClient = useQueryClient();
  const DeleteUser = useMutation({
    mutationFn: async () => {
      const res = await api.post(
        `account/delete/user/${user_id}`
      );
      return res.data;
    },
    onSuccess: (data) => {
        queryClient.invalidateQueries(["createuser"]);
        toast.success(data.message);
      },
      onError: (error) => {
        
      },
  });

  return DeleteUser
};

export default useDeleteUser;
