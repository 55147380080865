import React from 'react'
import api from "../../../api";
import { useQuery } from '@tanstack/react-query';

const useAllUsers = () => {

    const GetAllUser = useQuery({
        queryKey: ['createuser'],
        queryFn: async() => {
          let resp = await api.get(`account/allusers`);
          return resp?.data
        }
      })
  
    return GetAllUser

 
}

export default useAllUsers