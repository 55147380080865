import { useEffect, useState } from "react";
import usePrs from "../../libs/queries/graphs/usePrs";
import useUpdatePrs from "../../libs/mutation/prs/useUpdatePrs";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useUpdateResearchPrs from "../../libs/mutation/prs/useUpdateResearchPrs";
import RoleRestriction from "../../utils/roleRestriction";
import PrsModal from "../../ui/modals/prsModal";
// import EditIcon from "../../../img/icon/edit-white.svg";

function ResearcherPrs({prsData, type}){
    const [contentCreated, setContentCreated] = useState(0);
    const [delivery, setDelivery] = useState(0);
    const [indexes, setIndexes] = useState([]);
    const [indexes2, setIndexes2] = useState([]);
    const [indexes3, setIndexes3] = useState([]);
    const [tagetScore, setTargetScore] = useState(0);
    const [operationalRating, setOperationalRating] = useState(0);
    const [atsRating, setAtsRating] = useState(0);
    const [superRating, setSuperRating] = useState(0);
    const [selectedItem, setSelectedItem] = useState({});
    const [submitIndex, setSubmitIndex] = useState(null);
    const [count, setCount] = useState(0);
    const restrict = RoleRestriction();
    const [isPrsVisible, setIsPrsVisible] = useState(false);
    const [stageName, setStageName] = useState('');
    const [filterIndex, setFilterIndex] = useState(0);

    const [newPrsData, setNewPrsData] = useState(prsData?.data);

    const {mutate: updatePrs} = useUpdateResearchPrs();

    const {
      register,
      formState: { errors },
      handleSubmit,
      reset,
      control,
      watch,
      getValues
    } = useForm();

    useEffect(()=>{
        setNewPrsData(prsData?.data);
    },[prsData]);

    // console.log('oe__', watch(`overall_SA_${0}`));
    // console.log('checkrev__', watch(`review_remark_${0}`))

    useEffect(()=>{
      if(selectedItem){
        reset({
          user : selectedItem?.user,
          month: selectedItem?.month,
          year: selectedItem?.year,
        //   week: selectedItem?.week,
          leads_generated_target: selectedItem?.leads_generated_target,
          leads_generated_achieved:selectedItem?.leads_generated_achieved,
        })
      }
    },[selectedItem])

    const onSubmit = () => {
      const formData = getValues();
      console.log('fmmm__',formData);
      let new_obj = {};
      Object.keys(formData).forEach((item)=>{
        if(item.split('_').pop()==submitIndex){
          let arr = item.split('_');
          let new_key = arr.slice(0,arr.length-1).join('_');
          new_obj[new_key] = formData[item];

          if(new_key=="SA_total"){
            new_obj[new_key] = (watch(`overall_SA_${submitIndex}`) || prsData?.data?.[submitIndex]?.SA_total)
          }
          if(new_key=="OE_total"){
            new_obj[new_key] = (watch(`overall_OE_${submitIndex}`) || prsData?.data?.[submitIndex]?.OE_total)
          }
          
          if(new_key=="CD_total"){
            new_obj[new_key] = (
                (parseFloat(watch(`compliance_rating_${submitIndex}`) || 0)+
                parseFloat(watch(`content_created_score_${submitIndex}`)|| 0)+
                parseFloat(watch(`supervisory_rating_${submitIndex}`) || 0))
            )
          }
          if(new_key=="final"){
            new_obj[new_key] = (
            (parseFloat(watch(`overall_SA_${submitIndex}`) || prsData?.data?.[submitIndex]?.SA_total || 0)+
            parseFloat(watch(`overall_OE_${submitIndex}`) || prsData?.data?.[submitIndex]?.OE_total || 0)+
            (
            (parseFloat(watch(`compliance_rating_${submitIndex}`) || 0)+
            parseFloat(watch(`content_created_score_${submitIndex}`) || 0)+
            parseFloat(watch(`supervisory_rating_${submitIndex}`) || 0))
            ))
            )
          }
        }
        else if(isNaN(item.split('_').pop())){
          new_obj[item] = formData[item];
        }
      })
      console.log('new_obj',new_obj);
      updatePrs(new_obj)

    };

    useEffect(()=>{
      if(count){
        handleSubmit(onSubmit)();
      }
    },[count]);
    return(
        <div>
            <table className="prs-table" width="100%">
            <tr className="prs-head">
                <td rowspan="4">parameters/month</td>
                <td rowspan="4">User</td>
                <td colspan="6">Strategic Achievements (SA)</td>
                <td colspan="4">Operational Excellence(OE)</td>
                {/* <td>Compliance</td> */}
                <td colSpan="4">Compliance & Capability Development (CD)</td>
                {/* <td></td> */}
                {/* <td colspan="4">Capability Development</td> */}
                <td colspan="4">Total (100)</td>
                <td className="bg-white border-0"></td>
                <td className="bg-white border-0"></td>
            </tr>
            <tr className="prs-head">
                <td colSpan='6'>Broad Project Objectives</td>
                <td colspan="4">Productivity and Conversions (At Individual Level)</td>
                <td>ATS Office Time, LMS</td>
                <td  colspan="2">Content Created (Number)</td>
                {/* <td  colspan="2">Delivery (Number)</td> */}
                <td >Supervising Rating</td>
                <td rowspan="3">SA (20)</td>
                <td rowspan="3">OE (50)</td>
                <td rowspan="3">CD (30)</td>
                <td className="px-2" rowspan="3">Final 100</td>
                <td className="bg-white border-0"></td>
                <td className="bg-white border-0"></td>
            </tr>
            <tr className="prs-head">
                <td colspan="5">Project Based</td>
                <td rowSpan="2">Overall (20)</td>
                <td colspan="2">Leads Generated</td>
                <td>Report Submitted</td>
                <td rowSpan="2">Overall (50)</td>
                <td rowspan="2">Rating (10)</td> 
                <td rowspan="2">Number</td>
                <td rowspan="2">Score (10)</td>
                {/* <td rowspan="2">Number</td>
                <td rowspan="2" className="px-1">Score (10)</td> */}
                <td rowspan="2">Rating (10)</td> 
                <td className="bg-white border-0"></td>
                <td className="bg-white border-0"></td>
            </tr>
            <tr className="prs-head">
                <td>No. of Projects</td>
                <td>Week</td>
                <td>Name of the Projects</td>
                <td>Progress</td>
                <td>Review Remark</td>
                <td>Target</td>
                <td>Achieved</td>
                <td>Number</td>
                <td className="bg-white border-0"></td>
                <td className="bg-white border-0"></td>
            </tr>
            {
                newPrsData?.map((item, index)=>{
                    return(
                        <tr key={index}>
                        <td>{item?.month_name}</td>
                        <td>{item?.user_fullname}</td>
                        <td style={{
                          backgroundColor: indexes3?.includes(index)? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                            <Controller
                            name={`no_of_projects_${index}`}
                            control={control}
                            defaultValue={item?.no_of_projects}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={indexes3.includes(index)?false:true}
                                type="number"
                                min={0}
                                value={field.value}
                                onChange={field.onChange}
                                className="bg-transparent border-0 no-spinners"
                              />
                            )}
                            />
                        </td>
                        <td style={{
                          backgroundColor: indexes3?.includes(index)? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                            <Controller
                            name={`week_${index}`}
                            control={control}
                            defaultValue={item?.week}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={indexes3.includes(index)?false:true}
                                type="number"
                                min={0}
                                value={field.value}
                                onChange={field.onChange}
                                // onChange={(e)=>{
                                //   if(e.target.value>=1 && e.target.value<=5){
                                //     field.onChange(e);
                                //   }                                
                                // }}
                                className="bg-transparent border-0 no-spinners"
                              />
                            )}
                            />
                        </td>
                        <td style={{
                          backgroundColor: indexes3?.includes(index)? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                            <Controller
                            name={`name_of_the_projects_${index}`}
                            control={control}
                            defaultValue={item?.name_of_the_projects}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={indexes3.includes(index)?false:true}
                                type="text"
                                value={field.value}
                                onChange={field.onChange}
                                className="bg-transparent border-0"
                              />
                            )}
                            />
                        </td>
                        <td style={{
                          backgroundColor: indexes3?.includes(index)? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                            <Controller
                            name={`progress_${index}`}
                            control={control}
                            defaultValue={item?.progress}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={indexes3.includes(index)?false:true}
                                type="text"
                                value={field.value}
                                onChange={field.onChange}
                                className="bg-transparent border-0"
                              />
                            )}
                            />
                        </td>
                        <td style={{
                          backgroundColor: (indexes3?.includes(index) && restrict!=='researcher')? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                            <Controller
                            name={`review_remark_${index}`}
                            control={control}
                            defaultValue={item?.review_remark}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={(indexes3.includes(index)&& restrict!=='researcher')?false:true}
                                type="text"
                                value={field.value}
                                onChange={field.onChange}
                                className="bg-transparent border-0"
                              />
                            )}
                            />
                        </td>
                        <td style={{
                          backgroundColor: (indexes3?.includes(index) && restrict!=='researcher')? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                            <Controller
                            name={`overall_SA_${index}`}
                            control={control}
                            defaultValue={item?.overall_SA}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={(indexes3.includes(index) && restrict!=='researcher')?false:true}
                                type="text"
                                value={field.value}
                                // onChange={field.onChange}
                                onChange={(e)=>{
                                  if(e.target.value>=0 && e.target.value<=20){
                                    field.onChange(e);
                                  }                                
                                }}
                                className="bg-transparent border-0"
                              />
                            )}
                            />
                        </td>
                        <td>{item?.leads_generated_target}</td>
                        {/* <td>{item?.leads_generated_achieved}</td> */}
                        <td>
                        <div 
                          onClick={()=>{
                            setFilterIndex(index);
                            setIsPrsVisible(true);
                            setStageName('Lead Generated');
                            // setIsLeadAchieved(true);
                            // setIsCrm(false);
                            // setIsIndustry(false);
                          }}
                            className="selectContainer"
                            style={{
                              color: '#3366CC'
                            }}
                          >
                            {item?.leads_generated_achieved}
                           </div> 
                        </td>
                        <td style={{
                          backgroundColor: indexes3?.includes(index)? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                            <Controller
                            name={`reports_submitted_number_${index}`}
                            control={control}
                            defaultValue={item?.reports_submitted_number}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={indexes3.includes(index)?false:true}
                                type="text"
                                value={field.value}
                                onChange={field.onChange}
                                className="bg-transparent border-0"
                              />
                            )}
                            />
                        </td>
                        <td style={{
                          backgroundColor: (indexes3?.includes(index) && restrict!=='researcher')? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                            <Controller
                            name={`overall_OE_${index}`}
                            control={control}
                            defaultValue={item?.overall_OE}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={(indexes3.includes(index) && restrict!=='researcher')?false:true}
                                type="number"
                                value={field.value}
                                // onChange={field.onChange}
                                onChange={(e)=>{
                                  if(e.target.value>=0 && e.target.value<=50){
                                    field.onChange(e);
                                  }                                
                                }}
                                className="bg-transparent border-0 no-spinners"
                              />
                            )}
                            />
                        </td>
                        <td style={{
                          backgroundColor: (indexes3?.includes(index) && restrict!=='researcher')? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                            <Controller
                            name={`compliance_rating_${index}`}
                            control={control}
                            defaultValue={item?.compliance_rating}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={(indexes3.includes(index) && restrict!=='researcher')?false:true}
                                type="text"
                                value={field.value}
                                // onChange={field.onChange}
                                onChange={(e)=>{
                                  if(e.target.value>=0 && e.target.value<=10){
                                    field.onChange(e);
                                  }                                
                                }}
                                className="bg-transparent border-0"
                              />
                            )}
                            />
                        </td>
                        <td style={{
                          backgroundColor: (indexes3?.includes(index) && restrict!=='researcher')? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                            <Controller
                            name={`content_created_number_${index}`}
                            control={control}
                            defaultValue={item?.content_created_number}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={(indexes3.includes(index) && restrict!=='researcher')?false:true}
                                type="text"
                                value={field.value}
                                onChange={field.onChange}
                                className="bg-transparent border-0"
                              />
                            )}
                            />
                        </td>
                        <td style={{
                          backgroundColor: (indexes3?.includes(index) && restrict!=='researcher')? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                            <Controller
                            name={`content_created_score_${index}`}
                            control={control}
                            defaultValue={item?.content_created_score}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={(indexes3.includes(index) && restrict!=='researcher')?false:true}
                                type="number"
                                value={field.value}
                                // onChange={field.onChange}
                                onChange={(e)=>{
                                  if(e.target.value>=0 && e.target.value<=10){
                                    field.onChange(e);
                                  }                                
                                }}
                                // className="bg-transparent border-0"
                                className="bg-transparent border-0 no-spinners"
                              />
                            )}
                            />
                        </td>
                        {/* <td
                        style={{
                          backgroundColor: indexes3?.includes(index)? 'rgb(200,200,200)' : '#e9ebf4'
                        }}
                        >
                          <Controller
                            name={`delivery_number_${index}`}
                            control={control}
                            defaultValue={item?.delivery_number}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={indexes3.includes(index)?false:true}
                                type="number"
                                min={0}
                                value={field.value}
                                onChange={field.onChange}
                                className="bg-transparent border-0 no-spinners"
                              />
                            )}
                          />
                        </td>
                        <td>
                          {<Controller
                          name={`delivery_score_${index}`}
                          control={control}
                            render={({ field }) => (
                            <input
                              name={field.name}
                              disabled={true}
                              type="number"
                              min={0}
                              // value={calculateContentScore(
                              //   parseFloat(watch(`content_created_number_${index}`) || item?.content_created_number || 0),
                              //   parseFloat(watch(`delivery_number_${index}`) || item?.delivery_number || 0)
                              //   )}
                              onChange={field.onChange}
                              className="bg-transparent border-0 no-spinners"
                            />
                          )}
                          />}
                        </td> */}
                        <td style={{
                          backgroundColor: (indexes3?.includes(index) && restrict!=='researcher')? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                            <Controller
                            name={`supervisory_rating_${index}`}
                            control={control}
                            defaultValue={item?.supervisory_rating}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={(indexes3.includes(index) && restrict!=='researcher')?false:true}
                                type="text"
                                value={field.value}
                                // onChange={field.onChange}
                                onChange={(e)=>{
                                  if(e.target.value>=0 && e.target.value<=10){
                                    field.onChange(e);
                                  }                                
                                }}
                                className="bg-transparent border-0"
                              />
                            )}
                            />
                        </td>
                        <td>
                            <Controller
                            name={`SA_total_${index}`}
                            control={control}
                            // defaultValue={watch('overall_SA') || item?.SA_total}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={true}
                                type="text"
                                // value={field.value}
                                value={watch(`overall_SA_${index}`) || item?.SA_total}
                                onChange={field.onChange}
                                className="bg-transparent border-0"
                              />
                            )}
                            />
                        </td>
                        <td>
                            <Controller
                            name={`OE_total_${index}`}
                            control={control}
                            // defaultValue={watch('overall_OE') || item?.OE_total}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={true}
                                type="text"
                                // value={field.value}
                                value={watch(`overall_OE_${index}`) || item?.OE_total}
                                onChange={field.onChange}
                                className="bg-transparent border-0"
                              />
                            )}
                            />
                        </td>
                        <td>
                            <Controller
                            name={`CD_total_${index}`}
                            control={control}
                            // defaultValue={watch('overall_OE') || item?.CD_total}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={true}
                                type="text"
                                // value={field.value}
                                value={
                                    (
                                    //   (watch(`compliance_rating_${index}`) && 
                                    // watch(`content_created_score_${index}`) &&
                                    // watch(`supervisory_rating_${index}`)) ?
                                    (parseFloat(watch(`compliance_rating_${index}`) || 0)+
                                    parseFloat(watch(`content_created_score_${index}`) || 0)+
                                    parseFloat(watch(`supervisory_rating_${index}`) || 0))
                                    // :
                                    // ''
                                    ) 
                                }
                                onChange={field.onChange}
                                className="bg-transparent border-0"
                              />
                            )}
                            />
                        </td>
                        <td>
                            <Controller
                            name={`final_${index}`}
                            control={control}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={true}
                                type="text"
                                // value={field.value}
                                value={(
                                    // (((watch(`overall_SA_${index}`) || item?.SA_total || 0) && 
                                    // (watch(`overall_OE_${index}`) || item?.OE_total || 0) &&
                                    // (watch(`supervisory_rating_${index}`) || 0)) ?
                                    (parseFloat(watch(`overall_SA_${index}`) || item?.SA_total || 0)+
                                    parseFloat(watch(`overall_OE_${index}`) || item?.OE_total || 0)+
                                    (
                                    (parseFloat(watch(`compliance_rating_${index}`) || 0)+
                                    parseFloat(watch(`content_created_score_${index}`) || 0)+
                                    parseFloat(watch(`supervisory_rating_${index}`) || 0))
                                    ))
                                    // :
                                    // ''
                                    ) 
                                }
                                onChange={field.onChange}
                                className="bg-transparent border-0"
                              />
                            )}
                            />
                        </td>
                        

                        {(restrict==='admin' || restrict==='practice' || restrict==='researcher') 
                        && <td className="bg-white border-0">
                          <a
                          style={{
                            width: '20px',
                            height: '20px'
                          }}
                            className="selectContainer action-icon ms-auto"
                            // onClick={() => setEdit(true)}
                            onClick={()=>{
                              setIndexes3([...indexes3, index]);
                              setSelectedItem(item)
                            }}
                          >
                            <img
                            style={{
                              width: '10px',
                              height: '10px'
                            }}
                            src={"assets/img/icon/edit-white.svg"} />
                          </a>
                        </td>}

                        {(restrict==='admin' || restrict==='practice' || restrict==='researcher') 
                        && <td className="bg-white border-0">
                          <a
                          style={{
                            width: '20px',
                            height: '20px'
                          }}
                            className="selectContainer action-icon ms-auto"
                            onClick={()=>{
                              if(indexes3.includes(index)){
                                setSubmitIndex(index);
                                setCount(count+1);
                                setIndexes3((v)=>v.filter((ite)=>ite!==index));
                              }
                              else{
                                toast.error('Please Edit Some Values to Save')
                              }
                            }}
                            >
                            <img
                            src={"assets/img/lock.png"} />
                          </a>
                        </td>}
                        </tr>
                    )
                })
            }
        </table>

          {
          isPrsVisible &&
            (
              <PrsModal
                onCancel={() => setIsPrsVisible(false)}
                stageName={stageName}
                // stageId={stageId}
                leadOwner={newPrsData?.[filterIndex]?.user}
                month={newPrsData?.[filterIndex]?.month}
                year={newPrsData?.[filterIndex]?.year}
                type={type}
                // data={}
              />
            )}
        </div>
    )
}

export default ResearcherPrs;