import React from 'react'
import FunnelChart from '../../ui/graphs/FunnelChart'
// import FunnelChart from '../../ui/graphs/FunnelChart'

const BDMonth = ({monthData}) => {

  const { clients_onboarded } = monthData?.data || {};
  const datamap = clients_onboarded?.map((item) => ({
    label: item?.Stage_Name,
    value: item?.count,
  }));

  // console.log(datamap)
    
// const data = [
//     {count: 20, name: "check"},
//     {count: 80, name: "see"},
//     {count: 10, name: "any"}
// ]
//     console.log(monthData)

  return (
    <div>
      {
        datamap?.filter((item)=>item?.value != 0)?.length?
        <FunnelChart 
        datamap={datamap} 
        // datamap={datamap?.filter((item)=>item?.value != 0)} 
        />
        :
        <p className='d-flex text-align-center justify-content-center'>No Data</p>
        
      }
      {/* <FunnelChart datamap={datamap?.filter((item)=>item?.value != 0)} /> */}
      </div>
  )
}

export default BDMonth