import React, { useState } from "react";
import EditIcon from "../../../img/icon/edit-white.svg";
import UserGenerationEdit from "./UserGenerationEdit";

const UserGeneratedModal = ({ data, index }) => {
  const { id, fullname, email, phone , role, role_display, designation, targets, team } = data || {};
  const {
    accepted_leads_generated,
    proposal_submission,
    meeting_organized,
    assignment_recieved,
    onboarding_renewal,
  } = targets || {};
  const [edit, setEdit] = useState(false);
  //   

  return (
    <>
      {!edit && (
        <div
          className="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3  acco-info-table"
          // id="acco_info"
        >
          <div className="d-flex">
            <div className="action-first" style={{width: "70px"}}>
              <span className="number d-flex align-items-center justify-content-center rounded-circle fs-24 semibold">
                {index + 1}
              </span>
            </div>
            <div>
              <div className="row gx-lg-5 align-items-center">
                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                    Full Name
                  </label>
                  <h5 className="regular">{fullname}</h5>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label className="d-block text-sky fs-12 pb-2">Email</label>
                  <h5 className="regular">{email}</h5>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label className="d-block text-sky fs-12 pb-2">Phone</label>
                  <h5 className="regular">{phone}</h5>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label className="d-block text-sky fs-12 pb-2">
                    Password
                  </label>
                  <h5 className="regular"></h5>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label className="d-block text-sky fs-12 pb-2">Role</label>
                  <h5 className="regular">{role_display}</h5>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                    Designation
                  </label>
                  <h5 className="regular">{designation}</h5>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                   Team
                  </label>
                  <h5 className="regular">{team}</h5>
                </div>
              </div>

              <h4 className="pt-4 pb-3">Targets</h4>
              <div className="row gx-lg-5 align-items-center">
                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                    Accepted Leads Generated
                  </label>
                  <h5 className="regular">{accepted_leads_generated}</h5>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label
                    className="d-block text-sky fs-12 pb-2"
                    defaultValue={10}
                  >
                    Proposal Submission
                  </label>
                  <h5 className="regular">{proposal_submission}</h5>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label className="d-block text-sky fs-12 pb-2">
                    Meeting/E-meeting Organised
                  </label>
                  <h5 className="regular">{meeting_organized}</h5>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label className="d-block text-sky fs-12 pb-2">
                    Pilot Assignment Received
                  </label>
                  <h5 className="regular">{assignment_recieved}</h5>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                    Onboarding & Agreement & Renewal
                  </label>
                  <h5 className="regular">{onboarding_renewal}</h5>
                </div>
              </div>
            </div>
            <div>
              <div>
                <a
                  // href="#"
                  className="selectContainer action-icon ms-auto"
                  data-bs-toggle="collapse"
                  data-bs-target="#edit_info_1"
                  onClick={() => setEdit(true)}
                >
                  <img src={EditIcon} />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {edit && (
        <UserGenerationEdit
          data={data}
          index={index}
          onHide={() => setEdit(false)}
        />
      )}
    </>
  );
};

export default UserGeneratedModal;
