import React from "react";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import Heading from "../../ui/modals/Heading";
import LeadForm from "../../Components/leads/LeadFormContainer";
import AgreementContainer from "../../Components/agreement/AgreementContainer";

const NewAgreement = () => {
  return (
    <div class="main py-4 position-relative">
      <div class="container">
      {/* <Subheader leftsideshow={<TwoButtonHolder type={'agreement'} />} />
      <Heading heading="Agreement" /> */}
      <AgreementContainer />
      </div>
    </div>
  );
};

export default NewAgreement;
