import React, { useCallback, useState } from "react";

const useToggle = (value) => {
  const [detail, showDetail] = useState(value || false);
  const toggleDetail = useCallback(() => {
    showDetail(!detail);
  }, [detail]);
  return [detail, toggleDetail];
};

export default useToggle;
