import React from "react";
import useCheckIn from "../../libs/mutation/dashboard/useCheckIn";
import { useDispatch } from "react-redux";
import { setCheckedIn } from "../../slice/checkInSlice";

const CheckInModal = (props) => {
  const dispatch = useDispatch();
const { data : checkInData, mutate : checkIn } = useCheckIn();

  const ConfirmCheckIn = () => {
    checkIn();
    dispatch(setCheckedIn("usercheckedin"));
    localStorage.setItem('checkintime', new Date().getTime().toString())
    props.onClose()
  };

  return (
    <div className="modaldelete">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content rounded-10 border-0 text-center">
          <div className="modal-body pt-3">
            <h4 style={{
                paddingBottom : '15px'
            }}>Attention</h4>
            <p className="px-4 pb-3">
              There is no running shift. Do you want to check in now !
            </p>
          </div>
          <div className="border-0 pt-2 text-center pb-4">
            <button
              type="button"
              style={{ 
                color: "#fff",
            }}
            className="selectContainer bg-sky border-0 rounded-10 py-2 mx-2 text-center"
              onClick={ConfirmCheckIn}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckInModal;
