import React, { useEffect, useState } from "react";
import useUpdateEmailTemplate from "../../../libs/mutation/emailtemplate/useUpdateEmailTemplate";
import { useForm, Controller } from "react-hook-form";
import Multiselect from "multiselect-react-dropdown";
import useGetEmailVariables from "../../../libs/queries/emailtemplate/ useGetEmailVariables";
import useUpdateStage from "../../../libs/mutation/Stage/useUpdateStage";
import useGetEmailTemplate from "../../../libs/queries/emailtemplate/useGetEmailTemplate";

const StageEdit = ({ data, index, onHide }) => {
    const {
      id ,
      name, 
      order_no, 
      template_name, 
      stage_background_color, 
      email_template
    } = data || {}
    const { data: emailtemplate } = useGetEmailTemplate(null, null, true);

  const { mutate: updateStage, isLoading } = useUpdateStage(
    id,
    onHide
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm();

  useEffect(()=>{
    if(data){
      reset({
        email_template,
        stage_background_color
    })
    }
  },[data])

  let colorData = [
    {
      name: "Red",
    },
    {
      name: "Green",
    },
    {
      name: "Blue",
    },
    {
      name: "Orange",
    },
    {
      name: "Yellow",
    },
    {
      name: "Black",
    },
    {
      name: "Pink",
    },
    {
      name: "Purple",
    },
    {
      name: "Violet",
    },
    {
      name: "Brown",
    },
    {
      name: "Grey",
    },
  ];
  return (
    <form
      onSubmit={handleSubmit(updateStage)}
      className="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3 edit-info-table "
    >
      <table className="action-table w-100 manage-table">
        <tr>
          <td className="action-first">
            <span className="number d-flex align-items-center justify-content-center rounded-circle fs-24 semibold">
              {index + 1}
            </span>
          </td>

          <td className="action-mid">
            <div className="row pe-xl-5">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="pb-3">
                  <h6 className="fs-12 text-sky regular  pb-1">
                    Name
                  </h6>
                  <input
                   defaultValue={name}
                    name="name"
                    type="text"
                    placeholder=""
                    {...register("name", { required: true })}
                  />
                </div>

                {/* <div className="pb-3">
                  <h6 className="fs-12 text-sky regular  pb-1">Template Name</h6>
                  <input
                    disabled={true}
                    defaultValue={template_name}
                    type="text"
                    placeholder=""
                  />
                </div> */}

                <div className="pb-3">
                <label className="fs-12 text-sky regular  pb-1">
                  Choose Email Template
                </label>
                <select
                style={{
                  borderRadius: '10px'
                }}
                  // defaultValue={email_template}
                  id="email_template"
                  name="email_template"
                  {...register("email_template", {
                    required: true,
                  })}
                >
                  <option value="">Select email template</option>
                  {emailtemplate?.data?.map((item, index) => {
                    return (
                      <>
                        <option
                          // id="email_template"
                          className="form-field"
                          key={index}
                          value={item.id}
                        >
                          {item?.name}
                        </option>
                      </>
                    );
                  })}
                </select>
                <span className="fs-12 text-red">
                  {errors.email_template?.type === "required" &&
                    "Email template is required"}
                </span>
              </div>

              </div>

              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="pb-3">
                  <h6 className="fs-12 text-sky regular  pb-1">Order No.</h6>
                  <input
                    defaultValue={order_no}
                    name="order_no"
                    type="text"
                    placeholder=""
                    {...register("order_no", { required: true })}
                  />
                </div>
              </div>

              {/* <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="pb-3">
                  <h6 className="fs-12 text-sky regular  pb-1">Stage Background Color</h6>
                  <input
                    defaultValue={stage_background_color}
                    name="stage_background_color"
                    type="text"
                    placeholder=""
                    {...register("stage_background_color", { required: true })}
                  />
                </div>
              </div> */}

              <div className="col-lg-3 col-md-4 col-sm-6">
                <label className="fs-12 text-sky regular  pb-1">
                  Stage Background Color
                </label>
                <select
                style={{
                  borderRadius: '10px'
                }}
                  // defaultValue={stage_background_color}
                  id="stage_background_color"
                  name="stage_background_color"
                  {...register("stage_background_color", {
                    required: true,
                  })}
                >
                  <option value="">Select background color</option>
                  {colorData?.map((item, index) => {
                    return (
                      <>
                        <option
                          className="form-field"
                          key={index}
                          value={item.name}
                        >
                          {item?.name}
                        </option>
                      </>
                    );
                  })}
                </select>
                <span className="fs-12 text-red">
                  {errors.stage_background_color?.type === "required" &&
                    "Stage background color is required"}
                </span>
              </div>

              <div className="col-lg-12">
                
                <div className="d-flex border-0 butn-group justify-content-center  gap-3">
                  <button
                    type="submit"
                    className="custom-btn border-0 py-2 rounded-3 raleway medium"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="custom-btn  py-2  rounded-3 border-bl-btn px-4 raleway semibold "
                    onClick={onHide}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </form>
  );
};

export default StageEdit;
