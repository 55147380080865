import React from 'react'
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useGetEmailTemplate = (page, itemsPerPage, pagination) => {

    const GetEmailTemplate = useQuery({
        queryKey: ['createdemailtemplate', page, itemsPerPage, pagination],
        queryFn: async () => {
            const res = await api.get(
                `record/email-templates/?page=${page || 1}&items_per_page=${itemsPerPage || 5}&pagination=${pagination? "off": ""}`
                );
            return res.data
        }
    })

  return GetEmailTemplate;
}

export default useGetEmailTemplate