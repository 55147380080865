import React from "react";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useProposalService = ({ proposalServiceType, date_filter }) => {
  const queryParams = {
    organisation: proposalServiceType === "organisation" ? 1 : "",
    date_filter: date_filter || "",
  };

  const queryString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "") // Filter out keys with no value
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");

  const queryresult = useQuery({
    queryKey: [`proposalservice`, { queryString }],
    keepPreviousData: true,
    queryFn: async () => {
      const res = await api.get(
        `market/proposalsent-serviceoffering?${queryString}`
      );

      return res?.data;
    },
  });

  return queryresult;
};

export default useProposalService;
