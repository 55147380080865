import React from "react";
import { Endpoints } from "../../api/Endpoints";

function PdfModal({
    url,
    onClose,
    docType
}){

    console.log('checurl__',Endpoints?.baseUrl + url)
    return(
        <>
        <div 
        className="modalpdf" 
        >
            <div 
            style={{
                width: '90%',
                height: '90%'
            }}
            // className="modal-dialog" 
            >
                <div 
                // className="modal-content"
                style={{
                    backgroundColor: 'white',
                    height: '100%'
                }}
                >
                <div className="modal-header">
                    <h5 className="modal-title"></h5>
                    <button
                    onClick={onClose}
                    type="button" className="close bg-transparent border-0 fs-24" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div
                style={{
                    height: '100%'
                }}
                // className="modal-body"
                >
                    {/* <embed 
                    src={Endpoints?.baseUrl + pdfurl} 
                    frameborder="0" 
                    width="100%" 
                    height="550px"
                    /> */}
                    {(docType=='643' || docType=='644' || docType=='645')?
                        <iframe 
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${Endpoints?.baseUrl + url}`} 
                        width="100%" 
                        height="600px" 
                        frameborder="0">
                        </iframe>
                        // :
                        // docType=="644"?
                        // <a target='_blank' href={Endpoints?.baseUrl + url}></a>
                        :
                        <embed 
                        src={Endpoints?.baseUrl + url} 
                        frameborder="0" 
                        width="100%" 
                        height="550px"
                        />
                    }
                    {/* <iframe
                    style={{
                        width: '100%',
                        // backgroundColor: 'yellow',
                        height: '100%'
                    }}
                    src={Endpoints?.baseUrl + pdfurl}
                    // src="https://www.africau.edu/images/default/sample.pdf"
                    ></iframe> */}
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default PdfModal;