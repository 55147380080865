import React from "react";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import Heading from "../../ui/modals/Heading";
import LeadForm from "../../Components/leads/LeadFormContainer";

const NewLead = () => {
  return (
    <div className="main py-4 position-relative">
      <div className="container">
      {/* <Subheader leftsideshow={<TwoButtonHolder />} /> */}
      {/* <Heading heading="Counldn't Contact Leads" /> */}
      <LeadForm />
      </div>
    </div>
  );
};

export default NewLead;
