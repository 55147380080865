import React from "react";
import api from "../../../api/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Endpoints } from "../../../api/Endpoints";
import { useNavigate } from "react-router-dom";

const useChangeStage = (id, contactId) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let data = {
    contact_ids: contactId,
    new_stage_id: id,
  };
  console.log(contactId);

  const mutation = useMutation({
    mutationFn: async () => {
      if (contactId.length <= 0) {
        throw new Error("Please Select atleast one Contact");
      }
      const res = await api.post(Endpoints.updateStage, data);
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([`leads`]);
      toast.success(data.message);
      if (id === "6") {
        navigate("/agreement/new/contact");
      }
    },
    onError: (error) => {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error(`${error["response"].data.message}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
      
    },
  });

  return mutation;
};

export default useChangeStage;
