import React, { useEffect } from "react";
import * as d3 from 'd3';
import D3Funnel from "d3-funnel";
import useBDFunnel from "../../libs/queries/dashboard/useBDFunnel";

const FunnelChart = ({datamap}) => {
//   const datamap = [
//     {
//         "label": "Could Not Contact",
//         "value": 4
//     },
//     {
//         "label": "Contact Initiated",
//         "value": 3
//     },
//     {
//         "label": "Proposal Sent",
//         "value": 5
//     },
//     {
//         "label": "Meeting Done",
//         "value": 1
//     },
//     {
//         "label": "Engagement Initiated",
//         "value": 6
//     },
//     {
//         "label": "Agreement Signed",
//         "value": 1
//     }
// ]

  const data = [...(datamap || [{ label: "Application Review", value: 1500 }])];

  const nivo = [
    "#F17E33",
    "#F1E15B",
    "#61CDBB",
    "#9CE3A0",
    "#0386C2",
    "#F47560",
    "#FEFFB3",
    "#3EC7A5",
    // Your color array here
  ];

  const chartColorScale = d3.scaleQuantize().domain([0, data?.length]).range(nivo);
  const totalData = data.reduce((a, c) => {
    return a + c.value;
  }, 0);

  const options = {
    block: {
      dynamicHeight: true,
      minHeight: 30,
      // border: {
      //   color: "red", // Set border color to red
      //   width: 1, // Set border width
      // },
      // borderStyle: 'solid', borderWidth: '5px', borderColor: 'red',
      // stroke: 'darkgreen', strokeWidth: '10px'
      // marginLeft: 40,
    },
    chart: {
      curve: {
        enabled: false,
      },
      bottomPinch: 1,
      height: 200,
      width: 500,
      // borderStyle: 'solid', borderWidth: '5px', borderColor: 'red',
      // marginTop: '100px'
      // bottomWidth: 0.2
    },
    tooltip: {
      enabled: true,
      format: (l, f) => {
        const percentage = ((f / totalData) * 100).toFixed(1);
        return `${l}: ${f}`;
      },
    },
    label: {
      fill: "#000",
      fontFamily: "Open Sans",
      fontSize: 13,
    },
  };

  const tooltipFormatter = (data) => {
    return (
      <div style={{ display: "flex", alignItems: "center", zIndex: "1000"}}>
        <div
          style={{
            width: "1px",
            height: "1px",
            marginRight: "5px",
            background: `${data.color}`,
          }}
        />
        <div>{data?.indexValue}</div>
        <div style={{ marginLeft: "5px" }}>
          <b>{data.value}</b>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const chart = new D3Funnel("#funnel");

    // const funnelData = data.sort((a, b) => b.value - a.value)?.map((row, index) => {
    const funnelData = data?.map((row, index) => {
      return [
        row.label, // label
        row.value, // value
        d3.color(row.value? chartColorScale(index): '#fff').hex(), // backgroundColor
      ];
    });
    // chart?.bottomWidth(1/5)
    chart.draw(funnelData, options);

    // const svg = d3.select("#funnel").select("svg");
    // svg.selectAll(".d3-funnel-block")
    //   .selectAll("path")
    //   .style("stroke", "red")
    //   .style("stroke-width", "2px");
  }, ); // Empty dependency array to run this effect only once

  return <div id="funnel" className="funnel-chart"/>;
};

export default FunnelChart;