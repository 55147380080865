import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  agreementid: 1,
  newagreementid: 1,
  agreementname: "",
  agreementnamechange: "Contact Department",
};

const agreementSlice = createSlice({
  name: "agreementSlice",
  initialState,
  reducers: {
    setagreementId: (state, action) => {
      state.agreementid = action.payload;
    },
    setnewagreementId: (state, action) => {
      state.newagreementid = action.payload;
    },
    setagreementname: (state, action) => {
      state.agreementname = action.payload;
    },
    setagreementnamechange: (state, action) => {
      state.agreementnamechange = action.payload;
    },
  },
});

export const {setagreementId, setnewagreementId, setagreementname,  setagreementnamechange} = agreementSlice.actions;
export default agreementSlice.reducer;
