import React from "react";
import getInputError from "../../utils/getInputError";

const DateInputBox = ({
  label,
  errors,
  value,
  onChange,
  placeholder,
  onFocus,
  name,
  disabled,
  ...res
}) => {
  let isError = getInputError(errors, name);
console.log(disabled)

  return (
    <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
      <label className="d-block text-sky fs-12 pb-2">{label}</label>
      <input
        type="date"
        disabled={disabled}
        className="form-field"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        onFocus={onFocus}
        {...res}
      />
      <div style={{ height: "10px" }}>
        {isError && <span className="fs-12 text-red">{isError?.message}</span>}
      </div>
    </div>
  );
};

export default DateInputBox;
