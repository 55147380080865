import React from "react";

const SaveButton = ({type, onClick, disabled}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className="custom-btn border-0 py-2 rounded-3 raleway medium"
      onClick={onClick}
    >
      Save
    </button>
  );
};

export default SaveButton;