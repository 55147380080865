import { useEffect, useState } from "react";
import usePrs from "../../libs/queries/graphs/usePrs";
import useUpdatePrs from "../../libs/mutation/prs/useUpdatePrs";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import RoleRestriction from "../../utils/roleRestriction";
import { Link } from "react-router-dom";
import PrsModal from "../../ui/modals/prsModal";
// import EditIcon from "../../../img/icon/edit-white.svg";

function PRS({prsData, type}){
    const [contentCreated, setContentCreated] = useState(0);
    const [delivery, setDelivery] = useState(0);
    const [indexes, setIndexes] = useState([]);
    const [indexes2, setIndexes2] = useState([]);
    const [indexes3, setIndexes3] = useState([]);
    const [tagetScore, setTargetScore] = useState(0);
    const [operationalRating, setOperationalRating] = useState(0);
    const [atsRating, setAtsRating] = useState(0);
    const [superRating, setSuperRating] = useState(0);
    const [selectedItem, setSelectedItem] = useState({});
    const [submitIndex, setSubmitIndex] = useState(null);
    const [count, setCount] = useState(0);
    const [isPrsVisible, setIsPrsVisible] = useState(false);
    const [stageName, setStageName] = useState('');
    const [stageId, setStageId] = useState('');
    const [isLeadAchieved, setIsLeadAchieved] = useState(false);
    const [filterIndex, setFilterIndex] = useState(0);
    const [isCrm, setIsCrm] = useState(false);
    const [isIndustry, setIsIndustry] = useState(false);

    const [newPrsData, setNewPrsData] = useState(prsData?.data);
    const restrict = RoleRestriction();


    const {mutate: updatePrs} = useUpdatePrs();

    const {
      register,
      formState: { errors },
      handleSubmit,
      reset,
      control,
      watch,
      getValues,
    } = useForm({defaultValues: newPrsData});

    useEffect(()=>{
      if(submitIndex){
        console.log('oeeee___', watch(`OE_total_${submitIndex}`))
        console.log('operation___', watch(`operational_rating_${submitIndex}`))
      }
    },[submitIndex])
    
    // useEffect(()=>{
    //   reset(newPrsData)
    //   console.log('abc')
    // },[newPrsData])

    // console.log('abc098')


    useEffect(()=>{
      setNewPrsData(prsData?.data);
    },[prsData]);

    console.log('abc__', newPrsData)

    // console.log('oe__', watch(`OE_total_${0}`))

    useEffect(()=>{
      if(selectedItem){
        reset({
          user : selectedItem?.user,
          month: selectedItem?.month,
          year: selectedItem?.year,
          accepted_leads_generated_target: selectedItem?.accepted_leads_generated_target,
          accepted_leads_generated_achieved:selectedItem?.accepted_leads_generated_achieved,
          proposal_submission_target: selectedItem?.proposal_submission_target,
          proposal_submission_achieved: selectedItem?.proposal_submission_achieved,
          meeting_organized_target: selectedItem?.meeting_organized_target,
          meeting_organized_achieved: selectedItem?.meeting_organized_achieved,
          assignment_recieved_target: selectedItem?.assignment_recieved_target,
          assignment_recieved_achieved: selectedItem?.assignment_recieved_achieved,
          onboarding_renewal_target: selectedItem?.onboarding_renewal_target,
          onboarding_renewal_achieved: selectedItem?.onboarding_renewal_achieved,
        })
      }
    },[selectedItem])

    const onSubmit = () => {
      const formData = getValues();
      console.log('fmmm__',formData);
      let new_obj = {};
      Object.keys(formData).forEach((item)=>{
        if(item.split('_').pop()==submitIndex){
          let arr = item.split('_');
          let new_key = arr.slice(0,arr.length-1).join('_');
          new_obj[new_key] = formData[item];

          if(new_key=="content_created_score"){
            new_obj[new_key] = calculateContentScore(
              parseFloat(watch(`content_created_number_${submitIndex}`) || prsData?.data?.[submitIndex]?.content_created_number || 0),
              parseFloat(watch(`delivery_number_${submitIndex}`) || prsData?.data?.[submitIndex]?.delivery_number || 0)
              )
            // (watch(`content_created_number_${submitIndex}`) && 
            // watch(`content_created_number_${submitIndex}`)==1) 
            // || 
            // (!watch(`content_created_number_${submitIndex}`) &&
            // prsData?.data?.[submitIndex]?.content_created_number==1)? parseInt(5) : 0
          }
          if(new_key=="delivery_score"){
            new_obj[new_key] = calculateContentScore(
              parseFloat(watch(`content_created_number_${submitIndex}`) || prsData?.data?.[submitIndex]?.content_created_number || 0),
              parseFloat(watch(`delivery_number_${submitIndex}`) || prsData?.data?.[submitIndex]?.delivery_number || 0)
              )
            // (watch(`delivery_number_${submitIndex}`) && 
            // watch(`delivery_number_${submitIndex}`)==1) 
            // || 
            // (!watch(`delivery_number_${submitIndex}`) &&
            // prsData?.data?.[submitIndex]?.delivery_number==1)? parseInt(5): 0
          }
          if(new_key=="OE_total"){
            new_obj[new_key] = (
              calculateTargetScore(
                prsData?.data?.[submitIndex]?.accepted_leads_generated_achieved,
                prsData?.data?.[submitIndex]?.proposal_submission_achieved, 
                prsData?.data?.[submitIndex]?.meeting_organized_achieved, 
                prsData?.data?.[submitIndex]?.assignment_recieved_achieved, 
                prsData?.data?.[submitIndex]?.onboarding_renewal_achieved
                ) + parseFloat(watch(`operational_rating_${submitIndex}`))
            )
          }
          if(new_key=="CD_total"){
             new_obj[new_key] = (parseFloat(watch(`compliance_rating_${submitIndex}`) || 0))+
             (parseFloat(watch(`supervisory_rating_${submitIndex}`) || 0) +
            //  ((watch(`delivery_number_${submitIndex}`) && 
            //  watch(`delivery_number_${submitIndex}`)==1) 
            //  || 
            //  (!watch(`delivery_number_${submitIndex}`) &&
            //  prsData?.data?.[submitIndex]?.delivery_number==1)? 5 : 0) +
            //  ((watch(`content_created_number_${submitIndex}`) && 
            //  watch(`content_created_number_${submitIndex}`)==1) 
            //  || 
            //  (!watch(`content_created_number_${submitIndex}`) &&
            //  prsData?.data?.[submitIndex]?.content_created_number==1)? 5 : 0)
            calculateContentScore(
              parseFloat(watch(`content_created_number_${submitIndex}`) || prsData?.data?.[submitIndex]?.content_created_number || 0),
              parseFloat(watch(`delivery_number_${submitIndex}`) || prsData?.data?.[submitIndex]?.delivery_number || 0)
              )
             )
            // (
            //   parseFloat(watch(`compliance_rating_${submitIndex}`))+
            //   parseFloat(watch(`supervisory_rating_${submitIndex}`) +
            //   ((watch(`delivery_number_${submitIndex}`) && 
            //   watch(`delivery_number_${submitIndex}`)==1) 
            //   || 
            //   (!watch(`delivery_number_${submitIndex}`) &&
            //   prsData?.data?.[submitIndex]?.delivery_number==1)? parseInt(5) : 0) +
            //   ((watch(`content_created_number_${submitIndex}`) && 
            //   watch(`content_created_number_${submitIndex}`)==1) 
            //   || 
            //   (!watch(`content_created_number_${submitIndex}`) &&
            //   prsData?.data?.[submitIndex]?.content_created_number==1)? parseInt(5) : 0)
            //   )
            // )
          }
          if(new_key=="target_achievement_score"){
            new_obj[new_key] = (calculateTargetScore(prsData?.data?.[submitIndex]?.accepted_leads_generated_achieved,
              prsData?.data?.[submitIndex]?.proposal_submission_achieved, 
              prsData?.data?.[submitIndex]?.meeting_organized_achieved, 
              prsData?.data?.[submitIndex]?.assignment_recieved_achieved, 
              prsData?.data?.[submitIndex]?.onboarding_renewal_achieved))
          }
          if(new_key=="final"){
            new_obj[new_key] = (calculateTargetScore(
              prsData?.data?.[submitIndex]?.accepted_leads_generated_achieved,
              prsData?.data?.[submitIndex]?.proposal_submission_achieved, 
              prsData?.data?.[submitIndex]?.meeting_organized_achieved, 
              prsData?.data?.[submitIndex]?.assignment_recieved_achieved, 
              prsData?.data?.[submitIndex]?.onboarding_renewal_achieved
              ) +
              (
              (parseFloat(watch(`operational_rating_${submitIndex}`)) || 0) ||
              parseFloat(prsData?.data?.[submitIndex]?.operational_rating || 0))+
            (parseFloat(watch(`compliance_rating_${submitIndex}`) || 0)+
            (parseFloat(watch(`supervisory_rating_${submitIndex}`) || 0) +
            // ((watch(`delivery_number_${submitIndex}`) && 
            // watch(`delivery_number_${submitIndex}`)==1) 
            // || 
            // (!watch(`delivery_number_${submitIndex}`) &&
            // prsData?.data?.[submitIndex]?.delivery_number==1)? parseInt(5) : 0) +
            // ((watch(`content_created_number_${submitIndex}`) && 
            // watch(`content_created_number_${submitIndex}`)==1) 
            // || 
            // (!watch(`content_created_number_${submitIndex}`) &&
            // prsData?.data?.[submitIndex]?.content_created_number==1)? 5 : 0)
            calculateContentScore(
              parseFloat(watch(`content_created_number_${submitIndex}`) || prsData?.data?.[submitIndex]?.content_created_number || 0),
              parseFloat(watch(`delivery_number_${submitIndex}`) || prsData?.data?.[submitIndex]?.delivery_number || 0)
              )
            ))
            )
          }
        }
        else if(isNaN(item.split('_').pop())){
          new_obj[item] = formData[item];
        }
      })
      console.log('new_obj',new_obj);
      updatePrs(new_obj)

    };

    const calculateTargetScore=(LGA, PSA, MOA, PAA, OAA)=> {
        let score = 0;
      
        if (LGA < 60) {
          score += 0;
        } else if (LGA < 75) {
          score += 5;
        } else {
          score += 10;
        }
      
        if (PSA < 30) {
          score += 0;
        } else if (PSA < 45) {
          score += 5;
        } else {
          score += 10;
        }
      
        if (MOA < 5) {
          score += 0;
        } else if (MOA < 6) {
          score += 5;
        } else {
          score += 10;
        }
      
        if (PAA === 1) {
          score += 5;
        } else if (PAA === 0) {
          score += 0;
        } else {
          score += 10;
        }
      
        if (OAA === 0) {
          score += 0;
        } else {
          score += 10;
        }
    
        return parseFloat(score);
      }

      const calculateContentScore=(Q5, S5)=>{
        let result = 0;

        if (Q5 === 0 && S5 === 0) {
          result = 0;
        } else if ((Q5 === 1 && S5 === 0) || (Q5 === 0 && S5 === 1)) {
          result = 5;
        } else {
          result = 10;
        }

        return parseFloat(result);
      }

      // console.log('contentscore_', calculateContentScore(0,0));
      // console.log('watccontent_', typeof(watch(`content_created_number_${0}`)))
      // console.log('watdelive_', watch(`delivery_number_${0}`))

    useEffect(()=>{
      if(count){
        handleSubmit(onSubmit)();
      }
    },[count]);
    return(
        <div>
            <table className="prs-table" width="100%">
            <tr className="prs-head">
                <td rowspan="4">parameters/month</td>
                <td rowspan="4">User</td>
                <td colspan="14">Operational Excellence(OE)</td>
                <td>Compliance</td>
                <td colspan="4">Capability Development</td>
                <td colspan="4">Total (100)</td>
                <td className="bg-white border-0"></td>
                <td className="bg-white border-0"></td>
            </tr>
            <tr className="prs-head">
                <td colspan="14">Productivity and Conversions</td>
                <td>ATS Office Time, LMS</td>
                <td  colspan="2">Content Created (Number)</td>
                <td  colspan="2">Delivery (Number)</td>
                <td >Supervising Rating</td>
                <td className="px-2" rowspan="3">OE (70)</td>
                <td rowspan="3">CD+ Compliance (30)</td>
                <td className="px-2" rowspan="3">Final (100)</td>
                <td className="bg-white border-0"></td>
                <td className="bg-white border-0"></td>
            </tr>
            <tr className="prs-head">
                <td colspan="2">Accepted Leads Genearated</td>
                <td colspan="2">Proposal Submission</td>
                <td colspan="2">Mecting/E-mecting Organised</td>
                <td colspan="2">Pilot Assignment Received</td>
                <td colspan="2">Onboarding & Agreeement & Renewal</td>
                <td className="px-2">{"CRM"}</td>
                <td>{`Industry/\nSector Ownership`}</td>
                <td rowspan="2">Target Achivement Score (50)</td>
                <td rowspan="2">Operational Rating(20)</td>
                <td rowspan="2">Rating (10)</td> 
                <td rowspan="2">Number</td>
                <td rowspan="2" className="px-1">Score (10)</td>
                <td rowspan="2">Number</td>
                <td rowspan="2" className="px-1">Score (10)</td>
                <td rowspan="2">Rating (10)</td> 
                <td className="bg-white border-0"></td>
                <td className="bg-white border-0"></td>
            </tr>
            <tr className="prs-head">
                <td>Target</td>
                <td>Achieved</td>
                <td>Target</td>
                <td>Achieved</td>
                <td>Target</td>
                <td>Achieved</td>
                <td>Target</td>
                <td>Achieved</td>
                <td>Target</td>
                <td>Achieved</td>

                <td>Number</td>
                <td>Number</td>
                <td className="bg-white border-0"></td>
                <td className="bg-white border-0"></td>
            </tr>
            {
                newPrsData?.length>0 && newPrsData?.map((item, index)=>{
                    return(
                        <tr key={index}>
                        <td>{item?.month_name}</td>
                        <td>{item?.user_fullname}</td>
                        <td>{item?.accepted_leads_generated_target}</td>

                        <td>
                          <div 
                          onClick={()=>{
                            setFilterIndex(index);
                            setIsPrsVisible(true);
                            setStageName('Lead Generated');
                            setIsLeadAchieved(true);
                            setIsCrm(false);
                            setIsIndustry(false);
                          }}
                            className="selectContainer"
                            style={{
                              color: '#3366CC'
                            }}
                          >
                            {item?.accepted_leads_generated_achieved}
                           </div> 
                        </td>

                        <td>{item?.proposal_submission_target}</td>

                        <td>
                          <div 
                          onClick={()=>{
                            setFilterIndex(index);
                            setIsPrsVisible(true);
                            setStageName('Proposal Sent');
                            setStageId(prsData?.stage_data?.PROPOSAL_SENT_STAGE);
                            setIsCrm(false);
                            setIsLeadAchieved(false);
                            setIsIndustry(false);
                          }}
                            className="selectContainer"
                            style={{
                              color: '#3366CC'
                            }}
                            >{item?.proposal_submission_achieved}
                          </div>  
                        </td>
                        <td>{item?.meeting_organized_target}</td>

                        <td>
                          <div 
                          onClick={()=>{
                            setFilterIndex(index);
                            setIsPrsVisible(true);
                            setStageName('Meeting Organized');
                            setStageId(prsData?.stage_data?.MEETING_ORGANIZED_STAGE);
                            setIsCrm(false);
                            setIsLeadAchieved(false);
                            setIsIndustry(false);
                          }}
                          className="selectContainer"
                          style={{
                            color: '#3366CC'
                          }}
                          >{item?.meeting_organized_achieved}
                          </div>
                        </td>

                        <td>{item?.assignment_recieved_target}</td>

                        <td>
                          <div 
                          onClick={()=>{
                            setFilterIndex(index);
                            setIsPrsVisible(true);
                            setStageName('Pilot Received');
                            setStageId(prsData?.stage_data?.PILOT_RECEIVED_STAGE);
                            setIsCrm(false);
                            setIsLeadAchieved(false);
                            setIsIndustry(false);
                          }}
                            className="selectContainer"
                            style={{
                              color: '#3366CC'
                            }}
                            >{item?.assignment_recieved_achieved}
                          </div>
                        </td>

                        <td>{item?.onboarding_renewal_target}</td>
                        <td>
                          <div 
                          onClick={()=>{
                            setFilterIndex(index);
                            setIsPrsVisible(true);
                            setStageName('Onboarding Renewal');
                            setStageId(prsData?.stage_data?.ONBOARDING_RENEWAL_STAGE)
                            setIsCrm(false);
                            setIsLeadAchieved(false);
                            setIsIndustry(false);
                          }}
                            className="selectContainer"
                            style={{
                              color: '#3366CC'
                            }}
                            >{item?.onboarding_renewal_achieved}
                            </div>
                        </td>
                        <td 
                        >
                          <div
                          onClick={()=>{
                            setFilterIndex(index);
                            setIsPrsVisible(true);
                            setStageName('CRM');
                            setIsCrm(true);
                            setIsLeadAchieved(false);
                            setIsIndustry(false);
                          }}
                          >
                            <Controller
                            name={`crm_${index}`}
                            control={control}
                            defaultValue={item?.crm}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                // disabled={indexes3.includes(index)?false:true}
                                type="number"
                                min={0}
                                value={field.value}
                                onChange={field.onChange}
                                // onClick={()=>{
                                //   setFilterIndex(index);
                                //   setIsPrsVisible(true);
                                //   setStageName('CRM');
                                //   setIsCrm(true);
                                // }}
                                className="bg-transparent border-0 selectContainer no-spinners"
                                style={{
                                  color: '#3366CC',
                                  // WebkitAppearance: 'none', margin: 0
                                }}
                              />
                            )}
                            />
                            </div>
                        </td>
                        <td>
                        <div
                          onClick={()=>{
                            setFilterIndex(index);
                            setIsPrsVisible(true);
                            setStageName('Industry Sector');
                            setIsLeadAchieved(false);
                            setIsCrm(false);
                            setIsIndustry(true);
                          }}
                          >
                            <Controller
                            name={`industry_sector_ownership_${index}`}
                            control={control}
                            defaultValue={item?.industry_sector_ownership}
                            render={({ field }) => (
                              <input
                              style={{
                                color: '#3366CC'
                              }}
                                name={field.name}
                                type="number"
                                min={0}
                                value={field.value}
                                onChange={field.onChange}
                                className="bg-transparent border-0 selectContainer no-spinners"
                              />
                            )}
                            />
                            </div>
                        </td>
                        <td>
                           {/* {calculateTargetScore(
                            item?.accepted_leads_generated_achieved,
                            item?.proposal_submission_achieved, 
                            item?.meeting_organized_achieved, 
                            item?.assignment_recieved_achieved, 
                            item?.onboarding_renewal_achieved
                            )} */}
                          <Controller
                          name={`target_achievement_score_${index}`}
                          defaultValue={item?.target_achievement_score}
                          control={control}
                            render={({ field }) => (
                            <input
                              name={field.name}
                              disabled={true}
                              value={calculateTargetScore(
                                item?.accepted_leads_generated_achieved,
                                item?.proposal_submission_achieved, 
                                item?.meeting_organized_achieved, 
                                item?.assignment_recieved_achieved, 
                                item?.onboarding_renewal_achieved
                                )}
                              onChange={field.onChange}
                              className="bg-transparent border-0"
                            />
                          )}
                          />
                        </td>
                        <td style={{
                          backgroundColor: indexes3?.includes(index)? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                            
                            <Controller
                            name={`operational_rating_${index}`}
                            // name={selectedItem.id==item.id ? `operational_rating` : ''}
                            // name={"operational_rating"}
                            control={control}
                            defaultValue={item?.operational_rating}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                // name={`operational_rating_${index}`}
                                disabled={indexes3.includes(index)?false:true}
                                type="number"
                                min={0}
                                max={20}
                                value={field.value}
                                onChange={(e)=>{
                                  if(e.target.value>=0 && e.target.value<=20){
                                    field.onChange(e);
                                  }                                
                                }}
                                className="bg-transparent border-0 no-spinners"
                              />
                            )}
                            />
                        </td>
                        <td style={{
                          backgroundColor: indexes3?.includes(index)? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                          {/* {console.log('4errrr___',index + " " + item?.compliance_rating)} */}
                            <Controller
                            name={`compliance_rating_${index}`}
                            control={control}
                            // defaultValue={99}
                            defaultValue={item.compliance_rating}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={indexes3.includes(index)?false:true}
                                type="number"
                                min={0}
                                value={field.value}
                                // onChange={field.onChange}
                                onChange={(e)=>{
                                  if(e.target.value>=0 && e.target.value<=10){
                                    field.onChange(e);
                                  }                                
                                }}
                                className="bg-transparent border-0 no-spinners"
                              />
                            )}
                            />
                        </td>
                        <td style={{
                          backgroundColor: indexes3?.includes(index)? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                            <Controller
                            name={`content_created_number_${index}`}
                            control={control}
                            defaultValue={parseFloat?.(item?.content_created_number)}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={indexes3.includes(index)?false:true}
                                type="number"
                                min={0}
                                value={field.value}
                                onChange={field.onChange}
                                className="bg-transparent border-0 no-spinners"
                              />
                            )}
                            />
                        </td>
                        <td>
                          {<Controller
                          name={`content_created_score_${index}`}
                          control={control}
                            render={({ field }) => (
                            <input
                              name={field.name}
                              disabled={true}
                              type="number"
                              min={0}
                              // value={(watch(`content_created_number_${index}`) && 
                              // watch(`content_created_number_${index}`)==1) 
                              // || 
                              // (!watch(`content_created_number_${index}`) &&
                              // item?.content_created_number==1)? parseInt(5) : 0}
                              value={calculateContentScore(
                                parseFloat(watch(`content_created_number_${index}`) || item?.content_created_number || 0),
                                parseFloat(watch(`delivery_number_${index}`) || item?.delivery_number || 0)
                                )}
                              onChange={field.onChange}
                              className="bg-transparent border-0 no-spinners"
                            />
                          )}
                          />}
                          {/* {(watch(`content_created_number_${index}`) && 
                          watch(`content_created_number_${index}`)==1) 
                          || 
                          (!watch(`content_created_number_${index}`) &&
                          item?.content_created_number==1)? 5 : 0} */}
                        </td>
                        <td
                        style={{
                          backgroundColor: indexes3?.includes(index)? 'rgb(200,200,200)' : '#e9ebf4'
                        }}
                        >
                          <Controller
                            name={`delivery_number_${index}`}
                            control={control}
                            defaultValue={item?.delivery_number}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={indexes3.includes(index)?false:true}
                                type="number"
                                min={0}
                                value={field.value}
                                onChange={field.onChange}
                                className="bg-transparent border-0 no-spinners"
                              />
                            )}
                          />
                        </td>
                        <td>
                          {/* {delivery==1 && indexes2.includes(index)?5:0} */}
                          {<Controller
                          name={`delivery_score_${index}`}
                          control={control}
                            render={({ field }) => (
                            <input
                              name={field.name}
                              disabled={true}
                              type="number"
                              min={0}
                              // value={(watch(`delivery_number_${index}`) && 
                              // watch(`delivery_number_${index}`)==1) 
                              // || 
                              // (!watch(`delivery_number_${index}`) &&
                              // item?.delivery_number==1)? parseInt(5) : 0}
                              value={calculateContentScore(
                                parseFloat(watch(`content_created_number_${index}`) || item?.content_created_number || 0),
                                parseFloat(watch(`delivery_number_${index}`) || item?.delivery_number || 0)
                                )}
                              onChange={field.onChange}
                              className="bg-transparent border-0 no-spinners"
                            />
                          )}
                          />}
                        </td>
                        <td style={{
                          backgroundColor: indexes3?.includes(index)? 'rgb(200,200,200)' : '#e9ebf4'
                        }}>
                            <Controller
                            name={`supervisory_rating_${index}`}
                            control={control}
                            defaultValue={item?.supervisory_rating}
                            render={({ field }) => (
                              <input
                                name={field.name}
                                disabled={indexes3.includes(index)?false:true}
                                type="number"
                                min={0}
                                value={field.value}
                                onChange={(e)=>{
                                  if(e.target.value>=0 && e.target.value<=10){
                                    field.onChange(e);
                                  }                                
                                }}
                                className="bg-transparent border-0 no-spinners"
                              />
                            )}
                            />
                        </td>
                        <td>
                            {/* {calculateTargetScore(
                            item?.accepted_leads_generated_achieved,
                            item?.proposal_submission_achieved, 
                            item?.meeting_organized_achieved, 
                            item?.assignment_recieved_achieved, 
                            item?.onboarding_renewal_achieved
                            ) + parseFloat(operationalRating)} */}
                          {
                          <Controller
                          name={`OE_total_${index}`}
                          defaultValue={item?.OE_total}
                          control={control}
                            render={({ field }) => (
                            <input
                              name={field.name}
                              disabled={true}
                              value={calculateTargetScore(
                                item?.accepted_leads_generated_achieved,
                                item?.proposal_submission_achieved, 
                                item?.meeting_organized_achieved, 
                                item?.assignment_recieved_achieved, 
                                item?.onboarding_renewal_achieved
                                ) + ((
                                parseFloat(watch(`operational_rating_${index}`) || 0)) ||
                                parseFloat(item?.operational_rating || 0))}
                              onChange={field.onChange}
                              className="bg-transparent border-0"
                            />
                          )}
                          />}
                        </td>
                        <td>
                            {/* {
                                parseFloat(atsRating) + 
                                (delivery==1 && indexes2.includes(index)?5:0) +
                                (contentCreated==1 && indexes.includes(index)?5:0) +
                                parseFloat(superRating)
                            } */}
                          <Controller
                          name={`CD_total_${index}`}
                          defaultValue={item?.CD_total}
                          control={control}
                            render={({ field }) => (
                            <input
                              name={field.name}
                              disabled={true}
                              value={(parseFloat(watch(`compliance_rating_${index}`) || 0))+
                              (parseFloat(watch(`supervisory_rating_${index}`) || 0) +
                              // (
                              //   (watch(`delivery_number_${index}`) && 
                              // watch(`delivery_number_${index}`)==1) 
                              // || 
                              // (!watch(`delivery_number_${index}`) &&
                              // item?.delivery_number==1)? 5 : 0
                              // ) 
                              // +
                              // ((watch(`content_created_number_${index}`) && 
                              // watch(`content_created_number_${index}`)==1) 
                              // || 
                              // (!watch(`content_created_number_${index}`) &&
                              // item?.content_created_number==1)? 5 : 0)
                              calculateContentScore(
                                parseFloat(watch(`content_created_number_${index}`) || item?.content_created_number || 0),
                                parseFloat(watch(`delivery_number_${index}`) || item?.delivery_number || 0)
                                )
                              )
                              }
                              onChange={field.onChange}
                              className="bg-transparent border-0"
                            />
                          )}
                          />
                        </td>
                        <td>
                            {/* {
                                (calculateTargetScore(
                                item?.accepted_leads_generated_achieved,
                                item?.proposal_submission_achieved, 
                                item?.meeting_organized_achieved, 
                                item?.assignment_recieved_achieved, 
                                item?.onboarding_renewal_achieved
                                ) + parseFloat(operationalRating)) +
                                (parseFloat(atsRating) + 
                                (delivery==1 && indexes2.includes(index)?5:0) +
                                (contentCreated==1 && indexes.includes(index)?5:0) +
                                parseFloat(superRating))
                            } */}
                          <Controller
                          name={`final_${index}`}
                          defaultValue={item?.final}
                          control={control}
                            render={({ field }) => (
                            <input
                              name={field.name}
                              disabled={true}
                              value={
                                (calculateTargetScore(
                                  item?.accepted_leads_generated_achieved,
                                  item?.proposal_submission_achieved, 
                                  item?.meeting_organized_achieved, 
                                  item?.assignment_recieved_achieved, 
                                  item?.onboarding_renewal_achieved
                                  )
                                  + ((
                                    parseFloat(watch(`operational_rating_${index}`) || 0)) ||
                                    parseFloat(item?.operational_rating || 0))+
                                (parseFloat(watch(`compliance_rating_${index}`) || 0)+
                                parseFloat(parseFloat(watch(`supervisory_rating_${index}`) || 0) +
                                // ( (parseFloat(watch(`delivery_number_${index}`))==1?5:0) || 
                                // (item?.delivery_number==1)? 5 : 0) +
                                // ((watch(`content_created_number_${index}`) && 
                                // watch(`content_created_number_${index}`)==1) 
                                // || 
                                // (!watch(`content_created_number_${index}`) &&
                                // item?.content_created_number==1)? 5 : 0)
                                calculateContentScore(
                                  parseFloat(watch(`content_created_number_${index}`) || item?.content_created_number || 0),
                                  parseFloat(watch(`delivery_number_${index}`) || item?.delivery_number || 0)
                                  )
                                ))
                                )
                              }
                              onChange={field.onChange}
                              className="bg-transparent border-0"
                            />
                          )}
                          />
                        </td>

                        {(restrict==='admin' || restrict==='practice') 
                        && <td className="bg-white border-0">
                          <a
                          style={{
                            width: '20px',
                            height: '20px'
                          }}
                            className="selectContainer action-icon ms-auto"
                            // onClick={() => setEdit(true)}
                            onClick={()=>{
                              setIndexes3([...indexes3, index]);
                              setSelectedItem(item)
                            }}
                          >
                            <img
                            style={{
                              width: '10px',
                              height: '10px'
                            }}
                            src={"assets/img/icon/edit-white.svg"} />
                          </a>
                        </td>}

                        {(restrict==='admin' || restrict==='practice') 
                        && <td className="bg-white border-0">
                          <a
                          style={{
                            width: '20px',
                            height: '20px'
                          }}
                            className="selectContainer action-icon ms-auto"
                            onClick={()=>{
                              if(indexes3.includes(index)){
                                setSubmitIndex(index);
                                setCount(count+1);
                                setIndexes3((v)=>v.filter((ite)=>ite!==index));
                              }
                              else{
                                toast.error('Please Edit Some Values to Save')
                              }
                            }}
                            >
                            <img
                            src={"assets/img/lock.png"} />
                          </a>
                        </td>}
                        </tr>
                    )
                })
            }
        </table>

        {
        isPrsVisible &&
         (
              <PrsModal
                onCancel={() => setIsPrsVisible(false)}
                stageName={stageName}
                stageId={stageId}
                leadOwner={newPrsData?.[filterIndex]?.user}
                month={newPrsData?.[filterIndex]?.month}
                year={newPrsData?.[filterIndex]?.year}
                isCrm={isCrm}
                isLeadAchieved={isLeadAchieved}
                type={type}
                isIndustry={isIndustry}
                // data={}
              />
            )}
        </div>
    )
}

export default PRS;