import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import api from "../../../api";
import { Endpoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useCreateLocation = ()=> {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const createLocationMutation = useMutation({
        mutationKey: ['createlocation'],
        mutationFn: async(data)=> {
            let resp = await api.post(Endpoints.createLocation, data);
            return resp.data
        },
        onSuccess: (data)=>{
        queryClient.invalidateQueries(["locationlist"]);
            if(data.status){
                toast.success(data.message);
                navigate('/manage/locations')
            }
        },
        onError: (error)=>{
            toast.error(`${error['response'].data.message}`);
        }
    });
    return createLocationMutation;
}

export default useCreateLocation;