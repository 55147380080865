import React, { useEffect, useRef, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useKeyPress } from "react-use";
import zoomPlugin from 'chartjs-plugin-zoom';
import Zoom from "chartjs-plugin-zoom";

const PieChart = ({datalabel = [], datacolor = [], data = [] }) => {
  const chartRef = useRef(null);

  ChartJS.register(ArcElement, Tooltip, Legend, zoomPlugin);

  const options = {
    labels: datalabel?.map((item) => item),
    datasets: [
      {
        data: data?.map((item) => item),
        backgroundColor: datacolor?.map((item) => item)
      },
    ],
  };

  const options2 = {
    maintainAspectRatio: false,
  };

  return (
    <div style={{ width: "350px" }}>
      <Pie style={{
        width: '350px',
        height: '350px'
      }} ref={chartRef} data={options} 
      options={options2} 
      />
    </div>
  );
};

export default PieChart;
