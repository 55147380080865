import React, { useState } from "react";
import Subheader from "../../ui/modals/Subheader";
import PlusButton from "../../ui/buttons/PlusButton";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import useProfile from "../../libs/queries/profile/useProfile";
import Heading from "../../ui/modals/Heading";
import TableContainer from "../../Components/leads/TableContainer";
import useGetLeadListContact from "../../libs/queries/leads/useGetLeadListContact";
import { useNavigate } from "react-router-dom";
import Pagination from "../../ui/pagination";
import ViewAllSort from "../../ui/dropdown/ViewAllFilter";
import Search from "../../ui/search/Search";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useSelector } from "react-redux";

const ViewAll = () => {
  const [type, setType] = useState("my");
  const [pageToLoad, SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [stageId, setStageId] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { data: userprofile } = useProfile();
  const [search, setSearch] = useState("");
  
  const navigate = useNavigate();
  
  // const searchkey = JSON.parse(localStorage.getItem("search"))
  const [searchData] = useLocalStorage("globalsearch");
  const { searchSlice } = useSelector((state) => state.searchSlice);
 

  const { data: listcontact } = useGetLeadListContact({
    page: pageToLoad,
    itemsPerPage: itemsPerPage,
    id: stageId,
    leadType: type,
    search: search?.length > 0  ? search : searchSlice?.source == "lead" ? searchSlice?.search : "" 
  });
  // console.log(listcontact?.results.map((item) => item.name))


  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <Subheader
          leftsideshow={
          <TwoButtonHolder 
          onSelect={setType} 
          label={"Leads"} 
          />}
         
          rightsideshow={
            userprofile?.role_display === "Enabling Function" ? (
              ""
            ) : (
              <PlusButton name="New Lead" navto="/leads/new" />
            )
          }
        />
        <Heading
        search={<Search onSearch={setSearch} />} 
        heading="Active Leads" 
        // isLocation={true} 
        showSortt={setIsOpen}
        sortComponent={
        <ViewAllSort
        onStageSelect={setStageId}
        showSort={isOpen}
        /> 
        }
        />
        <Pagination
          onPageChange={SetPageToLoad}
          onItemsChanged={setItemsPerPage}
          totalPages={Math.ceil(listcontact?.count / itemsPerPage)}
          defaultCount={itemsPerPage}
        />
        <TableContainer listcontact={listcontact} itemsPerPage={itemsPerPage} pageToLoad={pageToLoad} type={type}/>
      </div>
    </div>
  );
};

export default ViewAll;
