import React from "react";
import PlusIcon from "../../img/icon/plus.svg";
import { useState } from "react";
import { useEffect } from "react";
import useProfile from "../../libs/queries/profile/useProfile";
import RoleRestriction from "../../utils/roleRestriction";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNavigateSource } from "../../slice/leadviewSlice";

const TwoButtonHolder = ({ label, onSelect, isAgreement, isPrs }) => {
  const location = useLocation();
  const restrict = RoleRestriction();
  const [selected, setSelected] = useState(location.state?.view || "my");
  const { data } = useProfile();
  const navigate = useNavigate();
  const { navigatesource } = useSelector((state) => state.leadviewSlice);
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      (restrict === "admin" && window.location.pathname.includes("lead")) ||
      (restrict === "relation" &&
        window.location.pathname.includes("agreement")) ||
      ((restrict === "researcher" || restrict === "admin") &&
        label === "CRM") ||
      ((restrict === "relation" ||
        restrict === "BD" ||
        restrict === "practice") &&
        window.location.pathname.includes("documentrepository")) ||
      ((restrict === "researcher" || restrict === "admin") &&
        window.location.pathname.includes("alerts")) ||
      (restrict === "admin" && window.location.pathname.includes("reports")) ||
      (isPrs && restrict != "researcher" && restrict != "BD") || 
      (navigatesource === "organisation" && window.location.pathname.includes('leads'))
    ) {
      setSelected("organisation");
    }
  }, [restrict, isPrs]);

  useEffect(() => {
    onSelect?.(selected);
    
  }, [selected]);


  return (
    <>
      <div className="col-sm-6 pb-sm-0 pb-3">
        {((!isPrs &&
          ((window.location.pathname.includes("lead") &&
            restrict !== "admin") ||
            // (window.location.pathname.includes("agreement") &&
            //   restrict !== "relation") ||
            (window.location.pathname=="/agreement" &&
              restrict !== "relation") ||
            (window.location.pathname.includes("reports") &&
              restrict !== "admin") ||
            (window.location.pathname.includes("documentrepository") &&
              restrict !== "BD" &&
              restrict !== "relation" &&
              restrict !== "practice") ||
            (window.location.pathname.includes("alerts") &&
              (restrict !== "admin" &&
              restrict !== "researcher")) ||
            (window.location.pathname.includes("crm") &&
              restrict !== "researcher" &&
              restrict !== "admin") ||
            (window.location.pathname.includes("") && label == "Dashboard") ||
            (window.location.pathname.includes("calendar") &&
              label == "Calendar"))) ||
          (isPrs && (restrict == "researcher" || restrict == "BD"))) && (
          <a
            onClick={() => {
              setSelected("my");
            }}
            className={
              selected == "my"
                ? "custom-btn sm-btn bg-white text-sky selectContainer"
                : "text-black2 fs-12 medium ps-3 selectContainer"
            }
          >
            {"My " + label}
          </a>
        )}

        

        {/* {(label != "Calendar" || ((window.location.pathname.includes('alerts') && restrict != "BD"))) && ( */}
        {label=='Calendar' || (window.location.pathname.includes('alerts') && restrict=='BD') ? null :
          <span
            style={{
              marginLeft: selected == "organisation" ? "10px" : "",
            }}
            onClick={() => {
              setSelected("organisation");
            }}
            className={
              selected == "organisation"
                ? "custom-btn sm-btn bg-white text-sky selectContainer"
                : "text-black2 fs-12 medium ps-3 selectContainer"
            }
          >
            Organisation {label}
          </span>
        }
        {window.location.pathname.includes("viewall") && (
          <span
            onClick={() => navigate("/leads/")}
            style={{ marginLeft: "10px" }}
            className="custom-btn sm-btn text-white selectContainer"
          >
            Back
          </span>
        )}
      </div>
    </>
  );
};

export default TwoButtonHolder;
