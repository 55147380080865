import React from "react";
import useToggle from "../../hooks/useToggle";
import CrmListModal from "./CrmListModal";
import EmailModal from "../../ui/modals/EmailModal";
import { useState } from "react";

const SingleCrmList = ({ data }) => {
  const {id, client_name, mobile_contact, spoc_name, lead_contact } = data || {};
  const [detail, toggleDetail] = useToggle(false);
  const [emailView, setEmailView] = useState(false);
  const [contactId, setContactId] = useState('');
  // console.log(data)

  return (
    <div className="bg-white rounded-10 px-md-4 px-3 py-3 mb-3 shadow-sm">
      <table className="action-table w-100">
        <tbody>
          <tr>
            <td className="action-first">
              <div className="d-flex align-items-center gap-lg-4 gap-2">
                <input type="checkbox" />
                {/* <img
                  src="assets/img/hdfc.png"
                  width="48"
                  height="48"
                  className="rounded-3"
                  alt=""
                /> */}
              </div>
            </td>

            <td className="action-mid">
              <h6 className="fs-12 text-sky regular  pb-md-2">Client Name</h6>
              <h5>{client_name}s</h5>
            </td>
            <td
              className="action-mid"
              style={{ textAlign: "left", width: "200px" }}
            >
              <h6 className="fs-12 text-sky regular  pb-md-2">Spoc Name</h6>
              <h5>{spoc_name}</h5>
            </td>
            <td
              className="action-mid"
              style={{ textAlign: "left", width: "200px" }}
            >
              <h6 className="fs-12 text-sky regular  pb-md-2">Contact</h6>
              <h5>{mobile_contact}</h5>
            </td>
            <td className="action-last">
              <div className="d-flex align-items-center gap-xl-3 gap-2 justify-content-end">
                <a
                  onClick={() => {
                    setContactId(lead_contact);
                    setEmailView(true);
                  }}
                  className="selectContainer custom-btn medium sm-btn fs-16"
                >
                  Mail
                </a>

                {/* <a
                href="#"
                className="action-icon bg-red"
                data-bs-toggle="modal"
                data-bs-target="#delete"
              >
                <img src="assets/img/icon/trash.svg" />
              </a> */}

                <a
                  // href="#"
        
                  className="acco-info-btn selectContainer"
                  onClick={() => toggleDetail()}
                >
                  <i className="fa-solid fa-angle-down fs-20 align-middle"></i>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {detail && <CrmListModal data={data} />}
      {emailView && (
        <EmailModal
          // email={email}
          crmid={id}
          contactId={contactId}
          onClose={() => setEmailView(false)}
        />
      )}
    </div>
  );
};

export default SingleCrmList;
