import React from "react";
import api from "../../../api/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Endpoints } from "../../../api/Endpoints";

const useDeleteEmailAttachment = () => {
  const queryClient = useQueryClient();
  const deleteEmailAttachment = useMutation({
    mutationFn: async (id) => {
      const res = await api.get(
        Endpoints.deleteEmailAttachement + id
      );
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("createdemailtemplate");
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
    // enabled: id ? true : false,
  });

  return deleteEmailAttachment;
};

export default useDeleteEmailAttachment;
