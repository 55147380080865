import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

const BarChart = ({ data = [], datalabel = [] ,datacolor = [], dataOnboarded }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  // console.log('data_', JSON.stringify(data, null,4))
  // console.log('datamax_', Math.max(...data))

  const dataconfig = {
    labels: datalabel?.map((item) => item),
    datasets: [
      {
        backgroundColor: datacolor?.map((item) => item),
        data: data?.map((item) => item),
      },
    ],
    options: {
      scales: {
        y: {
          display: false,
          min: 0,
          max: 55,
        },
      },
      plugins: {
        datalabels: {
          display: true,
          formatter: (value, context) => {
            return value;
          },
          anchor: "end",
          align: "start",
          offset: -20,
        },
      },
    },
  };

  const optionsKg = {
    maintainAspectRatio: false,
    plugins: {
      legend: false,
      tooltip: {
        callbacks: {
          label: (context) => {
            const costvalueKg = context.formattedValue + "";
            return costvalueKg;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            // return Math.floor(value);
            if (Number.isInteger(value)) {
                  return value;
                }
                return null;
          }
        },
        min: 0,
        // max: 30,
        max: Math.max(...data),
        grid: {
          display: true,
        },
        display: true,
      },

      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  const data2 = {
    labels: dataOnboarded?.labels,
    datasets: dataOnboarded?.datasets?.map((item)=>{
      return({
        label: item?.name,
        data: item?.data,
        backgroundColor: item?.color
      })
    }),
    // [
    //   {
    //     label: 'Rpo',
    //     backgroundColor: 'rgba(75, 192, 192, 0.2)', // Color for the bars
    //     borderColor: 'rgba(75, 192, 192, 1)', // Border color
    //     borderWidth: 1, // Border width
    //     data: [65, 59, 80, 81, 56, 55, 40, 32, 22, 45, 75, 95], // Data for RPO
    //   },
    //   {
    //     label: 'DNi',
    //     backgroundColor: 'rgba(255, 99, 132, 0.2)', // Color for the bars
    //     borderColor: 'rgba(255, 99, 132, 1)', // Border color
    //     borderWidth: 1, // Border width
    //     data: [45, 55, 70, 40, 72, 60, 50, 42, 28, 60, 80, 90], // Data for DNI
    //   },
    // ],
  };

  return (
    <div>
      
      {dataOnboarded? 
      (dataOnboarded?.datasets?.length>0? <Bar
        data={data2}
        options={{
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function(value) {
                  // return Math.floor(value);
                  if (Number.isInteger(value)) {
                        return value;
                      }
                      return null;
                }
              }
            },
          },
        }}
      />: 
      <div className="d-flex justify-content-center">No data</div>
      )
      :
      <Bar
      style={{width: '100%', height: '300px'}}
        // style={{ height: "200px", width: "200px" }}
        options={optionsKg}
        data={dataconfig}
      />
      }
    </div>
  );
};

export default BarChart;
