import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import useUpdateLocation from "../../../libs/mutation/location/useUpdateLocation";

const LocationEdit = ({ data, index, onHide }) => {
    const {id ,city, state} = data || {}

  const { mutate: updateLocation, isLoading } = useUpdateLocation(
    id,
    onHide
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm();

  return (
    <form
      onSubmit={handleSubmit(updateLocation)}
      className="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3 edit-info-table "
    >
      <table className="action-table w-100 manage-table" style={{marginLeft: "70px", marginRight: "70px"}}>
        <tr>
          <td className="action-first">
            <span className="number d-flex align-items-center justify-content-center rounded-circle fs-24 semibold">
              {index + 1}
            </span>
          </td>

          <td className="action-mid">
            <div className="row pe-xl-5">
              

              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="pb-3">
                  <h6 className="fs-12 text-sky regular  pb-1">State</h6>
                  <input
                    defaultValue={state}
                    name="state"
                    type="text"
                    placeholder=""
                    {...register("state", { required: true })}
                  />
                  <span className="fs-12 text-red">
                  {errors.state?.type === "required" &&
                    "State is required"}
                  </span>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="pb-3">
                  <h6 className="fs-12 text-sky regular  pb-1">
                    City
                  </h6>
                  <input
                   defaultValue={city}
                    name="city"
                    type="text"
                    placeholder=""
                    {...register("city", { required: true })}
                  />

                  <span className="fs-12 text-red">
                  {errors.city?.type === "required" &&
                    "City is required"}
                  </span>
                </div>
              </div>

              <div className="col-lg-12">
                
                <div className="d-flex border-0 butn-group justify-content-center  gap-3">
                  <button
                    type="submit"
                    className="custom-btn border-0 py-2 rounded-3 raleway medium"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="custom-btn  py-2  rounded-3 border-bl-btn px-4 raleway semibold "
                    onClick={onHide}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </form>
  );
};

export default LocationEdit;
