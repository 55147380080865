import api from '../../../api/index'
import { useMutation } from '@tanstack/react-query'
import { toast } from "react-toastify";

const useLogout = () => {
    const refresh_token = localStorage.getItem("refresh_token");
   const data={
    refresh_token
   }

const logoutMutation = useMutation({
    
    mutationFn: async() => {
        const res = await api.post("account/logout/", data);
        return res.data
    },
    onSuccess: (data) => {
        localStorage.clear()
        window.location.href = "/login"
    },
    onError: (error) => {
        toast.error(`${error['response'].data.message}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
    }
})

  return logoutMutation
}

export default useLogout