import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dateType: "day"
  };

  const dashboardSlice = createSlice({
    name: "dashboardSlice",
    initialState,
    reducers: {
        setDateType: (state, action) => {
          state.dateType = action.payload;
        },
       
      },
  })

  export const { setDateType } = dashboardSlice.actions;
  export default dashboardSlice.reducer;