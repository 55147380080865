import React, { useEffect, useState } from "react";
/// Icons Or Images import ///
import TrashIcon from "../../../img/icon/trash.svg";

/// Imports of Component ///
import useToggle from "../../../hooks/useToggle";
import LocationModal from "./LocationModal";
// import DeleteLead from './DeleteLead';

const ListLocation = ({ index, data, statecheck, statecheckhandler, selectedAll }) => {
  const { id, state, city, created_at } = data || {};
  // console.log("this is location",data)
  const [detail, toggleDetail] = useToggle(false);
  const [dele, setdele] = useState(false);
 

  const date = created_at.substring(0, 16);
  const datefinal = date.split("T").join(" ");
  const time = datefinal.substring(11, 13);

  // console.log('selectedall++', selectedAll)

  useEffect(()=>{
    if(selectedAll?.length){
      statecheckhandler(selectedAll)
    }
    else{
      statecheckhandler([])
    }
  },[selectedAll?.length])

  const handleAddCheckbox = (event, id) => {
    // console.log(id);
    if (event.target.checked) {    
      statecheckhandler([...statecheck,id])

    } else {
      const updatestatecheck=statecheck.filter(item=>item!==id);
      statecheckhandler(updatestatecheck);
     
    }
  };


  return (
    <div>
      <div className="bg-white rounded-10 px-md-4 px-3 py-3 mb-3 shadow-sm">
        <table className="action-table w-100  manage-table">
          <tbody>
            <tr>
              <td className="action-first">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  className="align-middle"
                  checked={statecheck.filter(item=>item==id).length > 0 ? true : false}
                  onClick={(event) => handleAddCheckbox(event, id)}
                />
              </td>

              <td className="action-first">
                <span className="number d-flex align-items-center justify-content-center rounded-circle fs-24 semibold">
                  {index + 1}
                </span>
              </td>

              <td className="action-mid">
                <h6 className="fs-12 text-sky regular  pb-md-2">Name</h6>
                <h5>{city + ", " + state}</h5>
              </td>

              <td className="action-mid" style={{ width: "200px" }}>
                <h6 className="fs-12 text-sky regular  pb-md-2">Created At</h6>
                <h5>
                  {datefinal} {time >= 12 ? "PM" : "AM"}
                </h5>
              </td>

              <td className="action-last">
                <div className="d-flex align-items-center gap-xl-3 gap-2 justify-content-end">
                  {/* <a
                  className="action-icon bg-red"
                  onClick={() => setdele(true)}
                >
                  <img src={TrashIcon} />
                </a> */}
                  <a className="selectContainer acco-info-btn" onClick={() => toggleDetail()}>
                    <i className="fa-solid fa-angle-down pe-none fs-20 align-middle"></i>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {detail && <LocationModal index={index} data={data} />}
      {/* {dele && <DeleteLead onClose={() => setdele(false)} id={id} /> } */}
    </div>
  );
};

export default ListLocation;
