import { Endpoints } from "../../../api/Endpoints";
import api from "../../../api/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const useUpdateResearchPrs = () => {
  const queryClient = useQueryClient();
  const updateResearcherPrs = useMutation({
    mutationFn: async (data) => {
      // if(!data?.week){
      //   delete data?.week
      // }
      if(!data?.name_of_the_projects){
        delete data?.name_of_the_projects
      }
      if(!data?.progress){
        delete data?.progress
      }
      if(!data?.review_remark){
        delete data?.review_remark
      }
      // if(!data?.content_created_score){
      //   delete data?.content_created_score
      // }
      let obj = {
        ...data,
        week: data?.week || 0,
        content_created_score: data?.content_created_score || 0
      }
      const res = await api.post(Endpoints.researcherPrsUpdate, obj );
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(`reseacherPrs`);
      if(data?.status){
        toast.success(data.message);
      }
      if(!data?.status){
        toast.error(data.message);
      }
    },
    onError: (error) => {
      toast.error(`${error['response'].data.message}`);
    },
  });
  return updateResearcherPrs;
};

export default useUpdateResearchPrs;
