import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PlusIcon from "../../../img/icon/plus.svg";

const PlusButton = () => {
  const navigate = useNavigate();
  const { marketgennamechange } = useSelector(
    (state) => state.marketIntGenerationSlice
  );

  return (
    <div className="col-sm-2 ">
      <div
        onClick={() => {
          navigate("/manage/marketgeneration/new");
        }}
        className="custom-btn medium sm-btn"
        style={{ paddingTop: "12px", paddingBottom: "12px", cursor: "pointer" }}
      >
        <img src={PlusIcon} className="me-2" style={{ marginLeft: "5px" }} />

        <a
          style={{
            color: "#fff",
            width: "160px",
            display: "flex",
            cursor: "pointer",
          }}
        >
          {marketgennamechange}
        </a>
      </div>
    </div>
  );
};

export default PlusButton;
