import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    marketgenid: 1,
    newmarketgenid: 1,
    marketgenname: "",
    marketgennamechange: "Doc Type",
};

const marketIntGenerationSlice = createSlice({
  name: "marketIntGenerationSlice",
  initialState,
  reducers: {
    setmarketgenId: (state, action) => {
      state.marketgenid = action.payload;
    },
    setnewmarketgenId: (state, action) => {
      state.newmarketgenid = action.payload;
    },
    setmarketgenname: (state, action) => {
      state.marketgenname = action.payload;
    },
    setmarketgennamechange: (state, action) => {
      state.marketgennamechange = action.payload;
    },
  },
});

export const {setmarketgenId, setnewmarketgenId, setmarketgenname,  setmarketgennamechange} = marketIntGenerationSlice.actions;
export default marketIntGenerationSlice.reducer;
