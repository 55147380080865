import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  attachments: [],
  linkattachment: [],
  documentId: [],
  checkbox: "",
  pageToLoad: 1,
  itemsPerPage: 10,
  search_key: "",
  industry: "",
  business_model: "",
  contact_department: "",
  location: "",
  potential_service: "",
  lead_owner: "",
  fortune_1000: "",
  business_potential: "",
};

const campaignSlice = createSlice({
  name: "campaignSlice",
  initialState,
  reducers: {
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },


    setDocumentId: (state, { payload }) => {
      const raw = state.documentId;
      const index = raw.indexOf(payload);
      if (index === -1) {
        raw.push(payload);
      } else {
        raw.splice(index, 1);
      }
      state.documentId = raw;
    },

    setPageToLoad: (state, action) => {
      state.pageToLoad = action.payload;
    },

    setCheckbox:  (state, action) => {
      const {id, checked} = action.payload
      state[id] = checked
      // state.checkbox = action.payload;
    },

    setAttachment: (state, { payload }) => {
      const raw = state.attachments;
      const index = raw.indexOf(payload);
      if (index === -1) {
        raw.push(payload);
      } else {
        raw.splice(index, 1);
      }
      state.attachments = raw;
    },

    setLinkAttachment: (state, {payload}) => {
      const raw = state.linkattachment;
      const index = raw.indexOf(payload);
      if(index === -1) {
        raw.push(payload);
      }else {
        raw.splice(index, 1)
      }
      state.linkattachment = raw;
    },

    setIsFortune: (state, action) => {
   
        state.fortune_1000 = action.payload
      
      
      // console.log(state.fortune_1000)
      // state.fortune_1000 = state.fortune_1000?.toString()
      // ?.split("")
      // ?.map((char, index) => (index === 0 ? char.toUpperCase() : char))
      // ?.join("") || "False";
      // if (state.fortune_1000 !== undefined) {
      //   state.fortune_1000 = state.fortune_1000
      //     ?.toString()
      //     ?.split("")
      //     ?.map((char, index) => (index === 0 ? char.toUpperCase() : char))
      //     ?.join("") || "False";
      // }
    },

    setSearch: (state, action) => {
      state.search_key = action.payload;
    },

    setIndustrySectorId: (state, action) => {
      state.industry = action.payload;
    },

    setBusinessModalId: (state, action) => {
      state.business_model = action.payload;
    },

    setContactDepId: (state, action) => {
      state.contact_department = action.payload;
    },

    setlocationId: (state, action) => {
      state.location = action.payload;
    },

    setServiceOffer: (state, action) => {
      state.potential_service = action.payload;
    },
    setLeadOwner: (state, action) => {
      state.lead_owner = action.payload;
    },

    setBusinessPotential: (state, action) => {
      state.business_potential = action.payload;
    },
  },
});

export const {
  setPageToLoad,
  setSearch,
  setAttachment,
  setItemsPerPage,
  setIndustrySectorId,
  setBusinessModalId,
  setContactDepId,
  setlocationId,
  setCheckbox,
  setServiceOffer,
  setLinkAttachment,
  setLeadOwner,
  setDocumentId,
  setIsFortune,
  setBusinessPotential,
} = campaignSlice.actions;
export default campaignSlice.reducer;
