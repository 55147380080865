import React, { useEffect, useState } from "react";
import TabNavItem from "./TabNavItem";
import TabContent from "./TabContent";
import LeadsReport from "./LeadsReport";
import ProductivityReport from "./ProductivityReport";
import AgreementReport from "./AgreementReport";
import { useSelector, useDispatch } from "react-redux";
import { setReportType } from "../../slice/reportSlice";
import RoleRestriction from "../../utils/roleRestriction";
import DocumentUploadReport from "./DocumentUploadReport";

const ReportsTabs = ({
  data,
  type,
  productivitydata,
  agreementdata,
  prsData,
  setType,
  isPrs,
  setIsPrs,
  roleId,
  onUserSelect,
}) => {
  // const [activeTab, setActiveTab] = useState("tab1");
  const dispatch = useDispatch()
  const {reportType} = useSelector(store => store.reportSlice)
  // console.log(reportType)
  const [bdUserId, setBdUserId] = useState('');
  const restrict = RoleRestriction();

  useEffect(()=>{
    if(bdUserId){
      onUserSelect?.(bdUserId)
    }
  },[bdUserId])

  

  return (
    <div className="">
      <ul className="d-md-flex align-items-center justify-content-end gap-3 py-4 report-list">
        <TabNavItem
          title="Leads Report"
       
          isActive={reportType === "leads"}
          setActiveTab={() => dispatch(setReportType("leads"))}
        />
        <TabNavItem
          title="Productivity Report"
          isActive={reportType === "productivity"}
          setActiveTab={() => dispatch(setReportType("productivity"))}
        />
        {(restrict !== "researcher" && restrict !== "relation") && 
        <TabNavItem
          title="Agreement Report"
       
          isActive={reportType === "agreement"}
          setActiveTab={() => dispatch(setReportType("agreement"))}
        />}
        {(restrict == 'practice' || restrict == 'researcher') && 
        <TabNavItem
          title="Document Upload Report"
       
          isActive={reportType === "document"}
          setActiveTab={() => dispatch(setReportType("document"))}
        />}
      </ul>

      <div className="outlet">
        <TabContent  isActive={reportType === "leads"}>
          <LeadsReport data={data} type={type} onUserSelect={setBdUserId}/>
        </TabContent>

        <TabContent  isActive={reportType === "productivity"}>
          <ProductivityReport 
          productivitydata={productivitydata} 
          isPrs={isPrs} 
          prsData={prsData}
          roleId={roleId}
          type={type}
          />
        </TabContent>

        <TabContent  isActive={reportType === "agreement"}>
          <AgreementReport agreementdata={agreementdata} type={type} onUserSelect={setBdUserId}/>
        </TabContent>

        <TabContent  isActive={reportType === "document"}>
          <DocumentUploadReport data={data} type={type} onUserSelect={setBdUserId}/>
        </TabContent>
      </div>
    </div>
  );
};

export default ReportsTabs;
