import React from "react";
import HorizontalBarChart from "../../ui/graphs/HorizontalBarChart";
import useGetAgreementGraph from "../../libs/queries/graphs/useGetAgreementGraph";

const AgreementTenure = ({agreementdata}) => {

  // const {data} = useGetAgreementGraph()
  const {tenure_data, tenure_label, tenure_color} = agreementdata || {}
  // console.log(data)

  return (
    <div className="bg-white py-4 px-4 border ">
      <div className="card-body">
        <h3 className="mb-3">Agreement Tenure with Client</h3>
        {
          tenure_data?.length >0 ?
          <HorizontalBarChart data={tenure_data} datalabel={tenure_label} datacolor={tenure_color} />
          :
          <div className='my-4 d-flex justify-content-center'>
            No Data
          </div>
        }
      </div>
    </div>
  );
};

export default AgreementTenure;
