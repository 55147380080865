import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: null,
  forgotpasswordemail: ""
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.auth = action.payload;
    },
    setforgotpasswordemail: (state, action) => {
      state.forgotemail = action.payload
  },
  },
});

export const { setAuth, setforgotpasswordemail } = authSlice.actions;
export default authSlice.reducer;
