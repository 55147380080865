import React from "react";
import EmailTemplateForm from "./EmailTemplateForm";

const NewEmailTemplateContainer = () => {
  return (
    <div className="pt-4">
      <div className="rounded-10 overflow-hidden">
        <div className="widget-header px-3 py-3 bg-sky">
          <h3 className="bold text-white fs-18">New Email Template</h3>
        </div>

        <EmailTemplateForm />
        {/* <LeadGenerationForm /> */}
      </div>
    </div>
  );
};

export default NewEmailTemplateContainer;
