import React, { useEffect, useState } from "react";
/// hooks used ///


import ListLocation from "./ListLocation";
import useLocationList from "../../../libs/queries/location/useLocation";
import BulkDeleteButton from "../../../ui/buttons/BulkDeleteButton";
import useDeleteLocation from "../../../libs/mutation/location/useDeleteLocation";
import { toast } from "react-toastify";
import Pagination from "../../../ui/pagination";
import { useDispatch, useSelector } from "react-redux";
import { setPageToLoad, setpage, setpageitems } from "../../../slice/locationSlice";
import Heading from "../../../ui/modals/Heading";
import Search from "../../../ui/search/Search";


const LocationContainer = ({ checkboxstate, checkboxstatechange }) => {
  const {pageToLoad} = useSelector(state => state.locationSlice)
  // const [pageToLoad, SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState("");
  const [search, setSearch] = useState('');
  const [locationIdsArr, setLocationIdsArr] = useState([]);
  //  const [checkboxadd, setCheckboxAdd] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  // console.log('checked__', isChecked);
  // console.log('checkboxst__', checkboxstate);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const { data: locationData, isLoading } = useLocationList(
    pageToLoad,
    itemsPerPage,
    search
  );
  const dispatch = useDispatch();

  // console.log('dt__', JSON.stringify(locationData,null,4));
  // console.log('dtIds__', locationIdsArr);

  useEffect(()=>{
    setLocationIdsArr(locationData?.results?.map(item => item.id))
  },[locationData])
  
  // console.log(locationData)
  const handlePageChange = (value) => {
    dispatch(setPageToLoad(value))
    setIsChecked(false)
  }

  useEffect(() => {
    if (!isLoading && locationData) {
      dispatch(setpage(Math.ceil((locationData?.count || 0)/10)));
      dispatch(setpageitems(locationData?.results?.length))
    }
    
  }, [isLoading, locationData]);

  return (
    <div>
      <Heading
      search={<Search onSearch={setSearch} />}
      // onSearch={setSearch}
      heading={"Locations"}
      isLocation={true}
      />
      <div
        className="d-flex "
        style={{ alignItems: "center", justifyContent: "space-between" }}
      > 
        <Pagination
          onPageChange={handlePageChange}
          onItemsChanged={setItemsPerPage}
          totalPages={Math.ceil(locationData?.count / itemsPerPage)}
          defaultCount={itemsPerPage}
        />
        {/* <BulkDeleteButton onClick={() => deleteCheckboxitem()} /> */}
        {locationData?.results?.length>1 && 
        <div 
        className="d-flex align-items-center pt-3 pb-4"
        >
        <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        />
        <label className="d-block text-sky fs-12 mx-2">
        Select all
        </label>
        </div>}
      </div>

      {locationData &&
        locationData?.results?.map((data, index) => {
          return (
            <ListLocation
              key={index}
              index={index}
              data={data}
              statecheck={checkboxstate}
              statecheckhandler={checkboxstatechange}
              selectedAll={isChecked? locationIdsArr : []}
            />
          );
        })}
         {locationData &&
        locationData?.results?.length === 0 ? <p className="ps-3">No results found</p> : ""}
    </div>
  );
};

export default LocationContainer;
