import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import api from "../../../api";
import { Endpoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useCreateUser=()=>{
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const createUserMutation = useMutation({
        // mutationKey: ['createuser'],
        mutationFn: async(data) => {
            
            let resp = await api.post(Endpoints.createUser, data);
            return resp.data
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(['createuser']);
            if(data.status){
                toast.success(data.message);
                navigate("/users/")
            }
          },
      
          onError: (error) => {
            toast.error(`${error['response'].data.message}`);
          },
    })

    return createUserMutation;
}

export default useCreateUser;