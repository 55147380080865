import React from "react";
import TableBody from "./TableBody";

const ProductivityAlertTableContainer = ({ data, type }) => {
  // console.log("this is real", data?.data);

  

  const tdStyle = {
    borderLeft: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
  };

  const lowColor = "red";
  const mediumColor = "yellow";
  const highColor = "green";
  const superColor = "blue";

  const getScoreColor = (grade) => {
    switch (grade) {
      case "Low":
        return lowColor;
      case "Medium":
        return mediumColor;
      case "High":
        return highColor;
      case "Super":
        return superColor;
      default:
        return "";
    }
  };

  return (
    <div className="bg-white rounded-10 overflow-hidden shadow">
      <table className="w-100 alert-table">
        <thead>
          <tr>
            <th className="fs-20 medium">Sr. no.</th>
            <th className="fs-20 medium" width="35%">
              Parameters
            </th>
            <th className="fs-20 medium">Score</th>
            <th className="fs-20 medium" width="45%">
              Grade
            </th>
            {type === "organisation" && <th className="fs-20 medium" width="45%">
              Username
            </th>}
          </tr>
        </thead>

        <tbody>
          {data?.results?.map((userData, index) =>
            userData.scores.map((score, scoreIndex) => (
              <tr key={`${index}-${scoreIndex}`}>
                <td style={tdStyle}>{scoreIndex + 1}</td>
                <td style={tdStyle}>{score.stage}</td>
                <td style={tdStyle}>{score.score}</td>
                <td style={{ tdStyle, color: getScoreColor(score.grade) }}>
                  {score.grade}
                </td>
                {scoreIndex === 0 && type === "organisation" ? (
                  <td style={tdStyle} rowSpan={userData.scores.length}>
                    {userData.user}
                  </td>
                ) : null}
              </tr>
            ))
          )}

          {!data?.results?.length && (
            <div
              style={{
                textAlign: "center",
              }}
              className="mt-3"
            >
              No Data Found
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProductivityAlertTableContainer;
