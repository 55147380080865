import React from "react";
import { useNavigate } from "react-router-dom";
import useGetLeadListContact from "../../libs/queries/leads/useGetLeadListContact";
import Viewmorecontact from "../../Components/leads/Viewmorecontact";

const ViewMoreModal = ({ heading, data, type }) => {
  const { id, stage_background_color } = data || {};
  const { data: contactlist } = useGetLeadListContact({
    id,
    leadType: type
  });
  // console.log("this is outer", id)
  // console.log("this is view",contactlist)
  const background_color = stage_background_color;
  const lowercasebackground_color = background_color.toLowerCase();

  const navigate = useNavigate();
  return (
    <div className="col-md-4">
      <div className="widget-box rounded-10 overflow-hidden shadow-sm mb-4 w-100 bg-white">
        <div
          className={`widget-header d-flex align-items-center justify-content-between  py-2 px-3`} style={{backgroundColor: lowercasebackground_color}}
        >
          <h3 className="text-white medium fs-16">{heading}</h3>
          <h6 className="result-number text-brown">{contactlist?.count}</h6>
        </div>
        <div className="bg-white lead-box">
          <table className="w-100">
            <thead>
              <tr>
                <th className="fs-14 medium border-bottom py-2 ps-3">
                  Spoc Name
                </th>
                <th className="fs-14 medium border-bottom py-2">Number</th>
              </tr>
            </thead>

            <Viewmorecontact id={id} key={id} type={type} />
          </table>

          <div className="text-end px-3 py-2 ">
            <a
              onClick={() => {
                navigate(`/leads/${id}`, {state: {view: type}});
              }}
              className="text-decoration-underline selectContainer"
            >
              View More...
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewMoreModal;
