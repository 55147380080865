import React from 'react'
import FilterRow from '../../../Components/Manage/MarketIntGeneration/FilterRow'
import StageRow from '../../../Components/Manage/NewStage/StageRow'
import { useSelector } from 'react-redux';
import NewMarketGenerationContainer from '../../../Components/Manage/MarketIntGeneration/NewMarketGenerationContainer';


const NewMarketGeneration = () => {

  const { marketgennamechange } = useSelector(
    (state) => state.marketIntGenerationSlice
  );

  return (
    <div className="main py-4 position-relative">
    <div className="container">
      <FilterRow />
      <StageRow
      heading={marketgennamechange}      
      />
      <NewMarketGenerationContainer />
    </div>
  </div>
  )
}

export default NewMarketGeneration