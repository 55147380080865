import React, { useState } from "react";
/// hooks used ///
import { useSelector } from "react-redux";

/// Imports of Component ///
import AgreementGenerated from "./AgreementGenerated";
import useFilters from "../../../libs/queries/manage/useFilters";

import useFilterData from "../../../libs/queries/manage/useFilterData";
import Pagination from "../../../ui/pagination";
import { useEffect } from "react";

const AgreementGeneratedContainer = () => {
  const [pageToLoad, SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { data: filters, isLoading } = useFilters("agreement");
  const { agreementid } = useSelector((state) => state.agreementSlice);

  const { data } = useFilterData(
    agreementid ? agreementid : null,
    pageToLoad,
    itemsPerPage
  );
  

  useEffect(()=>{
    if(agreementid){
      SetPageToLoad(1);
    }
  },[agreementid])

  return (
    <div>
      <Pagination
        onPageChange={SetPageToLoad}
        onItemsChanged={setItemsPerPage}
        totalPages={Math.ceil(data?.count / itemsPerPage)}
        defaultCount={itemsPerPage}
        focus={pageToLoad==1?true:false}
      />
      {data &&
        data?.results?.map((data, index) => {
          return <AgreementGenerated key={index} index={index} data={data} />;
        })}
    </div>
  );
};

export default AgreementGeneratedContainer;
