/// hooks used ///
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/// Imports of Component ///
import Header from "../../Components/auth/Header";
import AuthContainer from "../../Components/auth/AuthContainer";
import LoginForm from "../../Components/auth/LoginForm";

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {

    let token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, []);

  return (
    <div className="wrapper login-bg position-relative">
      <Header />
      <AuthContainer
        mainheading="Login to Nexus Edge"
        subheading="Welcome Back!"
        componentform={<LoginForm />}
      />
    </div>
  );
};

export default Login;
