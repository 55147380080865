import React from 'react'
import api from "../../../api";
import { Endpoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useUpdateUser = (id, onHide) => {
    const queryClient = useQueryClient();

    const UpdateUserMutation = useMutation({
        mutationFn: async(data) => {
            if(!data?.password?.length){
              delete data?.password
            }
            let resp = await api.post(`account/update/user/${id}`, data);
            return resp.data
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(`createuser`, id);
            if (onHide) onHide();
            toast.success(data.message);
          },
      
          onError: (error) => {
            toast.error(`${error['response'].data.message}`);
          },
    })

  return UpdateUserMutation
}

export default useUpdateUser