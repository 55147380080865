import React, { useState } from "react";
import useToggle from "../../hooks/useToggle";
import useDeleteLead from "../../libs/mutation/leads/useDeleteLead";
import useDeleteContact from "../../libs/mutation/leads/useDeleteContact";

const PrsCrmModal = ({ data, leadid, maindata }) => {
  const { client_name, mobile_contact, spoc_name, lead_contact } = data || {};

  return (
    <div 
    className="bg-white px-md-4 px-3 mb-3"
    >
      <table 
        className="action-table w-100 table table-bordered"
        >
          <tbody>
          <tr style={{
                    // marginBottom: '10px',
                    display: 'flex'
                  }}>
              <td style={{
                    textAlign: "left",
                    // width: "100%",
                    width: '30%'
                  }}>
                <h6 className="fs-12 text-sky regular  pb-md-2">
                  Client Name
                </h6>
              </td>
              <td style={{
                    textAlign: "left",
                    // width: "100%",
                    width: '30%'
                  }}>
                <h6 className="fs-12 text-sky regular  pb-md-2">
                  Spoc Name
                </h6>
              </td>
              <td style={{
                    textAlign: "left",
                    // width: "100%",
                    width: '40%'
                  }}>
                <h6 className="fs-12 text-sky regular  pb-md-2">
                  Contact
                </h6>
              </td>
            </tr>
            {
              maindata.map((data,index)=>{
              const { client_name, mobile_contact, spoc_name, lead_contact } = data || {};
                return(
                  <tr style={{
                    // marginBottom: '10px',
                    display: 'flex'
                  }} key={index}>
                  <td className="action-mid" 
                  // width={'50%'}
                  style={{
                    textAlign: "left",
                    // width: "100%",
                    width: '30%'
                  }}
                  >
                {/* <h6 className="fs-12 text-sky regular  pb-md-2">
                  Legal Entity Name
                </h6> */}
                <p 
                style={{
                  wordBreak: 'break-all'
                }}
                className="fs-12">{client_name}</p>
                </td>
                <td 
                // width={'25%'} 
                className="action-mid" 
                style={{
                  textAlign: "left",
                  // width: "100%",
                  width: '30%'
                }}
                >
                {/* <h6 className="fs-12 text-sky regular  pb-md-2">Contact Name</h6> */}

                <p 
                style={{
                  wordBreak: 'break-all'
                }}
                className="fs-12">{spoc_name}</p>
              </td>
              <td 
              // width={'25%'} 
              className="action-mid" 
              style={{
                textAlign: "left", 
                // width: "100%",
                width: '40%'
                }}>
                {/* <h6 className="fs-12 text-sky regular  pb-md-2">Email Id</h6> */}
                <p 
                style={{
                  wordBreak: 'break-all'
                }}
                className="fs-12">{mobile_contact}</p>
              </td>
              
            </tr>
                )
              })
            }
            
          </tbody>
        </table>
      </div>
    );
  };
  // return (
  //   <div className="bg-white rounded-10 px-md-4 px-3 mb-3 shadow-sm">
  //     <table className="action-table w-100">
  //       <tbody>
  //         <tr>

  //           <td className="action-mid" style={{textAlign: "left", width: "300px"}}>
  //             <h6 className="fs-12 text-sky regular  pb-md-2">Client Name</h6>
  //             <p className="fs-12">{client_name}</p>
  //           </td>
  //           <td
  //             className="action-mid"
  //             style={{ textAlign: "left", width: "200px" }}
  //           >
  //             <h6 className="fs-12 text-sky regular  pb-md-2">Spoc Name</h6>
  //             <p className="fs-12">{spoc_name}</p>
  //           </td>
  //           <td
  //             className="action-mid"
  //             style={{ textAlign: "left", width: "200px" }}
  //           >
  //             <h6 className="fs-12 text-sky regular  pb-md-2">Contact</h6>
  //             <p className="fs-12">{mobile_contact}</p>
  //           </td>
            
  //         </tr>
  //       </tbody>
  //     </table>
  //   </div>
  // );
// };

export default PrsCrmModal;
