import React, { useState } from "react";
import hdfc from "../../img/hdfc.png";
import DocIcon from "../../img/icon/doc.svg";
import ReplaceIcon from "../../img/icon/replace.svg";
import eyeIcon from "../../img/icon/eye.svg";
import TrashIcon from "../../img/icon/trash.svg";
import StageIcon from "../../img/icon/stage.svg";
import Slidedown from "../Manage/Slidedown";
import useDeleteAgreement from "../../libs/mutation/agreements/useDeleteAgreement";
import DeleteModal from "../../ui/modals/DeleteModal";
import AgreementListModal from "./AgreementListModal";
import useToggle from "../../hooks/useToggle";
import { Endpoints } from "../../api/Endpoints";
import PdfModal from "../../ui/modals/pdfModal";
import EmailModal from "../../ui/modals/EmailModal";
import { useNavigate } from "react-router-dom";

const SingleAgreementList = ({data, checkBoxAdd, setCheckBoxAdd}) => {
  const [dele, setdele] = useState(false);
  const [detail, toggleDetail] = useToggle(false);
  const [pdfView, setPdfView] = useState(false);
  const [emailView, setEmailView] = useState(false);
  const [contactId, setContactId] = useState('');
  const navigate = useNavigate();

  const {
    id,
    contact_department_display,
    contact_name,
    mobile_contact,
    record_of_signed_copy,
    client_execution_owner_name,
    lead_contact,
    lead_contact_display,
    email,
  } = data || {};

  const { mutate: deleteAgreement, isLoading } = useDeleteAgreement();

  const handleDelete = () => {
    let payload = { agreements: [id] };
    deleteAgreement(payload);
    setdele(false);
  };

  

  const handleAddCheckbox = (event, id) => {
    if (event.target.checked) {
      setCheckBoxAdd([...checkBoxAdd, id]);
    } else {
      const updatestatecheck = checkBoxAdd.filter((item) => item !== id);
      setCheckBoxAdd(updatestatecheck);
    }
  };

  return (
    <div className="bg-white rounded-10 px-md-4 px-3 py-3 mb-3 shadow-sm">
      <table className="action-table w-100">
        <tr>
          <td className="action-first">
            <div className="d-flex align-items-center gap-lg-4 gap-2">
              <input
                type="checkbox"
                checked={
                  checkBoxAdd.filter((item) => item == id).length > 0
                    ? true
                    : false
                }
                onClick={(event) => handleAddCheckbox(event, id)}
              />
              {/* <img
                src={hdfc}
                width="48"
                height="48"
                className="rounded-3"
                alt=""
              /> */}
            </div>
          </td>

          <td className="action-mid">
            <h6 className="fs-12 text-sky regular  pb-md-2">
              Client Execution Owner
              {/* Client Name */}
            </h6>
            <h5>{lead_contact_display}</h5>
          </td>
          <td className="action-mid">
            <h6 className="fs-12 text-sky regular  pb-md-2">
              Contact Department
              {/* Contact Exec Owner */}
            </h6>
            <h5>
              {contact_department_display}
              {/* {contact_name} */}
            </h5>
          </td>
          <td className="action-mid">
            <h6 className="fs-12 text-sky regular  pb-md-2">Contact</h6>
            <h5>{mobile_contact}</h5>
          </td>
          <td className="action-last">
            <div
              style={{
                // backgroundColor: 'green',
                display: "flex",
                justifyContent: "end",
              }}
              className="d-flex align-items-center gap-xl-3 gap-2"
            >
              <a
                onClick={() => {
                  // setContactId(lead_contact);
                  setContactId(id);
                  setEmailView(true);
                }}
                // href="#"
                className="custom-btn medium sm-btn fs-16 selectContainer"
                // data-bs-toggle="collapse"
                // data-bs-target="#mailbox"
              >
                Mail
              </a>
              <a
                // onClick={() => setPdfView(true)}
                onClick={() => {
                  localStorage.setItem("viewagreement", JSON.stringify(data));
                  navigate("/agreement/view");
                }}
                className="action-icon selectContainer"
              >
                <img src={DocIcon} />
              </a>
              <a
                onClick={() => setPdfView(true)}
                // onClick={() => {
                //   localStorage.setItem("viewagreement", JSON.stringify(data));
                //   navigate("/agreement/view");
                // }}
                target="_blank"
                className="action-icon selectContainer"
              >
                <img src={eyeIcon} />
              </a>

              <a
                className="action-icon bg-red selectContainer"
                // data-bs-toggle="modal"
                // data-bs-target="#delete"
                onClick={() => setdele(true)}
              >
                <img src={TrashIcon} />
              </a>
              <a
                onClick={() => toggleDetail()}
                aria-expanded="false"
                className="acco-info-btn selectContainer"
              >
                <i className="fa-solid fa-angle-down fs-20 align-middle"></i>
              </a>
            </div>
          </td>
        </tr>
      </table>
      {detail && <AgreementListModal data={data} />}
      {dele && (
        <DeleteModal
          disabled={isLoading}
          deletename="Agreement"
          onDeleteClick={handleDelete}
          onNoClick={() => setdele(false)}
        />
      )}
      {pdfView && (
        <PdfModal
          // heading=""
          url={record_of_signed_copy}
          onClose={() => setPdfView(false)}
        />
      )}
      {emailView && (
        <EmailModal
          // heading=""
          email={email}
          contactId={contactId}
          onClose={() => setEmailView(false)}
        />
      )}
    </div>
  );
};

export default SingleAgreementList;
