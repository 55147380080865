import React from "react";
import { useController, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useGetEmailTemplate from "../../../libs/queries/emailtemplate/useGetEmailTemplate";
import useCreateStage from "../../../libs/mutation/Stage/usecreateStage";
import useCreateLocation from "../../../libs/mutation/location/useCreateLoation";

const CreateLocationForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const { mutate: createLocation, isLoading } = useCreateLocation();
  const navigate = useNavigate();

  return (
    <div className="pt-4">
      <div className="rounded-10 overflow-hidden">
        <div className="widget-header px-3 py-3 bg-sky">
          <h3 className="bold text-white fs-18">New Location</h3>
        </div>

        <div className="bg-white p-4">
          <form className="add-form" onSubmit={handleSubmit(createLocation)}>
            <div className="row gx-lg-5 align-items-center">
              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label className="d-block text-sky fs-12 pb-2">State</label>
                <input
                  type="text"
                  className="form-field"
                  placeholder=""
                  name="state"
                  {...register("state", { required: true })}
                />
                <span className="fs-12 text-red">
                  {errors.state?.type === "required" &&
                    "State is required"}
                </span>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                  City
                </label>
                <input
                  type="text"
                  className="form-field"
                  placeholder=""
                  name="city"
                  {...register("city", { required: true })}
                />
                <span className="fs-12 text-red">
                  {errors.city?.type === "required" && "City is Required"}
                </span>
              </div>

            </div>

            <div className="d-flex border-0 butn-group justify-content-center gap-3 pt-4">
              <button
                type="submit"
                className="custom-btn border-0 py-2 rounded-3 raleway medium"
              >
                Save
              </button>

              <button
                type="button"
                onClick={() => navigate("/manage/locations")}
                className="selectContainer custom-btn close-modal py-2  rounded-3 border-bl-btn px-4 raleway semibold"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateLocationForm;
