import React from "react";

const CancelButton = ({onClick}) => {
  return (
    <button
      type="button"
      className="custom-btn  py-2  rounded-3 border-bl-btn px-4 raleway semibold "
      onClick={onClick}
    >
      Cancel
    </button>
  );
};

export default CancelButton;