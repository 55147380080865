import React, { useState } from "react";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import Heading from "../../ui/modals/Heading";
import Search from "../../ui/search/Search";
import Header from "../../Components/alerts/Header";
import useProductivityalerts from "../../libs/queries/alerts/useProductivityalerts";
import ProductivityAlertTableContainer from "../../Components/alerts/ProductivityAlertTableContainer";
import AlertFilter from "../../ui/dropdown/AlertFilter";
import { Endpoints } from "../../api/Endpoints";
import Pagination from "../../ui/pagination";


const ProductivityViewmore = () => {
  const [type, setType] = useState("my");
  const [alertStartDate, setAlertStartDate] = useState("");
  const [alertEnd, setAlertEnd] = useState("");
  const [search, setSearch] = useState("");
  const [pageToLoad, SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const { data: productivityalert } = useProductivityalerts({
    productivityalerttype: type,
    start_date: alertStartDate,
    end_date: alertEnd,
    search: search,
    page: pageToLoad,
    itemsPerPage: itemsPerPage,
  });
  
  const queryParams = {
    organisation: type === "organisation" ? 1 : "",
    download: 1,
    start_date: alertStartDate || '',
    end_date: alertEnd || '',
    search_key: search || '',
    page: pageToLoad || 1,
  };

  const queryString = Object.keys(queryParams)
  .filter((key) => queryParams[key] !== "")
  .map((key) => `${key}=${queryParams[key]}`)
  .join("&");

  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <Subheader
          leftsideshow={
            <TwoButtonHolder label={"Alerts"} onSelect={setType} type={type} />
          }
        />
        <Heading
          // search={<Search Search={setSearch} />}
          heading="Alerts"
          url={
            Endpoints.productivityalertCSV +
            "?" + queryString
            // `download=1&organisation=${type == "organisation" ? 1 : ""}`
          }
          showSortt={setIsOpen}
          sortComponent={
            <AlertFilter
              onAlertStart={setAlertStartDate}
              onAlertEnd={setAlertEnd}
              showSort={isOpen}
            />
          }
        />
        <Header
          heading="Productivity Alert"
          backgroundcolor="darkpink"
          url={"/alerts"}
        />

        <Pagination
          onPageChange={SetPageToLoad}
          onItemsChanged={setItemsPerPage}
          totalPages={Math.ceil(productivityalert?.count / itemsPerPage)}
          defaultCount={itemsPerPage}
        />
        <ProductivityAlertTableContainer data={productivityalert} type={type} />
      </div>
    </div>
  );
};

export default ProductivityViewmore;
