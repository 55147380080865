import api from "../../../api/index";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import { useMutation } from "@tanstack/react-query";

const useLogin = () => {
  const loginMutation = useMutation({
    
    mutationFn: async (data) => {
      const res = await api.post("account/login/", data);
      return { ...(res?.data && res.data), remember: data.remember };
    },
    onSuccess: (data) => {
      window.localStorage.setItem("token", data?.token || "");
      window.localStorage.setItem("refresh_token", data?.refresh || "");
      localStorage.setItem("isCheckedIn", JSON.stringify(data?.today_checkin || ''));
      localStorage.setItem("todayCheckinTime", JSON.stringify(data?.today_checkin || ''));
      localStorage.setItem("isCheckedOut", JSON.stringify(data.today_checkout || ''));
      if (data.remember) {
        window.localStorage.setItem("expire_time", dayjs().add(30, "days"));
      }
      window.location.href = "/";
    },
    onError: (error) => {
      toast.error(`${error['response'].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
    
  });
  
  return loginMutation;
};

export default useLogin;
