import React from "react";
import Funnel from "../../../img/bd-funnel.png";
import TabItem from "../../../ui/threetabs/TabItem";
import { useDispatch, useSelector } from "react-redux";
import { setDateType } from "../../../slice/dashboardSlice";
import TabContent from "../../../ui/threetabs/TabContent";

import useBDFunnel from "../../../libs/queries/dashboard/useBDFunnel";
import BDDay from "../BDDay";
import BDWeek from "../BDWeek";
import BDMonth from "../BDMonth";

const BDFunnel = ({type}) => {
  const dispatch = useDispatch();

  const { dateType } = useSelector((store) => store.dashboardSlice);

 

  const {data: funnel} = useBDFunnel({
    bdFunnelType: type,
    date_filter: dateType,
  })



  return (
    <div
      className="py-3 px-3 rounded-3 bg-pink-grad collapse multi-collapse"
      id="bd_funnel"
    >
      <div className="d-flex align-items-center justify-content-between pb-3">
        <h3 className="fs-24 medium text-black">BD Funnel</h3>

        <div className="sub-tab nav border-0 gap-2 pb-3 justify-content-end">
          <TabItem
            title="Days"
            isActive={dateType === "day"}
            setActiveTab={() => dispatch(setDateType("day"))}
          />
          <TabItem
            title="Week"
            isActive={dateType === "week"}
            setActiveTab={() => dispatch(setDateType("week"))}
          />
          <TabItem
            title="Month"
            isActive={dateType === "month"}
            setActiveTab={() => dispatch(setDateType("month"))}
          />
        </div>
      </div>

      <div className="tab-content">
        <div className="outlet">
          <TabContent isActive={dateType === "day"}>
            <BDDay dayData={funnel} />
          </TabContent>
          <TabContent isActive={dateType === "week"}>
            <BDWeek weekData={funnel} />
          </TabContent>
          <TabContent isActive={dateType === "month"}>
            <BDMonth monthData={funnel} />
          </TabContent>
          {/* <img src={Funnel} alt="" /> */}
        </div>
      </div>
    </div>
  );
};

export default BDFunnel;