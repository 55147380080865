import React, { useState } from "react";
/// hooks used ///
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

/// StyleSheet import ///
import "../../Styles/auth.css";

/// Icons Or Images import ///
import NumberIcon from "../../img/icon/number.svg";

/// Imports of Component ///
import useForgotpassword from "../../libs/mutation/auth/useForgotpassword";




const ForgotPasswordForm = () => {
  const { register, formState: {errors}, handleSubmit } = useForm();
  const { mutate: forgotpassword, isLoading } = useForgotpassword();
  const navigate = useNavigate();
 

  


  return (
    <>
      <form
        action=""
        onSubmit={handleSubmit(forgotpassword)}
        className="signup-form px-lg-5"
      >
        {/***  email ***/}
        <div className="form-field mb-3">
          <label  className="fs-12 d-block pb-1">
            Enter Email ID
          </label>
          <div className="position-relative">
            <img
              src={NumberIcon}
              alt=""
              className="position-absolute ms-3 mt-3 pt-1"
            />
            <input
              id="email"
              type="email"
              name="email"
              placeholder="Enter your Email ID"
              className="ps-5"
             
              {...register("email", {
                required: true,
                pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
              })}

            />
            <span className="fs-12 text-red">
              {errors.email?.type === "required" && "Email is Required"}
              {errors.email?.type === "pattern" && "Enter Valid Email"}
            </span>
          </div>
        </div>
        {/***  email ***/}

        {/***  buttons ***/}
        <button
          type="submit"
          disabled={isLoading}
          className="custom-btn fs-20  rounded-pill w-100 py-3 mt-3 mb-2"
        >
          Generate OTP
        </button>
        <button
          type="submit"
          className="custom-btn border-bl-btn fs-20 rounded-pill w-100 py-3 mt-3 mb-3"
          onClick={() => navigate("/login")}
        >
          Cancel
        </button>
        {/***  buttons ***/}
      </form>
    </>
  );
};

export default ForgotPasswordForm;
