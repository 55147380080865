import React, { useEffect, useRef, useState } from "react";
import useFilterData from "../../libs/queries/manage/useFilterData";
import { useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import useLocationList from "../../libs/queries/location/useLocation";
import useRoles from "../../libs/queries/profile/useRoles";
import useUsers from "../../libs/queries/profile/useUsers";
import useGetStageList from "../../libs/queries/StageApi/useGetStageList";
import RadioFilters from "./RadioFilters";

const ViewAllSort = ({
  onStageSelect,
  onUserSelect,
  showSort,
  type
}) => {
  const { data: roleOptions } = useRoles();
  const [isOpen, setIsOpen] = useState(showSort);
  const [roleId, setRoleId] = useState('');
  const [stageId, setStageId] = useState('');
  const {data: stageList} = useGetStageList();

  const dropdownRef = useRef(null);

  useEffect(()=>{
    setIsOpen(showSort)
  },[showSort])

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    onStageSelect?.(stageId);
  }, [stageId]);

  return (
    <div
    ref={dropdownRef}
    style={{
      position: 'absolute',
      right: 0,
      bottom: 'auto',
      left: 'auto'
    }}
      className={`dropdown-menu filter-menu p-0 rounded-10 dropdown-menu-end ${
        isOpen?"show":""}`}
    >
      <h6 className="text-white bg-sky p-3 filter-title semibold mb-1">Sort by</h6>
      
      {<div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#user"
          role="button"
          aria-expanded="false"
          aria-controls="user"
        >
          Stage Of Contact
        </a>
        <div className="collapse" id="user">
          <div style={{ overflowY: "scroll", height: "110px" }}>
            <RadioFilters options={stageList?.results} value={stageId} onChange={setStageId} name="stage_id" />
            {/* {stageList &&
              stageList?.results?.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between align-items-center py-1 px-3">
                    <label className="medium fs-14" for="TA-Sourcing">
                      {item.name}
                    </label>
                    <input
                    onChange={()=>{
                        setStageId(item.id)
                    }}
                    checked={stageId==item.id} 
                    type="radio" 
                    id="TA-Sourcing" 
                    />
                  </div>
                );
              })} */}
          </div>
        </div>
      </div>}

    </div>
  );
};

export default ViewAllSort;
