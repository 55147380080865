import React, { useState } from "react";
/// hooks used ///
import { useSelector } from "react-redux";

/// Imports of Component ///
import LeadGenerated from "./LeadGenerated";
import useFilters from "../../../libs/queries/manage/useFilters";

import useFilterData from "../../../libs/queries/manage/useFilterData";
import Pagination from "../../../ui/pagination";
import { useEffect } from "react";

const LeadGenerationContainer = () => {
  const [pageToLoad,SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState('');
  const { data: filters, isLoading } = useFilters("lead");

  const { filterid } = useSelector((state) => state.leadSlice);

  const { data } = useFilterData(
    filterid ? filterid : null,
    pageToLoad, itemsPerPage
  );

  useEffect(()=>{
    if(filterid){
      SetPageToLoad(1);
    }
  },[filterid])


  return (
    <div>
      <Pagination
        onPageChange={SetPageToLoad}
        onItemsChanged={setItemsPerPage}
        totalPages={Math.ceil(data?.count/itemsPerPage)}
        defaultCount={itemsPerPage}
        focus={pageToLoad==1?true:false}
        />
      {data &&
        data?.results?.map((data, index) => {
          return <LeadGenerated key={index} index={index}  data={data} />;
        })}
    
    </div>
  );
};

export default LeadGenerationContainer;
