import React, { useEffect, useMemo, useState } from "react";
import ReactPaginate from 'react-paginate';
const Pagination = ({
  totalPages,
  onPageChange,
  defaultCount,
  onItemsChanged,
  focus
}) => {
  const [focused, setFocused] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const pagesList = useMemo(() => {
    return Array.from({ length: totalPages }, (v, k) => k + 1);
  }, [totalPages]);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = pagesList.slice(itemOffset, endOffset);

  useEffect(()=>{
    if(focus){
      setFocused(0)
    }
  },[focus])

  const handlePageClick = (event) => {
    onPageChange?.(event.selected + 1);
    setFocused(event.selected);
    const newOffset = (event.selected * itemsPerPage) % totalPages;
    
    setItemOffset(newOffset);
  };

  useEffect(() => {
    onItemsChanged?.(itemsPerPage);
  }, [itemsPerPage]);

  return (
    <>
      {pagesList.length > 0 && (
        <div className="py-lg-4 py-3">
        <div className="d-flex gap-4 align-items-center pb-md-0 pb-3">
          <div className="paginations">
          <ReactPaginate
          forcePage={focused}
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={totalPages}
          previousLabel="< "
          renderOnZeroPageCount={null}
          previousLinkClassName={'selectContainer prev-btn'}
          nextLinkClassName={'selectContainer next-btn btn-active'}
          containerClassName={'pagination'}
          activeClassName={'page-active'}
          />
          </div>

          <div className="d-flex gap-2 align-items-center">
              <h6>Display</h6>
              {/* <input
              defaultValue={defaultCount}
              onChange={(e) => {
                if (e.target.value > 0) {
                  setItemsPerPage(e.target.value);
                }
                setFocused(0);
                onPageChange?.(1)
              }}
              min={1}
              type="number"
              className="custom-number text-center"
              /> */}
              <select
              style={{
                backgroundColor: '#f6f6f6',
                borderRadius: 20
              }}
              defaultValue={defaultCount}
              onChange={(e) => {
                if (e.target.value > 0) {
                  setItemsPerPage(e.target.value);
                }
                setFocused(0);
                onPageChange?.(1)
              }}
              >
                <option value={'10'}>10</option>
                <option value={'25'}>25</option>
                <option value={'50'}>50</option>
                <option value={'100'}>100</option>
                <option value={'500'}>500</option>
              </select>
            </div>
        </div>
        </div>
      )}
    </>
  );
};

export default Pagination;