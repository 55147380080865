import React from 'react'

const TabContent = ({isActive, children}) => {
  return (
    isActive ? <div className="tab-pane fade show active">
    { children }
  </div>
  : null
  )
}

export default TabContent