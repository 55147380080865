import React from 'react'
import PlusIcon from "../../../img/icon/plus.svg";
import { useNavigate } from "react-router-dom";

const EmailPlusButton = () => {

    const navigate = useNavigate();

  return (
    <div className="col-sm-12 text-sm-end">
      <div className='custom-btn medium sm-btn' style={{cursor: "pointer"}}>
      <img src={PlusIcon} className="me-2" />
      <a
        // className="custom-btn medium sm-btn"
        onClick={() => {
          navigate("/manage/emailtemplate/new");
        }}
        style={{cursor: "pointer"}}
      >
          New Template
        
      </a>
      </div>
     
    </div>
  )
}

export default EmailPlusButton