import React from "react";
import useLocationList from "../../libs/queries/location/useLocation";

const LocationDropdown = ({
  register,
  errors,
  label,
  name,
  rules = {
    required: "Indian hq is required",
  },
}) => {
  const { data: locations } = useLocationList(null, null);
  // console.log(name, errors)
  return (
    <>
      <label className="d-block text-sky fs-12 pb-2">{label}</label>
      <select name={name} {...(register && { ...register(name), rules })}>
        <option value="">Select a {label}</option>
        {locations?.results?.map((item, index) => {
          return (
            <>
              <option className="form-field" key={item?.id} value={item?.id}>
                {item?.city}
              </option>
            </>
          );
        })}
      </select>
      {errors[name] && (
        <span className="fs-12 text-red">{errors[name]?.message}</span>
      )}
    </>
  );
};

export default LocationDropdown;
