import React from "react";
import DoubleLineChart from "../../ui/graphs/DoubleLineChart";

const ProposalSent = ({productivitydata}) => {

  // console.log(productivitydata)
  const {proposal_sent_data} = productivitydata || {}
//   {"proposal_sent_data":
//     [
//     {
//         "name": "Kaamini Jha",
//         "proposal_sent": 2,
//         "proposal_sent_target": 10
//     },
//     {
//         "name": "Prakash Kumar",
//         "proposal_sent": 1,
//         "proposal_sent_target": 10
//     },
//     {
//       "name": "Pra Kumar",
//       "proposal_sent": 4,
//       "proposal_sent_target": 10
//   },
//   {
//     "name": "kash Kumar",
//     "proposal_sent": 5,
//     "proposal_sent_target": 10
// }
// ]}
  const proposallabel = proposal_sent_data?.map((item) => item?.name)
  const proposaldata = proposal_sent_data?.map((item) => item?.proposal_sent)
  const proposaltarget = proposal_sent_data?.map((item) => item?.proposal_sent_target)
  // console.log(productivitydata)
  return (

    <div className="bg-white py-4 px-4 border" style={{ width: "100%" }}>
      <div className="card-body">
        <h3 className="mb-3">Proposal Sent</h3>
        <DoubleLineChart proposallabel={proposallabel} proposaldata={proposaldata} proposaltarget={proposaltarget} />
      </div>
    </div>
  );
};

export default ProposalSent;
