import React from "react";
import api, { Imageapi } from "../../../api/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useUpdateLead = (id, onHide) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data) => {
      // console.log("le dekh", data)
      const leadsData = data?.leads;

      const formdata = new FormData();

      for (const name in leadsData) {
        if (
          typeof leadsData[name] === "object" &&
          name !== "news_research_file"
        ) {
          if (name === "lead_owner" || name === "indian_presence") {
            leadsData[name].forEach((element) => {
              formdata.append(name, element);
            });
          } else {
            formdata.append(name, JSON.stringify(leadsData[name]));
          }
        } else {
          formdata.append(name, leadsData[name]);
        }
      }
      console.log(formdata);
      let res;
      if (leadsData?.news_research_file) {
        // console.log("insideimage");
        res = await Imageapi.post(`management/lead/update/${id}`, formdata);
      } else {
        // console.log("insidewithoutimage");
        res = await api.post(`management/lead/update/${id}`, leadsData);
      }
      // return {...res?.data, stageid: data.stage_of_contact};

      // const res = await api.post(`management/lead/update/${id}`, data.leads);
      // const resContact = await api.post("management/create-contact/", {...data.contact, lead: id});
      return {
        leads: res?.data,
        // contact: resContact.data,
        stage_id: data?.contact?.stage_of_contact,
      };
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([`leads`]);
      toast.success("Updated successfully");
      if (!onHide) navigate("/leads/" + data.stage_id);
      if (onHide) onHide();
    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });

  return mutation;
};

export default useUpdateLead;
