import React from "react";
import SearchIcon from '../../img/icon/search2.svg'
import { useNavigate } from "react-router-dom";

const SearchButton = ({ name, navto }) => {

    const navigate = useNavigate()

  return (
    <div
      onClick={() => {
        navigate(navto);
      }}
      className="custom-btn medium sm-btn"
      style={{ paddingTop: "12px", paddingBottom: "12px", cursor: "pointer" ,marginRight: "5px" }}
    >
      <img src={SearchIcon} className="me-2" style={{ marginLeft: "5px" }} />

      <a
        style={{
          color: "#fff",
          width: "100%",
          display: "flex",
          cursor: "pointer",
        }}
      >
        {name}
      </a>
    </div>
  );
};

export default SearchButton;
