import React, { useEffect } from "react";
/// hooks used ///
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/// Imports of Component ///
import useCreatepwd from "../../libs/mutation/auth/useCreatepwd";

/// Icons Or Images import ///
import KeyIcon from "../../img/icon/key.svg";

/// StyleSheet import ///
import "../../Styles/auth.css";

const CreatepwdForm = () => {
  const navigate = useNavigate();

  const { auth } = useSelector((state) => state.authSlice);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { mutate: createpwd, isLoading } = useCreatepwd();

  useEffect(() => {
    if (!auth || !auth.status) {
      navigate("/login");
    }
  }, [auth]);

  return (
    <>
      <form
        onSubmit={handleSubmit(createpwd)}
        action=""
        className="signup-form px-lg-5"
      >
        {/*** new password ***/}
        <div className="form-field mb-3">
          <label className="fs-12 d-block pb-1">Enter New Password</label>
          <div className="position-relative">
            <img
              src={KeyIcon}
              alt=""
              className="position-absolute ms-3 mt-3 pt-1"
            />
            <input
              id="new_password"
              name="new_password"
              type="password"
              placeholder="**********"
              className="ps-5 newpasplace"
              {...register("new_password", {
                required: "This field is required",
                maxLength: 10,
                minLength: 8,
              })}
            />
            <span className="fs-12 text-red">
              {errors.new_password?.type === "required" && "New Password is Required"}
              {errors.new_password?.type === "minLength" &&
                "Enter Password is less than 8 digit"}
              {errors.new_password?.type === "maxLength" &&
                "Enter Password is more than 10 digit"}
            </span>
          </div>
        </div>
        {/*** new password ***/}

        {/*** confirm  password ***/}
        <div className="form-field mb-4">
          <label className="fs-12 d-block pb-1">Confirm New Password</label>
          <div className="position-relative">
            <img
              src={KeyIcon}
              alt=""
              className="position-absolute ms-3 mt-3 pt-1"
            />
            <input
              type="password"
              id="confirm_password"
              name="confirm_password"
              placeholder="htuvb@9489"
              className="ps-5"
              {...register("confirm_password", {
                required: "This field is required",
                maxLength: 10,
                minLength: 8,
              })}
            />
              <span className="fs-12 text-red">
              {errors.confirm_password?.type === "required" && "Confirm Password is Required"}
              {errors.confirm_password?.type === "minLength" &&
                "Enter Password is less than 8 digit"}
              {errors.confirm_password?.type === "maxLength" &&
                "Enter Password is more than 10 digit"}
            </span>
          </div>
        </div>
        {/*** confirm password ***/}

        {/***  buttons ***/}
        <div className="d-flex gap-3">
          <button
            type="submit"
            disabled={isLoading}
            className="custom-btn fs-20  rounded-pill w-100 py-3 "
          >
            Submit
          </button>
          <button
            type="submit"
            className="custom-btn border-bl-btn fs-20 rounded-pill w-100 py-3 "
            onClick={() => navigate("/login")}
          >
            Cancel
          </button>
        </div>
        {/***  buttons ***/}
      </form>
    </>
  );
};

export default CreatepwdForm;
