import fetch from "isomorphic-fetch";
import React, { useCallback, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { render } from "react-dom";

import "react-bootstrap-typeahead/css/Typeahead.css";
import api, { baseURL } from "../../api";
// import './styles.css';

const CACHE = {};
const PER_PAGE = 50;
// const SEARCH_URI = 'https://api.github.com/search/users';
const SEARCH_URI = baseURL + `management/leads`;

async function makeAndHandleRequest(query, page = 1) {
  console.log('q222333___', query)
  const res = await api.get(`management/leads?search_key=${query}&searching=1`);
  console.log(res?.data?.count)
  return { options: res?.data?.results, total_count: res?.data?.count };
}


function AsyncPaginationExample({ value, onChange, errors, name, placeholder }) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState("");

  // const handleInputChange = (q) => {
  //   setQuery(q);
  // };

  console.log(value)
  console.log('q222___', query)
  
  const handleSearch = useCallback((q) => {
    console.log('q___', q)
   
    // if (CACHE[q]) {
    //   setOptions(CACHE[q].options);
    //   return;
    // }

    setIsLoading(true);
    makeAndHandleRequest(q).then((res) => {
      // console.log(res)
      CACHE[q] = { ...res, page: 1 };

      setIsLoading(false);
      setOptions(res.options);
    });
  }, [query]);

  return (
    <div>
      {/* <label>legal Entity Name</label> */}
      <AsyncTypeahead
        id="async-pagination-example"
        isLoading={isLoading}
        labelKey="legal_entity_name"
        onChange={(selected) => {
          onChange(selected[0]);
        }}
        allowNew
        selected={value ? [value] : []}
        //   maxResults={PER_PAGE - 1}
        minLength={2}
        // onInputChange={handleInputChange}
        //   onPaginate={handlePagination}
        onSearch={handleSearch}
        // onSearch={(e)=> console.log('e__', e)}
        options={options}
        paginate
        placeholder={placeholder}
        renderMenuItemChildren={(option) => {
          return (
            <div key={option.id}>
              <span >{option.legal_entity_name}</span>
            </div>
          );
        }}
        useCache={false}
      />
      {
        errors[name] && <span className="fs-12 text-red">{errors[name].message}</span>
      }
    </div>
  );
}

export default AsyncPaginationExample;

// render(<AsyncPaginationExample />, document.getElementById('root'));
