import React from "react";

const FilePreview = ({ data, setFileUpload, fileupload }) => {

  return (
    <div>
      {fileupload && <div>
        {fileupload.name} <button type="button" onClick={() => setFileUpload(null)}>X</button>
      </div>}
      {data && (
        <div>
          {data} 
          {/* <button type="button" onClick={() => setFileUpload(null)}>X</button> */}
        </div>
      )}
    </div>
  );
};

export default FilePreview;
