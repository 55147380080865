import React from "react";

const TabItem = ({  title, isActive, setActiveTab }) => {
  return (
    <li
      onClick={setActiveTab}
    //   className={`selectContainer custom-btn bg-transparent text-black medium fs-16 border-0 mb-md-0 mb-2 ${
    //     isActive ? "navitem active" : ""
    //   }`}
    className={`selectContainer custom-btn sm-btn ${isActive ? "active" : ""}`}
    >
      {title}
    </li>
  );
};

export default TabItem;
