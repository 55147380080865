import React, { useState } from "react";
/// hooks used ///
import { useSelector } from "react-redux";

/// Icons Or Images import ///
import TrashIcon from "../../../img/icon/trash.svg";

/// Imports of Component ///
import useToggle from "../../../hooks/useToggle";
import LeadGeneratedModal from "./LeadGeneratedModal";
import DeleteModal from "../../../ui/modals/DeleteModal";
import useDeleteFilterData from "../../../libs/mutation/manage/useDeleteFilterData";

const LeadGenerated = ({ index, data }) => {
  const [detail, toggleDetail] = useToggle(false);
  const [dele, setdele] = useState(false);

  const { id, name, ddtype, created_at } = data || {};
  const { filtername, filterid } = useSelector((state) => state.leadSlice);
  const { mutate: deletedropdown, isLoading } = useDeleteFilterData(
    id,
    filterid
  );

  const date = created_at.substring(0, 16);
  const datefinal = date.split("T").join(" ");
  const time = datefinal.substring(11, 13);

  const handleDelete = () => {
    deletedropdown();
    setdele(false);
  };

  return (
    <div>
      <div className="bg-white rounded-10 px-md-4 px-3 py-3 mb-3 shadow-sm">
        <table className="action-table w-100  manage-table">
          <tbody>
            <tr>
              <td className="action-first">
                <span className="number d-flex align-items-center justify-content-center rounded-circle fs-24 semibold">
                  {index + 1}
                </span>
              </td>

              <td className="action-mid">
                <h6 className="fs-12 text-sky regular  pb-md-2">Name</h6>
                <h5>{name}</h5>
              </td>

              <td className="action-mid" style={{ width: "200px" }}>
                <h6 className="fs-12 text-sky regular  pb-md-2">Created At</h6>
                <h5>{datefinal} {time >= 12 ? "PM" : "AM"}</h5>
              </td>

              <td className="action-last">
                <div className="d-flex align-items-center gap-xl-3 gap-2 justify-content-end">
                  <a
                    className=" selectContainer action-icon bg-red"
                    onClick={() => setdele(true)}
                  >
                    <img src={TrashIcon} />
                  </a>
                  <a className="selectContainer acco-info-btn" onClick={() => toggleDetail()}>
                    <i className="fa-solid fa-angle-down pe-none fs-20 align-middle"></i>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {detail && <LeadGeneratedModal index={index} data={data} />}
      {dele && (
        <DeleteModal
          disabled={isLoading}
          deletename={filtername}
          onDeleteClick={handleDelete}
          onNoClick={() => setdele(false)}
        />
      )}
    </div>
  );
};

export default LeadGenerated;
