import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import useLocalStorage from "../../../hooks/useLocalStorage";

const useGetCampaignSearch2 = ({pageToLoad, itemsPerPage}) => {
    // const {
    //     search_key,
    //     industry,
    //     pageToLoad,
    //     itemsPerPage,
    //     business_model,
    //     contact_department,
    //     location,
    //     potential_service,
    //     lead_owner,
    //     fortune_1000,
    //     business_potential,
    //   } = useSelector((store) => store.campaignSlice);
    
    
      const [data] = useLocalStorage("campaign");
      const [type] = useLocalStorage("campaignType");
    
     
    
    
      let params = {
        ...data,
        page: pageToLoad,
        items_per_page: itemsPerPage,
        organisation: type === "organisation" ? 1 : "",
        // potential_service: potential_service?.toString() || data?.potential_service  ||  "",
        // ...(search_key && { search_key }),
        // ...(industry && { industry: data?.industry && industry }),
        // ...(business_model && { business_model: data?.business_model && business_model }),
        // ...(contact_department && { contact_department: data?.contact_department && contact_department }),
        // ...(location && { location: data?.location && location }),
        // // ...(potential_service && { potential_service: data?.potential_service && potential_service }),
        // ...(lead_owner && { lead_owner: data?.lead_owner && lead_owner }),
        // ...(fortune_1000 !== null && {fortune_1000: data?.fortune_1000 || fortune_1000}),
        // ...(business_potential && { business_potential }),
      };
    
      console.log('paramsw__', params);
     
    
      const queryString = Object.keys(params)
        .filter((key) => params[key] !== "")
        .map((key) => `${key}=${params[key]}`)
        .join("&");
    
        console.log('query__', queryString);
    
      const queryresult = useQuery({
        queryKey: [`campaignSearch`, queryString],
        keepPreviousData: true,
        queryFn: async () => {
          const res = await api.get(`market/campaign?${queryString}`);
    
          return res?.data;
        },
      });
    
      return queryresult;
}

export default useGetCampaignSearch2