import React from 'react'
import BarChart from '../../ui/graphs/BarChart'
import useGetLeadsAllGraph from '../../libs/queries/graphs/useGetLeadsAllGraph';

const LocationofClient = ({data}) => {

  // const { data } = useGetLeadsAllGraph({});
  const { location_data, location_label, location_color } = data || {};

  return (
    <div className="bg-white py-4 px-4 border" 
    // style={{ width: "100%"}}
    >
      <div className="card-body">
        <h3 className="mb-3">Location of Client</h3>
        {
          location_data?.length?
          <BarChart data={location_data} datalabel={location_label} datacolor={location_color} />
          :
          <div className='my-4 d-flex justify-content-center'>
          No Data
          </div>
        }
      </div>
    </div>
  )
}


export default LocationofClient