import React from "react";
import api from "../../../api/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const useUpdateOwner = (contactId, id, leadid) => {
 
    let data = {
      contact_ids: contactId,
      new_owner_ids: id,
    };
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await api.post(`management/leadcontact/update-owners/`, data);
      return res.data;
    },
    onSuccess: (data) => {
      // queryClient.invalidateQueries([`leads`, { id: leadid }]);
      queryClient.invalidateQueries([`leads`]);
      if(!data?.status){
        toast.error(data.message);
      }
      else{
        toast.success(data.message);
      }
      
    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });

  return mutation;
};

export default useUpdateOwner;
