import React from "react";
import TabItem from "../../ui/threetabs/TabItem";
import { useDispatch, useSelector } from "react-redux";
import { setDateType } from "../../slice/dashboardSlice";
import TabContent from "../../ui/threetabs/TabContent";
import PieChart from "../../ui/graphs/PieChart";
import IndustryMonth from "./IndustryMonth";
import ProposalIndustry from "../../libs/queries/dashboard/ProposalIndustry";
import IndustryWeek from "./IndustryWeek";
import IndustryDay from "./IndustryDay";
import useProposalService from "../../libs/queries/dashboard/useProposalService";
import ServiceDay from "./ServiceDay";
import ServiceWeek from "./ServiceWeek";
import ServiceMonth from "./ServiceMonth";

const ProposalTab = ({ isIndustry, type }) => {
  const dispatch = useDispatch();

  
  
  const { dateType } = useSelector((store) => store.dashboardSlice);

  const { data: proposalindustry } = ProposalIndustry({
    proposalIndustryType: type,
    date_filter: dateType,
  });

  
  const { data: proposalservice } = useProposalService({
    proposalServiceType: type,
    date_filter: dateType,
  });
  
 

  return (
    <div
      className="tab-pane fade active show"
      id="industry"
      aria-labelledby="industry-tab"
    >
      <div className="sub-tab nav border-0 gap-2 pb-3 justify-content-end">
        <TabItem
          title="Days"
          isActive={dateType === "day"}
          setActiveTab={() => dispatch(setDateType("day"))}
        />
        <TabItem
          title="Week"
          isActive={dateType === "week"}
          setActiveTab={() => dispatch(setDateType("week"))}
        />
        <TabItem
          title="Month"
          isActive={dateType === "month"}
          setActiveTab={() => dispatch(setDateType("month"))}
        />
      </div>

      <div className="outlet">
        <TabContent isActive={dateType === "day"}>
          {isIndustry === true ? (
            <IndustryDay dayData={proposalindustry} />
          ) : (
            <ServiceDay dayData={proposalservice} />
          )}
        </TabContent>

        <TabContent isActive={dateType === "week"}>
          {isIndustry === true ? (
            <IndustryWeek weekData={proposalindustry} />
          ) : (
            <ServiceWeek weekData={proposalservice} />
          )}
        </TabContent>

        <TabContent isActive={dateType === "month"}>
          {isIndustry === true ? (
            <IndustryMonth monthData={proposalindustry} />
          ) : (
           <ServiceMonth monthData={proposalservice} />
          )}
          {/* <IndustryMonth  monthData={proposalindustry} /> */}
        </TabContent>
      </div>
    </div>
  );
};

export default ProposalTab;
