import React from 'react'
import api from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";


const useDeleteEscalationMember = ({id}) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: async () => {
            const res = await api.get(`crm/escalationmember/delete/${id}`)
            return res.data;
        },
        onSuccess: data => {
           
            queryClient.invalidateQueries([`crm`])   
            toast.success(data.message);
        },
        onError: (error) => {
            toast.error(`${error["response"].data.message}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
              }); 
          },
          enabled: id ? true : false
    });
    return mutation;

}

export default useDeleteEscalationMember