const NoButton = ({onClick}) => {
    return (
      <button
        type="button"
        className="custom-btn close-modal py-2 border-bl-btn px-4 rounded-11"
        onClick={onClick}
      >
        No
      </button>
    );
  };
  
  export default NoButton;