import React, { useEffect, useState } from "react";
import Target from "../../../img/target.png";
import useProfile from "../../../libs/queries/profile/useProfile";
import { useForm } from "react-hook-form";
import useUpdateTarget from "../../../libs/mutation/updatetarget/useUpdateTarget";

const Targets = () => {
  const { data } = useProfile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate: updatetarget, isLoading } = useUpdateTarget(() => setIsModalOpen(false));
  const { targets } = data || {};
  const {
    accepted_leads_generated,
    proposal_submission,
    meeting_organized,
    assignment_recieved,
    onboarding_renewal,
  } = targets || {};


  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm();

  useEffect(() => {
    if (data) {
      reset({
        accepted_leads_generated,
        proposal_submission,
        meeting_organized,
        assignment_recieved,
        onboarding_renewal,
      });
    }
  }, [data]);

  

  return (
    <div>
      <a
        style={{ cursor: "pointer" }}
        onClick={() => {
          setIsModalOpen(true);
        }}
        className="collapse-butn medium p-4 d-block"
        data-bs-toggle="collapse"
        data-bs-target="#targets"
        aria-expanded={isModalOpen}
      >
        Targets
      </a>
      {isModalOpen && (
        <div className="collapse open-collapse" id="targets">
          <div className="bg-sky py-3 px-4 d-flex align-items-center gap-3">
            <img src={Target} width="52" height="52" alt="" />
            <h4 className="semibold text-white fs-20">Targets</h4>
          </div>
          <form onSubmit={handleSubmit(updatetarget)} className=" px-4 py-3">
            <div className="pb-3">
              <label htmlFor="" className="d-block medium fs-14 pb-2">
                Accepted Leads Generated
              </label>
              <input
                type="text"
                className="form-field"
                placeholder=""
                {...register("accepted_leads_generated", { required: true })}
              />
            </div>

            <div className="pb-3">
              <label htmlFor="" className="d-block medium fs-14 pb-2">
                Proposal Submission
              </label>
              <input
                type="text"
                className="form-field"
                placeholder=""
                {...register("proposal_submission", { required: true })}
              />
            </div>

            <div className="pb-3">
              <label htmlFor="" className="d-block medium fs-14 pb-2">
                Meeting/E-meeting Organised
              </label>
              <input
                type="text"
                className="form-field"
                placeholder=""
                {...register("meeting_organized", { required: true })}
              />
            </div>

            <div className="pb-3">
              <label htmlFor="" className="d-block medium fs-14 pb-2">
                Pilot Assignment Received
              </label>
              <input
                type="text"
                className="form-field"
                placeholder=""
                {...register("assignment_recieved", { required: true })}
              />
            </div>

            <div className="pb-3">
              <label htmlFor="" className="d-block medium fs-14 pb-2">
                Onboarding & Agreeement & Renewal
              </label>
              <input
                type="text"
                className="form-field"
                placeholder=""
                {...register("onboarding_renewal", { required: true })}
              />
            </div>

            <div className="d-flex border-0 butn-group gap-3 pt-2">
              <button
                type="submit"
                className="custom-btn border-0 py-2 rounded-4 raleway medium"
              >
                Save
              </button>
              <button
                type="button"
                className="custom-btn close-modal py-2 rounded-4 bg-white border-bl-btn px-4 raleway semibold"
                data-bs-toggle="collapse"
                data-bs-target="#targets"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Targets;
