import React from "react";
import api, { Imageapi } from "../../../api/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Endpoints } from "../../../api/Endpoints";
import { useNavigate } from "react-router-dom";

const useUpdateAgreement = (id, onHide) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data) => {
      const formdata = new FormData();

      for (const name in data) {
        if (name !== "record_of_signed_copy") {
          if (name === "service_offering") {
            data[name]?.forEach((element) => {
              formdata.append(name, element);
            });
          } else {
            formdata.append(name, data[name]);
          }
        } else if(name==="record_of_signed_copy") {
          formdata.append(name, data[name][0]);
        }
      }
      const res = await Imageapi.post(Endpoints.updateAgreement + id, formdata);
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([`agreementlist`]);
      toast.success(data.message);
      if (onHide) onHide();
      if(!onHide){
        navigate('/agreement')
      }
    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });

  return mutation;
};

export default useUpdateAgreement;
