import api from "../../../api/index";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useCreateFilterData = (filterid, redirectTo) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data) => {
      data.ddtype = filterid;
      const res = await api.post("record/dropdown-value/create/", data);

      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([`filters/single`, { id: filterid }]);
      toast.success(data.message);
      if (redirectTo) {
        navigate(redirectTo);
      }
    },

    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });

  return mutation;
};

export default useCreateFilterData;
