import React from 'react'
import api from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const useCreateContactLog = ({ id }) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const mutation = useMutation({
      mutationFn: async (data) => {
        data.crm = id;
        // console.log(data)
        const res = await api.post("crm/contactlogs/create", data);
        return res.data;
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries([`contactlog`]);
        toast.success(data.message);
        navigate(`/crm/lastcontactlogs/` + data?.data?.crm);
      },
  
      onError: (error) => {
        toast.error(`${error["response"].data.message}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      },
    });

    return mutation;
}

export default useCreateContactLog