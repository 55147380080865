import React, { useState } from "react";
import Heading from "../../ui/modals/Heading";
import Search from "../../ui/search/Search";
import CampaignHeader from "../../Components/marketintelligence/CampaignHeader";
import CampaignTableContainer from "../../Components/marketintelligence/CampaignTableContainer";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../ui/pagination";
import {
  setPageToLoad,
  setItemsPerPage,
  setSearch,
  setIndustrySectorId,
  setBusinessModalId,
  setContactDepId,
  setlocationId,
  setServiceOffer,
  setLeadOwner,
  setIsFortune,
  setBusinessPotential,
} from "../../slice/campaignSlice";
import useGetCampaignSearch from "../../libs/queries/market/useGetCampaignSearch";
import { Endpoints } from "../../api/Endpoints";
import CampaignFilter from "../../ui/dropdown/CampaignFilter";
import useGetCampaignSearch2 from "../../libs/queries/market/useGetCampaignSearch2";

const CampaignList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [checkBoxAdd, setCheckBoxAdd] = useState([]);
  const dispatch = useDispatch();
  const { data: campaigndata } = useGetCampaignSearch();
  const { data: campaigndata2 } = useGetCampaignSearch2({pageToLoad: 1, itemsPerPage: 200});
  const { itemsPerPage } = useSelector((state) => state.campaignSlice);


  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <Heading
          showSortt={setIsOpen}
          sortComponent={
            <CampaignFilter
              showSort={isOpen}
              onIndustrySectorSelect={(value) =>
                dispatch(setIndustrySectorId(value))
              }
              onBusinessModalSelect={(value) =>
                dispatch(setBusinessModalId(value))
              }
              onContactDepSelect={(value) => dispatch(setContactDepId(value))}
              onLocationSelect={(value) => dispatch(setlocationId(value))}
              onServiceOfferSelect={(value) => dispatch(setServiceOffer(value))}
              onUserSelect={(value) => dispatch(setLeadOwner(value))}
              onFortuneSelect={(value) => dispatch(setIsFortune(value))}
              onBusinessPotentialSelect={(value) =>
                dispatch(setBusinessPotential(value))
              }
            />
          }
          search={<Search onSearch={(value) => dispatch(setSearch(value))} />}
          heading="Document Repository"
          url={Endpoints.campaignCsv}
        />
        <CampaignHeader data={campaigndata2} checkBoxAdd={checkBoxAdd} />
        <Pagination
          onPageChange={(value) => dispatch(setPageToLoad(value))}
          onItemsChanged={(value) => dispatch(setItemsPerPage(value))}
          totalPages={Math.ceil(campaigndata?.count / itemsPerPage)}
          defaultCount={itemsPerPage}
        />
        <CampaignTableContainer
          setCheckBoxAdd={setCheckBoxAdd}
          checkBoxAdd={checkBoxAdd}
        />
      </div>
    </div>
  );
};

export default CampaignList;
