import React, { useEffect, useMemo, useState } from "react";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import PlusButton from "../../ui/buttons/PlusButton";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from "moment";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DatePicker from 'react-datepicker';
import AddMeetingModal from "../../ui/modals/AddMeetingModal";
import useGetCalendarEvents from "../../libs/queries/calendar/useGetCalendarEvents";
import useAllUsers from "../../libs/queries/profile/useAllUsers";
import usePracticeHead from "../../libs/queries/calendar/usePracticeHead";
import CalendarFilter from "../../ui/dropdown/CalendarFilter";
// import DatePicker from 'react-date-picker';
// import 'react-calendar/dist/Calendar.css';
// import 'react-date-picker/dist/DatePicker.css';
// import 'react-datepicker/dist/react-datepicker.css';

function CustomToolbar({ view, date, onView }) {
  const formattedDate = date?.toLocaleDateString();
  useEffect(()=>{
    if(date){
      onView('day')
    }
  },[date])
  return (
    <div className="rbc-toolbar">
      <span style={{
        display: 'flex',
        justifyContent: 'start'
      }} className="rbc-toolbar-label">
        {formattedDate? moment(formattedDate)?.format('MMM DD, YYYY') : ''}
      </span>
      <span className="rbc-btn-group">
        <button
          type="button"
          onClick={() => onView('day')}
          className={`rbc-btn ${view === 'day' ? 'rbc-active' : ''}`}
        >
          Day
        </button>
        <button
          type="button"
          onClick={() => onView('week')} 
          className={`rbc-btn ${view === 'week' ? 'rbc-active' : ''}`}
        >
          Week
        </button>
        <button
          type="button"
          onClick={() => onView('work_week')}
          className={`rbc-btn ${view === 'work_week' ? 'rbc-active' : ''}`}
        >
          Work Week
        </button>
        <button
          type="button"
          onClick={() => onView('month')}
          className={`rbc-btn ${view === 'month' ? 'rbc-active' : ''}`}
        >
          Month
        </button>
      </span>
    </div>
  );
}

function CalendarTab(){
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [modalShow, setModalShow] = useState(false);
    const [practiceId, setPracticeId] = useState([]);
    const [modalKey, setModalKey] = useState('mymodal');
    const localizer = momentLocalizer(moment);
    const {data: practiceHeads} = usePracticeHead();


    const currentDate = new Date(selectedDate);

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    const startDate = new Date(year, month, 1);

    const endDate = new Date(year, month + 1, 0);

    function formatDateToCustomFormat(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
    
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    function formatEndDateToCustomFormat(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
    
      return `${year}-${month}-${day} ${'11'}:${'59'}:${'00'}`;
    }

    // console.log('star__', formatDateToCustomFormat(startDate));
    // console.log('end_', endDate)

    const {data: eventList} = useGetCalendarEvents({
      // startDate: '2023-01-01 00:00:00',
      // endDate: '2023-01-31 23:59:59',
      startDate: formatDateToCustomFormat(startDate),
      endDate: formatEndDateToCustomFormat(endDate),
      filter_by_users: practiceId?.toString()
    });

    console.log('sele__', selectedDate)
    const handleDateChange = (date) => {
      console.log('calind__', date)
      setSelectedDate(date);
    };

    const handleDateSelect = (slotInfo) => {
      // Update the selected date when a date is selected in the month view
      console.log('slot__', slotInfo?.start)
      setSelectedDate(slotInfo.start);
      // setCurrentView(Views.DAY); // Switch to the day view when a date is selected
    };
    // let arr = [];
    const meetingsList = useMemo(()=>{
      let arr = [];
      eventList?.data?.forEach((item)=> {
        arr= arr.concat(item?.scheduleItems)
      })
      return arr;
    },[eventList])

    // console.log('meetingList__', JSON.stringify(meetingsList,null,4))
    const formattedData = 
    eventList?.data?.filter((item)=> item?.subject)?.map((item) => ({
    // eventList?.data?.[0]?.scheduleItems?.filter((item)=> item?.subject)?.map((item) => ({
    // meetingsList.filter((item)=> item?.subject)?.map((item) => ({
      title: item.subject,
      start: new Date(item.start.dateTime),
      end: new Date(item.end.dateTime),
    }));
    
    // console.log('checkconverted__',JSON.stringify(formattedData, null, 4));

    const events = [
      {
        title: "Meeting",
        start: new Date("2023-09-23T09:30:00.0000000"),
        end: new Date("2023-09-23T10:00:00.0000000")
      },
      {
        title: "Lunch",
        start: new Date("2023-09-26T09:00:00.0000000"),
        end: new Date("2023-09-26T09:30:00.0000000")
      },
      {
        title: "Appointment",
        start: new Date("2023-09-26T09:30:00.0000000"),
        end: new Date("2023-09-26T10:00:00.0000000")
      }
    ];
    return(
        <div className="main py-4 position-relative">
            <div className="container">
            <Subheader
            leftsideshow={<TwoButtonHolder label={"Calendar"} />}
            rightsideshow={
                <PlusButton
                name="Add Meeting"
                navto={'meeting'}
                onMeeting={setModalShow}
                meetingState={modalShow}
                />
            }
            />

            {/* <div className="d-flex gap-4 justify-content-between"> */}
            <div className="row">
              <div
              style={{
                // position: 'relative'
              }}
              className="col-sm-4 parent-div">
                <div 
                // className="date-picker-container" 
                style={{
                  // backgroundColor: 'green',
                  // width: '200px'
                }}>
                <DatePicker
                calendarClassName="custom-calendar"
                // calendarClassName="custom-calendar bg-green"
                // className="shadow-sm"
                inline
                showYearDropdown
                // yearDropdownItemNumber={10}
                // isOpen={true}
                // selected={selectedDate}
                open={true}
                // value={selectedDate}
                selected={selectedDate}
                // closeCalendar={false}
                onChange={handleDateChange}
              />
              </div>

                <div>
                  <div className='d-flex py-2 px-0 align-items-center ms-3'>
                    <div className='me-2'>
                      <img
                        style={{
                          width: '12px',
                          height: '12px',
                        }}
                        src='assets/img/calendar.png'
                      />
                    </div>

                    <div className="fs-14 bold">
                      Add Calendar
                    </div>
                  </div>
                <CalendarFilter
                onPracticeHeadSelect={setPracticeId}
                />
                </div>
              </div>
              
              <div style={{zIndex: 0}} className="col-sm-8">
              <Calendar
              className=""
              localizer={localizer}
              selectable
              events={formattedData}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              views={['month', 'week', 'day', 'work_week', 'agenda']}
              // onNavigate={handleNavigate}
              date={selectedDate || new Date()}
              // defaultDate={selectedDate}
              onSelectSlot={handleDateSelect}
              
              components={{
                toolbar: CustomToolbar,
              }}
              />
              </div>
            </div>

            </div>

            {/* {modalShow &&
            <AddMeetingModal
            />
            } */}
            <div key={modalKey}>
            <AddMeetingModal 
            show={modalShow} 
            onHide={() => setModalShow(false)} 
            email={JSON.parse(localStorage.getItem("profileData"))?.email}
            onSuccess={()=>{
              setModalKey(Math.floor(Math.random()*100000).toString());
              console.log('jfbdjbhknfdjnbjhbsdjkbjhsdbhjfdbhjdbjbfjhsdbhgfbdshgvdshgvfdsbnvsdhvgh')
            }}
            />
            </div>
        </div>
    )
}

export default CalendarTab;