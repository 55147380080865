import { Endpoints } from "../../../api/Endpoints";
import api from "../../../api/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const useUpdateStage = (id, onHide) => {
  const queryClient = useQueryClient();
  const updateStage = useMutation({
    mutationFn: async (data) => {
      const res = await api.post(Endpoints.updateManageStage + id, data);
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(`stagelist`, id);
      toast.success(data.message);
      if (onHide) onHide();
    },
    onError: (error) => {
      toast.error(`${error['response'].data.message}`);
    },
  });
  return updateStage;
};

export default useUpdateStage;
