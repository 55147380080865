import React, { useState } from "react";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import Heading from "../../ui/modals/Heading";
import Search from "../../ui/search/Search";
import AlertContainer from "../../Components/alerts/AlertContainer";
import usePendingReminder from "../../libs/queries/alerts/usePendingReminder";
import { useDispatch, useSelector } from "react-redux";
import { setalertType } from "../../slice/alertSlice";
import AlertFilter from "../../ui/dropdown/AlertFilter";
import { Endpoints } from "../../api/Endpoints";
import Pagination from "../../ui/pagination";

const Alerts = () => {
  const dispatch = useDispatch();
  const { alerttype } = useSelector((state) => state.alertSlice);
  const [alertStartDate, setAlertStartDate] = useState("");
  const [alertEnd, setAlertEnd] = useState("");
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [pageToLoad, SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { data: pendingreminder } = usePendingReminder({
    page: pageToLoad,
    itemsPerPage: itemsPerPage,
    pendingtype: alerttype,
    end_date: alertEnd,
    start_date: alertStartDate,
    search: search,
  });

  const queryParams = {
    organisation: alerttype === "organisation" ? 1 : "",
    download: 1, 
    search_key: search || '',
    start_date: alertStartDate || '',
    end_date: alertEnd || '',
    page: pageToLoad || 1,
  };


  const queryString = Object.keys(queryParams)
  .filter((key) => queryParams[key] !== "")
  .map((key) => `${key}=${queryParams[key]}`)
  .join("&");

 

  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <Subheader
          leftsideshow={
            <TwoButtonHolder
              label={"Alerts"}
              onSelect={(value) => dispatch(setalertType(value))}
              type={alerttype}
            />
          }
        />
        <Heading
          search={<Search onSearch={setSearch} />}
          heading="Alerts"
          showSortt={setIsOpen}
          url={
            Endpoints.pendingCSV +
            "?" + queryString
            // `download=1&organisation=${alerttype == "organisation" ? 1 : ""}`
          }
          sortComponent={
            <AlertFilter
              onAlertStart={setAlertStartDate}
              onAlertEnd={setAlertEnd}
              showSort={isOpen}
            />
          }
        />

        <Pagination
          onPageChange={SetPageToLoad}
          onItemsChanged={setItemsPerPage}
          totalPages={Math.ceil(pendingreminder?.count / itemsPerPage)}
          defaultCount={itemsPerPage}
        />

        <div className="row pt-4">
          <AlertContainer
            datareminder={pendingreminder}
            type={alerttype}
          />
        </div>
      </div>
    </div>
  );
};

export default Alerts;
