import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DropdownInput from "../../ui/inputs/DropdownInput";
import { useForm, Controller } from "react-hook-form";
import CheckboxInput from "../../ui/inputs/CheckboxInput";
import useCreateDocument from "../../libs/mutation/market/useCreateDocument";
import InputBox from "../../ui/inputs/InputBox";
import DateInputBox from "../../ui/inputs/DateInputBox";
import useGetLeadListContact from "../../libs/queries/leads/useGetLeadListContact";
import FilePreview from "../leads/FilePreview";
import LinkInputBox from "../../ui/inputs/LinkInputBox";
import useProfile from "../../libs/queries/profile/useProfile";
import ResearcherSearchInput from "./ResearcherSearchInput";
import ForClientSeachInput from "./ForClientSeachInput";
import { Typeahead } from "react-bootstrap-typeahead";
import useUsers from "../../libs/queries/profile/useUsers";

const NewDocumentForm = () => {
  const navigate = useNavigate();
  const [fileupload, setFileUpload] = useState(null);
  const [selectedLead, setSelectedLead] = useState(null);
  const [forClient, setForClient] =  useState(null)
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({ mode: "all" });

  const { data: userprofile } = useProfile();
  const { fullname } = userprofile || {};
  const { mutate: createdocument } = useCreateDocument();
  const { data: contactlist } = useGetLeadListContact({
    stage_id: null,
    leadType: false,
  });

  const profile = JSON.parse(localStorage.getItem("profileData"));
  console.log(profile.fullname);

  var someDate = new Date();
  var numberOfDaysToAdd = 0;
  var date = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
  var defaultValue = new Date(date).toISOString().split("T")[0];

  const handleChange = (event, onChange) => {
    setFileUpload(event.target.files[0]);
    onChange(event.target.files[0]);
  };

  const {data: userdata} = useUsers()
  console.log('doct__', watch("doc_type"))

  const researcherList = userdata?.results

  const handleLegalChange = (value, onChange) => {
    // console.log(value?.[0])
    if (value?.customOption) {
      setSelectedLead(null);
    } else {
      setSelectedLead(value?.[0]);
    }
    onChange(value?.[0]);
    // console.log(value);
  };

  const handleForClientChange = (value, onChange) => {
    if (value?.customOption) {
      setForClient(null);
    } else {
      setForClient(value);
    }
    onChange(value);
    // console.log(value);
  }

  return (
    <div className="bg-white p-4">
      <form
        onSubmit={handleSubmit(createdocument)}
        action=""
        className="add-form"
      >
        <div className="row  gx-lg-5 align-items-center">
          <Controller
            name="name"
            control={control}
            rules={{ required: "New Document is Required" }}
            render={({ field: { value, onChange, name } }) => {
              return (
                <InputBox
                  label="New Document"
                  name={name}
                  placeholder="Enter Name"
                  errors={errors}
                  type="text"
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          />

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            {/* <DropdownInput
              // isUsers={true}
              name="researcher"
              label="Researcher"
              register={register}
              errors={errors}
            /> */}
            <label className="d-block text-sky fs-12 pb-2">Researcher</label>
            <Controller
              // defaultValue={isView? updateData?.lead_contact : ''}
              name={"researcher"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Select Owner From The Given Options"
                },
              }}
              render={({ field: { value, onChange } }) => {
            
                return (
                  <Typeahead
                  id=""
                  labelKey="fullname"
                  allowNew
                  options={researcherList}
                  placeholder="Search Researcher"
                  // onChange={(value) => handleLegalChange(value, onChange)}
                  onChange={(list) => {
                    let value = list.map((items) => {
                      return items.fullname;
                    });
                    handleLegalChange(value, onChange)
                    
                    // onChange(value?.[0]);
                    // setSelectedLead(list[0]);
                  }}
                  />
                );
              }}
            />
            {/* <Controller
              name={"researcher"}
              control={control}
              rules={{ required: "Researcher is Required" }}
              render={({ field: { value, onChange, name } }) => {
                // console.log("see value", value);
                return (
                  <ResearcherSearchInput
                    value={value}
                    placeholder={"Enter Researcher"}
                    onChange={(value) => handleLegalChange(value, onChange)}
                    errors={errors}
                    name={name}
                  />
                );
              }}
            /> */}
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <label className="d-block text-sky fs-12 pb-2">For Client</label>
            <Controller
              name={"for_client"}
              control={control}
              rules={{ required: "For Client is Required" }}
              render={({ field: { value, onChange, name } }) => {
                // console.log("see value", value);
                return (
                  <ForClientSeachInput
                  value={value}
                    placeholder={"Enter For Client"}
                    onChange={(value) => handleForClientChange(value, onChange)}
                    errors={errors}
                    name={name}
                  />
                  // <ResearcherSearchInput
                  //   value={value}
                  //   placeholder={"Enter Researcher"}
                  //   onChange={(value) => handleLegalChange(value, onChange)}
                  //   errors={errors}
                  //   name={name}
                  // />
                );
              }}
            />
            {/* <Controller
              name="for_client"
              control={control}
              rules={{ required: "For Cient is Required" }}
              render={({ field: { value, onChange, name } }) => {
                return (
                  <DropdownInput
                    // isContact={true}
                    name={name}
                    label="For Client"
                    register={register}
                    errors={errors}
                  />
                );
              }}
            /> */}
          </div>
          {/* </div> */}

          <Controller
            name="date_of_upload"
            control={control}
            defaultValue={defaultValue}
            render={({ field: { name, onChange, value } }) => {
              return (
                <DateInputBox
                  label="Date of Upload"
                  name={name}
                  disabled="disabled"
                  errors={errors}
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
            rules={{
              required: {
                value: true,
                message: "Please select a date.",
              },
            }}
          />

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={30}
              name="doc_type"
              label="Doc Type"
              register={register}
              errors={errors}
            />
          </div>

          <Controller
            name="description"
            control={control}
            rules={{ required: "Description is Required" }}
            render={({ field: { value, onChange, name } }) => {
              return (
                <InputBox
                  label="Description"
                  name={name}
                  placeholder="Enter Description"
                  errors={errors}
                  type="text"
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          />

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={31}
              name="skills"
              label="Skills"
              register={register}
              errors={errors}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <Controller
              name="paid"
              control={control}
              // rules={{ required: "Company Name is Required" }}
              render={({ field: { value, onChange, name } }) => {
                // console.log(value);
                return (
                  <CheckboxInput
                    label="Paid"
                    name={name}
                    checked={value || false}
                    // value={value || ""}
                    onChange={onChange}
                  />
                );
              }}
            />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
            <DropdownInput
              id={34}
              name="doc_category"
              label="Doc Category"
              register={register}
              errors={errors}
            />
          </div>

          {/* <Controller
            name="uploaded_by"
            control={control}
            defaultValue={profile?.fullname}
            // rules={{ required: "Uploaded by is Required" }}
            render={({ field: { value, onChange, name } }) => {
              return (
                <InputBox
                  label="Uploaded By"
                  disabled="disabled"
                  defaultValue={profile?.fullname}
                  name={name}
                  placeholder="Enter Name"
                  // errors={errors}
                  type="text"
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          /> */}

          <div className="col-lg-3 col-md-4 col-sm-6 pb-0">
            <span>
              {/* <input type="file" id="upload" className="d-none" /> */}
              {/* {watch("doc_type") != 646 ?  */}
              {/* {watch("doc_type") != 646 && */}
              <Controller
                name="file"
                control={control}
                rules={{required:  'File is Required'}}
                render={({ field: { value, onChange } }) => {
                  return (
                    <input
                      type="file"
                      id="upload"
                      className="d-none"
                      files={value}
                      // accept=".doc, .pdf,'docx','xls','xlsx','eml'"
                      accept={watch("doc_type") == 643 ?".doc, .docx": 
                      watch("doc_type") == 642 ?".pdf": watch("doc_type") == 644 ? ".eml" :
                      watch("doc_type") == 645 ? ".xls, .xlsx": 'image/jpeg, image/png, image/gif'}
                      onChange={(event) => handleChange(event, onChange)}
                      multiple
                    />
                  );
                }}
              />
              {/* } */}
              
              {watch("doc_type") == 646 ? (
                <Controller
                  name="doc_link"
                  control={control}
                  rules={{ required: "Link is Required" }}
                  render={({ field: { value, onChange, name } }) => {
                    return (
                      <div>
                        <LinkInputBox
                          // label="New Document"
                          name={name}
                          placeholder="Enter Link"
                          errors={errors}
                          type="text"
                          value={value || ""}
                          onChange={onChange}
                        />
                      </div>
                    );
                  }}
                />
              ) : (
                <div>
                <label
                  htmlFor="upload"
                  className="px-4 py-2 rounded-2 text-nowrap attach-btn"
                >
                  Attach a File
                </label>
                <br/>
                {errors?.file && <span className="fs-12 text-red">
                  {errors?.file?.message}
                </span>}
                </div>
              )}
            </span>
            {/* {errors?.file && <span className="fs-12 text-red">
                  {errors?.file?.message}
              </span>} */}
            <span>
              {fileupload && (
                <FilePreview
                  fileupload={fileupload}
                  data={""}
                  setFileUpload={setFileUpload}
                />
              )}
            </span>
          </div>
        </div>
        <div className="d-flex border-0 butn-group justify-content-center gap-3 pt-4">
          <button
            type="submit"
            className="custom-btn border-0 py-2 rounded-3 raleway medium"
          >
            Save
          </button>
          <button
            type="button"
            onClick={() => navigate("/documentrepository")}
            className="custom-btn  py-2  rounded-3 border-bl-btn px-4 raleway semibold"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewDocumentForm;
