import React from "react";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useNextFollowUp = ({ followupType, page, itemsPerPage, search, start_date, end_date }) => {
  const queryParams = {
    page: page || 1,
    search_key: search || "",
    start_date: start_date || "",
    end_date: end_date || "",
    items_per_page: itemsPerPage || 500,
    organisation: followupType === "organisation" ? 1 : "",
  };

  const queryString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");

  const queryresult = useQuery({
    queryKey: [`nofollowup`, { queryString }],
    keepPreviousData: true,
    queryFn: async () => {
      const res = await api.get(`market/followup?${queryString}`);

      return res?.data;
    },
  });

  return queryresult;
};

export default useNextFollowUp;
