import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useEmailContent from "../../libs/queries/manage/useEmailContent";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import api from "../../api";
import { Endpoints } from "../../api/Endpoints";
import useSendEmail from "../../libs/mutation/emailtemplate/useSendEmail";
import { Controller, useForm } from "react-hook-form";
import InputBox from "../inputs/InputBox";
import useGetEscalationMember from "../../libs/queries/crm/useGetEscalationMember";
import BadgeInput from "../inputs/BadgeInput";
import { Modal } from "react-bootstrap";

const EmailModal = ({ onClose, contactId, crmid }) => {
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [emailContent, setEmailContent] = useState();

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    control,
    setError,
    clearErrors,
    getValues,
    watch,
    reset,
  } = useForm({ mode: "all" });

  const { mutate: sendEmail, isLoading } = useSendEmail(onClose);

  const { data } = useGetEscalationMember({
    memberType: null,
    id: crmid,
    // search: search
  });

  const externaldata = data?.results?.map((item) => item?.email);
  console.log(emailContent?.to_email);
  console.log(externaldata )
  //   const {data: emailContent, error} = useEmailContent(contactId);

  const getEmailContent = async () => {
    try {
      let resp = await api.get(window.location.pathname=="/agreement"? Endpoints.getAgreeementEmail + contactId :Endpoints.getEmailContent + contactId);
      // console.log('resp_', resp.data)
      if (resp && resp.data.status) {
        setEmailContent(resp.data);
        // console.log(emailContent)
      }
      if (resp && !resp.data.status) {
        // console.log('resp3_', resp.data)
        onClose();
        toast.error(resp.data?.message);
        // e.preventDefault();
        // return;
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (emailContent) {
      reset({
        attachments: emailContent?.attachements?.join(","),
      });
    }
  }, [emailContent]);

  useEffect(() => {
    if (contactId) {
      getEmailContent();
    }
  }, []);

  const handleSend = () => {
    // Implement sending logic here
    console.log("Sending email...");
  };

  const handleAttachmentChange = (e) => {
    const file = e.target.files[0];
    setAttachment(file);
  };

  console.log(externaldata, emailContent?.to_email)

  return (
    <>
    {/* <Modal
    
    show={true}
    fullscreen={'md-down'}
    // centered={false}
    dialogClassName=""
        // size='lg'
        // {...props}
      >
        <Modal.Header >
          <Modal.Title
            className='fs-16'
          >{" "}
          </Modal.Title> */}
          {/* <button
            onClick={onClose}
            type="button"
            className="bg-red border-0 rounded fs-12"
          >
            <span aria-hidden="true" style={{ color: 'white' }}>
              &times;
            </span>
          </button>
        </Modal.Header>
        <Modal.Body> */}

      {emailContent?.status && (
        <div
          // className="w-90"
          style={
            {
              // backgroundColor: 'red',
              // width: '100%',
              // maxHeight : '90%'
            }
          }
        >
          <form
            action=""
            className="add-form"
            
            onSubmit={handleSubmit(sendEmail)}
          >
            <div
              style={
                {
                
                  // backgroundColor: 'red',
                  // width: '100%',
                  // maxHeight : '90%'
                }
              }
              className="col-lg-5 mail-box py-3 rounded-10 shadow bg-white collapse show"
              id="mailbox"
            >
              <div className="px-4">
                <div className="d-flex gap-2 justify-content-end pb-2 align-items-end mail-action">
                  <a className="full-screen pb-1">
                    <img src="assets/img/icon/full-screen.svg" alt="" />
                  </a>
                  <a
                    onClick={onClose}
                    className="mail-close bg-red fs-12 d-flex align-items-center justify-content-center text-white selectContainer"
                    aria-expanded="true"
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </a>
                </div>

                <div style={{height: "70px", overflowY: "scroll"}}
                className="d-flex gap-2 pb-2 border-bottom"
                >
                  <span className="text-gray pt-2">To: </span>
                  <Controller
                    name="to_emails"
                    control={control}
                    defaultValue={
                      window.location.pathname.includes("crm")
                        ? [...(externaldata || []), emailContent?.to_email] 
                        : [emailContent?.to_email]
                    }
                    render={({ field }) => (
                    
                      <BadgeInput
                        value={field?.value}
                        onChange={field?.onChange}
                      />
                    )}
                  />
                  {/* <img src="assets/img/icon/mail-user.png" className=" mail-user" alt=""/> */}
                  {/* <input 
                    type="text" 
                    defaultValue={emailContent?.to_email}
                    className="semibold fs-16 raleway border-0" 
                    placeholder=""/> */}
                  {/* <input
                    defaultValue={
                      window.location.pathname.includes("crm")
                        ? externaldata + "," + emailContent?.to_email
                        : emailContent?.to_email
                    }
                    className="semibold fs-16 raleway border-0"
                    name="to_emails"
                    type="text"
                    placeholder=""
                    {...register("to_emails", { required: true })}
                  /> */}
                </div>
                <div className="py-2 border-bottom">
                  <input
                    name="subject"
                    defaultValue={emailContent?.subject}
                    type="text"
                    className="semibold fs-14 raleway border-0"
                    placeholder="Subject"
                    {...register("subject", { required: true })}
                  />
                </div>
                {/* <div 
                className="py-4 raleway fs-14"
                >
                <textarea name="" id="" cols="30" rows="6" 
                className="border-0 email-detail text-black fs-14 medium">
                </textarea>
                </div> */}
              </div>
              <div className="choose-heading px-4 pb-5 pt-2">
                <Controller
                  defaultValue={emailContent?.body}
                  name="body"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <ReactQuill
                        theme="snow"
                        value={value}
                        onChange={(body) => onChange(body)}
                        style={{ height: "140px" }}
                      />
                    );
                  }}
                />
              </div>
              <div className="ps-4 me-4" 
              style={{height: "50px", overflowY: "scroll"}}
              >
                {emailContent?.attachements?.map((item) => {
                  return (
                    <div
                    // style={{
                    //   marginBottom: '2px',
                    //   backgroundColor: 'rgb(240,240,240)',
                    // }}
                    >
                      <div
                        style={{
                          color: "#3366CC",
                          marginLeft: "2px",
                          // padding: '10px'
                        }}
                        className="fs-12 px-2 pt-2"
                      >
                        {item?.split("/")?.[3]?.toString()}
                      </div>
                    </div>
                  );
                })}

{/* <div
                    // style={{
                    //   marginBottom: '2px',
                    //   backgroundColor: 'rgb(240,240,240)',
                    // }}
                    >
                      <div
                        style={{
                          color: "#3366CC",
                          marginLeft: "2px",
                          // padding: '10px'
                        }}
                        className="fs-12 px-2 pt-2"
                      >
                        {'hjbbj.pdf'}
                      </div>
                    </div> */}

                    {/* <div
                    // style={{
                    //   marginBottom: '2px',
                    //   backgroundColor: 'rgb(240,240,240)',
                    // }}
                    >
                      <div
                        style={{
                          color: "#3366CC",
                          marginLeft: "2px",
                          // padding: '10px'
                        }}
                        className="fs-12 px-2 pt-2"
                      >
                        {'hjbbj.pdf'}
                      </div>
                    </div> */}

                    {/* <div
                    // style={{
                    //   marginBottom: '2px',
                    //   backgroundColor: 'rgb(240,240,240)',
                    // }}
                    >
                      <div
                        style={{
                          color: "#3366CC",
                          marginLeft: "2px",
                          // padding: '10px'
                        }}
                        className="fs-12 px-2 pt-2"
                      >
                        {'hjbbj.pdf'}
                      </div>
                    </div> */}
              </div>
              
              <div className="send-mail pt-3 px-4 d-flex gap-3 align-items-center">
                <button
                  type="submit"
                  // onClick={onClose}
                  className="selectContainer custom-btn medium rounded-10 semibold fs-16 py-1 px-4"
                >
                  Send
                </button>
                {/* <div>
                  <input
                    name="myImage"
                    type="file"
                    className="d-none"
                    id="file"
                    accept="image/*"
                  />
                  <label for="file" className="attachment text-center pe-auto">
                    <img src="assets/img/icon/attachment.svg" alt="" />
                  </label>
                </div> */}
              </div>
            </div>
          </form>
        </div>
      )}
    {/* </Modal.Body>
    </Modal> */}
    </>
  );
};

export default EmailModal;
