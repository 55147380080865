import React from "react";
import api from "../../../api";
import { Endpoints } from "../../../api/Endpoints";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useCheckOut = (isEnabled) =>{

    const getCheckOut = useMutation({
        mutationKey: ['checkout'],
        mutationFn: async () =>{
            const resp = await api.get(Endpoints.checkout);
            if(resp && resp.status){
                localStorage.setItem('isCheckedIn', JSON.stringify('usercheckedout'))
            }
            return resp.data
        }
    })

    return getCheckOut;
}

export default useCheckOut;