import React from "react";
import usePendingReminder from "../../libs/queries/alerts/usePendingReminder";
import { useSelector } from "react-redux";

const PendingReminder = ({
  data,
  type,
}) => {
  

  return (
    <div className="col-md-8">
      <div className="widget-box rounded-10 overflow-hidden shadow mb-4">
        <div className="widget-header py-2 px-3  bg-red">
          <h3 className="text-white medium py-1 fs-16">Pending Reminders</h3>
        </div>
        <div className="bg-white">
          <table
            className="w-100 alert-table"
            style={{ width: "100px", overflowX: "scroll" }}
          >
            <thead>
              <tr>
                <th className="fs-20 medium">Sr. no.</th>
                <th className="fs-20 medium">Alert Type</th>
                <th className="fs-20 medium">Client Name</th>
                <th className="fs-20 medium">Lead Name</th>
                <th className="fs-20 medium">Update at</th>
                {type === "organisation" && (
                  <th className="fs-20 medium">Username</th>
                )}
              </tr>
            </thead>
            <tbody style={{ width: "500px", overflowX: "scroll" }}>
              {data?.results?.map((item, index) => {
                const date = item?.updated_at?.substring(0, 10);
                const datefinal = date?.split("T").join(" ");
                const time = datefinal?.substring(11, 13);

                const nextdate = item?.created_at?.substring(0, 10);
                const nextdatefinal = nextdate?.split("T").join(" ");
                const nexttime = nextdatefinal?.substring(11, 13);

                const displaydate =
                  item?.alert === "Next Follow Up" ? nextdatefinal : datefinal;
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.alert}</td>
                    <td>{item?.alert.toLowerCase()?.includes("productivity alert")
                        ? "NA"
                        :item?.name}</td>
                    <td>{item?.alert.toLowerCase()?.includes("productivity alert")
                        ? "NA"
                        :item?.lead_detail?.legal_entity_name}</td>
                    <td>
                      {item?.alert.toLowerCase()?.includes("productivity alert")
                        ? "NA"
                        : displaydate}
                    </td>
                    {type === "organisation" && (
                      item?.alert.toLowerCase()?.includes("productivity alert")?
                      <td>{item?.username}</td>:
                      <td>{item?.lead_detail?.lead_generator_display}</td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PendingReminder;
