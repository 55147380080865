import React from "react";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useGetLeadListContactTypeahead = ({
    id, 
    page, 
    itemsPerPage, 
    leadType, 
    search,
    enabled,
    setIsLoading
    }) => {
    const queryParams = {
      page: page || 1,
      items_per_page: itemsPerPage || 50,
      organisation: leadType==='organisation'?1:'',
      search_key: search || '',
    };
  
    // console.log('enn2__', enabled)
  
    const queryString = Object.keys(queryParams)
        .filter(key => queryParams[key] !== '')
        .map(key => `${key}=${queryParams[key]}`)
        .join('&');
  
    const queryresult = useQuery({
      queryKey: [`leads`, {queryString}],
        refetchOnWindowFocus: false,
    //   keepPreviousData: true,
      enabled: enabled? true : false,
      queryFn: async () => {
    //    console.log('enn__', enabled)
        setIsLoading(true);
        const res = await api.get(`management/lead-contacts?${queryString}`);
        setIsLoading(false);
        
        return res?.data;
      },
    });
  
    return queryresult;
  };
  
  export default useGetLeadListContactTypeahead;
  