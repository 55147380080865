import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../api/index";
import { toast } from "react-toastify";

const useDeleteLead = (id) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await api.get(`management/lead/delete/${id}`);
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([`leads`]);
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
    enabled: id ? true : false,
  });

  return mutation;
};

export default useDeleteLead;


