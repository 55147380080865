import React, { useState } from "react";
import LeadListEdit from "./LeadListEdit";
import whiteEditIcon from "../../img/icon/edit-white.svg";
import hdfc from "../../img/hdfc.png";
import useGetLead from "../../libs/queries/leads/useGetLead";

const LeadListModal = ({ data }) => {
  const [edit, setEdit] = useState(false);
  const { id, lead: leaddetailsdata } = data || {};

  // console.log(data);

  const {
    legal_entity_name,
    global_hq,
    revenue,
    lead_generator_display,
    fortune_1000,
    incepted,
    india_operations,
    indian_presence,
    indian_presence_display,
    business_model_display,
    business_potential_display,
    industry_sector_display,
    lead_type_display,
    lead_owner_display,
    company_name,
    source_display,
    source_date,
    potential_service_offering_display,
    indian_hq,
    indian_hq_display,
    compnay_forecast_remark,
    stages_of_ops_india_display,
    news_research_repo,
    news_research_file,
    
  } = leaddetailsdata || {};

  // console.log(leaddetailsdata);

  const {
    name,
    email,
    title,
    mobile,
    linkedin_url,
    stage_of_contact_display,
    first_pitch_display,
    proposal_date,
    next_followup_date,
    timeline_to_aquire_display,
    last_remark,
  } = data || {};

  return (
    <>
      {!edit && (
        <div className="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3 ">
          <table className="action-table w-100">
            <tbody>
              <tr>
                <td className="action-first">
                  <div className="d-flex align-items-center gap-lg-4 gap-2">
                    {/* <input type="checkbox" /> */}
                    {/* <img
                      src={hdfc}
                      width="48"
                      height="48"
                      className="rounded-3"
                      alt=""
                    /> */}
                  </div>
                </td>

                <td className="action-mid">
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Legal Entity Name
                    </h6>
                    <h5 className="regular">{legal_entity_name}</h5>
                  </div>

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      India Operations
                    </h6>
                    <h5 className="regular">{india_operations}</h5>
                  </div>

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Industry/Sector
                    </h6>
                    <h5 className="regular">{industry_sector_display}</h5>
                  </div>

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">Source</h6>
                    <h5 className="regular">{source_display}</h5>
                  </div>

                  <div className="">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Source Date
                    </h6>
                    <h5 className="regular">{source_date}</h5>
                  </div>
                </td>
                <td className="action-mid">
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Global HQ
                    </h6>
                    <h5 className="regular">{global_hq}</h5>
                  </div>

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Indian Presence
                    </h6>
                    <h5 className="regular">{indian_presence_display}</h5>
                  </div>

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Lead Type
                    </h6>
                    <h5 className="regular">{lead_type_display}</h5>
                  </div>

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Potential Service Offerings
                    </h6>
                    <h5 className="regular">
                      {potential_service_offering_display}
                    </h5>
                  </div>

                  <div className="">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      {news_research_file === null ? "News/ Research Repository" : "News/ Research File"}
                    </h6>
                    <h5 className="regular">{news_research_file === null ? news_research_repo : news_research_file}</h5>
                  </div>
                </td>
                <td className="action-mid">
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">Revenue</h6>
                    <h5 className="regular">{revenue}</h5>
                  </div>
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Indian HQ
                    </h6>
                    <h5 className="regular">{indian_hq_display}</h5>
                  </div>
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Lead Generator
                    </h6>

                    <h5 className="regular">{lead_generator_display}</h5>
                  </div>
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Fortune 1000
                    </h6>
                    <h5 className="regular">
                      {fortune_1000 == true ? "Yes" : "No"}
                    </h5>
                  </div>
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Business Potential
                    </h6>
                    <h5 className="regular">{business_potential_display}</h5>
                  </div>
                </td>
                <td className="action-last">
                  <div className="d-md-flex justify-content-between">
                    <div>
                      <div className="pb-2">
                        <h6 className="fs-12 text-sky regular  pb-md-1">
                          Incepted
                        </h6>
                        <h5 className="regular">{incepted}</h5>
                      </div>

                      <div className="pb-2">
                        <h6 className="fs-12 text-sky regular  pb-md-1">
                          Business Mode
                        </h6>
                        <h5 className="regular text-yellow">
                          {business_model_display}
                        </h5>
                      </div>
                      <div className="pb-2">
                        <h6 className="fs-12 text-sky regular  pb-md-1">
                          Lead Owner
                        </h6>
                        <h5 className="regular">{lead_owner_display}</h5>
                      </div>
                      <div className="pb-2">
                        <h6 className="fs-12 text-sky regular  pb-md-1">
                          Company Name
                        </h6>
                        <h5 className="regular">{company_name}</h5>
                      </div>
                      <div className="pb-2">
                        <h6 className="fs-12 text-sky regular  pb-md-1">
                          Stage of Ops in India
                        </h6>
                        <h5 className="regular">
                          {stages_of_ops_india_display}
                        </h5>
                      </div>
                    </div>

                    <div>
                      <a
                        // href="#"
                        className="action-icon selectContainer"
                        onClick={() => setEdit(true)}
                      >
                        <img src={whiteEditIcon} />
                      </a>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="action-first">
                  <div className="d-flex align-items-center gap-lg-4 gap-2">
                    {/* <input type="checkbox" />
                    <img
                      src={hdfc}
                      width="48"
                      height="48"
                      className="rounded-3"
                      alt=""
                    /> */}
                  </div>
                </td>
                <td className="action-mid">
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">Name</h6>
                    <h5 className="regular">{name}</h5>
                  </div>

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Linkedin URL
                    </h6>
                    <h5
                      style={{ maxWidth: "100px" }}
                      className="regular text-wrap text-truncate"
                    >
                      {linkedin_url}
                    </h5>
                  </div>

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Next Follow Up Date
                    </h6>
                    <h5 className="regular">{next_followup_date}</h5>
                  </div>
                </td>

                <td className="action-mid">
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">Title</h6>
                    <h5 className="regular">{title}</h5>
                  </div>

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Stage of Contact
                    </h6>
                    <h5 className="regular">{stage_of_contact_display}</h5>
                  </div>

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Timeline to Aquired
                    </h6>
                    <h5 className="regular">{timeline_to_aquire_display}</h5>
                  </div>
                </td>

                <td className="action-mid">
                  {/* {mobile &&  */}
                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">Mobile</h6>
                    <h5 className="regular">{mobile || " "}</h5>
                  </div>
                  {/* } */}

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      First Pitch
                    </h6>
                    <h5 className="regular">{first_pitch_display}</h5>
                  </div>

                  <div className="pb-2">
                    <h6 className="fs-12 text-sky regular  pb-md-1">
                      Last Follow Up Remarks
                    </h6>
                    <h5 className="regular">{last_remark}</h5>
                  </div>
                </td>

                <td className="action-last">
                  <div className="d-md-flex justify-content-between">
                    <div>
                      {/* {email &&  */}
                      <div className="pb-2">
                        <h6 className="fs-12 text-sky regular  pb-md-1">
                          Email
                        </h6>
                        <h5 className="regular">{email || " "}</h5>
                      </div>
                      {/* } */}

                      <div className="pb-2">
                        <h6 className="fs-12 text-sky regular  pb-md-1">
                          Proposal Date
                        </h6>
                        <h5 className="regular text-yellow">{proposal_date}</h5>
                      </div>
                      <div className="pb-2">
                        <h6 className="fs-12 text-sky regular  pb-md-1">
                          Company Forecast Remark Remarks
                        </h6>
                        <h5 className="regular">{compnay_forecast_remark}</h5>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {edit && (
        <LeadListEdit
          contactdata={data}
          leaddata={leaddetailsdata}
          onHide={() => setEdit(false)}
        />
      )}
    </>
  );
};

export default LeadListModal;
