import React from "react";
import useGetLeadListContact from "../../libs/queries/leads/useGetLeadListContact";

const Viewmorecontact = ({ id, type }) => {
  const { data: contactlist } = useGetLeadListContact({
    id,
    leadType: type
  });

  return (
    <tbody>
      {contactlist &&
        contactlist?.results.slice(0,3).map((item, index) => {
          return (
            <>
              <tr>
                <td className="ps-3 fs-16 pt-2">{item.name}</td>
                <td className="fs-16 pt-2">{item.mobile}</td>
              </tr>
            </>
          );
        })}
      {contactlist?.results?.length === 0 ? <p className="ps-3">No contacts available</p> : ""}
    </tbody>
  );
};

export default Viewmorecontact;
