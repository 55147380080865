import React from "react";
import { Link } from "react-router-dom";
import useProductivityalerts from "../../libs/queries/alerts/useProductivityalerts";
import useAgreementPending from "../../libs/queries/alerts/useAgreementPending";
import { useSelector } from "react-redux";

const AgreementPending = () => {

  const { alerttype } = useSelector((state) => state.alertSlice);
  const { data: agreementalert } = useAgreementPending({
    pendingtype: alerttype,
  });

  console.log(agreementalert);

  return (
    <div className="widget-box rounded-10 overflow-hidden shadow mb-4">
      <div className="widget-header d-flex align-items-center justify-content-between  py-2 px-3  bg-green">
        <h3 className="text-white medium fs-16">Agreement Pending / Renewal</h3>
        <h6 className="result-number text-brown">
          {agreementalert?.results?.length}
        </h6>
      </div>
      <div className="bg-white p-3">
        <ol className="deafult-list ps-3">
          {agreementalert?.results?.slice(0, 3).map((item) => {
            return (
              <>
                <li>{item?.lead_detail?.legal_entity_name}</li>
              </>
            );
          })}

          {!agreementalert?.results?.length && (
            <div
              style={{
                textAlign: "center",
              }}
              className="mt-5"
            >
              No Data Found
            </div>
          )}
        </ol>

        <div className="text-end">
          <Link
            to="/alerts/agreementpendingrenewal"
            className="text-decoration-underline"
          >
            View More...
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AgreementPending;
