import React, { useRef, useState, useEffect } from "react";

/// Icons Or Images import ///
import Logo from "../../img/newlogo3.png";
import Profile from "../../img/profile.png";
import CheckInIcon from "../../img/icon/check-in.svg";
import SearchIcon from "../../img/icon/search.svg";
import NotificationIcon from "../../img/icon/notification.svg";
import useCheckOut from "../../libs/mutation/dashboard/useCheckOut";
import { useDispatch, useSelector } from "react-redux";
import { setCheckedIn } from "../../slice/checkInSlice";
import EditProfile from "../../img/editprofile.jpg";

/// StyleSheet import ///
import "../../Styles/header.css";

/// Imports of Component ///
import ProfileDropDown from "./NavDropDownContainer";
import useOuterClick from "../../hooks/UseOuterClick";
import useProfile from "../../libs/queries/profile/useProfile";
import { backendURL } from "../../api";
import useCheckIn from "../../libs/mutation/dashboard/useCheckIn";
import moment from "moment/moment";
import { useNavigate } from "react-router";
import { setsearchvalue } from "../../slice/globalSearchSlice";
import { Link } from "react-router-dom";
import SearchGlobal from "./SearchGlobal";
import useLocalStorage from "../../hooks/useLocalStorage";
import Notification from "../../ui/dropdown/Notification";
import useNotification from "../../libs/queries/notification/useNotification";

function Timer({ duration_, stop = true, isChanged }) {
  const [apiTime, setApiTime] = useState(
    JSON.parse(localStorage.getItem("todayCheckinTime"))
  );
  const checkedOutTime = JSON.parse(localStorage.getItem("isCheckedOut"));
  const [duration, setDuration] = useState(0); //seconds
  const [diffInMilliseconds, setDiffInMiliseconds] = useState(0);

  const timer = useRef();

  // const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setDuration(duration_);
  }, [duration_]);

  const apiTimeParts = apiTime?.split(":");
  const apiDate = new Date();
  apiDate.setHours(Number(apiTimeParts?.[0]));
  apiDate.setMinutes(Number(apiTimeParts?.[1]));
  apiDate.setSeconds(Number(apiTimeParts?.[2]));

  // Get the current time
  const currentDate = new Date();

  const givenTime = new Date(apiDate);
  // givenTime.setHours(givenTime.getHours() + 5);
  // givenTime.setMinutes(givenTime.getMinutes() + 30);

  useEffect(() => {
    setDiffInMiliseconds(currentDate - givenTime);
  }, [givenTime]);

  const formatTime = (diffInMilliseconds) => {
    // const hours = Math.floor(timeInSeconds / 3600);
    // const minutes = Math.floor((timeInSeconds % 3600) / 60);
    // const seconds = timeInSeconds % 60;

    if (apiTime?.includes(":") && !isChanged && !checkedOutTime) {
      const hours = Math.floor(diffInMilliseconds / (60 * 60 * 1000));
      const minutes = Math.floor(
        (diffInMilliseconds % (60 * 60 * 1000)) / (60 * 1000)
      );
      const seconds = Math.floor((diffInMilliseconds % (60 * 1000)) / 1000);
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    } else {
      const hours = Math.floor(diffInMilliseconds / 3600);
      const minutes = Math.floor((diffInMilliseconds % 3600) / 60);
      const seconds = diffInMilliseconds % 60;
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
  };

  useEffect(() => {
    if (!stop) {
      timer.current = setTimeout(() => {
        if (apiTime?.includes(":") && !isChanged && !checkedOutTime) {
          setDiffInMiliseconds((v) => v + 1);
        } else {
          setDuration((v) => v + 1);
        }
        // // setApiTime((v)=>v+1);
        // diffInMilliseconds.setSeconds(diffInMilliseconds.getSeconds() + 1);
      }, 1000);
    } else {
      if (apiTime?.includes(":") && !isChanged && !checkedOutTime) {
        setDiffInMiliseconds(0);
      } else {
        setDuration(0);
      }
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [duration, stop, diffInMilliseconds]);

  const startTime = () => {
    timer.current = setTimeout(() => {
      setDuration((v) => v + 1);
    });
  };
  const stopTime = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    // timer.current = null;
  };
  return (
    <div style={{ color: "white", display: "inline" }}>
      {/* {formatTime(duration)  + " Hrs"} */}
      {apiTime?.includes(":") && !isChanged && !checkedOutTime
        ? formatTime(diffInMilliseconds) + " Hrs"
        : formatTime(duration) + " Hrs"}
      {/* {formatTime_()  + " Hrs"} */}
    </div>
  );
}

const Header = () => {
  const [isChanged, setIsChanged] = useState(false);
  const [toggle, setToggle] = useState(false);
  const profileDrop = useRef();
  const profilebutton = useRef();
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [globalData, setGlobalData] = useLocalStorage("globalsearch", "");
  const [showOption, setShowOptions] = useState(false);
  const dispatch = useDispatch();
  const { data: checkoutData, mutate: checkOut } = useCheckOut();
  const { data: checkInData, mutate: checkIn } = useCheckIn();
  const { searchvalue } = useSelector((state) => state.globalSearchSlice);
  const [results, setResults] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const globalRef = useRef(null);

  const [searchData, setSearchData] = useLocalStorage("globalsearch", {
    search: "",
    source: null,
  });
  const [search, setSearch] = useState("");
  const {data: notificationdata, isLoading} = useNotification()

  const notificationshow = notificationdata?.data.filter(item => item?.is_read === false).length


  const handleClickOutside = (event) => {
    if (globalRef.current && !globalRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleNotification = () => {
    setIsOpen(!isOpen);
  };

  // console.log(searchData)

  // const handleChange = (event) => {
  //   console.log(event);
  // };
  // const searchkey = JSON.parse(localStorage.getItem("search"));
  const handleInputChange = (e) => {
    setSearch(e.target.value);

    // setGlobalData(e?.target?.value);
    // localStorage.setItem("search", JSON.stringify(e?.target?.value));
    // dispatch(setsearchvalue(e.target.value));
  };

  console.log('search__', search)

  const handleInlead = (source, url) => {
    setSearchData({ search, source });
    setShowOptions(false);
    setSearch('')
    navigate(url);
  };

  const profiles = [
    { id: "1", name: "Allie Grater" },
    { id: "2", name: "Aida Bugg" },
    { id: "3", name: "Gabrielle" },
    { id: "4", name: "Grace" },
    { id: "5", name: "Hannah" },
    { id: "6", name: "Heather" },
    { id: "7", name: "John Doe" },
    { id: "8", name: "Anne Teak" },
    { id: "9", name: "Audie Yose" },
    { id: "10", name: "Addie Minstra" },
    { id: "11", name: "Anne Ortha" },
  ];
  const navigate = useNavigate();

  const handleInagreement = () => {
    setShowOptions(false);
    navigate("/agreement");
  };

  const handleIncrm = () => {
    setShowOptions(false);
    navigate("/crm");
  };

  const handleIndocument = () => {
    setShowOptions(false);
    navigate("/documentrepository");
  };

  const handleChange = (e) => {
    const { target } = e;
    if (!target.value.trim()) return setResults([]);

    const filteredValue = profiles.filter((profile) =>
      profile.name.toLowerCase().startsWith(target.value)
    );
    setResults(filteredValue);
  };

  const handleOptionClick = (event) => {
    console.log("first", selectedValue);
    const selectedValue = event.target.value;
    // You can perform any other actions you need here
  };

  const ConfirmCheckIn = () => {
    checkIn();
    dispatch(setCheckedIn("usercheckedin"));
    localStorage.setItem("checkintime", new Date().getTime().toString());
  };

  const [checked, setChecked] = useState(
    localStorage.getItem("theme") === "dark" ? true : false
  );
  // const profile = JSON.parse(localStorage.getItem("profileData"));
  const { data } = useProfile();
  const image = data?.image ? backendURL + data?.image : EditProfile;

  console.log('img__', image);
  console.log('back__',backendURL)

  const { checkedIn } = useSelector((state) => state.checkInSlice);
  useOuterClick(profileDrop, profilebutton, setToggle);

  const handleProfileClick = () => {
    if (profileDrop?.current?.classList.contains("show")) {
      setToggle(false);
    } else setToggle(true);
  };

  //// dark mode useeffect ///
  useEffect(() => {
    document
      .getElementsByTagName("BODY")[0]
      .setAttribute("data-bs-theme", localStorage.getItem("theme"));
  }, []);

  const toggleThemeChange = () => {
    if (checked === false) {
      localStorage.setItem("theme", "dark");
      document
        .getElementsByTagName("BODY")[0]
        .setAttribute("data-bs-theme", localStorage.getItem("theme"));
      setChecked(true);
    } else {
      localStorage.setItem("theme", "light");
      document
        .getElementsByTagName("BODY")[0]
        .setAttribute("data-bs-theme", localStorage.getItem("theme"));
      setChecked(false);
    }
  };
  //// dark mode useeffect ///

  useEffect(() => {
    let currentTime = new Date().getTime();
    let checkintime = localStorage.getItem("checkintime");
    // let checkintime = localStorage.getItem("isCheckedIn");
    if (checkintime) {
      let diff = ((currentTime - checkintime) / 1000).toFixed(0);
      // console.log('difff__', diff)
      setTime(parseInt(diff));
      setIsRunning(true);
    }
  }, []);

  useEffect(() => {
    if (search?.length > 0) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
      // setSearchData({ search: "" });
    }
  }, [search]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("isCheckedIn")) === "usercheckedin") {
      dispatch(setCheckedIn("usercheckedin"));
    } else if (
      JSON.parse(localStorage.getItem("isCheckedIn")) === "usercheckedout" ||
      JSON.parse(localStorage.getItem("isCheckedOut"))
    ) {
      dispatch(setCheckedIn("usercheckedout"));
      localStorage.removeItem("checkintime");
    } else if (JSON.parse(localStorage.getItem("isCheckedIn")) === "") {
      dispatch(setCheckedIn(""));
    } else {
      dispatch(setCheckedIn("usercheckedin"));
    }
  }, []);

  return (
    <div className="top-header py-3">
      <div className="container py-1">
        <div className="row">
          <div className="col-lg-6 ">
            <a
              href="#"
              className="site-logo pe-lg-4 pe-md-3 pe-2 d-inline-block border-end"
            >
              <img style={{ height: "55px" }} src={Logo} alt="" />
            </a>

            <a
              href="#"
              className="toggle"
              data-bs-toggle="collapse"
              data-bs-target="#nav_area"
            >
              <i className="fa fa-bars"></i>
            </a>

            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsChanged(true);
                localStorage.setItem(
                  "todayCheckinTime",
                  JSON.stringify("removeTime")
                );
                if (checkedIn == "usercheckedin") {
                  checkOut();
                  dispatch(setCheckedIn("usercheckedout"));
                } else if (checkedIn == "usercheckedout") {
                  ConfirmCheckIn();
                }
              }}
              className={
                checkedIn == "usercheckedin"
                  ? "custom-btn fs-14 medium py-1 mx-3 checkout-btn"
                  : "custom-btn fs-14 medium py-1 mx-3 checkin-btn"
              }
            >
              {checkedIn == "usercheckedin" ? "Check-out" : "Check-in"}{" "}
              <img src={CheckInIcon} className="ps-1" alt="" />
            </a>
            {/* 00:20:20 Hrs */}
            {/* {formatTime(time) + " Hrs"} */}
            <Timer
              duration_={time}
              stop={checkedIn != "usercheckedin"}
              isChanged={isChanged}
            />
            {/* <span style={{
                color: 'white'
              }}> {timeDiff + " Hrs"} </span> */}

            <span className="theme-mode">
              <input
                type="checkbox"
                id="darkSwitch"
                className="d-none"
                defaultChecked={checked}
                onChange={() => toggleThemeChange()}
              />
              <label
                htmlFor="darkSwitch"
                className="d-flex align-items-center justify-content-between"
              >
                <i className="fas fa-moon"></i>
                <i className="fa fa-sun"></i>
              </label>
            </span>
          </div>

          {/* Profile Section */}

          <div className="col-lg-6">
            <div
              style={{ marginTop: "8px" }}
              className="d-flex align-items-center justify-content-md-end justify-content-between"
            >
              <div className="site-search">
                <form className="header-search d-flex flex-row-reverse align-items-center justify-content-end w-100">
                  {/* <datalist id="suggestions">
                      <option>Lead</option>
                      <option>Agreement</option>
                      <option>Crm</option>
                    </datalist> */}
                  {/* <input
                      autoComplete="on"
                      id="suggestions"
                      list="suggestions"
                      type="text"
                      placeholder="Search for people and groups "
                      name="search"
                      className="border-0 bg-transparent text-white fs-12 medium"
                    /> */}
                  {/* <SearchGlobal
                      results={results}
                      value={selectedProfile?.name}
                      renderItem={(item) => <p>{item.name}</p>}
                      onChange={handleChange}
                      onSelect={(item) => setSelectedProfile(item)}
                    /> */}
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Search Lead, Contact and more"
                      autoComplete="on"
                      value={search}
                      onChange={(e) => {
                        handleInputChange(e);
                        // setSearchText(e.target?.value);
                        // dispatch(setsearchvalue(e.target.value));
                      }}
                      className="border-0 bg-transparent text-white fs-12 medium"
                      list="browsers"
                      name="browser"
                    />
                  </div>

                  {showOption && (
                    <div
                    ref={globalRef}
                      className="text-light"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        height: "110px",
                        width: "210px",
                        backgroundColor: "black",
                        textColor: "white",
                        top: "55px",
                        padding: "10px 10px",
                        zIndex: 2
                      }}
                    >
                      <div
                        onClick={() => handleInlead("lead", "/leads/viewall")}
                      >
                        {search} in lead
                      </div>
                      <div
                        onClick={() => handleInlead("agreement", "/agreement")}
                      >
                        {search} in agreement
                      </div>
                      <div onClick={() => handleInlead("crm", "/crm")}>
                        {search} in crm
                      </div>
                      <div
                        onClick={() =>
                          handleInlead("document", "/documentrepository")
                        }
                      >
                        {search} in document repository
                      </div>
                      {/* <div onClick={() => navigate('/viewall')}>{globalData} is lead</div>
                      <div onClick={() => navigate('/agreement')}>{globalData} in agreement</div>
                      <div onClick={() => navigate('/crm')}>{globalData} in crm</div> */}
                    </div>
                  )}

                  <button
                    type="button"
                    // type="submit"
                    className="bg-transparent border-0 ps-0"
                  >
                    <img src={SearchIcon} alt="" />
                  </button>
                </form>
              </div>

              <div className="px-lg-4 px-2 mx-lg-4 mx-2 border-start border-end">
                <a  onClick={toggleNotification}>
                  <img  src={NotificationIcon} alt="" style={{cursor: "pointer"}} />
                </a>
                {notificationshow > 0 && <span style={{position: "absolute" , bottom: "auto", top: "30px"}}  className="notification-badge text-red ">{notificationshow}</span>}
                {isOpen && <Notification showSort={isOpen} onClose={toggleNotification} />}
              </div>

              <button
                className="selectContainer profilebuton d-flex align-items-center gap-2"
                onClick={() => handleProfileClick()}
                ref={profilebutton}
              >
                <img
                  src={image}
                  alt=""
                  width="30"
                  height="30"
                  className="rounded-circle"
                />
                <div>
                  <span className="fs-14 medium ps-2 text-white d-none d-xl-block">
                    {data?.fullname}
                  </span>
                  <span className="fs-12 light ps-2 text-white d-none d-xl-block">
                    {data?.role_display}
                  </span>
                </div>
              </button>

              {/* Profile Dropdown Element */}

              <div
                // className="profile-dropdown collapse py-4 rounded-10 border-0"
                // id="profile"
                className={`profile-dropdown collapse py-4 rounded-10 border-0 ${
                  toggle ? "show" : ""
                }`}
                data-bs-toggle="collapse"
                ref={profileDrop}
                id="profile-menu"
              >
                <ProfileDropDown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
