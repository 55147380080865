import React from "react";
import Targets from "./NavDropDownOptions/Targets";
import AccountSetting from "./NavDropDownOptions/AccountSetting";
import Security from "./NavDropDownOptions/Security";
import useProfile from "../../libs/queries/profile/useProfile";
import RoleRestriction from "../../utils/roleRestriction";
 
const NavDropDownOption = () => {
  const { data } = useProfile();
  const restrict = RoleRestriction();

  // console.log('checkrole', data)
  return (
    <>
      {(restrict=== "BD" || 
      restrict=== "researcher") && 
      <Targets />}
      <AccountSetting />
      <Security />
    </>
  );
};

export default NavDropDownOption;
