import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

const DoubleBarChart = ({ data = [], label=[], leadtarget= [] }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );


  console.log('dtt__', data)
  console.log('dttleadtr__', leadtarget)

  const dataconfig = {
    labels: label?.map((item) => item),
    datasets: [
      {
        label: "Target",
        backgroundColor: ["rgba(54, 162, 235, 1)"],
        data: leadtarget?.map((item) => item),
      },
      {
        label: "Achieved",
        backgroundColor: ["rgb(255, 165, 0)"],
        data: data?.map((item) => item),
      }
      
    ],
    options: {
      scales: {
        y: {
          display: false,
          min: 0,
          max: 10,
        },
      },
      plugins: {
        datalabels: {
          display: true,
          formatter: (value, context) => {
            return value;
          },
          anchor: "end",
          align: "start",
          offset: -20,
        },
      },
    },
  };

  const optionsKg = {
    maintainAspectRatio: false,
    plugins: {
      legend: true,
      tooltip: {
        callbacks: {
          label: (context) => {
            const costvalueKg = context.formattedValue + "";
            return costvalueKg;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: Math.max(...leadtarget, ...data),
        grid: {
          display: true,
        },
        display: true,
      },

      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div>
    {(data.length<1  && leadtarget.length<1) ?
    <div className="my-2 d-flex justify-content-center">No Data</div>
    :
    <Bar
      style={{ height: "400px"}}
      options={optionsKg}
      data={dataconfig}
    />}
    </div>
  );
};

export default DoubleBarChart;
