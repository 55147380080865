import api from "../../../api/index";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch} from "react-redux";
import { setAuth } from "../../../slice/authSlice";
import { toast } from "react-toastify";

const useEnterotp = () => {

  const dispatch = useDispatch()

  const navigate = useNavigate()
  
  const EnterOtpMutation = useMutation({
    mutationFn: async (data) => {
      const res = await api.post("account/verify-code/", data);
      return res.data;
    },
    onSuccess: (data) => {
      dispatch(setAuth(data))
      if(data.status === true){
        navigate('/createpwd')
      }
    },

    onError: (error) => {
      toast.error(`${error['response'].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      
    },
  });

  return EnterOtpMutation;
};

export default useEnterotp;
