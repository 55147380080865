import React, { useEffect, useRef, useState } from "react";
import useFilterData from "../../libs/queries/manage/useFilterData";
import { useSelector } from "react-redux";
import useGetRelationshipManager from "../../libs/queries/crm/useGetRelationshipManager";
import useRoles from "../../libs/queries/profile/useRoles";
import useGetAccountManager from "../../libs/queries/crm/useGetAccountManager";
import dayjs from "dayjs";
import useResearcherUsers from "../../libs/queries/graphs/useResearcherUsers";
import RoleRestriction from "../../utils/roleRestriction";
import RadioFilters from "./RadioFilters";

const PrsFilter = ({
  onRoleSelect,
  onUserSelect,
  onIndustrySectorSelect,
  onPotentialSelect,
  onRelationSelect,
  onCommunicationSelect,
  onMonthSelect,
  onYearSelect,
  showSort,
  type,
  month2
}) => {
  const restrict = RoleRestriction();
  const { data: businessmodeloptions } = useFilterData(8, null, true, true);
  const { data: industryoptions } = useFilterData(1, null, true, true);
  const { data: potentialserviceoptions } = useFilterData(9, null, true, true);
  const { data: communicationOptions } = useFilterData(33, null, true, true);
  const { data: relationOptions } = useGetRelationshipManager();
  const { data: roleData } = useRoles();
  const { data: researcherList } = useResearcherUsers();
  const { data: BdList } = useGetAccountManager();
  const [isOpen, setIsOpen] = useState(showSort);
  const [selectedRoleId, setSelectedRoleId] = useState(
    restrict == "researcher" ? 4 : 1
  );
  const [bdUserId, setBdUserId] = useState("");
  const [researcherId, setResearcherId] = useState("");
  const [monthId, setMonthId] = useState([]);
  const [yearId, setYearId] = useState("");
  const [businessModalId, setBusinessModalId] = useState("");
  const [industrySectorId, setIndustrySectorId] = useState("");
  const [relationId, setRelationId] = useState("");
  const [potentialId, setPotentialId] = useState([]);
  const [communicationId, setCommunicationId] = useState([]);
  const [yearData, setYearData] = useState([]);

  const PrsReportRef = useRef(null);

  const monthsArray = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  useEffect(() => {
    const years = [];
    for (let start_year = 2022; start_year <= dayjs().year(); start_year++) {
      years.push(start_year);
    }
    setYearData(years);
  }, []);

  useEffect(() => {
    setIsOpen(showSort);
  }, [showSort]);

  const handleClickOutside = (event) => {
    if (PrsReportRef.current && !PrsReportRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    onRoleSelect?.(selectedRoleId);
  }, [selectedRoleId]);

  useEffect(() => {
    onUserSelect?.(bdUserId);
  }, [bdUserId]);

  useEffect(() => {
    onMonthSelect?.(monthId.toString());
  }, [monthId]);

  useEffect(() => {
    onYearSelect?.(yearId);
  }, [yearId]);

  useEffect(()=>{
    if(type){
      setMonthId('');
    }
  },[type])

  return (
    <div
      ref={PrsReportRef}
      style={{
        position: "absolute",
        right: 0,
        bottom: "auto",
        left: "auto",
        // inset:'left'
      }}
      className={`dropdown-menu filter-menu p-0 rounded-10 dropdown-menu-end ${
        isOpen ? "show" : ""
      }`}
    >
      <h6 className="text-white bg-sky p-3 filter-title semibold mb-1">
        Sort by
      </h6>
      {type == "organisation" && (
        <div className="border-bottom">
          <a
            className="collapse-butn semibold"
            data-bs-toggle="collapse"
            href="#status"
            role="button"
            aria-expanded="false"
            aria-controls="status"
          >
            User Role
          </a>
          <div className="collapse" id="status">
            <RadioFilters
              options={roleData?.filter((ite) => ite?.id == 4 || ite?.id == 1)}
              value={selectedRoleId}
              onChange={setSelectedRoleId}
              name="selected_role"
            />
            {/* {roleData &&
              roleData
                ?.filter((ite) => ite?.id == 4 || ite?.id == 1)
                ?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="d-flex justify-content-between align-items-center py-1 px-3"
                    >
                      <label className="medium fs-14" htmlFor="active">
                        {item?.name}
                      </label>
                      <input
                        checked={selectedRoleId === item.id}
                        onChange={() => {
                          setSelectedRoleId(item?.id);
                          setBdUserId("");
                        }}
                        type="radio"
                        id="active"
                      />
                    </div>
                  );
                })} */}
          </div>
        </div>
      )}

      {type == "organisation" && (
        <div className="border-bottom">
          <a
            className="collapse-butn semibold"
            data-bs-toggle="collapse"
            href="#user"
            role="button"
            aria-expanded="false"
            aria-controls="user"
          >
            User
          </a>
          <div className="collapse" id="user">
            <div style={{ overflowY: "scroll", height: "110px" }}>
              <RadioFilters options={selectedRoleId == 1 ? BdList : researcherList} value={bdUserId} onChange={setBdUserId} name="bduser" />
              {/* {BdList &&
                (selectedRoleId == 1 ? BdList : researcherList)?.map(
                  (item, index) => {
                    return (
                      <div className="d-flex justify-content-between align-items-center py-1 px-3">
                        <label className="medium fs-14" for="TA-Sourcing">
                          {item.name}
                        </label>
                        <input
                          onChange={() => {
                            // if(selectedRoleId==1){
                            //     setBdUserId(item.id)
                            // }
                            // else{
                            //     setResearcherId(item?.id)
                            // }
                            setBdUserId(item.id);
                          }}
                          checked={bdUserId === item.id}
                          // checked={selectedRoleId==1? (bdUserId===item.id): (relationId===item?.id)}
                          type="radio"
                          id="TA-Sourcing"
                        />
                      </div>
                    );
                  }
                )} */}
            </div>
          </div>
        </div>
      )}

      <div className="border-bottom">
        <a
          className="collapse-butn semibold fs-15"
          data-bs-toggle="collapse"
          href="#month"
          role="button"
          aria-expanded="false"
          aria-controls="month"
        >
          Month
        </a>
        <div className="collapse" id="month">
          <div style={{ overflowY: "scroll", height: "110px" }}>
            {/* <RadioFilters
              options={monthsArray}
              value={monthId}
              onChange={setMonthId}
              name="month_id"
            /> */}
            {
              monthsArray?.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between align-items-center py-1 px-3">
                    <label className="medium fs-14" for="month">
                      {item.name}
                    </label>
                    <input 
                    checked={monthId?.includes(item.id)} 
                    onClick={(event) => {
                      // if (monthId == item?.id) {
                      //   setMonthId(null);
                      // } else {
                      //   setMonthId(item?.id);
                      // }
                      if(monthId.includes(item?.id)){
                        setMonthId(monthId.filter((ite)=>ite != item?.id));
                      }
                      else{
                        setMonthId([...monthId, item?.id])
                      }
                    }}
                    // onChange={()=>{
                    //   setMonthId(item?.id)
                    // }}
                    type="radio" 
                    id="month" />
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="border-bottom">
        <a
          className="collapse-butn semibold fs-15"
          data-bs-toggle="collapse"
          href="#year"
          role="button"
          aria-expanded="false"
          aria-controls="year"
        >
          Year
        </a>
        <div className="collapse" id="year">
          <RadioFilters
            options={yearData?.map((item) => ({ name: item, id: item }))}
            value={yearId}
            onChange={setYearId}
            name="year"
          />
          {/* <div>
            {yearData.length > 0 &&
              yearData?.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between align-items-center py-1 px-3">
                    <label className="medium fs-14" for="year">
                      {item}
                    </label>
                    <input
                      checked={yearId == item}
                      onChange={() => {
                        setYearId(item);
                      }}
                      type="radio"
                      id="year"
                    />
                  </div>
                );
              })}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PrsFilter;
