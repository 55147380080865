import React from "react";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import Heading from "../../ui/modals/Heading";
import NewCommunicationForm from "../../Components/crm/NewCommunicationForm";
import { useParams } from "react-router-dom";

const NewCommunication = () => {

  const { id } = useParams();

  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <Subheader leftsideshow={<div className="col-sm-6 pb-sm-0 pb-3"></div>} />
        <Heading heading="CRM" isLocation={true} />

        <NewCommunicationForm id={id} />
      </div>
    </div>
  );
};

export default NewCommunication;
