import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

const DonutChart = ({ datalabel=[], datacolor=[], data=[] }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const options = {
    labels: datalabel?.map((item) => item),
    datasets: [
      {
        data: data?.map((item) => item),
        backgroundColor: datacolor?.map((item) => item)
      },
    ],
  };

  const options2 = {
    maintainAspectRatio: false,
  };

  return (
    <div style={{ width: "350px" }}>
      <Doughnut style={{
        width: '350px',
        height: '350px'
      }} data={options}
       options={options2} 
       />
    </div>
  );
};

export default DonutChart;
