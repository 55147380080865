import React, { useEffect, useRef, useState } from "react";
import useFilterData from "../../libs/queries/manage/useFilterData";
import { useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import useLocationList from "../../libs/queries/location/useLocation";
import useRoles from "../../libs/queries/profile/useRoles";
import useUsers from "../../libs/queries/profile/useUsers";

const ProductivityReportSort = ({
  onRoleSelect,
  onUserSelect,
  onStartDateSelect,
  onEndDateSelect,
  showSort,
  type
}) => {
  const { data: roleOptions } = useRoles();
  const [isOpen, setIsOpen] = useState(showSort);
  const [roleId, setRoleId] = useState('');
  const [userId, setUserId] = useState('');
  const [startDate, setstartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const {data: userList} = useUsers();

  const isReport = window.location.pathname.includes("report")

  const dropdownRef = useRef(null);

  useEffect(()=>{
    setIsOpen(showSort)
  },[showSort])

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    onUserSelect?.(userId);
  }, [userId]);

  useEffect(() => {
    // if(startDate && endDate){
      onStartDateSelect?.(startDate);
      onEndDateSelect?.(endDate);
    // }
  }, [startDate, endDate]);

  return (
    <div
    ref={dropdownRef}
    style={{
      position: 'absolute',
      right: 0,
      bottom: 'auto',
      left: 'auto'
    }}
      className={`dropdown-menu filter-menu p-0 rounded-10 dropdown-menu-end ${
        isOpen?"show":""}`}
    >
      <h6 className="text-white bg-sky p-3 filter-title semibold mb-1">Sort by</h6>
      
      {type=='organisation' && <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#user"
          role="button"
          aria-expanded="false"
          aria-controls="user"
        >
          User
        </a>
        <div className="collapse" id="user">
          <div style={{ overflowY: "scroll", height: "110px" }}>
            {userList &&
              userList?.results?.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between align-items-center py-1 px-3">
                    <label className="medium fs-14" for="TA-Sourcing">
                      {item.fullname}
                    </label>
                    <input
                    onChange={()=>{
                        setUserId(item.id)

                    }}
                    checked={userId==item.id} 
                    type="radio" 
                    id="TA-Sourcing" 
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>}

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#date"
          role="button"
          aria-expanded="false"
          aria-controls="date"
        >
          Date
        </a>
        <div className="collapse" id="date">
        <div style={{ overflowY: "scroll", height: "140px" }}>
            <div className="my-2 align-items-center py-1 px-3">
              <label className="medium fs-14" for="date">
                {'Start Date'}
              </label>
              <input
              onChange={(e)=>{
                setstartDate(e.target.value)
              }}
              style={{
                borderRadius: 0,
                padding: '5px',
              }}
              className="mt-1"
              type="date"/>
              <label className="medium fs-14 mt-2" for="sourcedate">
                {'End Date'}
              </label>
              <input
              onChange={(e)=>{
                setEndDate(e.target.value)
              }}
              style={{
                borderRadius: 0,
                padding: '5px',
              }}
              className="mt-1"
              type="date"/>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#status"
          role="button"
          aria-expanded="false"
          aria-controls="status"
        >
          Role
        </a>
        <div className="collapse" id="status">
          {roleOptions &&
            roleOptions?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center py-1 px-3"
                >
                  <label className="medium fs-14" htmlFor="active">
                    {item?.name}
                  </label>
                  <input
                  checked={roleId===item.id} 
                  onChange={()=>{
                    setRoleId(item?.id)
                  }}
                  type="radio" id="active" 
                  />
                </div>
              );
            })}
        </div>
      </div> */}

    </div>
  );
};

export default ProductivityReportSort;
