import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSort: false,
};

const sortSlice = createSlice({
  name: "sortSlice",
  initialState,
  reducers: {
    setShowSort: (state, action) => {
      state.showSort = action.payload;
    },
  },
});

export const {setShowSort} = sortSlice.actions;
export default sortSlice.reducer;
