import React, { useState } from "react";
import { Link } from "react-router-dom";
import useProductivityalerts from "../../libs/queries/alerts/useProductivityalerts";
import { useSelector } from "react-redux";

const ProductivityAlert = () => {
  const { alerttype } = useSelector((state) => state.alertSlice);
  const { data: productivityalert } = useProductivityalerts({
    productivityalerttype: alerttype,
  });

  // console.log(productivityalert);

  return (
    <div className="widget-box rounded-10 overflow-hidden shadow mb-4">
      <div className="widget-header d-flex align-items-center justify-content-between  py-2 px-3  bg-darkpink">
        <h3 className="text-white medium fs-16">Productivity Alert</h3>
        <h6 className="result-number text-darkpink">
          {productivityalert?.results?.length}
        </h6>
      </div>
      <div className="bg-white p-3">
        {productivityalert?.results?.slice(0, 1)?.map((userData, index) =>
          userData.scores.map((score, scoreIndex) => (
            <div className="d-flex align-items-center justify-content-between pb-2">
              {score?.stage}
              <span className="medium text-red">{score?.score}</span>
            </div>
          ))
        )}

        {!productivityalert?.results?.length && (
          <div
            style={{
              textAlign: "center",
            }}
            className="mt-3"
          >
            No Data Found
          </div>
        )}

        <div className="text-end">
          <Link to="/alerts/productivity" className="text-decoration-underline">
            View More...
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductivityAlert;
