import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import store from "./store/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import router from "./routes";
import { RouterProvider } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

/// React Query setup ///
const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  // defaultOptions: {
  //   queries: {
  //     staleTime: 19 * (60 * 1000), // 15 mins
  //     cacheTime: 20 * (60 * 1000), // 20 mins
  //   },
  // },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
      <ToastContainer />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
