import React from "react";
import useGetAccountManager from "../../libs/queries/crm/useGetAccountManager";

const AccountManagerDropdown = ({ register, errors, label, name }) => {
  const { data } = useGetAccountManager();

  return (
    <>
      <label className="d-block text-sky fs-12 pb-2">{label}</label>
      <select
        name="source"
        {...(register && {
          ...register(name, {
            required: "Account Manager is required",
          }),
        })}
      >
        <option disabled value="">
          Select a {label}
        </option>
        {data?.map((item, index) => {
          return (
            <>
              <option className="form-field" key={item?.id} value={item?.id}>
                {item?.name}
              </option>
            </>
          );
        })}
      </select>
      {errors[name] && (
        <span className="fs-12 text-red">{errors[name]?.message}</span>
      )}
    </>
  );
};

export default AccountManagerDropdown;
