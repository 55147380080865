import React from "react";
import RoleRestriction from "../../utils/roleRestriction";
import ReplaceIcon from "../../img/icon/replace.svg";

const RelocateButton = ({ type, setRelocateVisible }) => {
  const relocateOn = ["admin", "practice", "researcher"];

  return (
    <>
      {type !== "my" && relocateOn.includes(RoleRestriction()) && (
        <a
          // href=""
          className="action-icon selectContainer"
          // data-bs-toggle="modal"
          // data-bs-target="#reallocated_lead"
          onClick={() => setRelocateVisible(true)}
        >
          <img src={ReplaceIcon} />
        </a>
      )}
    </>
  );
};

export default RelocateButton;
