import { useQuery } from "@tanstack/react-query";
import React from "react";
import api from "../../../api";
import { Endpoints } from "../../../api/Endpoints";

const useLocationList = (page, itemsPerPage, search)=>{
    const getLocationList = useQuery({
        queryKey: ['locationlist',{ page, itemsPerPage, search}],
        // staleTime: '5000',
        refetchOnWindowFocus: false,
        queryFn: async()=>{
            let resp = await api.get(Endpoints.getLocationList+ `?page=${page || 1}&items_per_page=${itemsPerPage || 1500}&search_key=${search || ''}`);
            return resp?.data
        }
    })

    return getLocationList;
}

export default useLocationList;