import { useDispatch } from "react-redux";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";
import { setagreementId } from "../../../slice/agreementformSlice";
import { setmarketgenId } from "../../../slice/marketIntGenerationSlice";


const useFilters = (module) => {
    const dispatch = useDispatch();
    const filters = useQuery({
        queryKey: ["filters", module],
        queryFn: async () => {
            const res = await api.get(`record/dropdown-types/?module=${module}`);
            if(module=="agreement"){
                dispatch(setagreementId(res.data?.data[0]?.id));
            }
            if(module=="market_intelligence"){
                dispatch(setmarketgenId(res.data?.data[0]?.id));
            }
            return res.data?.data;
        } 
    });
  return filters;
}

export default useFilters