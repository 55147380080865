import React from 'react'
/// Imports of Component ///
import Header from "../../Components/auth/Header";
import AuthContainer from "../../Components/auth/AuthContainer";
import CreatepwdForm from '../../Components/auth/CreatepwdForm';


const Createpwd = () => {
  return (
    <div className="wrapper login-bg position-relative">
    <Header />
    <AuthContainer
      mainheading="Forgot password?"
      subheading="Reset it Now!"
      componentform={<CreatepwdForm />}
    />
  </div>
  )
}

export default Createpwd