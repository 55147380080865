import React, { useEffect, useState } from "react";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import PlusButton from "../../ui/buttons/PlusButton";
import Heading from "../../ui/modals/Heading";
import Search from "../../ui/search/Search";
import SearchButton from "../../ui/buttons/SearchButton";
import SingleMarketList from "../../Components/marketintelligence/SingleMarketList";
import useGetDocument from "../../libs/queries/market/useGetDocument";
import { Endpoints } from "../../api/Endpoints";
import Pagination from "../../ui/pagination";
import MarketIntelligenceSort from "../../ui/dropdown/MarketIntelligenceFilter";
import useLocalStorage from "../../hooks/useLocalStorage";
import RoleRestriction from "../../utils/roleRestriction";
import { useSelector } from "react-redux";

const MarketIntelligence = () => {
  const [pageToLoad, SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [docId, setDocId] = useState("");
  const [skillId, setSkillId] = useState("");
  const [researcherId, setResearcherId] = useState("");
  const [clientId, setClientId] = useState("");
  const [paid, setPaid] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [viewStartDate, setViewStartDate] = useState("");
  const [viewEndDate, setViewEndDate] = useState("");
  const [campaignStartDate, setCampaignStartDate] = useState("");
  const [campaignEndDate, setCampaignEndDate] = useState("");
  const [type, setType] = useState("my");
  const [search, setSearch] = useState("");

  // const searchkey = JSON.parse(localStorage.getItem("search"))
  const [searchData] = useLocalStorage("globalsearch");
  const restrict = RoleRestriction()
  const { searchSlice } = useSelector((state) => state.searchSlice);


  // console.log('search___',searchData);
  // console.log('search2___',search);
  // console.log('ll__', localStorage.getItem("globalsearch"))
  console.log('searchslic__', searchSlice)

  // useEffect(()=>{
  //   setSearch(searchData?.source == "document"
  //     ? searchData?.search
  //     : "")
  // },[searchData])
  // console.log(type)

  const { data } = useGetDocument({
    page: pageToLoad,
    itemsPerPage: itemsPerPage,
    marketType: type,
    search:
      search?.length > 0
        ? search
        : searchSlice?.source == "document"
        ? searchSlice?.search
        : "",
    // search:
    //   search?.length > 0
    //     ? search
    //     : searchData?.source == "document"
    //     ? searchData?.search
    //     : "",
    docType: docId,
    skill: skillId,
    researcherId: researcherId,
    forClient: clientId,
    uploadStart: startDate,
    UploadEnd: endDate,
    paid: paid,
    campaignStart: campaignStartDate,
    campaignEnd: campaignEndDate,
    viewStart: viewStartDate,
    viewEnd: viewEndDate,
  });

  const queryParams = {
    page: pageToLoad || 1,
    download: 1,
    organisation: type === "organisation" ? 1 : "",
    search_key: search || "",
    for_client: clientId || "",
    doc_type: docId || "",
    skills: skillId || "",
    paid: paid || "",
    researcher: researcherId || "",
    upload_start_date: startDate || "",
    upload_end_date: endDate || "",
    view_start_date: viewStartDate || "",
    view_end_date: viewEndDate || "",
    campaign_start_date: campaignStartDate || "",
    campaign_end_date: campaignEndDate || ""
  };

  
  const queryString = Object.keys(queryParams)
  .filter((key) => queryParams[key] !== "")
  .map((key) => `${key}=${queryParams[key]}`)
  .join("&");

  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <Subheader
          additionalbutton={
            <SearchButton
              name="New Campaign"
              navto="/documentrepository/newcampaign"
            />
          }
          leftsideshow={
            <TwoButtonHolder onSelect={setType} label="Document Repository" />
          }
         {...restrict !== "BD" && restrict !== "relation" && restrict !== "practice" && {rightsideshow: 
            <PlusButton
              name="New Document"
              navto="/documentrepository/newdocument"
            />
          }}
        />
        <Heading
          search={<Search onSearch={setSearch} />}
          heading="Document Repository"
          url={Endpoints.listDocumentCsv + "?"+ queryString
          // `download=1&organisation=${type=="organisation"?1:''}`
          }
          showSortt={setIsOpen}
          // onSearch={setSearch}
          sortComponent={
            <MarketIntelligenceSort
              onDocTypeSelect={setDocId}
              onSkillsSelect={setSkillId}
              onResearcherSelect={setResearcherId}
              onClientSelect={setClientId}
              onStartDateSelect={setStartDate}
              onEndDateSelect={setEndDate}
              onPaidSelect={setPaid}
              onCampaignStart={setCampaignStartDate}
              onCampaignEnd={setCampaignEndDate}
              onViewStart={setViewStartDate}
              onViewEnd={setViewEndDate}
              showSort={isOpen}
              type={type}
            />
          }
        />
        <Pagination
          onPageChange={SetPageToLoad}
          onItemsChanged={setItemsPerPage}
          totalPages={Math.ceil(data?.count / itemsPerPage)}
          defaultCount={itemsPerPage}
        />
        {data &&
          data.results.map((item, index) => {
            return <SingleMarketList key={index} data={item} />;
          })}

        {!data?.results?.length && (
          <div
            style={{
              textAlign: "center",
            }}
            className="mt-5"
          >
           
            No Data Found
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketIntelligence;
