import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import InputBox from "../../ui/inputs/InputBox";
import DateInput from "../../ui/inputs/DateInput";
import UserlistdropdownInput from "../../ui/inputs/UserlistdropdownInput";
import useUsers from "../../libs/queries/profile/useUsers";
import useCreateContactLog from "../../libs/mutation/crm/useCreateContactLog";
import Multiselect from "multiselect-react-dropdown";
import useFilterData from "../../libs/queries/manage/useFilterData";
import useGetContactLog from "../../libs/queries/crm/useGetContactLog";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useUpdateCreateLog from "../../libs/mutation/crm/useUpdateCreateLog";

const NewCommunicationForm = ({ id }) => {
  const isView = window.location.pathname.includes("view/");
  const { memberid, membertype } = useSelector((state) => state.crmSlice);
  const {
    control,
    register,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();
  const { data: users } = useUsers();
  const navigate = useNavigate();

  const { mutate: createcontactlog } = useCreateContactLog({ id: id });
  const { mutate: updatecreatelog } = useUpdateCreateLog({
    memberid,
  });
  const { data: communicationType } = useFilterData(33);
  const { data } = useGetContactLog({search:''});
  const memberdata = data?.results?.filter((item) => item?.id === memberid);

  const communicationTypedata = communicationType?.results;

  const handlevalue = (value) => {
    let obj = [];

    if (communicationTypedata && isView) {
      typeof value === "object" &&
        value?.forEach((element) => {
          let found = communicationTypedata?.find((etv) => etv.id === element);

          if (found) {
            obj.push(found);
          }
        });
    }
    return obj;
  };

  const onSubmit = (data) => {
    if (isView) {
      updatecreatelog(data)
    } else {
      createcontactlog(data)
    }
  }

  useEffect(() => {
    if (memberdata && isView) {
      reset({
        last_contacted_date: memberdata[0]?.last_contacted_date,
        last_contacted_time: memberdata[0]?.last_contacted_time,
        done_by: memberdata[0]?.done_by,
        communication_type: memberdata[0]?.communication_type,
      });
    }
  }, []);

  return (
    <div className="pt-4">
      <div className="">
        <div className="rounded-10 overflow-hidden" style={{ height: "440px" }}>
          <div className="widget-header px-3 py-3 bg-sky">
            <h3 className="bold text-white fs-18">
              {isView ? "Edit New Communication" : "New Communication"}
            </h3>
          </div>

          <div className="bg-white p-4">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="add-form"
            >
              <div className="row  gx-lg-5">
                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                    Contacted Date
                  </label>
                  <input
                    type="date"
                    {...register("last_contacted_date", { required: true })}
                  />
                  <span className="fs-12 text-red">
                    {errors.last_contacted_date?.type === "required" &&
                      "Contact Time is Required"}
                  </span>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                    Contacted Time
                  </label>
                  <input
                    type="time"
                    {...register("last_contacted_time", { required: true })}
                  />
                  <span className="fs-12 text-red">
                    {errors.last_contacted_time?.type === "required" &&
                      "Contact Time is Required"}
                  </span>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                    Communication Type
                  </label>
                  <Controller
                    name="communication_type"
                    control={control}
                    defaultValue={isView ? "communication_type" : ""}
                    rules={{
                      required: {
                        value: true,
                        message: "Communication Type is required",
                      },
                    }}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Multiselect
                          options={communicationType?.results}
                          displayValue="name"
                          selectedValues={handlevalue(value)}
                          onKeyPressFn={function noRefCheck() {}}
                          onRemove={(list) => {
                            let value = list.map((items) => {
                              return items.id;
                            });
                            if (value.length <= 0) {
                              setError("communication_type", {
                                type: "customerr",
                                message: "Communication Type is required",
                              });
                            }
                            onChange(value);
                          }}
                          onSelect={(list) => {
                            let value = list.map((items) => {
                              return items.id;
                            });
                            if (value.length > 0) {
                              clearErrors("communication_type");
                            }

                            onChange(value);
                          }}
                        />
                      );
                    }}
                  />
                  {errors.communication_type && (
                    <span className="fs-12 text-red">
                      {errors.communication_type.message}
                    </span>
                  )}
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                  <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                    Done By
                  </label>
                  <select {...register("done_by", { required: true })}>
                    <option value={""}>Select a Done By</option>
                    {users?.results?.map((item) => {
                      return (
                        <option key={item?.id} value={item?.id}>
                          {item?.fullname}
                        </option>
                      );
                    })}
                  </select>
                  <span className="fs-12 text-red">
                    {errors.done_by?.type === "required" &&
                      "Done by is Required"}
                  </span>
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex gap-3 border-0 px-md-5 px-4 pb-4 butn-group justify-content-center">
                  <button
                    type="submit"
                    className="custom-btn border-0 py-2 rounded-3 raleway medium"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate(`/crm/lastcontactlogs/${id}`)}
                    className="custom-btn  py-2  rounded-3 border-bl-btn px-4 raleway medium"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCommunicationForm;
