import React from "react";
import { Link } from "react-router-dom";

const Header = ({heading, backgroundcolor, url}) => {
  return (
    <div className="pt-4">
      <div className={`widget-header py-3 px-3 d-flex align-items-center justify-content-between  bg-${backgroundcolor} rounded-10 overflow-hidden mb-3`}>
        <Link to={url} className="text-white medium py-1 fs-16">
          <i className="fa fa-chevron-left pe-3"></i> {heading}
        </Link>
      </div>
    </div>
  );
};

export default Header;
