import React, { useState } from "react";
import useToggle from "../../hooks/useToggle";
import useDeleteLead from "../../libs/mutation/leads/useDeleteLead";
import useDeleteContact from "../../libs/mutation/leads/useDeleteContact";

const PrsIndustryModal = ({ leadid, maindata }) => {
  const [dele, setdele] = useState(false);
  // const { id, name, mobile, lead, email } = data || {};
  const [contactId, setContactId] = useState(0);
   
  return (
    <div 
    className="bg-white px-md-4 px-3 mb-3"
    >
      <table 
      style={{
        // border: ;
      }}
      className="action-table w-100 table table-bordered"
      >
        <tbody>
        <tr style={{
                  // marginBottom: '10px',
                  display: 'flex'
                }}>
                <td style={{
                textAlign: "left",
                // width: "100%",
                width: '30%'
              }}>
              <h6 className="fs-12 text-sky regular  pb-md-2">
                Industry 
              </h6>
            </td>
            <td style={{
                  textAlign: "left",
                  // width: "100%",
                  width: '30%'
                }}>
              <h6 className="fs-12 text-sky regular  pb-md-2">
                Legal Entity Name
              </h6>
            </td>
            <td style={{
                  textAlign: "left",
                  // width: "100%",
                  width: '30%'
                }}>
              <h6 className="fs-12 text-sky regular  pb-md-2">
                Contact Name
              </h6>
            </td>
            <td style={{
                  textAlign: "left",
                  // width: "100%",
                  width: '40%'
                }}>
              <h6 className="fs-12 text-sky regular  pb-md-2">
                Email
              </h6>
            </td>
          </tr>
          {
            maindata.map((data,index)=>{
            const {legal_entity_name, contact_details, industry} = data || {};
              return(
                <tr style={{
                  // marginBottom: '10px',
                  display: 'flex'
                }} key={index}>
                  <td className="action-mid" 
                // width={'50%'}
                style={{
                  textAlign: "left",
                  // width: "100%",
                  width: '30%'
                }}
                >
              <p 
              style={{
                wordBreak: 'break-all'
              }}
              className="fs-12">{industry}</p>
              </td>
                <td className="action-mid" 
                // width={'50%'}
                style={{
                  textAlign: "left",
                  // width: "100%",
                  width: '30%'
                }}
                >
              {/* <h6 className="fs-12 text-sky regular  pb-md-2">
                Legal Entity Name
              </h6> */}
              <p 
              style={{
                wordBreak: 'break-all'
              }}
              className="fs-12">{legal_entity_name}</p>
              </td>
              <td 
              // width={'25%'} 
              className="action-mid" 
              style={{
                textAlign: "left",
                // width: "100%",
                width: '30%'
              }}
              >
              {/* <h6 className="fs-12 text-sky regular  pb-md-2">Contact Name</h6> */}

              <p 
              style={{
                wordBreak: 'break-all'
              }}
              className="fs-12">{contact_details?.name}</p>
            </td>
            <td 
            // width={'25%'} 
            className="action-mid" 
            style={{
              textAlign: "left", 
              // width: "100%",
              width: '40%'
              }}>
              {/* <h6 className="fs-12 text-sky regular  pb-md-2">Email Id</h6> */}
              <p 
              style={{
                wordBreak: 'break-all'
              }}
              className="fs-12">{contact_details?.email}</p>
            </td>
            
          </tr>
              )
            })
          }
          
        </tbody>
      </table>
    </div>
  );
};

export default PrsIndustryModal;
