import { ClipLoader } from "react-spinners";

export default function Loading({isButton}) {
    return <div style={{
        // flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: isButton? '': '100vh'
    }}>
        <ClipLoader size={20} color={isButton? "white" : "#0054a1"} />
    </div>
}