import React, { useEffect, useRef, useState } from "react";
import useFilterData from "../../libs/queries/manage/useFilterData";
import { useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import useLocationList from "../../libs/queries/location/useLocation";
import useRoles from "../../libs/queries/profile/useRoles";
import useUsers from "../../libs/queries/profile/useUsers";
import RadioFilters from "./RadioFilters";
import UserRadioFilter from "./UserRadioFilter";

const LeadSort = ({
  onBusinessModalSelect,
  onIndustrySectorSelect,
  onSourceSelect,
  onFortuneSelect,
  onPotentialSelect,
  onStartDateSelect,
  onEndDateSelect,
  onLocationSelect,
  showSort,
  onRoleSelect,
  onProposalStart,
  onProposalEnd,
  onFollowStart,
  onFollowEnd,
  onUserSelect,
  type
}) => {
  const { data: businessmodeloptions } = useFilterData(8, null, true, true);
  const { data: industryoptions } = useFilterData(1, null, true, true);
  const { data: sourceoptions } = useFilterData(2, null, true, true);
  const { data: potentialserviceoptions } = useFilterData(9, null, true, true);
  const { data: roleOptions } = useRoles();
  const [isOpen, setIsOpen] = useState(showSort);
  const [businessModalId, setBusinessModalId] = useState('');
  const [industrySectorId, setIndustrySectorId] = useState('');
  const [sourceId, setSourceId] = useState('');
  const [potentialId, setPotentialId] = useState([]);
  const [locationId, setLocationId] = useState('');
  const [startDate, setstartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [proposalStartDate, setProposalStartDate] = useState('');
  const [proposalEnd, setProposalEnd] = useState('');
  const [followStart, setFollowStart] = useState('');
  const [followEnd, setFollowEnd] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const { data: locations } = useLocationList(null, null);
  const [roleId, setRoleId] = useState('');
  const [userId, setUserId] = useState('');
  const {data: userList} = useUsers();

  const locationList = locations?.results;
  const isReport = window.location.pathname.includes("report")

  const dropdownRef = useRef(null);

  // console.log('strtdt__', startDate);
  // console.log('enddt__', endDate);

  useEffect(()=>{
    setIsOpen(showSort)
  },[showSort])

  const userlist = userList?.results
  // console.log(userList?.results)

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    onBusinessModalSelect?.(businessModalId);
  }, [businessModalId]);

  useEffect(() => {
    onIndustrySectorSelect?.(industrySectorId);
  }, [industrySectorId]);

  useEffect(() => {
    onSourceSelect?.(sourceId);
  }, [sourceId]);

  useEffect(() => {
    onFortuneSelect?.(isChecked);
  }, [isChecked]);

  useEffect(() => {
    onPotentialSelect?.(potentialId);
  }, [potentialId]);

  useEffect(() => {
    onLocationSelect?.(locationId);
  }, [locationId]);

  useEffect(() => {
    // if(startDate && endDate){
      onStartDateSelect?.(startDate);
      onEndDateSelect?.(endDate);
    // }
  }, [startDate, endDate]);

  useEffect(() => {
    // if(proposalStartDate && proposalEnd){
      onProposalStart?.(proposalStartDate);
      onProposalEnd?.(proposalEnd);
    // }
  }, [proposalStartDate, proposalEnd]);

  useEffect(() => {
    // if(followStart && followEnd){
      onFollowStart?.(followStart);
      onFollowEnd?.(followEnd);
    // }
  }, [followStart, followEnd]);

  useEffect(() => {
    onRoleSelect?.(roleId);
  }, [roleId]);

  useEffect(() => {
    onUserSelect?.(userId);
  }, [userId]);

  return (
    <div
    ref={dropdownRef}
    style={{
      position: 'absolute',
      // top: 10,
      right: 0,
      bottom: 'auto',
      left: 'auto'
      // inset:'left'
    }}
      className={`dropdown-menu filter-menu p-0 rounded-10 dropdown-menu-end ${
        isOpen?"show":""}`}
      // onClick="event.stopPropagation()"
    >
      <h6 className="text-white bg-sky p-3 filter-title semibold mb-1">Sort by</h6>
      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#status"
          role="button"
          aria-expanded="false"
          aria-controls="status"
        >
          Business Model
        </a>
        <div className="collapse" id="status">
         <RadioFilters options={businessmodeloptions?.data} value={businessModalId} onChange={setBusinessModalId} name="business_model" />
        </div>
      </div>

      {!isReport && <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#role"
          role="button"
          aria-expanded="false"
          aria-controls="role"
        >
          Industry/Sector
        </a>
        <div className="collapse" id="role">
          <div style={{ overflowY: "scroll", height: "110px" }}>
            <RadioFilters options={industryoptions?.data} value={industrySectorId} onChange={setIndustrySectorId} name="industry_sector" />
            {/* {industryoptions &&
              industryoptions?.data?.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between align-items-center py-1 px-3">
                    <label className="medium fs-14" for="TA-Sourcing">
                      {item.name}
                    </label>
                    <input
                    onChange={()=>{
                      setIndustrySectorId(item.id)
                    }}
                    // value={item.id} 
                    checked={industrySectorId===item.id} 
                    type="radio" 
                    id="TA-Sourcing" 
                    />
                  </div>
                );
              })} */}
          </div>
        </div>
      </div>}

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#manager"
          role="button"
          aria-expanded="false"
          aria-controls="manager"
        >
          Source
        </a>
        <div className="collapse" id="manager">
          <div style={{ overflowY: "scroll", height: "110px" }}>
            <RadioFilters options={sourceoptions?.data} value={sourceId} onChange={setSourceId} name="source_id" />
            {/* {sourceoptions &&
              sourceoptions?.data?.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between align-items-center py-1 px-3">
                    <label className="medium fs-14" for="Dilip-Yadav">
                      {item.name}
                    </label>
                    <input 
                    checked={sourceId===item.id} 
                    onChange={()=>{
                      setSourceId(item?.id)
                    }}
                    type="radio" 
                    id="Dilip-Yadav" />
                  </div>
                );
              })} */}
          </div>
        </div>
      </div>

      {!isReport && <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#fortune"
          role="button"
          aria-expanded="false"
          aria-controls="fortune"
        >
          Fortune 1000
        </a>
        <div className="collapse" id="fortune">
          <div>
            <div className="my-2 d-flex justify-content-between align-items-center py-1 px-3">
              <label className="medium fs-14" for="fortune">
                {'Fortune 1000'}
              </label>
              <input 
              onChange={handleCheckboxChange}
              checked={isChecked} 
              // onChange={()=>{
              //   setSourceId()
              // }}
              type='checkbox' 
              id="fortune" />
            </div>
          </div>
        </div>
      </div>}

      <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#sourcedate"
          role="button"
          aria-expanded="false"
          aria-controls="sourcedate"
        >
          Source Date
        </a>
        <div className="collapse" id="sourcedate">
        <div style={{ overflowY: "scroll", height: "140px" }}>
            <div className="my-2 align-items-center py-1 px-3">
              <label className="medium fs-14" for="sourcedate">
                {'Start Date'}
              </label>
              <input
              onChange={(e)=>{
                setstartDate(e.target.value)
              }}
              style={{
                borderRadius: 0,
                padding: '5px',
              }}
              className="mt-1"
              type="date"/>
              <label className="medium fs-14 mt-2" for="sourcedate">
                {'End Date'}
              </label>
              <input
              onChange={(e)=>{
                setEndDate(e.target.value)
              }}
              style={{
                borderRadius: 0,
                padding: '5px',
              }}
              className="mt-1"
              type="date"/>
            </div>
          </div>
        </div>
      </div>

      {!isReport && <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#proposaldate"
          role="button"
          aria-expanded="false"
          aria-controls="proposaldate"
        >
          Proposal Date
        </a>
        <div className="collapse" id="proposaldate">
        <div style={{ overflowY: "scroll", height: "140px" }}>
            <div className="my-2 align-items-center py-1 px-3">
              <label className="medium fs-14" for="proposaldate">
                {'Start Date'}
              </label>
              <input
              onChange={(e)=>{
                setProposalStartDate(e.target.value)
              }}
              style={{
                borderRadius: 0,
                padding: '5px',
              }}
              className="mt-1"
              type="date"/>
              <label className="medium fs-14 mt-2" for="sourcedate">
                {'End Date'}
              </label>
              <input
              onChange={(e)=>{
                setProposalEnd(e.target.value)
              }}
              style={{
                borderRadius: 0,
                padding: '5px',
              }}
              className="mt-1"
              type="date"/>
            </div>
          </div>
        </div>
      </div>}

      {!isReport && <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#followdate"
          role="button"
          aria-expanded="false"
          aria-controls="followdate"
        >
          Next Follow Up Date
        </a>
        <div className="collapse" id="followdate">
        <div style={{ overflowY: "scroll", height: "140px" }}>
            <div className="my-2 align-items-center py-1 px-3">
              <label className="medium fs-14" for="followdate">
                {'Start Date'}
              </label>
              <input
              onChange={(e)=>{
                setFollowStart(e.target.value)
              }}
              style={{
                borderRadius: 0,
                padding: '5px',
              }}
              className="mt-1"
              type="date"/>
              <label className="medium fs-14 mt-2" for="sourcedate">
                {'End Date'}
              </label>
              <input
              onChange={(e)=>{
                setFollowEnd(e.target.value)
              }}
              style={{
                borderRadius: 0,
                padding: '5px',
              }}
              className="mt-1"
              type="date"/>
            </div>
          </div>
        </div>
      </div>}

      

      <div className="border-bottom">
        <a
          className="collapse-butn semibold fs-15"
          data-bs-toggle="collapse"
          href="#mana"
          role="button"
          aria-expanded="false"
          aria-controls="mana"
        >
          Potential Service Offerings
        </a>
        <div className="collapse" id="mana">
          <div style={{ overflowY: "scroll", height: "110px" }}>
            {potentialserviceoptions &&
              potentialserviceoptions?.data?.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between align-items-center py-1 px-3">
                    <label className="medium fs-14" for="potential">
                      {item.name}
                    </label>
                    <input 
                    checked={potentialId?.includes(item.id)} 
                    onClick={()=>{
                      if(potentialId.includes(item?.id)){
                        setPotentialId(potentialId.filter((ite)=>ite != item?.id));
                      }
                      else{
                        setPotentialId([...potentialId, item?.id])
                      }
                      // setPotentialId(item?.id)
                    }}
                    type="radio" 
                    id="potential" />
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      {type=='organisation' && <div className="border-bottom">
          <a
            className="collapse-butn semibold"
            data-bs-toggle="collapse"
            href="#user"
            role="button"
            aria-expanded="false"
            aria-controls="user"
          >
            {window.location.pathname.includes('lead')?"Lead Owner" : "User"}
          </a>
          <div className="collapse" id="user">
            <div style={{ overflowY: "scroll", height: "110px" }}>
              <UserRadioFilter options={userlist} value={userId} onChange={setUserId} name="user_id" />
            {/* <RadioFilters options={userlist} value={userId} onChange={setUserId} name="user_id" /> */}
              {/* {userList &&
                userList?.results?.map((item, index) => {
                  return (
                    <div className="d-flex justify-content-between align-items-center py-1 px-3">
                      <label className="medium fs-14" for="TA-Sourcing">
                        {item.fullname}
                      </label>
                      <input
                      onChange={()=>{
                          setUserId(item.id)
  
                      }}
                      checked={userId==item.id} 
                      type="radio" 
                      id="TA-Sourcing" 
                      />
                    </div>
                  );
                })} */}
            </div>
          </div>
        </div>
        }

      {isReport && <div className="border-bottom">
        <a
          className="collapse-butn semibold fs-15"
          data-bs-toggle="collapse"
          href="#location"
          role="button"
          aria-expanded="false"
          aria-controls="location"
        >
          Location
        </a>
        <div className="collapse" id="location">
          <Typeahead
          inputProps={{
            style: {padding: '10px'}
          }}
          id=""
          labelKey="city"
          options={locationList}
          placeholder="Search Location"
          onChange={(list) => {
            let value = list.map((items) => {
              return items.id;
            });
            // onChange(value?.[0]);
            setLocationId(value?.[0])
          }}
          />
        </div>
      </div>}

      {/* <div className="border-bottom">
        <a
          className="collapse-butn semibold"
          data-bs-toggle="collapse"
          href="#status"
          role="button"
          aria-expanded="false"
          aria-controls="status"
        >
          Role
        </a>
        <div className="collapse" id="status">
          {roleOptions &&
            roleOptions?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center py-1 px-3"
                >
                  <label className="medium fs-14" htmlFor="active">
                    {item?.name}
                  </label>
                  <input
                  checked={roleId===item.id} 
                  onChange={()=>{
                    setRoleId(item?.id)
                  }}
                  type="radio" id="active" 
                  />
                </div>
              );
            })}
        </div>
      </div> */}
    </div>
  );
};

export default LeadSort;
