import React from "react";
import api from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const useCreateCrm = (checkBoxAdd) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await api.post("managment/crm/create", {agreement_ids: checkBoxAdd});
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([`crm`]);
      if(!data?.status){
        toast.error(data?.message)
      }
      else{
        toast.success(data.message);
      }
    },

    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });
  return mutation;
};

export default useCreateCrm;
