import React from "react";
import RoleRestriction from "../../utils/roleRestriction";

const ManageButton = () => {

    const managerestrict = ["practice", "admin"]

  return (
    <>
      {managerestrict.includes(RoleRestriction()) && (
          <a
            href="#"
            id="manage"
            style={{ padding: "18px", display: "block" }}
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Manage
            <span className="arrow">
              <i className="fa fa-angle-down" aria-hidden="true"></i>
            </span>
          </a>
        )}
    </>
  );
};

export default ManageButton;
