import React from "react";
import BarChart from "../../ui/graphs/BarChart";
import useGetSource from "../../libs/queries/graphs/useGetSource";
import useGetLeadsAllGraph from "../../libs/queries/graphs/useGetLeadsAllGraph";

const SourceChart = ({data}) => {
  // const { data } = useGetLeadsAllGraph();

  const { source_data, source_color, source_label } = data || {};

  return (
    <div className="bg-white py-4 px-4 border" 
    style={{ width: "100%" }}
    >
      <div 
      className="card-body"
      >
        <h3 className="mb-3">Source</h3>
        {source_data?.length?
        <BarChart datalabel={source_label} datacolor={source_color} data={source_data} />
        :
          <div className='my-4 d-flex justify-content-center'>
          No Data
          </div>
        }
      </div>
    </div>
  );
};

export default SourceChart;
