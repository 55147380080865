import React, { useEffect, useState } from "react";
import EmailTemplateGenerated from "./EmailTemplateGenerated";
import useGetEmailTemplate from "../../../libs/queries/emailtemplate/useGetEmailTemplate";
import Pagination from "../../../ui/pagination";

const EmailTemplateContainer = () => {
  const [pageToLoad,SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5)
  const { data: emailtemplate } = useGetEmailTemplate(
    pageToLoad, itemsPerPage
  );

  // 

  return (
    <div>
      <Pagination
        onPageChange={SetPageToLoad}
        onItemsChanged={setItemsPerPage}
        totalPages={Math.ceil(emailtemplate?.count/itemsPerPage)}
        defaultCount={itemsPerPage}
        />
      {emailtemplate &&
        emailtemplate?.results?.map((data, index) => {
          return (
            <EmailTemplateGenerated key={index} index={index} data={data} />
          );
        })}
    </div>
  );
};

export default EmailTemplateContainer;
