import React, { useEffect } from "react";
/// hooks used ///
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//icons and images ///
import PlusButton from "./PlusButton";

/// Imports of Component ///
import {
  setagreementId,
  setagreementname,
  setagreementnamechange,
  setnewagreementId,
} from "../../../slice/agreementformSlice";
import useFilters from "../../../libs/queries/manage/useFilters";

import useFilterData from "../../../libs/queries/manage/useFilterData";

const FilterRow = () => {
  const { data: filters, isLoading } = useFilters("agreement");
  const { agreementnamechange, agreementid } = useSelector(
    (state) => state.agreementSlice
  );

  // const { data } = useFilterData(filters ? filters[0].id : null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (filters && !agreementid) {
      dispatch(setagreementId(filters[0]?.id));
      dispatch(setagreementname(filters[0]?.name));
    }
  }, [filters]);

  return (
    <div className="row pb-4">
      <div className="col-sm-10 pb-sm-0 pb-3">
        <div className="">
          {filters &&
            filters.map((data, index) => {
              return (
                <a
                  key={index}
                  style={{ marginLeft: "5px", cursor: "pointer" }}
                  className={
                    agreementnamechange === data.name
                      ? "custom-btnlead sm-btn"
                      : "custom-btn sm-btn bg-white text-sky"
                  }
                  onClick={() => {
                    dispatch(setagreementId(data.id));
                    dispatch(setagreementname(data.name));
                    dispatch(setagreementnamechange(data.name));
                    dispatch(setnewagreementId(data.id));
                    navigate("/manage/agreementgeneratedform");
                  }}
                >
                  {data.name}
                </a>
              );
            })}
        </div>
        <span className="text-black2 fs-12 medium ps-3">
          {/* Organisation Market Intelligence */}
        </span>
      </div>
      <PlusButton />
    </div>
  );
};

export default FilterRow;
