import React from "react";
import { useState } from "react";
import useProfile from "../libs/queries/profile/useProfile";

const RoleRestriction = () => {
    const {data} = useProfile();
    const [roleState, setRoleState] = useState('');
    // console.log('role___c_', data)

    if(data?.role === 5){
        return "admin";
    }
    else if(data?.role === 1){
        return "BD"
    }
    else if(data?.role === 2){
        return "relation"
    }
    else if(data?.role === 3){
        return "practice"
    }
    else if(data?.role === 4){
        return "researcher"
    }
    else{
        return "other"
    }
    // return;
}

export default RoleRestriction;