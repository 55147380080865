import React, { useState } from "react";
import LeadForm from "./LeadForm";
import LeadFormContact from "./LeadFormContact";
import SaveButton from "../../ui/buttons/SaveButton";
import CancelButton from "../../ui/buttons/CancelButton";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import useCreateLeads from "../../libs/mutation/leads/useCreateLeads";
import useUpdateLead from "../../libs/mutation/leads/useUpdateLead";
import useCreateContact from "../../libs/mutation/leads/useCreateContact";
import dayjs from "dayjs";
import useUpdateLeadContact from "../../libs/mutation/leads/useUpdateLeadContact";

const LeadFormContainer = () => {
  const [selectedLead, setSelectedLead] = useState(null);

  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({ mode: "all" });

  const { mutate: createleads, isLoading } = useCreateLeads();
  const { mutate: updateleads } = useUpdateLead(selectedLead?.id);
  const { mutate: createcontact } = useCreateContact(selectedLead?.id);

  const onSubmit = (data) => {
    data.legal_entity_name = data?.legal_entity_name?.legal_entity_name;
    if (selectedLead) {
      data.leads.incepted = data?.leads?.incepted
        ? dayjs(data?.leads.incepted).format("YYYY-MM-DD")
        : null;
      data.leads.source_date = data?.leads?.source_date
        ? dayjs(data?.leads.source_date).format("YYYY-MM-DD")
        : null;
      data.leads.india_operations = data?.leads?.india_operations
        ? dayjs(data?.leads.india_operations).format("YYYY-MM-DD")
        : null;
      data.contact.proposal_date = data?.contact?.proposal_date
        ? dayjs(data?.contact?.proposal_date).format("YYYY-MM-DD")
        : null;
      data.contact.next_followup_date = data?.contact?.next_followup_date
        ? dayjs(data?.contact?.next_followup_date).format("YYYY-MM-DD")
        : null;
      updateleads(data);
      createcontact(data.contact);
    } else {
      data.incepted = data?.incepted
        ? dayjs(data.incepted).format("YYYY-MM-DD")
        : null;
      data.source_date = data?.source_date
        ? dayjs(data.source_date).format("YYYY-MM-DD")
        : null;
      data.india_operations = data?.india_operations
        ? dayjs(data.india_operations).format("YYYY-MM-DD")
        : null;
      data.proposal_date = data?.proposal_date
        ? dayjs(data?.proposal_date).format("YYYY-MM-DD")
        : null;
      data.next_followup_date = data?.next_followup_date
        ? dayjs(data?.next_followup_date).format("YYYY-MM-DD")
        : null;
      // console.log(data)
      createleads(data);
    }
  };

  // console.log("see this", errors);

  return (
    <div className="pt-4">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="rounded-10 overflow-hidden"
      >
        <div className="widget-header px-3 py-3 bg-sky">
          <h3 className="bold text-white fs-18">New Leads</h3>
        </div>
        <LeadForm
          control={control}
          errors={errors}
          register={register}
          watch={watch}
          setValue={setValue}
          reset={reset}
          setError={setError}
          clearErrors={clearErrors}
          selectedLead={selectedLead}
          setSelectedLead={setSelectedLead}
        />
        <LeadFormContact
          watch={watch}
          control={control}
          errors={errors}
          register={register}
          selectedLead={selectedLead}
          clearErrors={clearErrors}
          setError={setError}
          reset={reset}
        />
        <div className="d-flex border-0 butn-group justify-content-center gap-3">
          <SaveButton type="submit" disabled={isLoading} />

          <CancelButton onClick={() => navigate("/leads/")} />
        </div>
      </form>
    </div>
  );
};

export default LeadFormContainer;
