import React from "react";

const FilterRow = ({emaiplusbutton}) => {
  return (
    <div className="row pb-4">
      <div className="col-sm-6 pb-sm-0 pb-3">
        {/* <div className=""> */}
        {/* <a className="custom-btn sm-btn bg-white text-sky ">
          My Market Intelligence
        </a> */}
        {/* </div> */}
        {/* <span className="text-black2 fs-12 medium ps-3">
          Organisation Market Intelligence
        </span> */}
      </div>
      <div className="col-sm-6 pb-sm-0 pb-3">
        {emaiplusbutton}
      </div>
    </div>
  );
};

export default FilterRow;
