import React from "react";
import getInputError from "../../utils/getInputError";

const LinkInputBox = ({
  label,
  errors,
  value,
  onChange,
  placeholder,
  onFocus,
  name,
  disabled,
  ...res
}) => {
  let isError = getInputError(errors, name);

  return (
    <div className="col-lg-10 col-md-4 col-sm-6 pb-3">
      <label className="d-block text-sky fs-12 pb-4">{label}</label>
      <div className="pt-2">
        <input
          maxLength={label == "Mobile" ? 10 : null}
          disabled={disabled}
          className="form-field"
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          onFocus={onFocus}
          {...res}
        />
      </div>

      <div style={{ height: "10px" }}>
        {isError && <span className="fs-12 text-red">{isError?.message}</span>}
      </div>
    </div>
  );
};

export default LinkInputBox;
