import React, { useEffect, useState } from "react";
import useToggle from "../../hooks/useToggle";
import DocumentListModal from "./DocumentListModal";
import DeleteModal from "../../ui/modals/DeleteModal";
import PdfModal from "../../ui/modals/pdfModal";
import useDeleteDocument from "../../libs/mutation/market/useDeleteDocument";
import {
  setAttachment,
  setLinkAttachment,
  setCheckbox,
  setDocumentId,
} from "../../slice/campaignSlice";
import { useDispatch, useSelector } from "react-redux";
import api, { backendURL } from "../../api";
import { Endpoints } from "../../api/Endpoints";
import useUpdateLastView from "../../libs/mutation/market/useUpdateLastView";
import { useLocation } from "react-router-dom";

const SingleMarketList = ({ data }) => {
  const dispatch = useDispatch();
  const [detail, toggleDetail] = useToggle(false);
  const [dele, setdele] = useState(false);
  const [pdfView, setPdfView] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const location = useLocation();
  // const { checkbox } = useSelector((state) => state.campaignSlice);
  
  const {
    id,
    name,
    researcher,
    doc_link,
    for_client_display,
    for_client,
    date_of_upload,
    file,
    doc_type,
    researcher_display,
  } = data || {};
  const { mutate: deletedocument, isLoading } = useDeleteDocument({
    id: id,
  });
  const { mutate: updateLastView } = useUpdateLastView({
    id: id,
  });
  const isCheckbox = useSelector((state) => state.campaignSlice[id] || false)
  const { documentId } = useSelector((state) => state.campaignSlice);

  console.log('isCheck__', documentId)

  useEffect(()=>{
    if(location.pathname=="/documentrepository"){
      dispatch(setCheckbox({id, checked: false}))
      // dispatch(setDocumentId([]))
    }
  },[])

  const handleAttachmentbox = () => {
    dispatch(setCheckbox({id, checked: !isCheckbox}));
    dispatch(setDocumentId(id))
    // setIsChecked(!isChecked);
    if (file === null) {
      dispatch(setLinkAttachment(doc_link));
      console.log("this is iiddd", id)
    } else {
      dispatch(setAttachment(file));
    }
  };

  // console.log(data)

  const handleDelete = () => {
    deletedocument();
    setdele(false);
  };
  const { attachments } = useSelector((state) => state.campaignSlice);

  return (
    <div className="bg-white rounded-10 px-md-4 px-3 py-3 mb-3 shadow-sm">
      <table className="action-table w-100">
        <tbody>
          <tr>
            <td className="action-first" width="10%">
              <div className="d-flex align-items-center gap-lg-4 gap-2">
                <input
                  id={id}
                  type="checkbox"
                  checked={
                    isCheckbox
                    // isChecked
                    // (file !== null || doc_link !== null) &&
                    // attachments.filter((item) => item == file).length > 0
                    // attachments.filter((item) => item == multiname).length > 0
                    // file !== null && doc_link === null && attachments.filter((item) => item == file).length > 0
                    //   ? true
                    //   : false
                  }
                  onClick={handleAttachmentbox}
                />
              </div>
            </td>

            <td
              className="action-mid"
              style={{ width: "200px", flexWrap: "wrap" }}
            >
              <h6 className="fs-12 text-sky regular  pb-md-2">Client Name</h6>
              <h5>{name}</h5>
            </td>
            <td
              className="action-mid"
              style={{ width: "150px", textAlign: "left" }}
            >
              <h6 className="fs-12 text-sky regular  pb-md-2">Researcher</h6>
              <h5>{researcher}</h5>
            </td>
            <td className="action-mid" style={{ width: "200px" }}>
              <h6 className="fs-12 text-sky regular  pb-md-2">For Client</h6>
              <h5>{for_client}</h5>
            </td>
            <td className="action-mid" style={{ width: "200px" }}>
              <h6 className="fs-12 text-sky regular  pb-md-2">
                Date of Upload
              </h6>
              <h5>{date_of_upload}</h5>
            </td>
            <td className="action-last">
              <div className="d-flex align-items-center gap-xl-3 gap-2 justify-content-end">
                <a
                target={'_blank'}
                href={doc_type==646 ? doc_link:  doc_type==644? Endpoints.baseUrl + file : null}
                  className="filter-bar-icon bg-sky selectContainer"
                  onClick={() => {
                    updateLastView();
                    doc_type!=644 && doc_type!=646 &&
                    setPdfView(true)
                  }}
                >
                  <img src="assets/img/icon/eye.svg" />
                </a>
                <a
                  href={file !== null ? backendURL + file : doc_link}
                  target="_blank"
                  rel="noreferrer"
                  className="filter-bar-icon bg-sky"
                >
                  <img src="assets/img/icon/download.svg" />
                </a>
                <a
                  href="#"
                  className="action-icon bg-red"
                  onClick={() => setdele(true)}
                  // data-bs-toggle="modal"
                  // data-bs-target="#delete"
                >
                  <img src="assets/img/icon/trash.svg" />
                </a>
                <a
                  // href="#"

                  className="acco-info-btn"
                  onClick={() => toggleDetail()}
                >
                  <i
                    className={`fa-solid  ${
                      detail ? "fa-angle-up" : "fa-angle-down"
                    } fs-20 align-middle`}
                  ></i>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {detail && <DocumentListModal data={data} />}
      {pdfView && (
        <PdfModal
          // heading=""
          docType={doc_type}
          url={file}
          onClose={() => setPdfView(false)}
        />
      )}
      {dele && (
        <DeleteModal
          deletename="Document"
          disabled={isLoading}
          onDeleteClick={handleDelete}
          onNoClick={() => setdele(false)}
        />
      )}
    </div>
  );
};

export default SingleMarketList;
