
import React, { useCallback, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { render } from "react-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import api, { baseURL } from "../../api";

const CACHE = {};

async function makeAndHandleRequest(query, page = 1) {
    const res = await api.get(`account/users/?search_key=${query}&searching=1`);
    // console.log(res?.data?.count)
    return { options: res?.data?.results, total_count: res?.data?.count };
  }

const ResearcherSearchInput = ({ value, onChange, errors, name, placeholder }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState("");


  const handleInputChange = (q) => {
    setQuery(q);
  };

  const handleSearch = useCallback((q) => {
   
    if (CACHE[q]) {
      setOptions(CACHE[q].options);
      return;
    }

    console.log(value)

    setIsLoading(true);
    makeAndHandleRequest(q).then((res) => {
      // console.log(res)
      CACHE[q] = { ...res, page: 1 };

      setIsLoading(false);
      setOptions(res.options);
    });
  }, []);

  return (
    <div>
      {/* <label>legal Entity Name</label> */}
      <AsyncTypeahead
        id="async-pagination-example"
        isLoading={isLoading}
        labelKey="fullname"
        onChange={(selected) => {
          // console.log(selected[0].id)
          onChange(selected[0]?.fullname);
        }}
        allowNew
        selected={value ? [value] : []}
        //   maxResults={PER_PAGE - 1}
        minLength={2}
        onInputChange={handleInputChange}
        //   onPaginate={handlePagination}
        onSearch={handleSearch}
        options={options}
        paginate
        placeholder={placeholder}
        renderMenuItemChildren={(option) => {
          return (
            <div key={option.id}>
              <span >{option.fullname}</span>
            </div>
          );
        }}
        useCache={false}
      />
      {
        errors[name] && <span className="fs-12 text-red">{errors[name].message}</span>
      }
    </div>
  )
}

export default ResearcherSearchInput