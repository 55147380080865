import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useFilterData = (id, page, itemsPerPage, pagination) => {
  const queryresult = useQuery({
    queryKey: [`filters/single`, {id, page, itemsPerPage, pagination}],
    keepPreviousData:true,
    // staleTime: '5000',
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await api.get(`record/dropdown-values/${id}?page=${page || 1}&items_per_page=${itemsPerPage || 10}&pagination=${pagination? "off": ""}`);

      return res.data;
    },
    enabled: id ? true : false,
  });

  return queryresult;
};

export default useFilterData;