import React, { useEffect } from "react";
import useFilterData from "../../libs/queries/manage/useFilterData";
import useUsers from "../../libs/queries/profile/useUsers";
import useGetStageList from "../../libs/queries/StageApi/useGetStageList";
import { useWatch } from "react-hook-form";
import getInputError from "../../utils/getInputError";
import useGetLeadListContact from "../../libs/queries/leads/useGetLeadListContact";
import useResearcherUsers from "../../libs/queries/graphs/useResearcherUsers";

const DropdownInput = ({
  register,
  errors,
  label,
  id,
  reset,
  data,
  name,
  isUsers,
  isUpdate,
  required,
  isContact,
  isStage,
  rules = { required: "Please select an option" },
}) => {
  const { data: options } = useFilterData(id, null, true, true);
  const { data: contactlist } = useGetLeadListContact({
    stage_id: null,
    leadType: false,
  });
  const { data: users } = useUsers();
  const { data: Researchers } = useResearcherUsers();
  const { data: getstage } = useGetStageList();
  const userlist = users?.results;
  const isError = getInputError(errors, name);

  
  // console.log(isError);
  return (
    <>
      <label className="d-block text-sky fs-12 pb-2">{label}</label>
      <select
        // defaultValue={isUsers? users?.results?.[0]?.id :  options?.data?.[0]?.id}
        style={{
          borderRadius: isUpdate ? "30px" : "0px",
        }}
        name={name}
        // { ...register(name), {required: true} }
        {...register(name, rules)}
      >
        <option
          // disabled
          value=""
        >
          Select a {label}
        </option>

        {/* user dropdown */}
        {isUsers &&
          users?.results?.map((item, index) => {
            return (
              <>
                <option className="form-field" key={item?.id} value={item?.id}>
                  {item?.fullname}
                </option>
              </>
            );
          })}

          {label=="Researcher" &&
          Researchers?.map((item, index) => {
            return (
              <>
                <option className="form-field" key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              </>
            );
          })}

        {isContact &&
          contactlist?.results.map((item, index) => {
            const { legal_entity_name } = item?.lead;
            return (
              <>
                <option key={item?.id} value={item?.id}>{legal_entity_name}</option>
              </>
            );
          })}

          {label=="For Client" &&
          contactlist?.results.map((item, index) => {
            return (
              <>
                <option key={item?.id} value={item?.id}>{item.name}</option>
              </>
            );
          })}

        {/* stage dropdown */}
        {isStage &&
          getstage?.results?.map((item, index) => {
            return (
              <>
                <option className="form-field" key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              </>
            );
          })}

          

        {/* other dropdowns */}
        {!isUsers &&
          options?.data?.map((item, index) => {
            
            return (
              <>
                <option className="form-field" key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              </>
            );
          })}
      </select>
      {isError && <span className="fs-12 text-red">{isError?.message}</span>}
    </>
  );
};

export default DropdownInput;
