import React from "react";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";
import { Endpoints } from "../../../api/Endpoints";

const useGetCalendarEvents = ({ 
  startDate,
  endDate,
  filter_by_users,
}) => {

  const queryParams = {
    start_date_time: startDate || "",
    end_date_time: endDate || "",
    filter_by_users: filter_by_users || ""
  };

  const queryString = Object.keys(queryParams)
      .filter(key => queryParams[key] !== "") 
      .map(key => `${key}=${queryParams[key]}`)
      .join('&');
  const queryresult = useQuery({
    queryKey: [`calendar`, {queryString}],
    keepPreviousData: true,
    queryFn: async () => {
      const res = await api.get(Endpoints.listEventCalendar +"?"+queryString);

      return res?.data;
    },
  });

  return queryresult;
};

export default useGetCalendarEvents;
