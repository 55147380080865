import React from "react";
import Heading from "../../ui/modals/Heading";
import Search from "../../ui/search/Search";
import NewDocumentForm from "../../Components/marketintelligence/NewDocumentForm";

const NewDocument = () => {
  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <Heading heading="Document Repository" isLocation="false" />

        <div className="pt-4">
          <div className="rounded-10 overflow-hidden">
            <div className="widget-header px-3 py-3 bg-sky">
              <h3 className="bold text-white fs-18">New Document</h3>
            </div>
            <NewDocumentForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDocument;
