import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import api from "../../../api";
import { Endpoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useCreateStage=()=>{
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const createStageMutation = useMutation({
        mutationFn: async(data) => {
            let resp = await api.post(Endpoints.createStage, data);
            return resp.data
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries("stagelist");
            navigate('/manage/stages')
            toast.success(data.message);
           
          },
      
          onError: (error) => {
            toast.error(`${error['response'].data.message}`);
          },
    })

    return createStageMutation;
}

export default useCreateStage;