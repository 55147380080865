import React from 'react'
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useAgreementPending = ({ agreementtype, page, itemsPerPage, search, start_date, end_date }) => {

    const queryParams = {
        page: page || 1,
        items_per_page: itemsPerPage || 500,
        search_key: search || '',
        start_date: start_date || "",
        end_date: end_date || "",
        organisation: agreementtype === "organisation" ? 1 : "",
      };

      const queryString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");

    const queryresult = useQuery({
        queryKey: [`agreementpending`, { queryString }],
        keepPreviousData: true,
        queryFn: async () => {
          const res = await api.get(`market/agreement-pending?${queryString}`);
    
          return res?.data;
        },
      });
    
      return queryresult;

  
}

export default useAgreementPending