import React from "react";
import { useController, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useGetEmailTemplate from "../../../libs/queries/emailtemplate/useGetEmailTemplate";
import useCreateStage from "../../../libs/mutation/Stage/usecreateStage";

const NewStageForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const { mutate: createStage, isLoading } = useCreateStage();
  const navigate = useNavigate();

  const { data: emailtemplate } = useGetEmailTemplate(null, null, true);

  let colorData = [
    {
      name: "Red",
    },
    {
      name: "Green",
    },
    {
      name: "Blue",
    },
    {
      name: "Orange",
    },
    {
      name: "Yellow",
    },
    {
      name: "Black",
    },
    {
      name: "Pink",
    },
    {
      name: "Purple",
    },
    {
      name: "Violet",
    },
    {
      name: "Brown",
    },
    {
      name: "Grey",
    },
  ];
  return (
    <div className="pt-4">
      <div className="rounded-10 overflow-hidden">
        <div className="widget-header px-3 py-3 bg-sky">
          <h3 className="bold text-white fs-18">New Stage</h3>
        </div>

        <div className="bg-white p-4">
          <form className="add-form" onSubmit={handleSubmit(createStage)}>
            <div className="row gx-lg-5 align-items-center">
              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label htmlFor="" className="d-block text-sky fs-12 pb-2">
                  Name
                </label>
                <input
                  type="text"
                  className="form-field"
                  placeholder=""
                  name="name"
                  {...register("name", { required: true })}
                />
                <span className="fs-12 text-red">
                  {errors.name?.type === "required" && "Name is Required"}
                </span>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label className="d-block text-sky fs-12 pb-2">Order No.</label>
                <input
                  type="number"
                  className="form-field"
                  placeholder=""
                  name="order_no"
                  {...register("order_no", { required: true })}
                />
                <span className="fs-12 text-red">
                  {errors.order_no?.type === "required" &&
                    "Order No. is required"}
                </span>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label className="d-block text-sky fs-12 pb-2">
                  Stage Background Color
                </label>
                <select
                  id="stage_background_color"
                  name="stage_background_color"
                  {...register("stage_background_color", {
                    required: true,
                  })}
                >
                  <option value="">Select background color</option>
                  {colorData?.map((item, index) => {
                    return (
                      <>
                        <option
                          className="form-field"
                          key={index}
                          value={item.name}
                        >
                          {item?.name}
                        </option>
                      </>
                    );
                  })}
                </select>
                <span className="fs-12 text-red">
                  {errors.stage_background_color?.type === "required" &&
                    "Stage background color is required"}
                </span>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 pb-3">
                <label className="d-block text-sky fs-12 pb-2">
                  Choose Email Template
                </label>
                <select
                  id="email_template"
                  name="email_template"
                  {...register("email_template", {
                    required: true,
                  })}
                >
                  <option value="">Select email template</option>
                  {emailtemplate?.data?.map((item, index) => {
                    return (
                      <>
                        <option
                          id="email_template"
                          className="form-field"
                          key={index}
                          value={item.id}
                        >
                          {item?.name}
                        </option>
                      </>
                    );
                  })}
                </select>
                <span className="fs-12 text-red">
                  {errors.email_template?.type === "required" &&
                    "Email template is required"}
                </span>
              </div>
            </div>

            <div className="d-flex border-0 butn-group justify-content-center gap-3 pt-4">
              <button
                type="submit"
                className="custom-btn border-0 py-2 rounded-3 raleway medium"
              >
                Save
              </button>

              <button
                type="button"
                onClick={() => navigate("/manage/stages")}
                className="selectContainer custom-btn close-modal py-2  rounded-3 border-bl-btn px-4 raleway semibold"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewStageForm;
