import React from "react";

import FunnelChart from "../ui/graphs/FunnelChart";

const Test = () => {
  const tableStyle = {
    borderCollapse: 'collapse',
    width: '100%',
  };

  const thStyle = {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px',
    backgroundColor: '#f2f2f2',
  };

  const tdStyle = {
    borderLeft: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px',
  };

  
      // Indicates the status of the response (e.g., success or failure)
   const  data= [        // An array containing user data
   {
    "user": "Swati",
    "scores": [
      {
        "stage": "Accepted Leads Generated",
        "score": 0.0,
        "grade": "Low"
      },
      {
        "stage": "Proposal Submission",
        "score": 36.67,
        "grade": "Medium"
      },
      {
        "stage": "Meeting Organized",
        "score": 0.0,
        "grade": "High"
      },
      {
        "stage": "Assignment Received",
        "score": 0.0,
        "grade": "Low"
      },
      {
        "stage": "Onboarding Renewal",
        "score": 0.0,
        "grade": "Low"
      },
     
    ]
  },
      // Add more user data objects as needed
      {
        "user": "Swati",
        "scores": [
          {
            "stage": "Accepted Leads Generated",
            "score": 0.0,
            "grade": "Low"
          },
          {
            "stage": "Proposal Submission",
            "score": 36.67,
            "grade": "Low"
          },
          {
            "stage": "Meeting Organized",
            "score": 0.0,
            "grade": "Low"
          },
          {
            "stage": "Assignment Received",
            "score": 0.0,
            "grade": "Low"
          },
          {
            "stage": "Onboarding Renewal",
            "score": 0.0,
            "grade": "Low"
          },
         
        ]
      },
    ]

    const lowColor = 'red';
    const mediumColor = 'yellow';
    const highColor = 'green';
    const superColor = 'blue';

    const getScoreColor = (grade) => {
      switch (grade) {
        case 'Low':
          return lowColor;
        case 'Medium':
          return mediumColor;
        case 'High':
          return highColor;
        case 'Super':
          return superColor;
        default:
          return '';
      }
    };
  
  
  return (
    // <div><FunnelChart /></div>
    <>
     <div className="bg-white rounded-10 overflow-hidden shadow">
     <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>User</th>
            <th style={thStyle}>Stage</th>
            <th style={thStyle}>Score</th>
            <th style={thStyle}>Grade</th>
          </tr>
        </thead>
        <tbody>
        {data.map((userData, index) => (
          userData.scores.map((score, scoreIndex) => (
            <tr key={`${index}-${scoreIndex}`}>
              {scoreIndex === 0 ? (
                <td rowSpan={userData.scores.length} style={tdStyle}>
                  {userData.user}
                </td>
              ) : null}
              <td style={tdStyle}>{score.stage}</td>
              <td style={{...tdStyle }}>{score.score}</td>
              <td style={{ ...tdStyle, color: getScoreColor(score.grade) }}>{score.grade}</td>
            </tr>
          ))
        ))}
        </tbody>
      </table>
     </div>
     
    </>
  );
};

export default Test;
