import React from "react";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useGetContactLog = ({ search, page, itemsPerPage }) => {
  const queryParams = {
    search_key: search || "",
    page: page || 1,
    items_per_page: itemsPerPage || 500,
  };

  const queryString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "") // Filter out keys with no value
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");

  const queryresult = useQuery({
    queryKey: [`contactlog`, { queryString }],
    keepPreviousData: true,
    queryFn: async () => {
      const res = await api.get(`crm/contactlogs?${queryString}`);

      return res?.data;
    },
  });

  return queryresult;
};

export default useGetContactLog;
