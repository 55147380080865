import React from "react";
import Proposal from "../../img/proposal.png";
import ProposalTab from "./ProposalTab";

const ProposalIndustry = ({type}) => {
  return (
    <div><ProposalTab isIndustry={true} type={type} /></div>
   
  );
};

export default ProposalIndustry;
