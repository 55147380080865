import React, { useState } from "react";
import hdfc from "../../img/hdfc.png";
import DocIcon from "../../img/icon/doc.svg";
import ReplaceIcon from "../../img/icon/replace.svg";
import TrashIcon from "../../img/icon/trash.svg";
import StageIcon from "../../img/icon/stage.svg";
import useToggle from "../../hooks/useToggle";
import LeadListModal from "./LeadListModal";
import Slidedown from "../Manage/Slidedown";
import DeleteModal from "../../ui/modals/DeleteModal";
import useDeleteLead from "../../libs/mutation/leads/useDeleteLead";
import RelocateLeadModal from "./RelocateLeadModal";
import useDeleteContact from "../../libs/mutation/leads/useDeleteContact";
import EmailModal from "../../ui/modals/EmailModal";
import useProfile from "../../libs/queries/profile/useProfile";
import RoleRestriction from "../../utils/roleRestriction";
import { useLocation } from "react-router-dom";
import LeadDeleteButton from "./LeadDeleteButton";
import RelocateButton from "./RelocateButton";
import DeactivateModal from "../../ui/modals/DeactivateModal";
import ViewLogsModal from "../../ui/modals/ViewLogsModal";

const SingleLeadList = ({
  data,
  checkBoxAdd,
  leadid,
  setCheckBoxAdd,
  type,
}) => {
  const [stageVisible, setStageVisible] = useState(false);
  const [relocateVisible, setRelocateVisible] = useState(false);
  const [emailView, setEmailView] = useState(false);
  const [detail, toggleDetail] = useToggle(false);
  const [dele, setdele] = useState(false);
  const { id, name, mobile, lead } = data || {};
  const [contactId, setContactId] = useState(0);
  const [isLogsVisible, setLogsVisible] = useState(false);

  const { mutate: deletelead, isLoading } = useDeleteLead(id);
  const { mutate: deletecontact } = useDeleteContact();
  const { data: profiledata } = useProfile();
  console.log(RoleRestriction());

  console.log(type);
  const handleDelete = () => {
    let payload = { leadcontacts: [id] };
    deletecontact(payload);
    setdele(false);
  };

  

  const relocateOn = ["admin", "practice", "researcher"];

  const handleAddCheckbox = (event, id) => {
    if (event.target.checked) {
      setCheckBoxAdd([...checkBoxAdd, id]);
    } else {
      const updatestatecheck = checkBoxAdd.filter((item) => item !== id);
      // console.log('upd',updatestatecheck)
      setCheckBoxAdd(updatestatecheck);
    }
  };
  // console.log('coontactdt_', data)

  // onRelocate = ["practice", "admin", "researcher"]

  return (
    <div className="bg-white rounded-10 px-md-4 px-3 py-3 mb-3 shadow-sm">
      <table className="action-table w-100">
        <tbody>
          <tr>
            <td className="action-first">
              <div className="d-flex align-items-center gap-lg-4 gap-2">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  checked={
                    checkBoxAdd.filter((item) => item == id).length > 0
                      ? true
                      : false
                  }
                  onClick={(event) => handleAddCheckbox(event, id)}
                />
                {/* <img
                  src={hdfc}
                  width="48"
                  height="48"
                  className="rounded-3"
                  alt=""
                /> */}
              </div>
            </td>

            <td
              className="action-mid"
              style={{ textAlign: "left", width: "300px" }}
            >
              <h6 className="fs-12 text-sky regular  pb-md-2">
                Legal Entity Name
              </h6>
              <h5>{lead?.legal_entity_name}</h5>
            </td>
            <td
              className="action-mid"
              style={{ textAlign: "left", width: "200px" }}
            >
              <h6 className="fs-12 text-sky regular  pb-md-2">Name</h6>

              <h5>{name}</h5>
            </td>
            <td
              className="action-mid"
              style={{ textAlign: "left", width: "200px" }}
            >
              <h6 className="fs-12 text-sky regular  pb-md-2">Contact</h6>
              <h5>{mobile}</h5>
            </td>
            <td className="action-last">
              <div
                style={{
                  // backgroundColor: 'green',
                  display: "flex",
                  justifyContent: "end",
                }}
                className="d-flex align-items-center gap-xl-3 gap-2"
              >

                <a
                  onClick={() => {
                    setContactId(id);
                    setLogsVisible(true);
                    // setEmailView(true);
                  }}
                  style={{
                    width: '75px'
                  }}
                  className="custom-btn medium sm-btn fs-16 selectContainer px-2"
                  // data-bs-toggle="collapse"
                  // data-bs-target="#mailbox"
                >
                  View Logs
                </a>

                <a
                  onClick={() => {
                    setContactId(id);
                    setEmailView(true);
                  }}
                  className="custom-btn medium sm-btn fs-16 selectContainer"
                  data-bs-toggle="collapse"
                  data-bs-target="#mailbox"
                >
                  Mail
                </a>
                
                {type === "my" && (
                  <a
                    // href=""
                    className="action-icon selectContainer"
                    // data-bs-toggle="modal"
                    // data-bs-target="#reallocated_lead"
                    onClick={() => setRelocateVisible(true)}
                  >
                    <img src={ReplaceIcon} />
                  </a>
                )}

                <RelocateButton
                  type={type}
                  setRelocateVisible={setRelocateVisible}
                />
                <a
                  onClick={() => {
                    // console.log('stg__', data)
                    setStageVisible(true);
                  }}
                  className="action-icon selectContainer"
                >
                  <img src={StageIcon} />
                </a>
                {(RoleRestriction() == "practice" ||
                  RoleRestriction() == "admin" ||
                  RoleRestriction() == "researcher") &&
                  type == "organisation" && (
                    <a
                      // width="20%"
                      className=" bg-red"
                      style={{
                        width: "100px",
                        height: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        textAlign: "center",
                        lineHeight: "20px",
                        color: "white",
                        borderRadius: "10px",
                        padding: "1px 2px",
                      }}
                      onClick={() => setdele(true)}
                    >
                      Deactivate
                      {/* <img src={TrashIcon} /> */}
                    </a>
                  )}
                <a
                  className="acco-info-btn selectContainer"
                  onClick={() => toggleDetail()}
                >
                  <i className="fa-solid fa-angle-down fs-20 align-middle"></i>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {relocateVisible && (
        <RelocateLeadModal
          leadid={leadid}
          contactId={[id]}
          data={data}
          onCancel={() => setRelocateVisible(false)}
        />
      )}

      {isLogsVisible && (
        <ViewLogsModal
        leadid={id}
        setLogsVisible={setLogsVisible}
        />
      )}

      {stageVisible && (
        <Slidedown
          contactId={[id]}
          data={data}
          // onUpdate={handleUpdate}
          onCancel={() => setStageVisible(false)}
          all={false}
        />
      )}
      {detail && <LeadListModal data={data} />}

      {RoleRestriction() != "BD" && dele && (
        // <DeleteModal
        //   disabled={isLoading}
        //   deletename="lead"
        //   onDeleteClick={handleDelete}
        //   onNoClick={() => setdele(false)}
        // />
        <DeactivateModal
          disabled={isLoading}
          deletename="lead"
          onDeleteClick={handleDelete}
          onNoClick={() => setdele(false)}
        />
      )}
      {emailView && (
        <EmailModal
          // heading=""
          contactId={contactId}
          onClose={() => setEmailView(false)}
        />
      )}
    </div>
  );
};

export default SingleLeadList;
