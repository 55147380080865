import React, { useState } from "react";
import UserlistdropdownInput from "../../ui/inputs/UserlistdropdownInput";
import { useForm } from "react-hook-form";
import useUpdateOwner from "../../libs/mutation/leads/useUpdateOwner";
import useUsers from "../../libs/queries/profile/useUsers";

const MultiRelocateModal = ({ onCancel, onUpdate, contactId, leadid, setCheckBoxAdd }) => {
  const [ownerId, setOwnerId] = useState([]);
  const [clicked, setClicked] = useState(false);

  // console.log('relocate2')

  const { data: users } = useUsers();
  const { mutate: changeOwner } = useUpdateOwner(contactId, ownerId, leadid);

  const handleOwnderId = (e) => {
    // console.log('sel_', e.target?.selected)
    setOwnerId([e.target?.value]);
    // if (e.target.selected) {
    //   console.log('ee_', e.target?.value)
    //   setOwnerId([...ownerId, e.target?.value]);
    //   // console.log('own',ownerId);
    // } else {
    //   console.log('ee_2', e.target?.value)
    //   const updatestatecheck = ownerId.filter(
    //     (item) => item !== e.target.value
    //   );
    //   setOwnerId(updatestatecheck);
    // }
  };

  return (
    <div
      className="modaldelete"
      style={{zIndex: "10"}}
      // id="change_stage_all"
    >
      <div
        style={
          {
            // backgroundColor: 'red',
            // width: '750px'
          }
        }
        className="modal-dialog modal-460 modal-dialog-centered"
      >
        <div
          style={
            {
              // backgroundColor: 'green',
              // width: '100%'
            }
          }
          className="modal-content rounded-10 border-0 raleway"
        >
          <div className="modal-header pt-4 pb-0 px-4">
            <h4 className="semibold fs-14 text-sky ">Change Lead Owner</h4>
          </div>
          <hr></hr>
          <div className="modal-body px-md-5 px-4 pb-5 ">
            <p className="">Are you sure to Rellocate Lead for all the field</p>
            <p>Select Allocation</p>
            <select
              onClick={(e) => {
                // setOwnerId(e.target?.value);
                // setClicked(true);
                handleOwnderId(e);
              }}
              className="field-select w-100"
              name="new_owner_ids"
              // { ...register("stage_of_contact") }
            >
              <option value="">Select</option>
              {users?.results?.map((item, index) => {
                return (
                  <>
                    <option
                      className="form-field"
                      key={item?.id}
                      value={item?.id}
                    >
                      {item?.fullname}
                    </option>
                  </>
                );
              })}
            </select>
            <span className="fs-12 text-red">
              {!ownerId && clicked && "Please select owner"}
            </span>
          </div>
          <div
            style={
              {
                // backgroundColor: 'yellow'
              }
            }
            className="modal-footer border-0 px-md-5 px-4 py-4 butn-group justify-content-start"
          >
            <button
              onClick={() => {
                setClicked(true);
                if (ownerId) {
                  changeOwner();
                  onCancel();
                  setCheckBoxAdd([])
                }
                // if(stageId){
                //   onCancel();
                //   changeStage();
                //   localStorage.setItem('contactdata', JSON.stringify(data))
                // }
              }}
              style={{
                marginRight: "15px",
              }}
              type="button"
              className="custom-btn border-0 py-2 rounded-3 raleway medium"
            >
              Update
            </button>
            <button
              onClick={onCancel}
              type="button"
              className="custom-btn close-modal py-2  rounded-3 border-bl-btn px-4 raleway medium"
              // data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiRelocateModal;
