import React, { useState } from "react";
import useGetContactLog from "../../libs/queries/crm/useGetContactLog";
import EditIcon from "../../img/icon/edit-white.svg";
import DeleteIcon from "../../img/icon/trash.svg";
import DeleteModal from "../../ui/modals/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { setmemberId, setmemberType } from "../../slice/CrmSlice";
import useDeleteContactLog from "../../libs/mutation/crm/useDeleteContactLog";
import { Link } from "react-router-dom";
import useGetEscalationMember from "../../libs/queries/crm/useGetEscalationMember";

const LastLogTable = ({ crmid, data , setCheckBoxAdd, checkBoxAdd, search, setSearch }) => {
  const [dele, setdele] = useState(false);
  const dispatch = useDispatch();
  const { memberid, membertype } = useSelector((state) => state.crmSlice);
  // const { data } = useGetContactLog({search: search});
  // const { data } = useGetContactLog({search: search});
  const { mutate: deletecontactlog, isLoading } = useDeleteContactLog({
    id: memberid,
  });
  // console.log(data)
  // this was done for mail in contactlog
  // const { data: getescalationmember } = useGetEscalationMember({
  //   memberType: null,
  //   id: crmid,
  // });

  
  const handleDelete = () => {
    deletecontactlog();
    setdele(false);
  };

  return (
    <div className="bg-white rounded-10 overflow-hidden shadow">
      <table className="w-100 alert-table crm-table">
        <thead>
          <tr>
            <th></th>
            <th className="fs-20 medium text-nowrap">Sr. no.</th>
            <th className="fs-20 medium text-nowrap">Last Contacted Date</th>
            <th className="fs-20 medium text-nowrap">Last Contacted time</th>
            <th className="fs-20 medium text-nowrap">Communication Type</th>
            <th className="fs-20 medium text-nowrap" width="90%">Done By</th>
            <th className="fs-20 medium">Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.results?.map((item, index) => {
            return (
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>{index + 1}</td>
                <td>{item?.last_contacted_date}</td>
                <td>{item?.last_contacted_time}</td>
                <td>{item?.communication_type_display}</td>
                <td>{item?.done_by_display}</td>
                <td className="action-last ">
                  <div className="d-flex align-items-right  gap-xl-3 gap-2">
                    <a
                      // href="#"
                      className="action-icon bg-red"
                      onClick={() =>
                        dispatch(setmemberId(item?.id)) && setdele(true)
                      }
                    >
                      <img src={DeleteIcon} />
                    </a>
                    <Link
                      to={`/crm/lastcontactlogs/view/${crmid}`}
                      onClick={() =>
                        dispatch(setmemberId(item?.id)) &&
                        dispatch(setmemberType(item?.member_type))
                      }
                      className="action-icon bg-sky"
                    >
                      <img src={EditIcon} />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {dele && (
        <DeleteModal
          disabled={isLoading}
          deletename="campaign"
          onDeleteClick={handleDelete}
          onNoClick={() => setdele(false)}
        />
      )}
    </div>
  );
};

export default LastLogTable;
