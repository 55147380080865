import React, { useState } from "react";
import DocumentListEdit from "./DocumentListEdit";
import moment from "moment";

const DocumentListModal = ({ data }) => {
  const [edit, setEdit] = useState(false);
  const {
    name,
    researcher,
    for_client,
    date_of_upload,
    doc_type,
    doc_link,
    description,
    skills,
    doc_category_display,
    for_client_display,
    paid,
    last_view,
    doc_type_display,
    last_campaign,
    file,
    researcher_display,
    doc_category,
    skills_display,
    uploaded_by_display
  } = data || {};

  

  const date = last_view?.substring(0, 10);
  const datefinal = date?.split("T").join(" ");
  const timedate = last_view?.substring(11, 16)
 
  const time = parseInt(timedate); // Convert to an integer

  // Determine whether it's AM or PM based on the time
  const period = time >= 12 ? "PM" : "AM";
  const formattedTime =
    time > 12 ? (time - 12).toString() : time?.toString();


  const campaigndate =  last_campaign?.substring(0, 10);
  const campaignfinal = campaigndate?.split("T").join(" ");
  const campaigntimedate = last_campaign?.substring(11, 16)

  const campaigntime = parseInt(campaigntimedate);

  const campaignperiod = campaigntime >= 12 ? "PM" : "AM";
  const campaignformattedTime =
  campaigntime > 12 ? (campaigntime - 12).toString() : campaigntime?.toString();


  return (
    <>
      {!edit && (
        <div className="bg-white rounded-10 px-md-4 px-3 py-3 shadow-sm mb-3  acco-info-table">
          <table className="action-table w-100">
            <tr>
              <td width="9%"></td>

              <td className="action-mid" width="30%">
                <div className="pb-2">
                  <h6 className="fs-12 text-sky regular  pb-md-1">
                    Research Topic
                  </h6>
                  <h5 className="regular">{name}</h5>
                </div>

                <div className="pb-2">
                  <h6 className="fs-12 text-sky regular  pb-md-1">Doc Type</h6>
                  <h5 className="regular">{doc_type_display}</h5>
                </div>

                {last_view && <div className="pb-2">
                  <h6 className="fs-12 text-sky regular  pb-md-1">Last View</h6>
                  {/* <h5 className="regular">{datefinal} {formattedTime}:{time} {period}</h5> */}
                  <h5 className="regular">{moment(last_view).format('YYYY-MM-DD HH:mm')}</h5>
                </div>}
                <div className="pb-2">
                  <h6 className="fs-12 text-sky regular  pb-md-1">Uploaded By</h6>
                  <h5 className="regular">{uploaded_by_display}</h5>
                </div>
              </td>

              <td className="action-mid" width="30%">
                <div className="pb-2">
                  <h6 className="fs-12 text-sky regular  pb-md-1">
                    Researcher
                  </h6>
                  <h5 className="regular">{researcher}</h5>
                </div>

                <div className="pb-2">
                  <h6 className="fs-12 text-sky regular  pb-md-1">
                    Description
                  </h6>
                  <h5 className="regular">{description}</h5>
                </div>

                {last_campaign && <div className="pb-2">
                  <h6 className="fs-12 text-sky regular  pb-md-1">
                    Last Campaign
                  </h6>
                  <h5 className="regular">{moment(last_campaign).format('YYYY-MM-DD HH:mm')}</h5>
                  {/* <h5 className="regular">{campaignfinal} {campaignformattedTime}:{campaigntime} {campaignperiod}</h5> */}
                </div>}
              </td>

              <td className="action-mid" width="50%">
                <div className="pb-2">
                  <h6 className="fs-12 text-sky regular  pb-md-1">For Cient</h6>
                  <h5 className="regular">{for_client}</h5>
                </div>
                <div className="pb-2">
                  <h6 className="fs-12 text-sky regular  pb-md-1">skills</h6>
                  <h5 className="regular">{skills_display}</h5>
                </div>

                <div className="pb-2">
                  <h6 className="fs-12 text-sky regular  pb-md-1">
                    Date of Upload
                  </h6>
                  <h5 className="regular">{date_of_upload}</h5>
                </div>
              </td>

              <td className="action-last">
                <div className="d-md-flex justify-content-between">
                  <div>
                    <div className="pb-2">
                      <h6 className="fs-12 text-sky regular  pb-md-1">
                        Paid
                      </h6>
                      <h5 className="regular">
                        {paid === false ? "No" : "Yes"}
                      </h5>
                    </div>

                    <div className="pb-2">
                      <h6 className="fs-12 text-sky regular  pb-md-1">
                      Doc Category
                      </h6>
                      <h5 className="regular">{doc_category_display}</h5>
                    </div>

                    <div className="pb-2">
                      <h6 className="fs-12 text-sky regular  pb-md-1">
                        Doc Uploaded
                      </h6>
                      {file === null ? <a className="regular text-darkpink text-decoration-underline fs-16">
                        {doc_link}
                      </a> : <a className="regular text-darkpink text-decoration-underline fs-16">
                        {file}
                      </a>}
                    </div>
                  </div>

                  <div>
                    <a
                      className="action-icon"
                      onClick={() => setEdit(true)}
                      //   data-bs-toggle="collapse"
                      //   data-bs-target="#edit_info_1"
                    >
                      <img src="assets/img/icon/edit-white.svg" />
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      )}

      {edit && (
        <DocumentListEdit data={data} onHide={() => setEdit(false)} />
        // <LeadListEdit
        //   contactdata={data}
        //   leaddata={leaddetailsdata}
        //   onHide={() => setEdit(false)}
        // />
      )}
    </>
  );
};

export default DocumentListModal;
