import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";
import api, { Imageapi, backendURL } from "../../../api/index";
import { toast } from "react-toastify";

const useUpdateDocument = ({ id, onHide }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data) => {

      console.log(data)
      let postForm = new FormData();
      postForm.append("name", data?.name);
      postForm.append("researcher", data?.researcher);
      postForm.append("for_client", data?.for_client);
      postForm.append("date_of_upload", data?.date_of_upload);
      postForm.append("doc_type", data?.doc_type);
      postForm.append("description", data?.description);
      postForm.append("skills", data?.skills);
      // postForm.append("file", data?.file);
      postForm.append("paid", data?.paid);
      postForm.append("doc_category", data.doc_category);

      let res;
      if (typeof data.file === "object") {
        console.log("append");
        postForm.append("file", data.file);
        res = await Imageapi.post(`market/documentfile/update/${id}`, postForm);
      } else {
        console.log("noo");
        delete data.file;
        postForm.append("doc_link", data?.doc_link);
        res = await api.post(`market/documentfile/update/${id}`, postForm);
      }

      // console.log(postForm);
      //  res = await Imageapi.post(`market/documentfile/update/${id}`, postForm);

      return res.data;
    },

    onSuccess: (data) => {
      queryClient.invalidateQueries(`market`, id);
      toast.success(data.message);
      if (onHide) onHide();
    },

    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
  });

  return mutation;
};

export default useUpdateDocument;
