import React from "react";
import { Bar } from "react-chartjs-2";

const DoubleHorizBarChart = ({ data = [], label=[], meetingtarget= [] }) => {

  
  const dataconfig = {
    labels: label?.map((item) => item),
    datasets: [
      {
        label: "Target",
        indexAxis: "y",
        backgroundColor: ["rgba(54, 162, 235, 1)"],
        data: meetingtarget?.map((item) => item),
      },
      {
        label: "Achieved",
        indexAxis: "y",
        backgroundColor: ["rgb(255, 165, 0)"],
        data: data?.map((item) => item),
      }
    ],

    options: {
      scales: {
        x: {
          display: false,
          min: 0,
          max: 10,
        },
      },
      plugins: {
        datalabels: {
          display: true,
          formatter: (value, context) => {
            return value;
          },
          anchor: "end",
          align: "start",
          offset: -20,
        },
      },
    },
  };

  const optionsKg = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        min: 0,
        max: Math.max(...meetingtarget, ...data),
        grid: {
          display: true,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div>
    {(data.length<1  && meetingtarget.length<1) ?
    <div className="my-2 d-flex justify-content-center">No Data</div>
    :
    <Bar
      style={{ height: "200px", width: "200px" }}
      options={optionsKg}
      data={dataconfig}
    />}
    </div>
  );
};

export default DoubleHorizBarChart;
