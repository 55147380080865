import React, { useState } from "react";
import Proposal from "../../../img/proposal.png";
import ProposalIndustry from "../ProposalIndustry";
import ProposalService from "../ProposalService";

const ProposalSent = ({type}) => {
  
  const [industryTab, setIndustryTab] = useState(false);

  return (
    <div className="py-3 px-3 lightblue-grad rounded-3 collapse multi-collapse " id="proposal">
      <div className="d-flex align-items-center justify-content-between pb-3">
        <h3 className="fs-24 medium text-black">Proposals Sent</h3>
        <div className="main-tab nav border-0 gap-2">
          <a
          onClick={() => setIndustryTab(false)}
            // href=""
            // data-bs-toggle="tab"
            // data-bs-target="#industry"
            // id="industry-tab"
            className={`selectContainer custom-btn sm-btn ${!industryTab ? "active" : ""}`}
          >
            Industry
          </a>
          <a
            // href=""
            onClick={() => setIndustryTab(true)}
            // data-bs-toggle="tab"
            // data-bs-target="#service"
            // id="service-tab"
            className={`selectContainer custom-btn sm-btn ${industryTab ? "active" : ""}`}
          >
            Service Offerings
          </a>
        </div>
      </div>

      {!industryTab && <div><ProposalIndustry type={type} /></div>}

      {industryTab && <div><ProposalService type={type}/></div>}
      
    </div>
  );
};

export default ProposalSent;
