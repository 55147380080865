import React from "react";
import DonutChart from "../../ui/graphs/DonutChart";
import useGetServiceOffering from "../../libs/queries/graphs/useGetServiceOffering";
import useGetLeadsAllGraph from "../../libs/queries/graphs/useGetLeadsAllGraph";

const ServiceOffering = ({data}) => {
  // const { data } = useGetLeadsAllGraph();
  const {
    service_offering_data,
    service_offering_color,
    service_offering_label,
  } = data || {};

  return (
    
    <div className="bg-white py-4 px-4 border">
      <div className="card-body">
        <h3 className="mb-3">Service Offering</h3>
        <div className="d-flex justify-content-center">
          {
            service_offering_data?.length>0 ?
          <DonutChart
            datalabel={service_offering_label}
            datacolor={service_offering_color}
            data={service_offering_data}
          />
          :
          <div className='my-4'>
          No Data
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ServiceOffering;
