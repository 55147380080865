import React from 'react'
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import api from '../../../api';

const useNotificationread = ({notification_ids}) => {

    let data = {
        notification_ids: notification_ids
    };
    
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: async () => {
        console.log(data)
          const res = await api.post(`market/notifications/read`, data);
          return res.data;
        },
        onSuccess: (data) => {
          queryClient.invalidateQueries([`notification`]);
          // toast.success(data.message);
        },
        onError: (error) => {
          // toast.error(`${error["response"].data.message}`, {
          //   position: "top-right",
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   theme: "colored",
          // });
        },
        // enabled: id ? true : false,
      });

    return mutation; 
}

export default useNotificationread