import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import api from "../../../api";
import { Endpoints } from "../../../api/Endpoints";


const useSendTo = () =>{
    const getSendTo = useQuery({
        queryKey: ['emailsendto'],
        queryFn: async()=>{
            let resp = await api.get(Endpoints.getSendToValues);
            // if(resp && resp.status){
            const sendToArr = Object.entries(resp.data?.data);
            // }
            return sendToArr;
        }
    })

    return getSendTo;
}


export default useSendTo;
