import React from "react";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";
import { Endpoints } from "../../../api/Endpoints";

const useIndustryPrs = ({ 
  month,
  year,
  userId
}) => {

  const queryParams = {
    month: month || '',
    year: year ||'',
    user_id: userId || ''
  };

  const queryString = Object.keys(queryParams)
      .filter(key => queryParams[key] !== '')
      .map(key => `${key}=${queryParams[key]}`)
      .join('&');
  const queryresult = useQuery({
    queryKey: [`industryprs`, {queryString}],
    keepPreviousData: true,
    queryFn: async () => {
      const res = await api.get(Endpoints.getIndustryListPrs + `?${queryString}`);

      return res?.data;
    },
  });

  return queryresult;
};

export default useIndustryPrs;
