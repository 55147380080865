import React from "react";
import DoubleBarChart from "../../ui/graphs/DoubleBarChart";
import BarChart from "../../ui/graphs/BarChart";

const OnboardedMonth = ({ monthData }) => {
  const { clients_onboarded } = monthData?.data || {};

  // console.log('monthdata__',JSON.stringify(monthData,null,4))

  // const countdata = clients_onboarded?.map((item) => item?.count);
  // const countdata = clients_onboarded?.map((item) => item?.service_offerings?.[0]?.count);
  // const labeldata = clients_onboarded?.map(
  //   // (item) => item?.name
  //   (item) => item?.month
  // );
  // const target = clients_onboarded?.map((item) => item.clients_onboarded_target)

  return (
    <div>
      <BarChart 
      // datalabel={labeldata} 
      // datacolor={['orange']} 
      // data={countdata} 
      dataOnboarded={clients_onboarded}
      />
      {/* <DoubleBarChart data={countdata} label={labeldata} leadtarget={target} /> */}
    </div>
  );
};

export default OnboardedMonth;
