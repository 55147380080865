import React, { useState } from "react";
/// hooks used ///
import { useSelector } from "react-redux";

/// Imports of Component ///
import StageRow from "../../../Components/Manage/NewStage/StageRow";
import FilterRow from "../../../Components/Manage/Leadgeneration/FilterRow";
import LeadGenerationContainer from "../../../Components/Manage/Leadgeneration/LeadGenerationContainer"
import { Endpoints } from "../../../api/Endpoints";
import UserContainer from "../../../Components/Manage/UsersManage/UserContainer";
import UserPlusButton from "../../../Components/Manage/UsersManage/UserPlusButton";

const UsersList = () => {
  
  
//   const { filternamechange } = useSelector((state) => state.leadSlice);
  
  return (
    <div className="main py-4 position-relative">
      <div className="container">
        {/* <FilterRow /> */}
        <div className="row pb-4">
        <UserPlusButton/>
        </div>
        
        <StageRow
        heading="Users"
        url={Endpoints.usergenerationCsv}
        />
        <UserContainer />
      </div>
    </div>
  );
};

export default UsersList;
