import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    alerttype: "",
  };

  const alertSlice = createSlice({
    name: "alertSlice",
    initialState,
    reducers: {
      setalertType: (state, action) => {
        state.alerttype = action.payload;
      }
    },
  });

  export const {  setalertType } = alertSlice.actions;
export default alertSlice.reducer;
  