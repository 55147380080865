import React from "react";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useGetProductivityGraph = ({ 
  graphType, 
  // role, 
  user_id, 
  start_date, 
  end_date 
}) => {
  const queryParams = {
    organisation: graphType === "organisation" ? 1 : "",
    // role: role || "",
    user_id: user_id || '',
    start_date: start_date || '',
    end_date: end_date || ''
  };
  
  const queryString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");
//   console.log(queryString);

  const queryresult = useQuery({
    queryKey: ["graph", { queryString }],
    // keepPreviousData:true,
    queryFn: async () => {
      const res = await api.get(`management/productivityreport?${queryString}`);
    //   console.log(res?.data)
      return res?.data;
    },
  });

  return queryresult;
};

export default useGetProductivityGraph;
