import React from "react";
import PieChart from "../../ui/graphs/PieChart";
import useGetLeadsAllGraph from "../../libs/queries/graphs/useGetLeadsAllGraph";

const ClientSegment = ({
  data
}) => {

  // const { data } = useGetLeadsAllGraph()
  const {business_model_data, business_model_color, business_model_label } = data || {}

  return (
    
    <div className="bg-white py-4 px-4 border ">
      <div className="card-body">
        <h3 className="mb-3">Client Segment</h3>
        <div className="d-flex justify-content-center">
        {
          business_model_data?.length >0 ?
          <PieChart datalabel={business_model_label} datacolor={business_model_color} data={business_model_data} />
          :
          <div className='my-4'>
          No Data
          </div>
        }
        </div>
      </div>
    </div>
  );
};

export default ClientSegment;
