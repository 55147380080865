import React from 'react'
import FilterRow from '../../../Components/Manage/EmailTemplate/FilterRow'
import StageRow from '../../../Components/Manage/NewStage/StageRow'
import EmailTemplateContainer from '../../../Components/Manage/EmailTemplate/EmailTemplateContainer'
import { Endpoints } from '../../../api/Endpoints'
import EmailPlusButton from '../../../Components/Manage/EmailTemplate/EmailPlusButton'

const EmailTemplate = () => {
  return (
    <div className="main py-4 position-relative">
    <div className="container">
      <FilterRow 
      emaiplusbutton={<EmailPlusButton />}
      />
      <StageRow 
      heading="Email Template"
      url={Endpoints.emailTemplatesCsv}
      />
      <EmailTemplateContainer />
    </div>
  </div>
  )
}

export default EmailTemplate