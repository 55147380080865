import React from "react";
import api from "../../../api/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";

const useDeleteStage = (id) => {
  const queryClient = useQueryClient();
  const deleteStage = useMutation({
    mutationFn: async () => {
      const res = await api.get(Endpoints.deleteStage + id);
      return res.data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries("stagelist");
    },
    onError: (error) => {
      toast.error(`${error['response'].data.message}`);
    },
    enabled: id ? true : false
  });

  return deleteStage
};

export default useDeleteStage;
