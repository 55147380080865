import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../api/index";
import { toast } from "react-toastify";
import { Endpoints } from "../../../api/Endpoints";

const useUpdateLastView = ({ id }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await api.get(Endpoints.updateLastView + id);
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([`market`]);
    //   toast.success(data.message);
    },
    onError: (error) => {
      toast.error(`${error["response"].data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    },
    enabled: id ? true : false
  });

  return mutation
};

export default useUpdateLastView;
