import React, { useEffect, useState } from "react";
/// hooks used ///
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

/// StyleSheet import ///
import "../../Styles/auth.css";

/// Icons Or Images import ///
import Emailicon from "../../img/icon/email.svg";
import PasswordIcon from "../../img/icon/key.svg";


/// Imports of Component ///
import useLogin from "../../libs/mutation/auth/useLogin";

export default function LoginForm() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { mutate: login, isLoading } = useLogin();

  const navigate = useNavigate();



  return (
    <>
      <form
        onSubmit={handleSubmit(login)}
        action=""
        className="signup-form px-lg-5"
      >
        {/***  email ***/}
        <div className="form-field mb-3">
          <label className="fs-12 d-block pb-1">E-mail</label>
          <div className="position-relative">
            <img
              src={Emailicon}
              alt=""
              className="position-absolute ms-3  mt-3 pt-1"
              style={{paddingRight: "10px"}}
            />
            <input
              id="email"
              type="email"
              name="email"
              placeholder="Enter your Email ID"
              className="ps-5"
              {...register("email", {
                required: true,
                pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
              })}
            />
            <span className="fs-12 text-red">
              {errors.email?.type === "required" && "Email is Required"}
              {errors.email?.type === "pattern" && "Enter Valid Email"}
            </span>
          </div>
        </div>
        {/***  email ***/}

        {/***  password ***/}
        <div className="form-field mb-3">
          <label className="fs-12 d-block pb-1">Password</label>
          <div className="position-relative">
            <img
              src={PasswordIcon}
              alt=""
              className="position-absolute ms-3 pt-1"
              style={{height: "24px" ,marginTop: "13px"}}
              
            />
            <input
              id="password"
              type="password"
              name="password"
              placeholder="Enter your Password"
              className="ps-5 valid-password"
              {...register("password", {
                required: true,
                maxLength: 60,
                minLength: 6,
              })}
            />
            <span className="fs-12 text-red">
              {errors.password?.type === "required" && "Password is Required"}
              {errors.password?.type === "minLength" &&
                "Entered Password is less than 6 digit"}
              {errors.password?.type === "maxLength" &&
                "Password can't contain more than 60 characters"}
            </span>
          </div>
        </div>
        {/***  password ***/}

        <div className="form-field mb-4">
          <div className="d-flex align-items-center justify-content-between ">
            <div className="custom-checkbox" style={{display: "flex", gap: "10px"}}>
              <input
                id="default-checkbox"
                type="checkbox"
                className="align-middle"
                {...register("remember")}
              />
              <label htmlFor="remeber" className="fs-12 ">
                Remember Me
              </label>
            </div>
            <a
              className="forgot-link fs-10 text-decoration-underline"
              onClick={() => navigate("/forgotpassword")}
              style={{cursor: "pointer"}}
            >
              Forgot Password?
            </a>
          </div>
        </div>

        {/***  buttons ***/}
        <button
          type="submit"
          disabled={isLoading}
          className="custom-btn fs-20 border-0 rounded-pill w-100 py-3 mt-3 mb-3"
        >
          Log In
        </button>
        {/***  buttons ***/}
      </form>
    </>
  );
}
