import React, { useEffect, useState } from "react";
/// Imports of Component ///
import SubRow from "../../Components/dashboard/SubRow";
import CounterContainer from "../../Components/dashboard/CounterContainer";
import MeetingTable from "../../Components/dashboard/MeetingTable";
import DropDownContainer from "../../Components/dashboard/DropDownContainer";
import WidgetButton from "../../Components/dashboard/WidgetButton";

/// StyleSheet import ///
import "../../Styles/header.css";
import "../../Styles/dashboard.css";
import "../../Styles/responsive.css";
import CheckInModal from "../../Components/dashboard/CheckInModal";
import { useDispatch, useSelector } from "react-redux";
import { setCheckedIn } from "../../slice/checkInSlice";
import useProfile from "../../libs/queries/profile/useProfile";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";

const Dashboard = () => {
  const [type, setType] = useState("my");
  const { checkedIn } = useSelector((state) => state.checkInSlice);
  const dispatch = useDispatch();

  const { data: profileData } = useProfile();

  //

  const [checkVisible, setCheckVisible] = useState(
    !checkedIn.length ? true : false
  );

  useEffect(() => {
    if (checkedIn.length) {
      setCheckVisible(false);
    } else {
      setCheckVisible(true);
    }
  }, [checkedIn]);

  return (
    <>
      <div className="main py-4  dashboard-bg position-relative">
        <div className="container">
          {/* <SubRow
          titleone="My Dashboard"
          titletwo="Organisation Dashboard"
          widget={<WidgetButton />}
        /> */}
          <Subheader
            leftsideshow={
              <TwoButtonHolder
                label={"Dashboard"}
                onSelect={setType}
                type={type}
              />
            }
          />
          <CounterContainer type={type} />
          <div className="row pt-3">
           {type === "my" ? <div className="col-md-6 pb-md-0 pb-4">
              <MeetingTable />
            </div> : ""}
            <div className="col-md-6">
              <DropDownContainer type={type} />
            </div>
          </div>
        </div>
      </div>
      {checkVisible && <CheckInModal onClose={() => setCheckVisible(false)} />}
    </>
  );
};

export default Dashboard;
