import React from "react";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

const useGetAgreementGraph = ({ 
  graphType,
  contactDepartment,
  cvValidity,
  agreementTenure,
  agreementApplicable,
  serviceOffer,
  clientCategory,
  role,
  signing,
  clientName,
  user_id,
  start_date,
  end_date
}) => {
  const queryParams = {
    organisation: graphType === "organisation" ? 1 : '',
    contact_level: contactDepartment || '',     
    cv_validity: cvValidity || '',
    agreement_tenure: agreementTenure || '',
    agreement_applicability: agreementApplicable || '',
    service_offering: serviceOffer || '',
    category_of_client: clientCategory  || '',
    role: role || "",
    signing_mode: signing || "",
    client_name: clientName || "",
    user_id: user_id || '',
    start_date: start_date || '',
    end_date: end_date || ''
  };

  const queryString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== '') // Filter out keys with no value
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");

  const queryresult = useQuery({
    queryKey: ["graph1", { queryString }],
    // keepPreviousData:true,
    queryFn: async () => {
      const res = await api.get(`management/agreementreport?${queryString}`);
      
      return res?.data;
    },
  });

  return queryresult;
};

export default useGetAgreementGraph;
