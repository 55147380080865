import React from 'react'
import PieChart from '../../ui/graphs/PieChart'

const ServiceDay = ({dayData}) => {

    const {proposal_sent_leads_by_service_offering} = dayData?.data || {}
    const datalabel = proposal_sent_leads_by_service_offering?.map((item) => item?.name)
    const data = proposal_sent_leads_by_service_offering?.map((item) => item?.count)
    const color = proposal_sent_leads_by_service_offering?.map((item) => item?.color)

  return (
    <div>
      {proposal_sent_leads_by_service_offering?.length<1 && 
      <div className='d-flex justify-content-center mt-2'>
        No Data
      </div>
      }
      <PieChart datalabel={datalabel} data={data} datacolor={color} /></div>
  )
}

export default ServiceDay