import React from 'react'
import MarketGenerationContainer from '../../../Components/Manage/MarketIntGeneration/MarketGenerationContainer'
import FilterRow from '../../../Components/Manage/MarketIntGeneration/FilterRow'
import { useSelector } from 'react-redux';
import StageRow from '../../../Components/Manage/NewStage/StageRow';
import { Endpoints } from '../../../api/Endpoints';

const MarketGeneration = () => {

    const { marketgennamechange } = useSelector(
        (state) => state.marketIntGenerationSlice
      );

      const { marketgenid } = useSelector(
        (state) => state.marketIntGenerationSlice
      );

  return (
    <div className="main py-4 position-relative">
    <div className="container">
      <FilterRow />
      <StageRow
      heading={marketgennamechange}
      url={Endpoints.dropdownCsv(marketgenid)}
      />
      <MarketGenerationContainer />
      {/* <Pagination 
      onPageChange={SetPageToLoad}
      totalPages={10}
      /> */}
      {/* <LeadGenerationContainer /> */}
    </div>
  </div>
  )
}

export default MarketGeneration