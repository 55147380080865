import React, { useEffect, useState } from "react";

const BadgeInput = ({ value = [], onChange, isCalendar, placeholder }) => {
  const [data, setdata] = useState(Array.isArray(value) ? value : []);
  const [email, setEmail] = useState("");
  const emailregex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;

  const handleKeyDown = (event) => {
    let newValue = email.trim();
    if (emailregex.test(newValue) && event.code == "Space") {
      setdata([...data, newValue]);
      setEmail("");
    }
  };

  const handleRemove = (index) => {
    setdata((previous) => {
      let updateData = [...previous];
      updateData.splice(index, 1);
      return updateData;
    });
  };
  //   useEffect(() => {
  //     if (Array.isArray(value)) {
  //       setdata(value);
  //     }
  //   }, [value]);

  useEffect(() => {
    onChange(data);
  }, [data]);
  //  console.log(data)

  const handleEmail=()=>{
    let newValue = email.trim();
    if (emailregex.test(newValue)) {
      setdata([...data, newValue]);
      setEmail("");
    }
  }
  return (
    <div  className="d-flex align-items-center bg-white p-0 m-0 gap-2 flex-wrap">
      <div className="d-flex align-items-center gap-2 flex-wrap">
        {data?.map((item, index) => {
          return (
            <>
              <div className="rounded-pill border px-2 py-1">
                {item} <span style={{cursor: "pointer"}} onClick={() => handleRemove(index)}>x</span>
              </div>
            </>
          );
        })}
      </div>

      <input
        placeholder={isCalendar?placeholder:""}
        value={email}
        onKeyDown={(event) => handleKeyDown(event)}
        onChange={(event) => setEmail(event.target.value)}
        className={isCalendar? "shadow-none border-0 border-bottom" : "border-0 outline-0"}
        onBlur={handleEmail}
      />
    </div>
  );
};

export default BadgeInput;
