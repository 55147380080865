import { Outlet } from "react-router-dom";
import Alerts from "../Pages/Alerts/Alerts";
import ProductivityViewmore from "../Pages/Alerts/ProductivityViewmore";
import NoFollowUpViewmore from "../Pages/Alerts/NoFollowUpViewmore";
import AgreementRenewalViewmore from "../Pages/Alerts/AgreementRenewalViewmore";

const alertsRouter = {
  path: "/alerts/",
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <Alerts />,
    },
    {
      path: "productivity",
      element: <ProductivityViewmore />,
    },
    {
      path: "nofollowup",
      element: <NoFollowUpViewmore />,
    },
    {
      path: "agreementpendingrenewal",
      element: <AgreementRenewalViewmore />,
    },
  ],
};

export default alertsRouter;
