import React from "react";

const Subheader = ({ leftsideshow, rightsideshow, additionalbutton }) => {
  return (
    <div className="row pb-4">
      {leftsideshow}
      <div className="col-sm-6 text-sm-end">{additionalbutton}{rightsideshow}</div>
    </div>
  );
};

export default Subheader;
