import React, { useState } from "react";
import Subheader from "../../ui/modals/Subheader";
import TwoButtonHolder from "../../ui/modals/TwoButtonHolder";
import PlusButton from "../../ui/buttons/PlusButton";
import Heading from "../../ui/modals/Heading";
import SingleLeadList from "../../Components/leads/SingleLeadList";
import useGetLead from "../../libs/queries/leads/useGetLead";
import Pagination from "../../ui/pagination";
import useGetLeadListContact from "../../libs/queries/leads/useGetLeadListContact";
import useGetStageList from "../../libs/queries/StageApi/useGetStageList";
import { useLocation, useParams } from "react-router-dom";
import Slidedown from "../../Components/Manage/Slidedown";
import MultiRelocateModal from "../../Components/leads/MultiRelocateModal";
import BulkDeleteButton from "../../ui/buttons/BulkDeleteButton";
import DeleteModal from "../../ui/modals/DeleteModal";
import useDeleteContact from "../../libs/mutation/leads/useDeleteContact";
import { toast } from "react-toastify";
import useProfile from "../../libs/queries/profile/useProfile";
import LeadSort from "../../ui/dropdown/LeadSort";
import RoleRestriction from "../../utils/roleRestriction";
import { useEffect } from "react";
import { Endpoints } from "../../api/Endpoints";
import MultiRelocateButton from "../../Components/leads/MultiRelocateButton";
import Search from "../../ui/search/Search";
import BulkRelocateButton from "../../ui/buttons/BulkDeactivateButton";
import BulkDeactivateButton from "../../ui/buttons/BulkDeactivateButton";
import DeactivateModal from "../../ui/modals/DeactivateModal";
import { useSelector } from "react-redux";

const LeadList = () => {
  const location = useLocation();
  const [pageToLoad, SetPageToLoad] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [multistageVisible, setMultiStageVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [type, setType] = useState(location.state?.view || "my");

  const [filterBy, SetFilterBy] = useState("");
  const [businessModalId, SetBusinessModalId] = useState("");
  const [industrySectorId, SetIndustrySectorId] = useState("");
  const [sourceId, setSourceId] = useState("");
  const [potentialId, setPotentialId] = useState("");
  const [isFortune, setIsFortune] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [multirelocateVisible, setMultiRelocateVisible] = useState(false);
  const [checkBoxAdd, setCheckBoxAdd] = useState([]);
  const [proposalStartDate, setProposalStartDate] = useState("");
  const [proposalEnd, setProposalEnd] = useState("");
  const [followStart, setFollowStart] = useState("");
  const [followEnd, setFollowEnd] = useState("");
  const [dele, setdele] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [leadOwner, setLeadOwner] = useState("");
  // const { data } = useGetLead(pageToLoad, itemsPerPage, leadType);
  const { data: getstagelist } = useGetStageList();
  const { data: userprofile } = useProfile();
  // console.log("checkStageIds", checkBoxAdd);

  const { id } = useParams();
  const headingData = getstagelist?.results.filter((item, index) => {
    return item.id == id ? item.name : "";
  })[0];
  const { navigatesource } = useSelector((state) => state.leadviewSlice);
  console.log('startdt__', startDate);
  console.log('Enddt__', endDate);

  const { data: contactlist } = useGetLeadListContact({
    id: id,
    page: pageToLoad,
    itemsPerPage: itemsPerPage,
    leadType: type,
    filterBy: filterBy,
    businessModel: businessModalId,
    industrySector: industrySectorId,
    sourceId: sourceId,
    potentialId: potentialId,
    isFortune: isFortune,
    startDate,
    endDate,
    search: search,
    proposal_date_start: proposalStartDate,
    proposal_date_end: proposalEnd,
    follow_up_date_start: followStart,
    follow_up_date_end: followEnd,
    leadOwner: leadOwner,
  });
  const { mutate: deletecontact, isLoading } = useDeleteContact();

  const handleModal = () => {
    setdele(true);
  };

  const deleteCheckboxitem = () => {
    // console.log("this is checkbox", checkBoxAdd);
    if (checkBoxAdd.length > 0) {
      let payload = { leadcontacts: checkBoxAdd };
      deletecontact(payload);
      setdele(false);
    } else {
      toast.error("Please select a leadContact");
      setdele(false);
    }
  };
  // console.log('locstt2_', location.state?.view)
// console.log("this is window",window.location.pathname)
  useEffect(() => {
    // console.log('inuseffect__', location.state?.view)
    setType(location.state?.view);
  }, [location.state?.view]);

  // console.log('locsttyppppeee_______', type);
  // console.log('nsource_____', navigatesource)

  const vio = () => {
    if(navigatesource){
      setType(navigatesource);
    }
    
  };

  useEffect(() => {
    if(navigatesource){
      vio();
    }
  }, [navigatesource]);

  const queryParams = {
    stage_id: id || '',     
    download:1,
    organisation: type==='organisation'?1:'',
    filter_by: filterBy || '',
    business_model: businessModalId || '',
    industry_sector: industrySectorId || '',
    source: sourceId || '',
    potential_service_offering: potentialId || '',
    fortune_1000: isFortune || '',
    source_date_start: startDate  || '',
    source_date_end: endDate || '',
    lead_owner: leadOwner || '',
    search_key: search || '',
    proposal_date_start: proposalStartDate || '',
    proposal_date_end: proposalEnd || '',
    follow_up_date_start: followStart || '',
    follow_up_date_end: followEnd || '',
  };

  const queryString = Object.keys(queryParams)
      .filter(key => queryParams[key] !== '')
      .map(key => `${key}=${queryParams[key]}`)
      .join('&');

  return (
    <div className="main py-4 position-relative">
      <div className="container">
        <Subheader
          leftsideshow={
            <TwoButtonHolder
              onSelect={
               
                   setType
              }
              label={"Leads"}
            />
          }
          additionalbutton={
            <BulkDeactivateButton onClick={() => handleModal()} type={type} />
            // (RoleRestriction() == "practice" ||
            //   RoleRestriction() == "admin" ||
            //   RoleRestriction() == "researcher") &&
            // type == "organisation" && (
            //   <BulkDeactivateButton onClick={() => handleModal()} />
            //   // <BulkDeleteButton onClick={() => handleModal()} />
            // )
          }
          rightsideshow={
            userprofile?.role_display === "Enabling Function" ? (
              ""
            ) : (
              <PlusButton name="New Lead" navto="/leads/new" />
            )
          }
        />
        {dele && (
          // <DeleteModal
          //   disabled={isLoading}
          //   deletename="leadcontact"
          //   onDeleteClick={deleteCheckboxitem}
          //   onNoClick={() => setdele(false)}
          // />
          <DeactivateModal
            disabled={isLoading}
            deletename="leadcontact"
            onDeleteClick={deleteCheckboxitem}
            onNoClick={() => setdele(false)}
          />
        )}
        <Heading
          search={<Search onSearch={setSearch} />}
          url={
            Endpoints.getLeadListContact + `?${queryString}`
            // "?" +
            // `stage_id=${id}&download=1&organisation=${
            //   type == "organisation" ? 1 : ""
            // }`
          }
          isDele={dele}
          heading={headingData?.name}
          showSortt={setIsOpen}
          // onSearch={setSearch}
          sortComponent={
            <LeadSort
              onBusinessModalSelect={SetBusinessModalId}
              onIndustrySectorSelect={SetIndustrySectorId}
              onPotentialSelect={setPotentialId}
              onSourceSelect={setSourceId}
              onFortuneSelect={setIsFortune}
              onStartDateSelect={setstartDate}
              onEndDateSelect={setEndDate}
              onProposalStart={setProposalStartDate}
              onProposalEnd={setProposalEnd}
              onFollowStart={setFollowStart}
              onFollowEnd={setFollowEnd}
              onUserSelect={setLeadOwner}
              showSort={isOpen}
              type={type}
            />
          }
        />
        <div>
          <div className="d-flex justify-content-between">
            <Pagination
              onPageChange={SetPageToLoad}
              onItemsChanged={setItemsPerPage}
              totalPages={Math.ceil(contactlist?.count / itemsPerPage)}
              defaultCount={itemsPerPage}
            />
            {contactlist?.results?.length > 0 && (
              <div className="d-flex  gap-4 align-self-center">
                {/* <a
                href="#"
                className="custom-btn medium sm-btn fs-16"
                onClick={() => setMultiRelocateVisible(true)}
              >
                Relocate Lead
              </a> */}
                {type === "my" && (
                  <a
                    href="#"
                    className="custom-btn medium sm-btn fs-16"
                    onClick={() => setMultiRelocateVisible(true)}
                  >
                    Reallocate Lead
                  </a>
                )}

                <MultiRelocateButton
                  type={type}
                  setMultiRelocateVisible={setMultiRelocateVisible}
                />

                <a
                  // href="#"
                  className="custom-btn medium sm-btn fs-16 selectContainer"
                  onClick={() => setMultiStageVisible(true)}
                >
                  Change Stage
                </a>
              </div>
            )}
            {multistageVisible && (
              <Slidedown
                onCancel={() => setMultiStageVisible(false)}
                all={true}
                contactId={checkBoxAdd}
                data={
                  contactlist?.results?.filter?.(
                    (item) => item?.id === checkBoxAdd?.[0]
                  )?.[0]
                }
                setCheckBoxAdd={setCheckBoxAdd}
              />
            )}

            {multirelocateVisible && (
              <MultiRelocateModal
                leadid={id}
                contactId={checkBoxAdd}
                onCancel={() => setMultiRelocateVisible(false)}
                setCheckBoxAdd={setCheckBoxAdd}
              />
            )}
          </div>

          {contactlist &&
            contactlist?.results.map((item, index) => {
              return (
                <SingleLeadList
                  data={item}
                  key={index}
                  type={type}
                  leadid={id}
                  checkBoxAdd={checkBoxAdd}
                  setCheckBoxAdd={setCheckBoxAdd}
                />
              );
            })}

          {!contactlist?.results?.length && (
            <div
              style={{
                textAlign: "center",
              }}
              className="mt-5"
            >
              No Data Found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeadList;
