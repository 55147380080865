import React from "react";
import RoleRestriction from "../../utils/roleRestriction";

const BulkDeactivateButton = ({ onClick, disabled, type }) => {
  const bulkdeactivate = ["practice", "admin", "researcher"];

  return (
    <>
      {bulkdeactivate.includes(RoleRestriction()) && type == "organisation" && 
        <button
          type="button"
          style={{ color: "#fff" }}
          className="custom-btn close-modal bg-red border-0 py-2 mx-3 rounded-11"
          onClick={onClick}
          disabled={disabled}
        >
          Bulk Deactivate
        </button>
      }
    </>
  );
};

export default BulkDeactivateButton;
