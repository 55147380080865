import { useState, useEffect } from "react";
import { setSearchSlice } from "../slice/searchSlice";
import { useDispatch } from "react-redux";

const parseJson = (data) => {
  
  try {
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
};

const stringifyJson = (data) => {
  try {
    if(typeof data === "string"){
      return data
    }
    return JSON.stringify(data);
  } catch (error) {
    return null;
  }
};

const useLocalStorage = (name, initialValue) => {
  const dispatch = useDispatch();
  let data = localStorage.getItem(name);
  data = parseJson(data);
  const [value, setValue] = useState(data || initialValue);

  const updater = (data) => {
   localStorage.setItem(name, stringifyJson(data));
  //  if(name=='globalsearch'){
    dispatch(setSearchSlice(data));
  //  }
    setValue(data)
  };


//   useEffect(() => {
//     console.log(value, initialValue);
//     localStorage.setItem(name, stringifyJson(value));
//   }, [name, value]);

  return [value, updater];
};

export default useLocalStorage;
