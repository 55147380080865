import React from "react";
import LeadGenerationForm from "./LeadGenerationForm";
import { useSelector } from "react-redux";

const NewLeadGenerationContainer = () => {

  const { filternamechange } = useSelector((state) => state.leadSlice);

  return (
    <div className="pt-4">
      <div className="rounded-10 overflow-hidden">
        <div className="widget-header px-3 py-3 bg-sky">
          <h3 className="bold text-white fs-18">New {filternamechange} Generation</h3>
        </div>
        <LeadGenerationForm />
      </div>
    </div>
  );
};

export default NewLeadGenerationContainer;
